import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';

/**
 * Simple memoization variables to speed up the below methods
 */
const cachedUrl: Record<string, string> = {};
let domain: string;
/**
 * Be sure to add to this array when new sites and resources are added
 */
const keys = ['account', 'admin', 'warehouse', 'order', 'yard', 'api'];

/**
 * Determines the url of other resources based on the current host
 * When called from a text url, replaces current address identifier (e.g. account) with target's identifier
 * When called from an ip url, replaces the port (e.g. 4201) with target's port
 * Works for all resources listed above in the [keys] variable. For this reason,
 * words contained in the above array are reserved and cannot be used for any routes on angular
 */
export function getUrl(link: string): string {
  if (link === 'api' && environment.apiUrl) {
    return environment.apiUrl;
  }
  if (cachedUrl[link]) {
    return cachedUrl[link];
  }
  const currentUrl = window.origin;
  const port = environment.webPort?.[link] ?? 3300;

  const linkUrl = link === 'idm' ? 'account' : link;

  const regEx = new RegExp(`(${keys.join('|')})\\.`);

  cachedUrl[link] = currentUrl.replace(/:\d+/, `:${port}`).replace(regEx, `${linkUrl}.`);
  return cachedUrl[link];
}

/**
 * Gets the current web domain based on current url
 * It strips ports, identifiers listed above in [keys] and https/https:// from current address
 */
export function getWebDomain(): string {
  if (domain) {
    return domain;
  }
  const regEx = new RegExp(`.*(${keys.join('|')})\\.`);
  domain = window.origin
    .replace(/https?:\/\//, '')
    .replace(/:\d+/, '')
    .replace(regEx, '');
  return domain;
}
