<form class="form-group update-quantity-form" [formGroup]="form">
  <input matInput
         type="number"
         id="quantityLoaded"
         name="quantityLoaded"
         formControlName="quantityLoaded"
         class="form-control update-quantity-input"
         [max]="max"
         [min]="1"
  >
<!--  <span *ngIf="form.controls.quantityLoaded.errors?.exceedingLoaded">   // <-&#45;&#45; invalidNumber is the key-->
<!--      Amount cannot exceed max loaded-->
<!--  </span>-->
<!--  <span *ngIf="form.controls.quantityLoaded.errors?.invalidNumber">   // <-&#45;&#45; invalidNumber is the key-->
<!--      Age should be a number-->
<!--  </span>-->
</form>
