import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { InventoryContainer, LoadAllocation } from '@ov-suite/models-warehouse';
import { UserModel } from '@ov-suite/models-idm';
import { PickingItemModel } from './picking-item.model';
import { Inventory } from './inventory.model';

@OVEntity({ name: 'PickingItemLog' })
export class PickingItemLogModel {
  @OVField({
    type: 'number',
    generated: true,
  })
  id: number;

  @OVField({
    type: () => PickingItemModel,
  })
  pickingItem: PickingItemModel;

  @OVField({
    type: () => LoadAllocation,
  })
  load: LoadAllocation;

  @OVField({
    type: 'number',
  })
  quantity: number;

  @OVField({
    type: 'date',
  })
  createdDate: Date;

  @OVField({
    type: 'date',
  })
  completedDate?: Date;

  @OVField({
    type: () => UserModel,
  })
  handledByUser?: UserModel;

  @OVField({
    type: 'string',
  })
  exceptionMessage?: string;

  @OVField({
    type: () => InventoryContainer,
  })
  containerPicked: InventoryContainer;

  @OVField({
    type: () => [Inventory],
  })
  inventoryPicked: Inventory[];

  @OVField({
    type: () => InventoryContainer,
  })
  source: InventoryContainer;

  @OVField({
    type: () => InventoryContainer,
  })
  target: InventoryContainer;

  @OVField({
    type: 'string',
  })
  comment: string;
}
