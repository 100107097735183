import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';
import { MasterRouteAddEditComponent } from './add-edit/master-route-add-edit.component';
import { MasterRouteListComponent } from './list/master-route-list.component';

export const MasterRouteRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: FeatureKey.ROUTES } },
        canActivate: [AuthGuard],
        path: 'add',
        component: MasterRouteAddEditComponent,
      },
      {
        data: { feature: { id: FeatureKey.ROUTES } },
        canActivate: [AuthGuard],
        path: 'edit',
        component: MasterRouteAddEditComponent,
      },
      {
        data: { feature: { id: FeatureKey.ROUTES } },
        canActivate: [AuthGuard],
        path: '',
        component: MasterRouteListComponent,
      },
    ],
  },
];
