/* eslint-disable new-cap */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OvAutoService } from '@ov-suite/services';
import { getCreate, getUpdate } from '@ov-suite/graphql-helpers';
import {
  CompiledFieldData,
  Constructor,
  FieldMetadata,
  GenericHierarchy,
  getCompiledSidebarFieldMetadata,
  getFieldMetadata,
  getTypeMetadata,
  isFieldParamsConstructor,
} from '@ov-suite/ov-metadata';
import { DomainService } from '@ov-suite/helpers-angular';
import { FormComponent } from '../form/form.component';
import { cloneDeep } from 'lodash-es';

interface DataSources<T extends GenericHierarchy = { id: number | string }> {
  [key: string]: T[] | OvAutoService | unknown;
}

type GenericHierarchyType = GenericHierarchy;

export interface Status {
  id: number;
  title: string;
  color?: string;
}

@Component({
  selector: 'ov-suite-create-or-edit',
  templateUrl: './create-or-edit.component.html',
  styleUrls: ['./create-or-edit.component.scss'],
})
export class CreateOrEditComponent<T extends GenericHierarchyType> implements OnInit {
  @Input() ovAutoService: OvAutoService;

  @Input() formClass: Constructor<T>;

  private _data: T;

  private original: T;

  loading = false;

  set data(item: T) {
    this.original = cloneDeep(item);
    this._data = item;
    this.assignDataValues();
  }

  get data() {
    return this._data;
  }

  metadata: FieldMetadata<T>;

  sidebarFields: CompiledFieldData[][] = [];

  @Input() public title: string;

  @Input() public statuses: Status[];

  @Output() public save = new EventEmitter<T>();

  @Output() public delete = new EventEmitter();

  @Output() public edit = new EventEmitter();

  @Input() public dataSources: DataSources<GenericHierarchy> = {};

  @Output() public createSuccess = new EventEmitter<T>();

  @Output() public deleteSuccess = new EventEmitter<T>();

  @Output() public updateSuccess = new EventEmitter<T>();

  @ViewChild('form') form: FormComponent;

  loadMap: Record<string, unknown> = {};

  constructor(public route: ActivatedRoute, private readonly domainService: DomainService) {}

  ngOnInit() {
    this.metadata = getFieldMetadata(this.formClass);
    this.sidebarFields = getCompiledSidebarFieldMetadata(this.formClass);
    this.assignDataValues();

    this.route.queryParamMap.subscribe(response => {
      if (response.has('_parentId')) {
        this.data = new this.formClass();
        this.data.parent = { id: Number(response.get('_parentId')) };
      } else if (response.has('id')) {
        const id = Number(response.get('id'));
        this.ovAutoService.get(this.formClass, id).then(item => {
          this.data = item;
        });
      } else {
        this.data = new this.formClass();
      }
    });
  }

  assignDataValues() {
    if (this.data) {
      this.fieldForEach(data => {
        if (this.data[data.propertyKey]) {
          data.value = this.data[data.propertyKey];
        }
      });
    }
  }

  private fieldForEach(callback: (data: CompiledFieldData) => void) {
    if (this.sidebarFields) {
      this.sidebarFields.forEach(row => {
        row.forEach(column => {
          callback(column);
        });
      });
    }
  }

  private async fieldForEachAsync(callback: (data: CompiledFieldData) => void): Promise<void> {
    if (this.sidebarFields) {
      for (const row of this.sidebarFields) {
        for (const column of row) {
          await callback(column);
        }
      }
    }
  }

  public onDelete(): void {
    // this.delete.emit();
    this.loading = true;
    this.ovAutoService
      .delete(this.formClass, this.data.id)
      .then(() => {
        this.delete.emit();
        this.deleteSuccess.emit(this.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public onCancel(): void {
    window.history.back();
  }

  public async onSave(): Promise<T | undefined> {
    let proceed = true;
    const formData = await this.form.submit(true);
    proceed = !!formData;

    const output = <T>formData ?? this.data ?? new this.formClass();
    let requiredFlag = false;

    const { validateField } = this;

    async function validate(data: CompiledFieldData): Promise<void> {
      if (data.propertyKey) {
        await validateField(data);
        if (data.danger) {
          requiredFlag = true;
        }
        if (!data.readonly) {
          output[data.propertyKey] = data.value;
        }
      }
    }

    const tryParseDomains = (original: unknown, _data: unknown): T => {
      if (original['domains']) {
        if (this.metadata.mapDomainsToIds) {
          _data['domainsIdList'] = original['domains'].filter(d => !!d).map(domain => domain.id);
          return _data as T;
        }
        _data['domainsIdList'] = original['domains'].filter(d => !!d).map(domain => domain.path);
        return _data as T;
      }
      return _data as T;
    };

    await this.fieldForEachAsync(async data => validate(data));

    if (!requiredFlag && proceed) {
      this.loading = true;
      if (output.id) {
        this.ovAutoService
          .update({ entity: this.formClass, item: tryParseDomains(this.data, getUpdate(output, this.original)) })
          .then(res => {
            this.updateSuccess.emit(res as T);
            this.save.emit(res as T);
          })
          .catch(res => console.error(res))
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.ovAutoService
          .create(this.formClass, tryParseDomains(this.data, getCreate(output)))
          .then(res => {
            this.createSuccess.emit(res);
            this.save.emit(res);
          })
          .catch(res => console.error(res))
          .finally(() => {
            this.loading = false;
          });
      }
      return output;
    }
  }

  validateField = async (data: CompiledFieldData) => {
    let danger = false;
    // if (data.validator) {
    //   const [valid, errorMessage] = await data.validator(data, null);
    //   if (!valid) {
    //     danger = true;
    //   }
    //   data.currentErrorMessage = errorMessage;
    // }
    if (data.required && (data.value ?? '') === '') {
      danger = true;
    }

    data.danger = danger;
  };

  getDataSource(data: CompiledFieldData): unknown {
    if (this.dataSources && this.dataSources[ data.propertyKey ]) {
      return this.dataSources[ data.propertyKey ];
    }
    if (this.loadMap[ data.propertyKey ] && this.loadMap[ data.propertyKey ] !== 'loading') {
      return this.loadMap[ data.propertyKey ];
    }
    if (!this.loadMap[ data.propertyKey ]) {
      if (isFieldParamsConstructor(data)) {
        this.loadMap[ data.propertyKey ] = 'loading';

        if (data.withQuantity) {
          const dataType = data.subType;
          const keys = data.keys.filter(key => key.startsWith(data.quantityKey)).map(key => key.slice(data.quantityKey.length + 1))
          const { entity: remoteEntity } = getTypeMetadata(dataType);
          this.ovAutoService
            ?.list({
              entity: remoteEntity,
              limit: 1000,
              keys,
            })
            .then(response => {
              this.loadMap[ data.propertyKey ] = response.data;
            });
        } else {
          const dataType = data.type;
          const { entity: remoteEntity } = getTypeMetadata(dataType);
          this.ovAutoService
            ?.list({
              entity: remoteEntity,
              limit: 1000,
              keys: data.keys
            })
            .then(response => {
              this.loadMap[ data.propertyKey ] = response.data;
            });
        }
      }
    }
    return [];
  }
}
