import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { LoadAllocation, LoadAmountModel, LoadDetailModel } from '@ov-suite/models-warehouse';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { OrderModel } from '@ov-suite/models-order';
import { MutationResult } from 'apollo-angular';
import gql from 'graphql-tag';

/**
 * This Service is used for Fetching, Manipulating and Saving Data.
 *
 * This should be the only service on invoice-resolution with ovAutoService. Please be sure to manage observables correctly
 */

interface InvoiceAdjustInput {
  itemCode: string;
  value: number;
  orderNo: string;
}

export interface OrderInvoiceTotal {
  orderId: number;
  orderNo: string;
  ordered: number;
  loadDetailIds: number[];
  loadedQuantity: number;
  hasConflict?: boolean;
  lineItems?: OrderInvoiceLineTotal[];
}

export interface OrderInvoiceLineTotal {
  skuId: number;
  itemCode: string;
  itemName: string;
  ordered: number;
  loadedQuantity: number;
  hasConflict?: boolean;
  orderNo: string;
}

@Injectable()
export class InvoiceResolutionDataService {
  loading = false;

  fetchData = _.debounce(this._fetchData, 500, { trailing: true });

  orders: BehaviorSubject<OrderModel[]> = new BehaviorSubject<OrderModel[]>([]);

  amounts = new BehaviorSubject<LoadAmountModel[]>([]);

  $invoices: BehaviorSubject<OrderInvoiceTotal[]> = new BehaviorSubject<OrderInvoiceTotal[]>([]);

  loadId: number;

  constructor(private readonly ovAutoService: OvAutoService) {}

  onInit(id: number) {
    this.loadId = id;
    // this.fetchData(id);
  }

  public _fetchData(id: number): void {
    this.loading = true;
    const loadPromise = this.ovAutoService.get({
      id,
      entity: LoadAllocation,
      keys: [
        'details.id',
        'details.title',
        'details.value',
        'details.orderId',
        'orders.id',
        'orders.specialInstructions',
        'orders.fulfilmentDate',
        'orders.orderDate',
        'orders.completedDate',
        'orders.approvalDate',
        'orders.orderCode',
        'orders.priority',
        'orders.customerId',
        'orders.status',
        'orders.originalOrderId',
        'orders.isSplit',
        'orders.alias',
        'orders.syncDate',
        'orders.integrationException',
        'orders.integrationId',
        'orders.salesRepId',
        'orders.createDate',
        'orders.deliveryAddress.line1',
        'orders.deliveryAddress.line2',
        'orders.deliveryAddress.line3',
        'orders.deliveryAddress.line4',
        'orders.deliveryAddress.line5',
        'orders.deliveryAddress.line6',
        'orders.deliveryAddress.postalCode',
        'orders.deliveryAddress.geography',
        'orders.orderItems.id',
        'orders.orderItems.quantity',
        'orders.orderItems.productSkuId',
        'orders.orderItems.unitPriceExcl',
        'orders.orderItems.unitPriceIncl',
        'orders.orderItems.productSku.id',
        'orders.orderItems.productSku.sku',
        'orders.orderItems.productSku.name',
        'orders.orderItems.productSku.category.id',
        'orders.orderItems.productSku.category.path',
      ],
    });

    const amountPromise = this.ovAutoService.list({
      entity: LoadAmountModel,
      limit: 1000,
      relations: ['productSku'],
      query: {
        loadId: [id],
      },
    });

    this.loading = true;

    Promise.all([loadPromise, amountPromise]).then(values => {
      const [load, amounts] = values;
      this.loading = false;
      this.orders.next(load.orders);
      this.amounts.next(amounts.data);
      this.initializeData(load.orders, amounts.data, load.details);
    });
  }

  initializeData(orders: OrderModel[], amounts: LoadAmountModel[], details: LoadDetailModel[]) {
    const orderAmounts: Record<string, number> = {};
    const quantityOrderedMap: Record<string, number> = {};

    amounts.forEach(amount => {
      if (orderAmounts[amount.productSku.sku]) {
        orderAmounts[amount.productSku.sku] = (amount.amount ?? 0) + (orderAmounts[amount.productSku.sku] ?? 0);
      } else {
        orderAmounts[amount.productSku.sku] = amount.amount ?? 0;
      }
    });

    orders.forEach(order => {
      order.orderItems.forEach(x => {
        if (quantityOrderedMap[x.productSku.sku]) {
          quantityOrderedMap[x.productSku.sku] += quantityOrderedMap[x.productSku.sku];
        } else {
          quantityOrderedMap[x.productSku.sku] = 1;
        }
      });
    });

    const ordersData: OrderInvoiceTotal[] = [];

    // for each order, find duplicate items and highlight them
    orders.forEach((order, orderIndex) => {
      // for each item
      const orderItems: OrderInvoiceLineTotal[] = [];

      let totalLoadedPerOrder = 0;
      let totalOrderedPerOrder = 0;

      let orderHasConflict = false;

      order.orderItems.forEach(orderItem => {
        totalOrderedPerOrder += orderItem.quantity;

        let loaded = 0;
        // making sure all the conflicted items sum up to total loaded.
        const totalOrderItemAmount = orderAmounts[orderItem.productSku.sku] ?? 0;
        if (orderIndex !== orders.length - 1) {
          loaded = totalOrderItemAmount - (totalOrderItemAmount - Math.round(totalOrderItemAmount / (orders.length - orderIndex)));
          orderAmounts[orderItem.productSku.sku] = totalOrderItemAmount - loaded;
        } else {
          // last order
          loaded = orderAmounts[orderItem.productSku.sku];
        }

        totalLoadedPerOrder += loaded || 0;

        const hasConflict = quantityOrderedMap[orderItem.productSku.sku] > 1;

        // if single item has conflict then entire order has a conflict
        if (hasConflict && orders.length > 1) orderHasConflict = true;

        orderItems.push({
          hasConflict,
          itemCode: orderItem.productSku.sku,
          itemName: orderItem.productSku.name,
          ordered: orderItem.quantity,
          loadedQuantity: loaded || 0,
          orderNo: order.orderCode,
          skuId: orderItem.productSku.id,
        });
      });

      ordersData.push({
        orderNo: order.orderCode,
        ordered: totalOrderedPerOrder,
        loadedQuantity: totalLoadedPerOrder,
        hasConflict: orderHasConflict,
        lineItems: orderItems,
        loadDetailIds: this.prepareLoadDetails(order.id, details),
        orderId: order.id,
      });
    });

    this.$invoices.next(ordersData);
  }

  prepareLoadDetails(orderId: number, details: LoadDetailModel[]): number[] {
    const sealIds = details
      .filter(detail => detail.title.toLowerCase() === 'seal')
      .filter(detail => detail.orderId === orderId)
      .map(detail => detail.id);

    const wrapIds = details.filter(detail => detail.title.toLowerCase() === 'wraps').map(detail => detail.id);

    return [...sealIds, ...wrapIds];
  }

  getConflictOrderItemsByCode(itemCode: string) {
    const orderLines = this.$invoices.value.map(inv => inv.lineItems.filter(item => item.itemCode === itemCode));
    return orderLines.reduce((acc, val) => acc.concat(val), []);
  }

  onSetInvoice(orderInvoiceTotal: InvoiceAdjustInput[]) {
    const invoices = this.$invoices.value;
    orderInvoiceTotal.forEach(item => {
      const invoice = invoices.find(inv => inv.orderNo === item.orderNo);
      if (invoice) {
        const line = invoice.lineItems.find(itm => itm.itemCode === item.itemCode);
        line.loadedQuantity = item.value;
        // invoice totals
        invoice.loadedQuantity = invoice.lineItems.reduce((a, b) => +a + +b.loadedQuantity, 0);
      }
    });

    this.$invoices.next(invoices);
  }

  async onSaveInvoices() {
    const invoicePromises = [];

    for (const invoice of this.$invoices.value) {
      const payload = {
        data: {
          loadId: this.loadId,
          orderId: invoice.orderId,
          detailIds: invoice.loadDetailIds,
          invoiceLines: invoice.lineItems.map(line => ({
            quantity: line.loadedQuantity,
            skuId: line.skuId,
          })),
        },
      };

      invoicePromises.push(this.executeGraphQL(payload));
    }

    return Promise.all(invoicePromises);
  }

  executeGraphQL<T, U>(data: T): Promise<MutationResult<U>> {
    return this.ovAutoService.apollo
      .mutate<U>({
        mutation: gql(
          `mutation createOrderInvoice($data: InvoiceCreateInput!) {
            createOrderInvoice(data: $data) { id }
          }`,
        ),
        fetchPolicy: 'no-cache',
        variables: {
          ...data,
        },
      })
      .toPromise();
  }
}
