import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { Vehicle, VehicleSimple } from '@ov-suite/models-admin';
import { VehicleOverride } from '@ov-suite/models-warehouse';
import { BehaviorSubject, combineLatest, filter, first, mergeMap, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LazyService } from './load-allocation.lazy.service';
import { LoadAllocationExternalVehicleService } from './load-allocation.external-vehicle.service';
import { LoadAllocationLoadAllocationService } from './load-allocation.load-allocation.service';

/**
 * Used to manage orders in various ways
 */

@Injectable()
export class LoadAllocationVehicleService extends LazyService<Vehicle> {
  totalWeightMap: Map<number, BehaviorSubject<number>> = new Map();

  totalVolumeMap: Map<number, BehaviorSubject<number>> = new Map();

  public vehiclesDisplay$ = new BehaviorSubject<Vehicle[]>([]);

  constructor(
    private readonly ovAutoService: OvAutoService,
  ) {
    super(Vehicle, ovAutoService, { relations: [] });
    this.setInitialData();
  }

  public getTotalWeight$(vehicleId: number): BehaviorSubject<number> {
    let subject = this.totalWeightMap.get(vehicleId);
    if (subject) {
      return subject;
    }
    subject = new BehaviorSubject<number>(0);
    this.get$(vehicleId)
      .pipe(
        filter(vehicle => !!vehicle),
        map(vehicle => vehicle.class.weightLoadAllowed),
      )
      .subscribe(res => subject.next(res));
    this.totalWeightMap.set(vehicleId, subject);
    return subject;
  }

  public getTotalVolume$(vehicleId: number): BehaviorSubject<number> {
    let subject = this.totalVolumeMap.get(vehicleId);
    if (subject) {
      return subject;
    }
    subject = new BehaviorSubject<number>(0);
    this.get$(vehicleId)
      .pipe(
        filter(vehicle => !!vehicle),
        map(vehicle => (vehicle.class.width ?? 0) * (vehicle.class.height ?? 0) * (vehicle.class.length ?? 0)),
      )
      .subscribe(res => subject.next(res));
    this.totalVolumeMap.set(vehicleId, subject);
    return subject;
  }

  private setInitialData() {
    this.$itemList
      .pipe(
        filter(value => !!value?.length), // Ignore when empty array is emitted
        first(), // Unsubscribe after first event emitted
      )
      .subscribe(value => {
        this.vehiclesDisplay$.next(value);
      });
  }

  public async loadVehicles(): Promise<void> {
    const vehicles = await this.ovAutoService
      .list({
        entity: VehicleOverride,
        relations: ['exceptions', 'resource', 'class'],
        limit: 1000,
      })
      .then(res => res.data.filter(v => !!v.resource && !!v.class));
    vehicles.forEach(vehicle => this.loadItem(vehicle as unknown as Vehicle));
  }
}
