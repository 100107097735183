import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function cancelLoadGql(): DocumentNode {
  return gql(
    `mutation cancelLoad($loadId: Int!) {
      cancelLoad(loadId: $loadId) { id }
      }`,
  );
}

export function restartLoadGql(): DocumentNode {
  return gql(
    `mutation restartLoad($loadId: Int!) {
      restartLoad(loadId: $loadId) { id }
      }`,
  );
}

export function delayLoadGql(): DocumentNode {
  return gql(
    `mutation delayLoad($data: DelayLoadCreateInput!) {
      delayLoad(data: $data) { id }
      }`,
  );
}

export function updateTruckDriverGql(): DocumentNode {
  return gql(
    `mutation updateTruckDriver($data: TruckDriverUpdateInput!) {
      updateTruckDriver(data: $data) { id, truckDriverName }
      }`,
  );
}

export function removeContainersFromLocationGql(): DocumentNode {
  return gql(
    `mutation removeContainersFromLocation($containerIds: [Int!]!) {
      removeContainersFromLocation(containerIds: $containerIds) {
      rowsAffected
      }
      }`,
  );
}


export function changeLoadBayGql(): DocumentNode {
  return gql(
    `mutation changeLoadBay($loadId: Int!, $loadBayId: Int!, $loadBayMinCapacity: Int) {
      changeLoadBay(loadId: $loadId, loadBayId: $loadBayId, loadBayMinCapacity: $loadBayMinCapacity) { id }
      }`,
  );
}

export function listOrderInvoiceByOrderIds(): DocumentNode {
  return gql(
    `query listOrderInvoiceByOrderIds($orderIds: [Int!]!) {
      listOrderInvoiceByOrderIds(orderIds: $orderIds) {
          id
          createDate
          commitDate
          invNum
          invoiceException
          deliveryNote
          invoiceItems {
            id
            quantity
            orderItemId
            productSku {
              id
              name
              sku
              description
            }
          }
          loadId
          order {
            id
            specialInstructions
            fulfilmentDate
            orderDate
            completedDate
            approvalDate
            orderCode
            priority
            customerId
            orderItems {
              id
              quantity
              productSkuId
              unitPriceExcl
              unitPriceIncl
              productSku {
                id
                sku
                name
              }
            }
            salesRepId
            createDate
            externalOrderNo
            deliveryAddress {
              line1
              line2
              line3
              line4
              line5
              line6
              postalCode
              geography
            }
            status
            originalOrderId
            isSplit
            alias
            syncDate
            integrationException
            integrationId
          }
        }
      }`,
  );
}
