import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { Factory, Vehicle } from '@ov-suite/models-admin';
import { PermissionType } from '@ov-suite/helpers-shared';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

@OVEntity('VehicleResource', 'warehouselink')
@OVTable<VehicleResource>([
  {
    key: 'available',
    title: 'Availability',
    editable: true,
    type: 'boolean',
  },
  {
    title: 'Vehicle Reg.',
    type: 'deep-string',
    filterKey: 'vehicle.registration',
    key: 'vehicle.registration',
  },
  {
    title: 'Vehicle Class',
    type: 'deep-string',
    filterKey: 'vehicle.class.name',
    key: 'vehicle.class.name',
  },
  {
    title: 'Dimension',
    type: 'other',
    keys: ['name'],
    disableFiltering: true,
    disableSorting: true,
    action: item => String(item.vehicle.class.height * item.vehicle.class.width),
  },
  {
    title: 'Weight',
    type: 'other',
    disableFiltering: true,
    keys: ['name'],
    action: item => String(item.vehicle.class.weightLoadAllowed),
  },
  {
    key: 'startTime',
    title: 'Start Time',
    editable: true,
    type: 'time',
    permissionId: FeatureKey.VEHICLE_RESOURCES,
    permissionType: PermissionType.update,
  },
  {
    key: 'endTime',
    title: 'End Time',
    editable: true,
    type: 'time',
  },
  {
    title: 'Start Location',
    type: 'dropdown',
    key: 'startLocation',
    editable: true,
    displayKeys: ['id', 'name'],
  },
  {
    title: 'End Location',
    type: 'dropdown',
    editable: true,
    key: 'endLocation',
    displayKeys: ['id', 'name'],
  },
])
export class VehicleResource {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Wave Name',
    placeholder: 'Required',
    required: true,
  })
  available: string;

  @OVField({
    type: () => Vehicle,
    title: 'Vehicle',
    keys: ['name', 'code', 'id', 'class.name', 'class.height', 'class.width', 'class.length', 'class.weightLoadAllowed'],
    placeholder: 'Required',
    selectionType: 'single',
    required: true,
  })
  vehicle: Vehicle;

  @OVField({
    type: 'string',
    title: 'Start Time',
    placeholder: 'Required',
    required: false,
  })
  startTime: string;

  @OVField({
    type: 'string',
    title: 'Start Date',
    placeholder: 'Required',
    required: false,
  })
  endTime: string;

  @OVField({
    type: () => Factory,
    title: 'Start Location',
    placeholder: 'Required',
    selectionType: 'single',
    dropdown: true,
    keys: ['id', 'name'],
    required: false,
  })
  startLocation: Factory;

  @OVField({
    type: () => Factory,
    title: 'End Location',
    keys: ['id', 'name'],
    dropdown: true,
    selectionType: 'single',
    placeholder: 'Required',
    required: false,
  })
  endLocation: Factory;
}
