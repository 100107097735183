<div class="outer-container">
  <div class="main-container">
<!--    <button *ngIf="!loadManagementDataService.lastOrderCommitDate.value"-->
<!--            mat-raised-button-->
<!--            color='primary'-->
<!--            (click)="onClickGenerate()"-->
<!--    >-->
<!--      Generate Invoice-->
<!--    </button>-->
<!--    <div *ngIf="loadManagementDataService.lastOrderCommitDate.value">-->
<!--      <h6> Invoice Date: &nbsp; {{ loadManagementDataService.lastOrderCommitDate.value | date }} </h6>-->
<!--    </div>-->
    <mat-card>
      <div class="filter-row">
        <ng-container>
          <ov-suite-excel-formatter [formClass]="formClass"
                                    [columnData]="columnData"
                                    (overrideExportEmitter)="fetchData()"
                                    [overrideExportData]="fetchDownloadData"
                                    [overrideFetchQuery]="true"
                                    class="filter-item"
          ></ov-suite-excel-formatter>
        </ng-container>
      </div>
      <ov-suite-clean-table
        #loadTable
        [columnData]="columnData"
        [dataSource]="dataSource"
        emptyMessage="No products ordered or amounts loaded"
      >
      </ov-suite-clean-table>
    </mat-card>
  </div>
</div>

<ng-template #InvoiceDialogComponent>
  <ov-suite-invoice-resolution-modal
    (dialogEmitter)="handleDialogEvents($event)"
  ></ov-suite-invoice-resolution-modal>
</ng-template>
