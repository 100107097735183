import { OVField, OVSearchable, OVEntity, OVForm, OVTable, OVSearch, OVSidebar } from '@ov-suite/ov-metadata';

@OVEntity('VehicleType', 'shared')
@OVSidebar([['id']])
@OVForm([['name']])
@OVTable<VehicleType>([
  {
    key: 'id',
    title: 'Vehicle ID',
    type: 'number',
  },
  {
    key: 'name',
    title: 'Vehicle Type',
    type: 'string',
  },
])
@OVSearch<VehicleType>({ searchKeys: ['name'] })
export class VehicleType {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Type',
    placeholder: 'Required',
    required: true,
  })
  name: string;
}
