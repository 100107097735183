import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { CustomerModel, StageTypeModel } from '@ov-suite/models-admin';

@OVEntity('CustomerStageInstruction', 'shared')
export class CustomerStageInstructionModel {
  @OVField({
    type: 'number',
    title: 'ID'
  })
  id: number;

  @OVField({
    type: () => CustomerModel,
    title: 'Customer'
  })
  customer: CustomerModel;

  @OVField({
    type: 'number',
    title: 'Stage Type ID'
  })
  stageTypeId: number;

  @OVField({
    type: () => StageTypeModel,
    title: 'Stage Type'
  })
  stageType: StageTypeModel;

  @OVField({
    type: 'string',
    title: 'Instruction'
  })
  instruction: string;
}
