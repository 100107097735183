import { Injectable } from '@angular/core';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { CRUD } from '@ov-suite/helpers-shared';
import { getWebCrud } from '@ov-suite/authguard-angular';
import { WaveAllocationDataService } from './wave-allocation.data.service';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

/**
 * This service is used to handle any common actions that aren't related to:
 *  Data ( use the Data Service )
 *  User interaction ( use the Action Service )
 *
 * This is not the place to put code relating to a single panel on load-allocation, that logic should be on that panels component:
 *  map - Top Left
 *  order - Top Right
 *  vehicle - Bottom Left. Also see: vehicle-layout service
 *  detail - Bottom Right
 */

@Injectable()
export class WaveAllocationBaseService {
  // Used by order and vehicle
  lists: string[] = [];

  permissions: CRUD;

  constructor(
    private readonly calendar: NgbCalendar,
    public readonly formatter: NgbDateParserFormatter,
    public readonly ovAutoService: OvAutoService,
    private readonly dataService: WaveAllocationDataService,
    private readonly authenticationService: AuthenticationService,
  ) {
    dataService.displayUpdate.subscribe(() => {
      this.updateLists();
    });

    const user = authenticationService.user.value;

    getWebCrud(user, FeatureKey.WAVE_ALLOCATION).then(crud => {
      this.permissions = crud;
    });
  }

  updateLists(): void {
    const dropList = ['load-list'];
    this.dataService.loadBays.value.forEach(bay => {
      this.dataService.waveDays.value.forEach(waveDay => {
        waveDay.forEach(wave => {
          dropList.push(`load_${wave.id}_${bay.id}`);
        });
      });
    });
    this.lists = dropList;
  }
}
