import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { AdminLayoutComponent } from './admin-layout.component';
import { UiModule } from '../ui.module';
import { AdminLayoutParams } from './admin-layout.types';
import { NotificationModule } from '../notification/notification.module';

@NgModule()
export class AdminLayoutModule {
  static forRoot(options: AdminLayoutParams): ModuleWithProviders<AdminLayoutModule> {
    @NgModule({
      imports: [RouterModule.forRoot(options.angularRoutes), UiModule, NotificationModule],
      declarations: [AdminLayoutComponent],
      exports: [RouterModule],
      providers: [[{ provide: APP_BASE_HREF, useValue: '/' }]],
    })
    class AppRoutingModule {}

    return {
      ngModule: AppRoutingModule,
      providers: [{ provide: 'SIDEBAR_OPTIONS', useValue: options }],
    };
  }
}
