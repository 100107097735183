import { QueryDateRange, QueryParam, QueryParams } from '@ov-suite/helpers-shared';
import { FormControl, FormGroup } from '@angular/forms';

export interface FilterChange {
  filter: Record<string, QueryParams[]>;
  query: Record<string, QueryParams[]>;
  search: Record<string, QueryParams[]>;
}

export type FormFieldData = {
  key: string,
  metadata: {
    type: string;
    title: string;
    keys?: string[];
    key?: string;
    options?: string[];
  };

  queryParamType: QueryParam;

  control: FormControl | FormGroup;
}

export function getBasicFormField(formKey: string, type: string, title: string, key: string, defaultValue) {
  return {
    key: formKey,
    metadata: {
      type,
      title,
      key,
    },
    queryParamType: QueryParam.filter,
    control: new FormControl(defaultValue),
  }
}

export function getDropdownFormField(formKey: string, title: string, keys: string[], options: [], defaultValue = []) {//
  return {
    key: formKey,
    metadata: {
      type: 'dropdown',
      title,
      keys,
      options,
    },
    queryParamType: QueryParam.filter,
    control: new FormControl(defaultValue[0]),
  }
}

export function getOtherFormField(formKey: string, type: string, title: string, keys: string[], defaultValue: QueryParams) {
  const metadata = {
    type,
    title,
    key: type === 'deep-string' ? keys[0] : undefined,
    keys: type !== 'deep-string' ? keys : undefined,
  }

  return {
    key: formKey,
    metadata,
    queryParamType: QueryParam.filter,
    control: new FormControl(defaultValue),
  }
}

export function getDateFormField(formKey: string, title: string, key: string, defaultValue: QueryDateRange) {
  return {
    key: formKey,
    metadata: {
      type: 'date',
      title: title,
      key: key,
    },
    queryParamType: QueryParam.query,
    control: new FormGroup({
      start: new FormControl(defaultValue[0]?.from || null),
      end: new FormControl(defaultValue[0]?.to || null),
    })
  }
}
