<div class="vehicle">
  <div class="vehicle-container">
    <div class="header-bar">
      <div class="header-left">
        <ov-suite-input type="date" [ngModel]="dateService.date$ | async" (ngModelChange)="onDateChange($event)"></ov-suite-input>
        <ov-suite-input
          type="text"
          placeholder="Search"
          (keyup)="viewService.setVehicleSearchTerm($event)">
        </ov-suite-input>
      </div>
      <div class="header-center">
        <div>{{ dateDisplay$ | async }}</div>
      </div>
      <div class="header-right">
        <div ngbDropdown class="d-inline-block" placement="bottom-right">
          <button id="load-allocation-filter-btn" class="btn btn-outline-primary" ngbDropdownToggle>Filter</button>
          <div ngbDropdownMenu aria-labelledby="dropdownForm1" class="filter-dropdown">
            <div>
              <ov-suite-input
                type="dropdown"
                [data]="filterStatuses"
                [label]="['label']"
                [placeholder]="'Status'"
                title="Status"
                [(ngModel)]="statusFilter"
                (change)="onStatusFilterChange()"
              ></ov-suite-input>
            </div>
            <div>
              <ov-suite-input
                type="dropdown"
                [data]="vehicleClassData$ | async"
                [label]="['name']"
                [placeholder]="'Vehicle Class'"
                title="Vehicle Class"
                [(ngModel)]="classFilter"
                (change)="onClassFilterChange()"
              ></ov-suite-input>
            </div>
          </div>
        </div>
<!--        <ov-suite-input-->
<!--          type="dropdown"-->
<!--          [data]="templates"-->
<!--          [label]="['name']"-->
<!--          placeholder="Template"-->
<!--          [(ngModel)]="template"-->
<!--          (change)="onTemplateChange()"-->
<!--        ></ov-suite-input>-->
        <button
          [disabled]="!base.permissions.create"
          id="external-vehicle-add-btn"
          class="btn btn-primary"
          (click)="onAddExternalVehicle()"
        >
          <i class="fa fa-truck" aria-hidden="true"></i>&nbsp;
        </button>
      </div>
    </div>
    <div class="static-bar">
      <div class="left-static">
        <div class="v-cell v-name">Vehicle Reg.</div>
        <div class="v-cell v-class">Class</div>
        <div class="v-cell v-capacity">Capacity</div>
        <div class="v-cell v-button"></div>
      </div>
      <div #rightStatic class="right-static">
        <div [style]="{ width: '1500px' }">
          <div class="v-cell v-times">
<!--            <div *ngFor="let time of layout.timeSlot.headings" class="time-header" [style]="{ left: time.left + 'px' }">-->
<!--              {{ time.name }}-->
<!--            </div>-->
            <div class="header-pad" [style.left]="'1500px'"></div>
          </div>
        </div>
      </div>
<!--      <div class="scale-bar">-->
<!--        <button (click)="layout.zoomOut()">-->
<!--          <span class="fa fa-minus"></span>-->
<!--        </button>-->
<!--        <button (click)="layout.zoomIn()">-->
<!--          <span class="fa fa-plus"></span>-->
<!--        </button>-->
<!--      </div>-->
    </div>
    <div class="vehicle-inner" #vehicleInner>
      <div #leftTable class="left-table" (mousedown)="dragLeft($event, vehicleInner, leftTable)">
        <div class="vehicle-body">
          <ng-container *ngIf="viewService.displayedLoadDTOs$ | async as loadDTOs">
            <div
              *ngFor="let loadDTO of loadDTOs"
              class="body-row"
              [ngClass]="{ 'vehicle-committed': loadDTO.committed }"
            >
              <ng-container *ngIf="loadDTO.vehicleSimple as vehicle; else loader">
                <div class="capacity-bar">
                  <div class="cap1" [ngStyle]="getWeightStyle$(loadDTO) | async"></div>
                  <div class="cap2" [ngStyle]="getVolumeStyle$(loadDTO) | async"></div>
                </div>
                <!--              <div class="v-cell v-name">{{ veh.load.getVehicle()?.registration }}</div>-->
                <div class="v-cell v-name">{{ vehicle.registration }}</div>
                <div class="v-cell v-class">{{ vehicle.className }}</div>
                <div class="v-cell v-capacity">
                  <div>{{ getWeightDisplay$(loadDTO) | async }}</div>
                  <div>{{ getVolumeDisplay$(loadDTO) | async }}</div>
                </div>
                <div class="v-cell v-button">
<!--                  <button-->
<!--                    class="btn-commit"-->
<!--                    (click)="commit($event, load)"-->
<!--                    [disabled]="!base.permissions.create || loaders[load.vehicleId] || !!veh.load.releaseDate"-->
<!--                    [ngClass]="{ 'commit-disabled': !!veh.load.releaseDate }"-->
<!--                  >-->
                  <ng-container *ngIf="loadAllocationService.get$(loadDTO.loadId) | async as load">
                    <button
                      class="btn-commit"
                      (click)="commit($event, loadDTO)"
                      [disabled]="!base.permissions.create || loaders[load.vehicleId]"
                    >
                      <span *ngIf="!load.releaseDate && load.commitDate" class="fa fa-arrow-left"></span>
                      <span *ngIf="!load.releaseDate && !load.commitDate" class="fa fa-arrow-right"></span>
                      <span *ngIf="!!load.releaseDate" class="fa fa-times"></span>
                    </button>
                  </ng-container>
                </div>
              </ng-container>

            </div>
          </ng-container>
        </div>
        <div class="scroll-filler"></div>
      </div>
      <div #rightTable class="right-table" (mousedown)="dragRight($event, vehicleInner, rightTable, rightStatic)">
        <div [style]="{ 'max-width': '1500px', width: '1500px' }">
          <div class="vehicle-body">
            <ng-container *ngIf="viewService.displayedLoadDTOs$ | async as loads">
              <div
                *ngFor="let load of loads"
                class="body-row"
                [ngClass]="{ 'vehicle-committed': load.committed }"
              >
                <div class="v-cell v-times">
                  <ov-suite-load-allocation-vehicle-wave [dto]="load">
                  </ov-suite-load-allocation-vehicle-wave>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <ov-suite-load-loader></ov-suite-load-loader>
</ng-template>
