import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { RouteInfo } from '@ov-suite/ui';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ov-suite-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  misc = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
    sidebar_mini_active: false,
  };

  location: Location;

  private readonly nativeElement: Node;

  private toggleButton;

  sidebarVisible: boolean;

  public open = true;

  public hover: boolean;

  public menuItems: RouteInfo[] = [];

  @Input() routes: RouteInfo[];

  @Input() projectName = 'ADMINLINK';

  @Output() signOutEvent = new EventEmitter();

  subscriptions: Subscription[] = [];

  constructor(
    location: Location,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly element: ElementRef,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = true;

    const sub = authenticationService.user.subscribe({
      next: () => this.ngOnInit(),
    });
    this.subscriptions.push(sub);
  }

  ngOnInit() {
    this.menuItems = this.routes;
    this.initPermissions();

    const navbar: HTMLElement = this.element.nativeElement;
    const [body] = document.getElementsByTagName('body') as unknown as Element[];
    [this.toggleButton] = navbar.getElementsByClassName('navbar-toggler') as unknown as Element[];
    if (body.classList.contains('sidebar-mini')) {
      this.misc.sidebar_mini_active = true;
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  initPermissions() {
    const permissions = this.authenticationService.user.value?.permissions ?? {};

    // Hide items without permission
    this.menuItems?.forEach(item => {
      item.children?.forEach(itemChild => {
        if (!itemChild.hidden && itemChild.permissionId) {
          itemChild.hidden = !permissions[itemChild.permissionId] ?? false;
        }
      });
    });

    // Hide Labels without displayed children
    this.menuItems?.forEach(item => {
      if (!item.hidden) {
        item.hidden = item.children?.some(itemChild => !itemChild.hidden) ?? false;
      }
    });

    this.menuItems?.forEach(parentLink => {
      parentLink?.children?.forEach(childLink => {
        if (this.router.url.split('?')[0].endsWith(`${childLink.path}`)) {
          parentLink['isCollapsed'] = false;
        } else if (parentLink['isCollapsed'] === undefined) {
          parentLink['isCollapsed'] = true;
        }
      });
    });
  }

  signOut() {
    this.signOutEvent.emit();
    this.authenticationService.logout();
  }

  minimizeSidebar() {
    this.sidebarToggle();
    const body = document.getElementsByTagName('body')[0];
    const { misc } = this;

    if (this.misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
      this.open = true;
    } else {
      setTimeout(() => {
        body.classList.add('sidebar-mini');
        this.open = false;
        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarOpen() {
    const { toggleButton } = this;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(() => {
      toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = document.getElementsByClassName('main-panel')[0] as HTMLElement;
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = document.getElementsByClassName('main-panel')[0] as HTMLElement;

    if (window.innerWidth < 991) {
      setTimeout(() => {
        mainPanel.style.position = '';
      }, 500);
    }
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
}
