<h2 mat-dialog-title>Change Load Bay</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
  <label for="loadBayId">Load Bays
  </label>
  <select name="loadBayId"
          id="loadBayId"
          formControlName="loadBayId"
          class="form-control"
  >
    <option disabled>Select Load Bay</option>
    <option *ngFor="let bay of dataService.loadBays | async" [value]="bay.id">
      {{bay.name}}
          </option>
        </select>
    <br>
    <label for="loadBayMinCapacity">Load Bay Min Capacity</label>
    <input id="loadBayMinCapacity"
           name="loadBayMinCapacity"
           formControlName="loadBayMinCapacity"
           type="number"
           class="form-control"
    >
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button  (click)="onSave()" cdkFocusInitial>Set Load Bay</button>
</mat-dialog-actions>
