import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';

@OVEntity('VehicleClassStatus', 'shared')
export class VehicleClassStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('VehicleClass', 'shared')
@OVForm([
  ['#Vehicle Class Details'],
  ['vehicleClassId', 'name'],
  ['description', 'classType'],
  ['#Measurements'],
  ['axelWeightInformation', 'weightLoadAllowed', ''],
  ['#Packing Measurements'],
  ['weightLimit'],
  ['length', 'height', 'width'],
  ['#Load Status Checks'],
  ['volumeRedPercentage', 'volumeOrangePercentage'],
  ['weightRedPercentage', 'weightOrangePercentage'],
])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<VehicleClass>([
  {
    key: 'vehicleClassId',
    title: 'Vehicle Class ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    title: 'Weight Load Allowed',
    type: 'number',
    key: 'weightLoadAllowed',
    disableFiltering: true,
  },
  {
    title: 'Weight Red Percentage',
    type: 'number',
    key: 'weightRedPercentage',
    disableFiltering: true,
  },
  {
    title: 'Weight Orange Percentage',
    type: 'number',
    key: 'weightOrangePercentage',
    disableFiltering: true,
  },
  {
    title: 'Dimensions',
    type: 'other',
    disableFiltering: true,
    action: item => {
      const message = `${item.length} cm x ${item.height} cm x ${item.width} cm`;
      return message.includes('null') ? '' : message;
    },
    keys: ['length', 'height', 'width'],
  },
])
@OVSearch<VehicleClass>({
  searchKeys: ['length', 'height', 'width', 'vehicleClassId', 'name', 'weightLoadAllowed', 'weightRedPercentage', 'weightOrangePercentage'],
})
export class VehicleClass {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Class ID',
    tooltip: 'Used as a unique id throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  vehicleClassId: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Class Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Class Type',
    placeholder: 'Required',
    required: true,
  })
  classType: string;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Weight Load Allowed',
    required: true,
    step: '0.01',
    min: 0,
    tooltip: 'kilograms',
  })
  weightLoadAllowed: number;

  @OVField({
    type: 'string',
    title: 'Axel Weight Information',
    required: false,
  })
  axelWeightInformation?: string;

  @OVField({
    type: 'number',
    title: 'Length',
    step: 'any',
    min: 0,
    tooltip: 'centimeters',
  })
  length: number;

  @OVField({
    type: 'number',
    title: 'Height',
    step: 'any',
    min: 0,
    tooltip: 'centimeters',
  })
  height: number;

  @OVField({
    type: 'number',
    title: 'Width',
    step: 'any',
    min: 0,
    tooltip: 'centimeters',
  })
  width: number;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Volume Red Percentage',
    step: 'any',
    min: 0,
    max: 100,
    tooltip: 'Shows the volume percentage at which load allocation will display a red status',
  })
  volumeRedPercentage = 100;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Volume Orange Percentage',
    step: 'any',
    min: 0,
    max: 100,
    tooltip: 'Shows the volume percentage at which load allocation will display an orange status',
  })
  volumeOrangePercentage = 100;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Weight Orange Percentage',
    step: '1',
    tooltip: 'Shows the weight percentage at which load allocation will display an orange status',
  })
  weightOrangePercentage = 100;

  @OVSearchable()
  @OVField({
    type: 'number',
    title: 'Weight Red Percentage',
    step: 'any',
    min: 0,
    max: 100,
    tooltip: 'Shows the weight percentage at which load allocation will display a red status',
  })
  weightRedPercentage = 100;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => VehicleClassStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
    required: false,
  })
  status: VehicleClassStatus;
}
