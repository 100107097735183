import { Component, Input, OnInit } from '@angular/core';
import { Constructor } from '@ov-suite/ov-metadata';
import { HasId } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'ov-suite-table-detail-modal',
  templateUrl: './table-detail-modal.component.html',
  // styleUrls: ['./table-detail-modal.component.scss'],
})
export class TableDetailModalComponent<T extends HasId> implements OnInit {
  @Input() id: number;

  @Input() entity: Constructor<T>;

  data: T;

  constructor(private readonly ovAutoService: OvAutoService, private readonly router: Router) {}

  ngOnInit(): void {
    this.ovAutoService
      .get({
        entity: this.entity,
        id: this.id,
      })
      .then(item => {
        this.data = item;
      });
  }

  edit(item) {
    const options: NavigationExtras = {
      queryParams: { id: item.id },
    };
    this.router.navigate([this.router.url.slice(1).split('?')[0], 'edit'], options);
  }
}
