export interface LoadAmountOrder {
  id: number;
  orderCode: string;
  orderedQuantity: number;
  loadedQuantity: number;
  loadAmountOrderItems: Map<number, LoadAmountOrderItem[]>;
}

export interface LoadAmountOrderItem {
  id: number;
  product: string;
  sku: string;
  ordered: number;
  loaded: number;
  difference: number;
  productSkuId: number;
  orderItemId: number;
}

export interface LoadAmounts {
  quantityOrdered: number;
  quantityLoaded: number;
  orderId: number;
  orderItemId: number;
  orderCode: string;
  productSkuId: number;
  productSkuName: string;
  productSku: string;
}

export interface LoadAmountInvoice {
  id: number;
  createDate: Date;
  commitDate: Date;
  invNum: string;
  invoiceException: string;
  deliveryNote: string;
  orderId: number;
  invoiceStatus: InvoiceStatus;
  loadAmountInvoiceItems: Map<number, LoadAmountInvoiceItem[]>;
}

export interface LoadAmountInvoiceItem {
  id: number;
  quantity: number;
  productSkuId: number;
  orderItemId: number;
}

export enum InvoiceStatus {
  EXCEPTION = 'EXCEPTION', // When exception in the invoice
  GENERATING = 'GENERATING', // When no exception and no commit date
  SUCCESS = 'SUCCESS', // when commit date and no exception
}
