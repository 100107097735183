<ng-container *ngIf="data.title">
  <h1 mat-dialog-title>{{ data.title }}</h1>
</ng-container>
<div mat-dialog-content>
  <ng-container *ngIf="data.text">
    {{ data.text }}
  </ng-container>
  <ng-container *ngIf="data.component">
    <ng-container *ngTemplateOutlet="data.component"></ng-container>
  </ng-container>
</div>
<div *ngIf="!data.overrideActions" mat-dialog-actions class="float-right">
  <button mat-stroked-button [mat-dialog-close]="false" color="warn">{{ data.cancelButtonText ?? 'Cancel' }}</button>
  <button mat-button
          *ngIf="!data.overrideConfirm; else override"
          [mat-dialog-close]="true"
          [disabled]="data?.disableConfirm || false"
          color="primary"
  >
    {{ data.confirmButtonText ?? 'Okay' }}
  </button>
  <ng-template #override>
    <button mat-button
            [mat-dialog-close]="true"
            [disabled]="allowClose"
            color="primary"
    >
      {{ data.confirmButtonText ?? 'Okay' }}
    </button>
  </ng-template>
</div>
