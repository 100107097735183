<form class="px-4 py-3 vehicle-table-container">
  <div class="row">
    <div class="form-group col">
      <ov-suite-input
        [id]="'startTime'"
        name="startTime"
        [type]="'time'"
        [inputLabel]="'Start Time'"
        [tooltip]="'Start Time'"
        [(ngModel)]="startTime"
        (ngModelChange)="onChange($event, 'startTime')"
        [placeholder]="''"
      >
      </ov-suite-input>
    </div>

    <div class="form-group col">
      <ov-suite-input
        [id]="'endTime'"
        name="endTime"
        [type]="'time'"
        [inputLabel]="'End Time'"
        [tooltip]="'End Time'"
        [(ngModel)]="endTime"
        (ngModelChange)="onChange($event, 'endTime')"
        [placeholder]="''"
      >
      </ov-suite-input>
    </div>
  </div>

  <div class="form-group">
    <ov-suite-input
      [id]="'startLocation'"
      name="startLocation"
      [type]="'dropdown'"
      [label]="['name']"
      [inputLabel]="'Start Location'"
      [data]="dropdownData?.startLocation"
      [tooltip]="'Start Location'"
      [(ngModel)]="startLocation"
      (ngModelChange)="onChange($event, 'startLocation')"
      [placeholder]="'Start Location'"
    >
    </ov-suite-input>
  </div>

  <div class="form-group">
    <ov-suite-input
      [id]="'endLocation'"
      name="endLocation"
      [type]="'dropdown'"
      [label]="['name']"
      [data]="dropdownData?.endLocation"
      [inputLabel]="'End Location'"
      [tooltip]="'End Location'"
      [(ngModel)]="endLocation"
      (ngModelChange)="onChange($event, 'endLocation')"
      [placeholder]="'End Location'"
    >
    </ov-suite-input>
  </div>
  <button type="button" (click)="onClearInputs()" class="btn btn-outline-primary btn-sm text-dark text-center clear-action-btn">
    Clear
  </button>
  <button type="submit" ngbDropdownItem (click)="onBulkActionApply()" class="btn btn-primary text-light text-center apply-action-btn">
    Apply Changes &nbsp;
    <i aria-hidden="true" class="fa fa-arrow-right text-white my-auto"></i>
  </button>
</form>
