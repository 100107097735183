import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';
import { Resource } from './resource.model';

@OVEntity({ name: 'LoadUser' })
export class LoadUserModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: () => UserModel })
  user?: UserModel;

  @OVField({ type: 'number' })
  userId?: number;

  @OVField({ type: () => Resource })
  resource?: Resource;

  @OVField({ type: 'number' })
  resourceId: number;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: 'date-time' })
  endDate: Date;

  @OVField({ type: 'string' })
  type: string; // 'Supervisor', 'Loader'

  @OVField({ type: 'number' })
  createdById: number;

  @OVField({ type: () => UserModel })
  createdBy: UserModel;
}
