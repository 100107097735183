import { Component } from '@angular/core';
import { OrderModel, OrderItemModel } from '@ov-suite/models-order';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderPriorityConstant } from '@ov-suite/helpers-shared';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';
import { WaveAllocationActionService } from '../wave-allocation.action.service';
import { WaveAllocationVehicleChangeComponent } from '../wave-allocation-vehicle-change/wave-allocation-vehicle-change.component';

@Component({
  selector: 'ov-suite-wave-allocation-detail',
  templateUrl: './wave-allocation-detail.component.html',
  styleUrls: ['./wave-allocation-detail.component.scss'],
})
export class WaveAllocationDetailComponent {
  active = 1;

  orderPriority = OrderPriorityConstant;

  constructor(
    public base: WaveAllocationBaseService,
    public action: WaveAllocationActionService,
    private readonly modalService: NgbModal,
  ) {}

  getOrderItems(orders: OrderModel[]): OrderItemModel[] {
    const orderItems = [];
    orders.forEach(order => {
      orderItems.push(...order.orderItems);
    });

    return orderItems;
  }

  onChangeVehicle() {
    const modalRef = this.modalService.open(WaveAllocationVehicleChangeComponent, { size: 'xl' });
    modalRef.componentInstance.load = this.action.loadSelected;
    modalRef.componentInstance.submitted.subscribe(res => {
      if (res) {
        window.location.reload();
      }
    });
  }
}
