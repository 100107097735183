
/**
 * Throws an exception if the given value is null or undefined
 */
export function missingFieldCheck<T>(value: T, property?: string, className?: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    let message = 'Missing property';
    if (property) {
      message += ` '${property}'`
      if (className) {
        message += ` on ${className}`;
      }
    }
    throw new Error(message);
  }
}

/**
 * Simple null check function. Throws the given error message if  value is null or undefined
 */
export function nullCheck<T>(value: T, errorMessage = 'Value cannot be null'): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(errorMessage);
  }
}
