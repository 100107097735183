import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@ov-suite/ui';
export interface ConfirmationDialogData {
  title?: string;
  component?: TemplateRef<unknown>;
  text?: string;
  width?: number;
  height?: number;
  cancelButtonText?: string;
  confirmButtonText?: string;
  overrideConfirm?: BehaviorSubject<boolean>;
  overrideActions?: boolean;
  disableConfirm?: boolean;
  preventClose?: boolean;
}
@Injectable()
export class ConfirmationDialogService {
  constructor(private readonly dialog: MatDialog) {}
  openComponentDialog(dialogData: ConfirmationDialogData): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = dialogData.preventClose ?? false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = dialogData;
    if (dialogData.width) {
      dialogConfig.width = `${dialogData.width}px`;
    }
    if (dialogData.height) {
      dialogConfig.height = `${dialogData.height}px`;
    }
    return this.dialog.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
  }
  // We can implement more types of dialogs here
  openConfirmRemovalDialog(): Observable<boolean>  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = {
      title: 'Are you sure you want to delete this item?',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    };
    return this.dialog.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
  }

  openConfirmDialog(dialogData: ConfirmationDialogData): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.hasBackdrop = true;
    dialogConfig.data = {
      title: dialogData.title,
      text: dialogData.text,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    };
    return this.dialog.open(ConfirmationDialogComponent, dialogConfig)
      .afterClosed()
  }

  forceClose() {
    this.dialog.closeAll();
  }
}
