import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  InventoryContainer,
  LoadAllocation,
  WaveInstance,
  LoadUserModel,
  LoadDetailModel
} from '@ov-suite/models-warehouse';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ColumnData, PickStageStatus, QueryParams } from '@ov-suite/helpers-shared';
import { OrderModel } from '@ov-suite/models-order';
import { ConfirmationDialogData, ConfirmationDialogService, OvAutoService } from '@ov-suite/services';
import { HasId } from '@ov-suite/ov-metadata';
import { CleanTableComponent, MatCustomDataSource } from '@ov-suite/ui';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationComponent, ConfirmationParams } from '../confirmation-dialog/confirmation.component';
import { LoadManagementWaveChangeComponent } from './load-management-wave-change/load-management-wave-change.component';
import { LoadManagementDataService } from '../load-management.data.service';
import {
  LoadManagementVehicleChangeComponent
} from './load-management-vehicle-change/load-management-vehicle-change.component';
import { renderPickStatuses, UpdatedLoadBay } from '../load-management.helpers';
import { LoadDetailLoadbayChangeComponent } from './load-detail-loadbay-change/load-detail-loadbay-change.component';


@Component({
  selector: 'ov-suite-load-detail-tab',
  templateUrl: './load-detail-tab.component.html',
  styleUrls: ['./load-detail-tab.component.css'],
})
export class LoadDetailTabComponent implements OnInit {

  palletsToRemove: InventoryContainer[] = [];

  restartIncompletePicks = false;

  loadData: LoadAllocation;

  containerDataSource: MatCustomDataSource<InventoryContainer> = new MatCustomDataSource<InventoryContainer>([]);

  // Pallet Table Data
  inventoryContainerColumnData: ColumnData<InventoryContainer>[] = [
    {
      key: 'name',
      title: 'Container Name',
      type: 'string',
    },
    {
      title: 'Inventory',
      type: 'other',
      action: ic =>
        ic.inventories
          .map(i => i.productSku?.name)
          .filter(i => !!i)
          .join(', '),
      disableSorting: true,
      keys: ['id', 'inventories', 'inventories.productSku', 'inventories.productSku.name'],
    },
    {
      title: 'Inventory Count',
      type: 'other',
      disableSorting: true,
      action: ic => ic.inventories?.reduce((p, c) => p + c.quantity, 0).toString(),
      keys: ['inventories', 'inventories.quantity'],
    },
  ];

  // Order/Customer Table Data
  orderDataSource: MatCustomDataSource<OrderModel> = new MatCustomDataSource<OrderModel>([]);

  orderColumnData: ColumnData<OrderModel>[] = [
    {
      title: 'Customer Code',
      type: 'deep-string',
      key: 'customer.customerCode',
    },
    {
      title: 'Customer Name',
      type: 'deep-string',
      key: 'customer.name',
    },
    {
      title: 'Order',
      type: 'string',
      key: 'orderCode',
    },
  ];

  // Loaders Users Table Data
  loadersDataSource: MatCustomDataSource<LoadUserModel> = new MatCustomDataSource<LoadUserModel>([]);

  loadBayId: number;

  loadersColumnData: ColumnData<LoadUserModel>[] = [
    {
      title: 'Name',
      type: 'other',
      action: lo => (lo.resourceId ? lo.resource?.name : lo.user?.username),
      keys: ['user.username', 'resource.name'],
    },
    {
      title: 'Type',
      type: 'string',
      key: 'type',
    },
    {
      title: 'Start Time',
      type: 'date-time',
      key: 'customer.startTime',
    },
    {
      title: 'End Time',
      type: 'date-time',
      key: 'customer.endTime',
    },
  ];

  constructor(
    public dataService: LoadManagementDataService,
    public readonly ovAutoService: OvAutoService,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly modalService: NgbModal,
    private readonly sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.dataService.containers.subscribe(data => {
      this.containerDataSource.data = data;
    });

    this.dataService.load.subscribe(data => {
      if (data) {
        this.dataService.getContainers(data.loadBayId);
        this.loadersDataSource.data = data.users;
        this.loadData = data;
        this.loadBayId = data.loadBayId;
      }
    });

    this.dataService.orders.subscribe(data => {
      if (data) {
        this.orderDataSource.data = data;
      }
    });
  }

  onHandleLoadCancel() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: <ConfirmationParams>{
        title: 'Cancel Load',
        text: `Are you sure you want to cancel this load?`,
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dataService.cancelLoadHandler().then(() => {
          this.router.navigate(['load-management']);
        });
      }
    });
  }

  onHandleLoadRestart() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: <ConfirmationParams>{
        title: 'Cancel Load',
        text: `Are you sure you want to restart this load?`,
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dataService.restartLoadHandler().then(response => {
          this.router.navigate(['load-management/load'], {queryParams: {id: response.data.restartLoad.id}});
        });
      }
    });
  }

  onChangeVehicle() {
    const modalRef = this.modalService.open(LoadManagementVehicleChangeComponent, {size: 'xl'});
    modalRef.componentInstance.load = this.dataService.load;
  }

  onChangeWave() {
    const dialogRef = this.dialog.open(LoadManagementWaveChangeComponent, { minWidth: '400px' });
    dialogRef.afterClosed().subscribe((waveData: WaveInstance) => {
      if (waveData) {
        const data = this.dataService.load.value;
        const updatedLoad: Record<string, unknown> = {
          id: data.id,
          waveId: waveData.id,
        };

        this.ovAutoService
          .update({
            entity: LoadAllocation,
            item: updatedLoad as unknown as Partial<LoadAllocation> & HasId,
            keys: ['id'],
          })
          .then(() => {
            data.wave = waveData;
            this.dataService.load.next(data);
            this.dataService.wave.next(data.wave);
          });
      }
    });
  }

  onHandleLoadDelay() {
    const dialogRef = this.dialog.open(LoadManagementWaveChangeComponent, {minWidth: '400px'});
    dialogRef.afterClosed().subscribe((waveData: WaveInstance) => {
      if (waveData) {
        const dialogRefConfirm = this.dialog.open(ConfirmationComponent, {
          data: {
            title: 'Reset Picks',
            text: `Do you want to reset picks?`,
          },
        });
        dialogRefConfirm.afterClosed().subscribe(resetPicks => {
          this.dataService.delayLoadHandler(this.dataService.load.value.id, waveData.id, resetPicks).then(() => {
            this.dataService.onInit(Number(this.dataService.load.value.id));
          });
        });
      }
    });
  }

  onRemovePallets() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Remove Pallets',
        text: `Are you sure you want to remove selected pallets from load?`,
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dataService.removePallets(this.palletsToRemove.map(x => x.id)).then(() => {
          this.palletsToRemove = [];
          this.dataService.getContainers(this.loadBayId);
        });
      }
    });
  }

  onPalletSelect(event: Set<InventoryContainer>) {
    this.palletsToRemove = [...event];
  }


  completeLoad() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: <ConfirmationParams>{
        title: 'Complete Load',
        text: `Are you sure you want to mark this load as complete?`,
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dataService.completeLoad();
      }
    });
  }

  reopenLoad<T>(template: TemplateRef<T>) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: <ConfirmationParams>{
        title: 'Reopen Load',
        text: `Are you sure you want to reopen this load?`,
        component: template,
      },
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dataService.reopen(this.restartIncompletePicks).then(() => {
          this.restartIncompletePicks = false;
        });
        return;
      }
      this.restartIncompletePicks = false;
    });
  }

  renderPickStatus() {
    const pickStatuses = this.dataService.picks.value?.map(i => i.pickStatus as unknown as PickStageStatus) ?? [];
    const stageStatuses = this.dataService.stagingItems.value?.map(i => i.stageStatus as unknown as PickStageStatus) ?? [];

    const statuses = [...pickStatuses, ...stageStatuses];

    return this.sanitizer.bypassSecurityTrustHtml(renderPickStatuses(statuses));
  }

  onHandleChangeLoadBay() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = this.loadData;
    dialogConfig.minWidth = '400px';
    const dialogRef = this.dialog.open(LoadDetailLoadbayChangeComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((loadBayData: UpdatedLoadBay) => {
      if (loadBayData) {
        this.dataService.changeLoadBayHandler(loadBayData)
          .then(() => {
            const data = this.dataService.load.value;
            data.loadBayId = loadBayData.loadBayId;
            data.loadBayMinCapacity = loadBayData.loadBayMinCapacity;
            this.dataService.setSelectedInventoryLocation(loadBayData.loadBayId);
            this.dataService.load.next(data);
            this.loadBayId = loadBayData.loadBayId;
          });
      }
    });
  }

  async updateSealNumber(updatedLoadDetail: LoadDetailModel[]): Promise<void> {
    const [original, updated] = updatedLoadDetail;
    const load = await this.dataService.updateSealNumber(original, updated);
    this.dataService.load.next(load);
  }

  async updateVehicleDriver(driverUpdate: [string, string]): Promise<void> {
    const [original, updated] = driverUpdate;
    const load = await this.dataService.updateDriverName(original, updated);
    // this.dataService.load.next(load);
  }
}
