import { OVEntity, OVField, OVSearch, OVTable } from '@ov-suite/ov-metadata';
import { InventoryLocationModel, PickModel, VehicleClass } from '@ov-suite/models-admin';
import { ExternalVehicle } from './external-vehicle.model';
import { VehicleOverride } from './vehicle-override.model';
import { VehicleResource } from './vehicle-resource.model';


@OVEntity({ name: 'LoadAllocation' })
@OVTable<LoadCompletedList>([
  {
    type: 'number',
    key: 'id',
    title: 'Load ID',
  },
  {
    type: 'deep-string',
    key: 'loadBay.name',
    title: 'Load Bay',
  },
  {
    type: 'deep-string',
    key: 'wave.name',
    title: 'Wave',
  },
  {
    type: 'date',
    key: 'date',
    title: 'Load Date',
    format: 'DD/MM/YYYY',
  },
  {
    type: 'date',
    key: 'completeDate',
    title: 'Completed Date',
    format: 'DD/MM/YYYY',
  },
  {
    type: 'deep-string',
    key: 'vehicle.registration',
    title: 'Vehicle Registration',
    disableSorting: true,
    disableFiltering: true,
  },
  {
    type: 'other',
    title: 'Status',
    action: (t) => 'Completed',
    keys: [''],
    disableSorting: true,
    disableFiltering: true,
  },
])
@OVSearch<LoadCompletedList>({ searchKeys: ['vehicle.name', 'vehicle.registration', 'wave.name', 'loadBay.name'] })
export class LoadCompletedList {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  date: string;

  @OVField({ type: () => ExternalVehicle })
  externalVehicle: ExternalVehicle;

  @OVField({ type: () => VehicleOverride })
  vehicle: VehicleOverride;

  // @OVField({ type: () => WaveInstance })
  // wave: WaveInstance;

  // @OVField({ type: () => [PickModel] })
  // picks: PickModel[];

  @OVField({ type: () => InventoryLocationModel })
  loadBay: InventoryLocationModel;

  @OVField({ type: 'number' })
  loadBayId: number;

  @OVField({ type: 'date-time' })
  releaseDate?: Date;

  @OVField({ type: 'date-time' })
  completeDate?: Date;

  getVehicle(): VehicleOverride {
    if (this.externalVehicle) {
      const output = new VehicleOverride();
      output.id = this.externalVehicle.id;
      output.name = `${this.externalVehicle.make} ${this.externalVehicle.model}`;
      output.class = {
        name: this.externalVehicle.vehicleClass,
        height: this.externalVehicle.height,
        width: this.externalVehicle.width,
        length: this.externalVehicle.length,
        weightLoadAllowed: this.externalVehicle.weightLimit,
        weightRedPercentage: 100,
        weightOrangePercentage: 90,
      } as VehicleClass;
      output.registration = this.externalVehicle.registration;
      output.resource = {
        startTime: this.externalVehicle.startTime,
        endTime: this.externalVehicle.endTime,
      } as unknown as VehicleResource;

      return output;
    }

    return this.vehicle;
  }
}
