// eslint-disable-next-line @typescript-eslint/naming-convention
export const OrderPriorityConstant: Record<number, { text: string; color: string }> = {
  1: { text: 'Critical', color: 'badge-danger' },
  2: { text: 'High', color: 'badge-info' },
  3: { text: 'Medium', color: 'badge-warning' },
  4: { text: 'Low', color: 'badge-success' },
};

export const OrderPriorityDropDownConstant: { id: number; text: string }[] = [
  {id:1, text: 'Critical' },
  {id:2, text: 'High' },
  {id:3, text: 'Medium' },
  {id:4, text: 'Low'},
];

export const ReturnPriorityConstant: Record<number, { text: string; color: string }> = {
  1: { text: 'Critical', color: 'badge-danger' },
  2: { text: 'High', color: 'badge-info' },
  3: { text: 'Medium', color: 'badge-warning' },
  4: { text: 'Low', color: 'badge-success' },
};
