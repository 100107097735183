export function filterSet<T>(set: Set<T>, predicate: (value: T, array: Set<T>) => boolean): Set<T> {
  const output = new Set(set);
  output.forEach((item, unused, array) => {
    if (!predicate(item, array)) {
      output.delete(item);
    }
  });
  return output;
}

export function filterMap<T, U = unknown>(set: Set<U>, predicate: (value: U, array: Set<U>) => T): Set<T> {
  const output = new Set<T>();
  set.forEach((item, unused, array) => {
    output.add(predicate(item, array));
  });
  return output;
}

export function filterEvery<T>(set: Set<T>, predicate: (value: T, array: Set<T>) => boolean): boolean {
  return [...set].every(item => predicate(item, set));
}
