import { Component } from '@angular/core';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { HierarchyTab } from '@ov-suite/ui';
import { ColumnData, PickStageStatus, QueryParams } from '@ov-suite/helpers-shared';
import { renderPickStatuses } from '../load-management.helpers';

@Component({
  selector: 'ov-suite-active-load-list',
  templateUrl: './active-load-list.component.html',
})
export class ActiveLoadListComponent {
  tabs: HierarchyTab[] = [
    { title: 'Active', path: '/load-management/active' },
    { title: 'Completed', path: '/load-management/completed' },
  ];

  filter: Record<string, QueryParams[]> = {
    completeDate: [{ operator: 'IS', value: null }],
    active: ['true'],
  };

  search: Record<string, QueryParams[]> = {
    releaseDate: [{ operator: 'IS NOT', value: null }],
    stagingReleaseDate: [{ operator: 'IS NOT', value: null }],
  };

  columnData: ColumnData<LoadAllocation>[] = [
    {
      type: 'number',
      key: 'id',
      title: 'Load ID',
    },
    {
      type: 'deep-string',
      key: 'loadBay.name',
      title: 'Load Bay',
    },
    {
      type: 'number',
      key: 'orderIds',
      title: 'Order ID',
      disableFiltering: true,
    },
    {
      type: 'other',
      title: 'Customer Name',
      action: load => {
        const uniqueCustomerNames = [...new Set(load.orders.map(order => order.customer.name))];
        return uniqueCustomerNames.map(name => `<span>${name}</span>`).join('<br>');
      },
      keys: ['orders.customer.name'],
    },
    {
      type: 'deep-string',
      key: 'wave.waveConfig.waveName',
      title: 'Wave',
    },
    {
      type: 'date',
      key: 'date',
      title: 'Load Date',
      format: 'DD/MM/YYYY',
    },
    {
      type: 'deep-string',
      key: 'vehicle.registration',
      title: 'Vehicle Registration',
      disableSorting: true,
    },
    {
      type: 'other',
      title: 'Picking Status',
      action: t => {
        const pickStatuses = t.pickingItems?.map(i => i.pickStatus as PickStageStatus) ?? [];
        const stageStatuses = t.stagingItems?.map(i => i['stageStatus'] as unknown as PickStageStatus) ?? [];

        const statuses = [...pickStatuses, ...stageStatuses];

        return renderPickStatuses(statuses);
      },
      keys: ['pickingItems.pickStatus', 'stagingItems.stageStatus'],
      disableSorting: true,
      disableFiltering: true,
    },
    {
      title: 'Actions',
      type: 'buttons',
      disableSorting: true,
      buttons: [
        {
          actionType: 'routeLink',
          classes: 'btn-sm',
          title: 'View',
          queryParams: data => ({ id: data.id }),
          routerLink: () => ['load-management/load'],
        },
      ],
      keys: [],
    },
  ];

  formClass = LoadAllocation;
}
