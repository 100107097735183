export function objectPath<T extends Record<string, unknown>>(object: T, path: string | string[]): unknown {
  if (typeof object !== 'object') return undefined;
  const [first, ...remainder] = typeof path === 'string' ? path.split('.') : path;
  if (remainder.length) {
    if (typeof object[first] === 'object') {
      return objectPath(object[first] as Record<string, unknown>, remainder);
    }
    return null;
  }
  return object[first];
}
