import { NgModule } from '@angular/core';
import { MasterRouteAddEditComponent } from './add-edit/master-route-add-edit.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { MasterRouteRoutes } from './master-route.routing';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MasterRouteListComponent } from './list/master-route-list.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(MasterRouteRoutes),
    FormsModule,
    UiModule,
    NgbDropdownModule,
    LeafletModule,
    DragDropModule,
  ],
  declarations: [MasterRouteAddEditComponent, MasterRouteListComponent],
})
export class MasterRouteModule {}
