import { OVField, OVForm, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { DomainModel, InventoryLocationModel } from '@ov-suite/models-admin';
import { InventoryContainerConfigurationItemModel } from './inventory-container-configuration-item.model';
import { InventoryContainerType } from './inventory-container.model';

@OVEntity('InventoryContainerConfiguration')
@OVForm(
  [['#Container Details'], ['name', 'type'], ['items'], ['inventoryLocations']]
)
@OVTable<InventoryContainerConfigurationModel>([
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    title: 'Type',
    key: 'type.name',
    type: 'deep-string',
  },
  {
    title: 'Product SKUs',
    type: 'other',
    action: icc =>
      icc.items
        .map(i => `${i.quantity} x ${i.productSku.name}`)
        .join(', '),
    disableSorting: true,
    filterKey: 'items.productSku.name',
    keys: ['items.productSku.name', 'items.quantity'],
  },
  {
    title: 'Locations',
    type: 'other',
    action: icc =>
      icc.inventoryLocations
        .map(l => l.name)
        .join(', '),
    disableSorting: true,
    keys: ['inventoryLocations.name'],
  },
])
@OVSearch<InventoryContainerConfigurationModel>({ searchKeys: ['name', 'barcodeData', 'type.name'] })
export class InventoryContainerConfigurationModel {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Container Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: () => InventoryContainerType,
    selectionType: 'simple',
    flat: true,
    title: 'Type',
    required: true,
  })
  type: InventoryContainerType;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
    readonly: true,
    generated: true,
  })
  domains: DomainModel[];

  @OVField({
    type: () => [InventoryContainerConfigurationItemModel],
    subType: () => 'ProductSku',
    title: 'SKUs',
    tooltip: 'Add multiple storage keeping units where necessary.',
    withQuantity: true,
    quantityKey: 'productSku',
    selectionType: 'multiple',
    flat: true,
    dropdownLimit: 1000,
    keys: ['id', 'quantity', 'productSku.id', 'productSku.name'],
  })
  items: InventoryContainerConfigurationItemModel[]

  @OVField({
    type: () => [InventoryLocationModel],
    keys: ['id', 'name'],
    selectionType: 'multiple',
    title: 'Locations',
    flat: true,
    required: false,
    query: {
      isStorage: [{ value: true, operator: '=' }]
    }
  })
  inventoryLocations: InventoryLocationModel[]
}
