import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';
import { OrderModel } from './order.model';

@OVEntity({ name: 'OrderComment' })
export class OrderCommentModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  message: string;

  @OVField({ type: () => OrderModel })
  order: OrderModel;

  @OVField({ type: () => UserModel })
  user: UserModel;

  @OVField({ type: 'date-time' })
  timestamp: string;

  @OVField({ type: () => OrderCommentModel })
  target?: OrderCommentModel;

  @OVField({ type: () => [OrderCommentModel] })
  replies: OrderCommentModel[];
}
