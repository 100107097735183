<div>
  <div class="card slide-in-right">
    <div class="top-bar">
      <div class="col-md-12">
        <div class="row mt-2 mb-1">
          <div class="col-md-4"></div>
          <div class="col-md-8">
            <div class="float-right">
              <button class="btn-link text-dark" (click)="customize()">
                <span class="float-right mr-2">
                  <i *ngIf="!customizing" aria-hidden="true" class="fa fa-pencil-square-o"></i>
                  <i *ngIf="customizing" aria-hidden="true" class="fa fa-check-square-o text-success nc-bold"></i>
                </span>
              </button>
              <button class="btn-link text-dark" (click)="toggleFiltering()">
                <span class="float-right mr-2">
                  <i *ngIf="!filtering" aria-hidden="true" class="fa fa-filter"></i>
                  <i *ngIf="filtering" aria-hidden="true" class="fa fa-filter text-success nc-bold"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body" [ngClass]="{ 'pt-1 table-hover': showTopBar, 'show-scrollbar': true }">
      <ov-suite-table
        [showIndex]="false"
        [striped]="!showTopBar"
        [selectableRows]="selectableRows"
        [showFiller]="showFiller"
        [data]="data"
        [loading]="loading"
        [columnData]="metadata?.table"
        [totalCount]="totalCount"
        [pageSize]="pageSize"
        (changePage)="changePage($event)"
        (itemSelect)="onItemSelected($event)"
        [select]="select"
        [back]="back"
        [ovAutoService]="popupAutoService"
        [hideColumnKeys]="hideColumnKeys"
        [keyStore]="metadata?.name"
        (filterChange)="filterChange($event)"
        (orderChange)="orderChange($event)"
        [columnHider]="customizing"
        [editableRows]="editableRows"
        (itemsEdited)="onItemEdit($event)"
        [dropdownData]="dropdownData"
        (changePageSize)="pageSizeInput($event)"
        [clickableRows]="!!subTableParams"
        (clickRow)="openModal(modalContent, $event)"
        [filterEnabled]="filtering"
      ></ov-suite-table>
    </div>
  </div>
</div>

<ng-template #modalContent>
  <div class="col-md-12">
    <div class="row mt-2 mb-1">
      <div class="col-md-4">
        <h5 class="mb-0 text-primary">{{ subTableParams.title }}</h5>
      </div>
    </div>
  </div>
  <ov-suite-table
    [showIndex]="false"
    [striped]="!showTopBar"
    [showFiller]="showFiller"
    [data]="modal.data"
    [loading]="loading"
    [columnData]="subTableParams.columnData"
    [totalCount]="modal.allData.length"
    [pageSize]="modal.pageSize"
    (changePage)="modalChangePage($event)"
    [keyStore]="metadata?.name"
    [columnHider]="customizing"
    [pageChangeEnabled]="false"
    [filterEnabled]="false"
  ></ov-suite-table>
</ng-template>
