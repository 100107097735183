import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalVehicle, LoadAllocation, VehicleOverride } from '@ov-suite/models-warehouse';
import moment from 'moment';
import { Vehicle } from '@ov-suite/models-admin';
import { HasId } from '@ov-suite/ov-metadata';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';

@Component({
  selector: 'ov-suite-wave-allocation-vehicle-change',
  templateUrl: './wave-allocation-vehicle-change.component.html',
  styleUrls: ['./wave-allocation-vehicle-change.component.scss'],
})
export class WaveAllocationVehicleChangeComponent implements OnInit {
  internalVehicleFormClass = Vehicle;

  externalVehicleFormClass = ExternalVehicle;

  load: { value: LoadAllocation };

  active = 1;

  today: string;

  selectedExternalVehicle: ExternalVehicle;

  selectedInternalVehicle: Vehicle;

  updatedLoad: LoadAllocation;

  @Output() submitted: EventEmitter<boolean> = new EventEmitter();

  loading: boolean;

  constructor(
    public readonly ovAutoService: OvAutoService,
    public readonly activeModal: NgbActiveModal,
    public readonly base: WaveAllocationBaseService,
    public readonly data: WaveAllocationDataService,
  ) {}

  ngOnInit() {
    this.today = moment().format('yyyy-MM-DD');
  }

  onCancel() {
    this.activeModal.close();
  }

  onClickInternalVehicle(selected) {
    this.selectedInternalVehicle = selected;
  }

  onClickExternalVehicle(selected) {
    this.selectedExternalVehicle = selected;
  }

  onAddInternalVehicle() {
    if (!this.selectedInternalVehicle) {
      alert('Please select a vehicle!');
      return;
    }
    const updatedLoad: LoadAllocation = new LoadAllocation();
    updatedLoad.id = this.load.value.id;
    updatedLoad.setExternalVehicle(null);
    updatedLoad.setVehicle(this.selectedInternalVehicle as unknown as VehicleOverride);
    this.onSave(updatedLoad);
  }

  onAddExternalVehicle() {
    if (!this.selectedExternalVehicle) {
      alert('Please select a vehicle!');
      return;
    }

    const updatedLoad: LoadAllocation = new LoadAllocation();
    updatedLoad.id = this.load.value.id;
    updatedLoad.setVehicle(null);
    updatedLoad.setExternalVehicle(this.selectedExternalVehicle);
    this.onSave(updatedLoad);
  }

  onSave(event: LoadAllocation) {
    this.loading = true;
    const updatedLoad: Record<string, unknown> = {
      id: event.id,
    };
    if (event.vehicle) {
      updatedLoad.vehicleId = event.vehicle.id;
      updatedLoad.externalVehicleId = null;
    } else if (event.externalVehicle) {
      updatedLoad.externalVehicleId = event.externalVehicle.id;
      updatedLoad.vehicleId = null;
    }
    this.ovAutoService
      .update({
        entity: LoadAllocation,
        item: updatedLoad as unknown as Partial<LoadAllocation> & HasId,
        keys: ['id'],
      })
      .then(() => {
        this.loading = false;
        this.submitted.emit(true);
        this.activeModal.dismiss();
      });
  }
}
