import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';
import { AdminLayoutParams } from '@ov-suite/helpers-angular/lib/admin-layout.interface';
import { hasFeaturePermission, PermissionAction } from './auth.helper';
import { AuthenticationService } from './authentication/authentication.service';

type ActivateChildResponse = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

/**
 * Primary AuthGuard for Angular,
 * Generic modules have their own AuthGuard over at ov-generic.guard.ts
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    @Inject('SIDEBAR_OPTIONS') private readonly options: AdminLayoutParams,
  ) {}

  public canActivateChild(): ActivateChildResponse {
    return this.activate();
  }

  public async canActivate(next: ActivatedRouteSnapshot) {
    const user = this.authenticationService.user.value;
    if (!user) {
      const [nextUrl] = next.url;
      if (nextUrl?.path.startsWith('/login')) {
        return true;
      }
      AuthGuard.goToLogin();

      return false;
    }
    const appId = this.getAppId();
    if (appId) {
      const hasAPP = this.hasAppPermission(Number(appId));
      if (!hasAPP) {
        return this.router.parseUrl('/unauthorized');
      }
    }

    if (next.data?.feature) {
      const r = await hasFeaturePermission(user, next.data.feature.id, PermissionAction.read);
      console.assert(r, 'Your not Authorized to view this feature');
      if (!r) {
        return Promise.resolve(false);
      }
    }
    return this.activate();
  }

  // todo: refactor this to use Dependency Injection instead of environment
  private getAppId(): string {
    return environment.appId[this.options.link].toString();
  }

  private static goToLogin() {
    window.location.href = `${getUrl('idm')}/login?redirect=${window.location.href}`;
  }

  private hasAppPermission(appId: number): boolean {
    const user = this.authenticationService.user.value;
    if (!user) {
      return appId === 8;
    }
    const hasAppId = user.appIds.find(id => id === appId);
    return !!hasAppId;
  }

  private activate(): Promise<boolean> {
    return this.authenticationService
      .isSessionValid()
      .then(valid => {
        if (!valid) {
          AuthGuard.goToLogin();
        }
        return valid;
      })
      .catch(() => {
        AuthGuard.goToLogin();
        return false;
      });
  }
}
