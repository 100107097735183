<mat-card class="mat-elevation-z0">
  <mat-card-content>
    <form [formGroup]="stageInstructionForm">
      <mat-form-field appearance="fill">
        <mat-label>Stage Type</mat-label>
        <mat-select formControlName="stageTypeId"
                    id="stageTypeId"
        >
          <mat-option *ngFor="let type of stageTypes"
                      [value]="type.id"
          >
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Stage Instruction</mat-label>
        <textarea matInput
                  placeholder="Placeholder"
                  formControlName="instruction"
                  name="instruction"
                  id="instruction"
        ></textarea>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
