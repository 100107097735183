import { OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { Vehicle } from '@ov-suite/models-admin';

@OVEntity('TemplateVehicleLineSelector', 'warehouselink')
@OVTable<TemplateVehicleLineSelector>([
  {
    title: 'Vehicle Reg.',
    type: 'other',
    filterKey: 'vehicle.registration',
    keys: ['vehicle.registration'],
    action: item => item.vehicle?.registration,
  },
  {
    title: 'Vehicle Class',
    type: 'other',
    filterKey: 'vehicle.class.name',
    keys: ['vehicle.class.name'],
    action: item => item.vehicle?.class?.name,
  },
  {
    title: 'Dimension',
    type: 'other',
    disableFiltering: true,
    keys: ['vehicle.class.height', 'vehicle.class.width', 'vehicle.class.length'],
    action: item => String(item.vehicle?.class?.height * item.vehicle?.class?.width * item.vehicle?.class?.length),
  },
  {
    title: 'Weight',
    type: 'other',
    disableFiltering: true,
    keys: ['vehicle.class.weightLoadAllowed'],
    action: item => String(item.vehicle?.class?.weightLoadAllowed),
  },
])
export class TemplateVehicleLineSelector {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: () => Vehicle,
    title: 'Vehicle',
    keys: ['name', 'code', 'id', 'class.name', 'class.width', 'class.height', 'class.length', 'class.weightLoadAllowed'],
    placeholder: 'Required',
    required: false,
  })
  vehicle: Vehicle;
}
