import { Injectable } from '@angular/core';
import { CRUD } from '@ov-suite/helpers-shared';
import { getWebCrud } from '@ov-suite/authguard-angular';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';
import { LoadAllocationViewService } from './view-services/load-allocation.view.service';

/**
 * This service is used to handle any common actions that aren't related to:
 *  Data ( use the Data Service )
 *  User interaction ( use the Action Service )
 *
 * This is not the place to put code relating to a single panel on load-allocation, that logic should be on that panels component:
 *  map - Top Left
 *  order - Top Right
 *  vehicle - Bottom Left. Also see: vehicle-layout service
 *  detail - Bottom Right
 */

@Injectable()
export class LoadAllocationBaseService {
  // Used by order and vehicle
  vehicleLists: string[] = [];

  permissions: CRUD;

  constructor(private readonly authenticationService: AuthenticationService, private readonly viewService: LoadAllocationViewService) {
    this.subscribeToVehicleChanges();
  }

  subscribeToVehicleChanges() {
    this.viewService.displayedLoadDTOs$.subscribe(vehicles => {
      const vehicleLists = ['order-list'];
      vehicles.forEach(vehicle => {
        vehicleLists.push(`veh_${vehicle.getVehicleIdentifier()}`);
      });
      this.vehicleLists = vehicleLists;
    });
  }

  public startUp() {
    const user = this.authenticationService.user.value;

    getWebCrud(user, FeatureKey.LOAD_ALLOCATION).then(crud => {
      this.permissions = crud;
    });
  }

  flush() {
    this.vehicleLists = [];

    this.permissions = undefined;
  }
}
