import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { PickStageStatus } from '@ov-suite/helpers-shared';
import { InventoryContainer, LoadAllocation } from '@ov-suite/models-warehouse';
import { OrderItemModel } from '@ov-suite/models-order';
import { ProductSkuModel } from './product-sku.model';
import { UserModel } from '@ov-suite/models-idm';
import { Inventory } from './inventory.model';
import { InventoryLocationModel } from './inventory-location.model';

@OVEntity({ name: 'StagingItem' })
export class StagingItemModel {
  @OVField({
    type: 'number',
    generated: true,
  })
  id: number;

  @OVField({ type: 'string' })
  stageStatus: PickStageStatus;

  @OVField({ type: 'number' })
  priority: number; // Lower = more urgent

  @OVField({ type: () => LoadAllocation })
  load: LoadAllocation;

  @OVField({ type: () => OrderItemModel })
  orderItem: OrderItemModel;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'number' })
  quantityStaged: number;

  @OVField({ type: 'number' })
  quantityReserved: number;

  @OVField({ type: () => ProductSkuModel })
  productSku?: ProductSkuModel;

  @OVField({ type: 'date' })
  createdDate: Date;

  @OVField({ type: 'date' })
  startDate?: Date;

  @OVField({ type: 'date' })
  completedDate?: Date;

  @OVField({ type: 'date' })
  reservationDate?: Date;

  @OVField({ type: 'date' })
  pauseDate?: Date;

  @OVField({ type: 'string' })
  stagingProcessId?: string;

  @OVField({ type: () => UserModel })
  createdByUser?: UserModel;

  @OVField({ type: () => UserModel })
  handledByUser?: UserModel;

  @OVField({ type: 'string' })
  exceptionMessage?: string;

  @OVField({ type: 'boolean' })
  generated?: boolean;

  @OVField({ type: () => InventoryContainer })
  containerStaged?: InventoryContainer;

  @OVField({ type: () => Inventory })
  inventoryStaged?: Inventory;

  @OVField({ type: () => InventoryLocationModel })
  source: InventoryLocationModel;

  @OVField({ type: () => InventoryLocationModel })
  target: InventoryLocationModel;
}
