import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import { InvoiceResolutionDataService, OrderInvoiceTotal } from '../invoice-resolution.data.service';

interface SliderOption {
  itemCode: string;
  orderedQuantity: number;
  value: number;
  orderNo: string;
  isLocked?: boolean;
  max: number;
}

/**
 * @title Invoice Order Line Editor Sliders
 */
@Component({
  selector: 'ov-suite-invoice-order-line-editor',
  styleUrls: ['invoice-order-line-editor.component.scss'],
  templateUrl: 'invoice-order-line-editor.component.html',
})
export class InvoiceOrderLineEditorComponent implements OnInit {
  isLoading = true;

  displayedColumns: string[] = ['orderNo', 'orderedQuantity', 'slider', 'action', 'value'];

  @Input() orderInvoice: OrderInvoiceTotal;

  slidersTotal = 1000;

  sliders: SliderOption[] = [];

  constructor(public dataService: InvoiceResolutionDataService, @Inject(MAT_DIALOG_DATA) public params: { itemCode: string }) {}

  ngOnInit(): void {
    this.isLoading = false;

    if (this.params.itemCode) {
      const data = this.dataService.getConflictOrderItemsByCode(this.params.itemCode);

      this.slidersTotal = Math.round(data.reduce((a, b) => +a + +b.loadedQuantity, 0));
      this.sliders = data.map(item => {
        return {
          ...item,
          max: this.slidersTotal,
          value: Math.round(item.loadedQuantity),
          orderedQuantity: item.ordered,
        };
      });
    }
  }

  onSliderChange(event: MatSliderChange, data: SliderOption) {
    const { value } = event;
    const { sliders, slidersTotal } = this;

    if (data.isLocked) {
      return;
    }

    const noOfLockedSliders = sliders.filter(slider => slider.isLocked).length;

    // value should not go past lockedValueTotal
    const lockedSliders = sliders.filter(slider => slider.isLocked);

    const runningLockedSliderTotal = lockedSliders.reduce((a, b) => +a + +b.value, 0);
    const lockedSlidersTotal = slidersTotal - runningLockedSliderTotal;

    // make sure current slider doesnt run over
    if (value >= lockedSlidersTotal) {
      event.source.value = lockedSlidersTotal;
    }

    // all sliders locked, except 1 or non
    if (sliders.length - noOfLockedSliders <= 1) {
      data.value = lockedSlidersTotal;
      event.source.value = lockedSlidersTotal;
      return;
    }

    let runningSliderTotal = slidersTotal - value - runningLockedSliderTotal;

    const nonLockedSliders = sliders.filter(slider => !slider.isLocked).filter(unLockedSlider => unLockedSlider.orderNo !== data.orderNo);

    // modifies sliders total
    nonLockedSliders.forEach((slider, index) => {
      if (nonLockedSliders.length !== index + 1) {
        slider.value = runningSliderTotal - Math.round(runningSliderTotal / (nonLockedSliders.length - noOfLockedSliders));
        runningSliderTotal -= slider.value;
      } else {
        // last slider
        slider.value = runningSliderTotal >= 0 ? runningSliderTotal : 0;
      }
    });

    this.sliders = [...sliders];
  }

  onToggleLock(currentSlideData: SliderOption) {
    currentSlideData.isLocked = !currentSlideData.isLocked;
    // check which one is locked then reduce max of others
    const { sliders, slidersTotal } = this;

    const lockedSliders = sliders.filter(slider => slider.isLocked);

    const runningLockedSliderTotal = lockedSliders.reduce((a, b) => +a + +b.value, 0);

    let runningSliderTotal = slidersTotal - runningLockedSliderTotal;
    const unLockedSliders = sliders
      .filter(slider => !slider.isLocked)
      .filter(unLockedSlider => unLockedSlider.orderNo !== currentSlideData.orderNo);

    if (currentSlideData.isLocked) {
      unLockedSliders.forEach((slider, index) => {
        if (unLockedSliders.length !== index + 1) {
          slider.value = runningSliderTotal - Math.round(runningSliderTotal / unLockedSliders.length);
          runningSliderTotal -= slider.value;
        } else {
          // last slider
          slider.value = runningSliderTotal;
        }
      });
    }

    this.sliders = [...sliders];
  }

  onSave() {
    this.dataService.onSetInvoice(this.sliders);
  }
}
