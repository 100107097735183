import { OVField, OVTable, OVEntity, OVForm } from '@ov-suite/ov-metadata';
import { InventoryContainer, InventoryContainerConfigurationModel } from '@ov-suite/models-warehouse';
import { ProductSkuModel } from './product-sku.model';
import { DomainModel } from './domain.model';

@OVEntity('Inventory', 'warehouselink')
@OVTable<Inventory>([
  {
    key: 'id',
    title: 'ID',
    type: 'number',
  },
  {
    title: 'Container',
    type: 'deep-string',
    key: 'container.name',
    disableSorting: true, // Issue on backend with ambiguous keys
    detailModal: data => ({
      id: data.container.id,
      entity: InventoryContainer,
    }),
    keys: ['container.name', 'container.id'],
  },
  {
    title: 'SKU',
    type: 'other',
    action: item => item.productSku?.sku,
    keys: ['productSku.sku'],
    disableFiltering: true,
  },
  {
    title: 'Name',
    type: 'other',
    action: item => item.productSku?.name,
    keys: ['productSku.name'],
    disableFiltering: true,
  },
])
@OVForm([['quantity'], ['productSku'], ['container'], ['currentConfiguration'], ['domains']])
export class Inventory {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: () => ProductSkuModel,
    title: 'Product Sku',
    flat: true,
    selectionType: 'single',
    keys: ['sku', 'name', 'weight', 'length', 'width', 'height'],
    required: true,
  })
  productSku: ProductSkuModel;

  @OVField({
    type: () => InventoryContainerConfigurationModel,
    title: 'Current Configuration',
    flat: true,
    selectionType: 'single',
    keys: ['id', 'name'],
    required: true,
  })
  currentConfiguration: InventoryContainerConfigurationModel;

  @OVField({
    type: () => InventoryContainer,
    title: 'Inventory Container',
    flat: true,
    selectionType: 'single',
    keys: ['id', 'name'],
    query: {
      'inventories.containerId': [
        {
          operator: 'IS',
          value: null,
        },
      ],
    },
  })
  container: InventoryContainer;

  @OVField({ title: 'Quantity', type: 'number', required: true })
  quantity: number;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
  })
  domains: DomainModel[];
}
