import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnData, QueryParams } from '@ov-suite/helpers-shared';
import { MatTableDataSource } from '@angular/material/table';
import { Constructor, FieldMetadata, getFieldMetadata, HasId } from '@ov-suite/ov-metadata';
import { OvAutoService } from '@ov-suite/services';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatestWith, map } from 'rxjs';
import { OvAutoDataSource } from './ov-auto-data.source';
import { MatCustomDataSource } from './mat-custom-data.source';

@Component({
  selector: 'ov-suite-clean-table',
  templateUrl: './clean-table.component.html',
  styleUrls: ['./clean-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CleanTableComponent<T extends HasId> implements OnInit, AfterViewInit {
  _columnData: ColumnData<T>[];

  allTicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input()
  set columnData(input: ColumnData<T>[]) {
    this._columnData = input.map(item => {
      item.id ??= item.title ?? 'Missing';
      return item;
    });
  }

  get columnData() {
    return this._columnData;
  }

  @Input() data: T[];

  @Input() formClass: Constructor<T>;

  @Input() totalPages: number;

  @Output() pageChange = new EventEmitter<number>();

  @Output() sortChange = new EventEmitter<{ column: ColumnData<T>; direction: 'ASC' | 'DESC' }>();

  // @Input() dataSource: DataSource<T>;
  @Input() dataSource: MatCustomDataSource<T> | OvAutoDataSource<T>;

  @Input() mapToQueryParams = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @Input() filter: Record<string, QueryParams[]> = {};

  @Input() query: Record<string, QueryParams[]> = {};

  @Input() search: Record<string, QueryParams[]> = {};

  @Input() useParams = false;

  @Input() pageSizeOptions = [10, 25, 100];

  _selectable = false;

  @Input()
  set selectable(input: boolean) {
    this._selectable = input;
    if (input) {
      if (this.columnsToDisplay.length && this.columnsToDisplay[0] !== 'select') {
        this.columnsToDisplay.unshift('select');
      }
    } else {
      this.columnsToDisplay = this.columnsToDisplay.filter(col => col !== 'select');
    }
  }

  get selectable() {
    return this._selectable;
  }

  @Output() selected = new EventEmitter<Set<T>>();

  @Input() disablePaging = false;

  @Input() disableSorting = false;

  @Input() emptyMessage = 'No Data';

  @Input() overrideUpdate = false;

  @Output() updateEventEmitter: EventEmitter<T> = new EventEmitter<T>();

  private metadata: FieldMetadata<T>;

  selectionSet$ = new BehaviorSubject(new Set<T>());

  columnsToDisplay: string[] = [];

  constructor(
    private readonly ovAutoService: OvAutoService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  toggle(item: T, event: Event) {
    event?.stopPropagation();
    if (this.selectionSet$.value.has(item)) {
      this.selectionSet$.value.delete(item);
    } else {
      this.selectionSet$.value.add(item);
    }
    this.updateSelected();
  }

  rowClick(item: T, event: Event) {
    event?.stopPropagation();
    if (this.selectable) {
      this.toggle(item, event);
    }
  }

  ngOnInit(): void {
    if (this.formClass) {
      this.metadata = getFieldMetadata(this.formClass);
      this.columnData ??= this.metadata.table;
      const keys = this.columnData.reduce<(keyof T | string)[]>((p, c) => {
        if ('key' in c) {
          p.push(c.key);
        }
        if ('keys' in c) {
          p.push(...c.keys);
        }
        return p;
      }, []);
      this.dataSource ??= new OvAutoDataSource<T>(this.formClass, this.ovAutoService, {
        keys,
        queryMap: this.query,
        filterMap: this.filter,
        searchMap: this.search,
      });
    } else if (this.data) {
      this.dataSource ??= new MatCustomDataSource(this.data);
    } else if (!this.dataSource) {
      throw new Error('Missing entity or Data');
    }
    this.columnsToDisplay = this.columnData.map(c => c.id);
    if (this.selectable) {
      this.columnsToDisplay.unshift('select');
    }

    if (this.dataSource instanceof OvAutoDataSource) {
      this.dataSource.current
        .pipe(
          combineLatestWith(this.selectionSet$),
          map(([data, selectionSet]) => {
            if (!selectionSet.size) {
              return false;
            }
            for (const item of data) {
              if (!selectionSet.has(item)) {
                return false;
              }
            }
            return true;
          }),
        )
        .subscribe(this.allTicked);
    } else if (this.dataSource instanceof MatCustomDataSource) {
      this.selectionSet$
        .pipe(
          combineLatestWith((this.dataSource as MatCustomDataSource<T>).getPageObserver()),
          map(([selectionSet, data]) => {
            if (!selectionSet.size) {
              return false;
            }
            for (const item of data) {
              if (!selectionSet.has(item)) {
                return false;
              }
            }
            return true;
          }),
        )
        .subscribe(this.allTicked);
    }
  }

  ngAfterViewInit(): void {
    if (!this.columnData) {
      throw new Error('Missing ColumnData');
    }

    if (this.useParams) {
      this.activatedRoute.queryParamMap.subscribe(paramMap => {
        let startPage = 0;
        if (this.mapToQueryParams && paramMap.has('_page')) {
          startPage = Number(paramMap.get('_page'));
        }
        if (this.dataSource instanceof OvAutoDataSource) {
          this.dataSource.init(this.sort, this.paginator, startPage);
        } else if (this.dataSource instanceof MatTableDataSource) {
          // noinspection JSConstantReassignment
          this.dataSource.paginator = this.paginator;
          // noinspection JSConstantReassignment
          this.dataSource.sort = this.sort;
        }
        if (this.mapToQueryParams) {
          this.subscribeToParams();
        }
      });
    } else if (this.dataSource instanceof OvAutoDataSource) {
      this.dataSource.init(this.sort, this.paginator, 0);
    } else if (this.dataSource instanceof MatTableDataSource) {
      // noinspection JSConstantReassignment
      this.dataSource.paginator = this.paginator;
      // noinspection JSConstantReassignment
      this.dataSource.sort = this.sort;
    }
  }

  async refreshData() {
    if (this.dataSource instanceof OvAutoDataSource) {
      await this.dataSource.refresh();
    }
  }

  subscribeToParams() {
    this.paginator.page.subscribe(page => {
      const queryParams: Params = {};
      if (page.pageIndex) {
        queryParams['_page'] = page.pageIndex;
      }
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        // queryParamsHandling: 'merge',
      });
    });
  }

  public clearSelection() {
    this.selectionSet$.value.clear();
    this.selectionSet$.next(this.selectionSet$.value);
    this.selected.emit(this.selectionSet$.value);
  }

  toggleAll(event: Event) {
    event.stopPropagation();
    const selecting = !this.allTicked.value;
    if (this.dataSource instanceof OvAutoDataSource) {
      this.dataSource.current.value.forEach(item => {
        if (selecting) {
          this.selectionSet$.value.add(item);
        } else {
          this.selectionSet$.value.delete(item);
        }
      });
      this.updateSelected();
    } else if (this.dataSource instanceof MatCustomDataSource) {
      this.dataSource.getPageObserver().value.forEach(item => {
        if (selecting) {
          this.selectionSet$.value.add(item);
        } else {
          this.selectionSet$.value.delete(item);
        }
      });
      this.updateSelected();
    }
  }

  updateSelected(selectionMap?: Set<T>): void {
    if (selectionMap) {
      this.selectionSet$.next(selectionMap);
    } else {
      this.selectionSet$.next(this.selectionSet$.value);
    }
    this.selected.emit(this.selectionSet$.value);
  }

  getColumnSize(): string {
    return `width: ${100/this.columnData.length}%`;
  }

  updateItem(item: T) {
    if (this.overrideUpdate) {
      this.updateEventEmitter.emit(item);
    }
    // Todo have some generic behaviour other?
  }
}
