import { BasicTableComponent } from './basic-table.component';
import { BooleanCellComponent } from './cells/boolean/boolean-cell.component';
import { ButtonsCellComponent } from './cells/buttons/buttons-cell.component';
import { DateTimeCellComponent } from './cells/date-time/date-time-cell.component';
import { DateCellComponent } from './cells/date/date-cell.component';
import { DeepStringCellComponent } from './cells/deep-string/deep-string-cell.component';
import { DropdownCellComponent } from './cells/dropdown/dropdown-cell.component';
import { NumberCellComponent } from './cells/number/number-cell.component';
import { OtherCellComponent } from './cells/other/other-cell.component';
import { PillsCellComponent } from './cells/pills/pills-cell.component';
import { StatusCellComponent } from './cells/status/status-cell.component';
import { StringCellComponent } from './cells/string/string-cell.component';
import { TimeCellComponent } from './cells/time/time-cell.component';
import { NgModule } from '@angular/core';
import { InputModule } from '../../input/input.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AgmMapModule } from '../../agm-map/agm-map.module';
import { PipesModule } from '../../pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { ActionDropdownCellComponent } from './cells/action-dropdown/action-dropdown-cell.component';
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  imports: [
    AgmMapModule,
    InputModule,
    FormsModule,
    CommonModule,
    PipesModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [
    BasicTableComponent,
    StringCellComponent,
    DeepStringCellComponent,
    NumberCellComponent,
    BooleanCellComponent,
    DateCellComponent,
    DateTimeCellComponent,
    TimeCellComponent,
    DropdownCellComponent,
    ActionDropdownCellComponent,
    OtherCellComponent,
    PillsCellComponent,
    ButtonsCellComponent,
    StatusCellComponent,
  ],
  exports: [
    BasicTableComponent,
    BooleanCellComponent,
    StringCellComponent,
    DeepStringCellComponent,
    NumberCellComponent,
    DateCellComponent,
    DateTimeCellComponent,
    TimeCellComponent,
    DropdownCellComponent,
    ActionDropdownCellComponent,
    OtherCellComponent,
    PillsCellComponent,
    ButtonsCellComponent,
    StatusCellComponent,
  ],
})
export class BasicTableModule {}
