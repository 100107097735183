<ng-container *ngIf="legacy; else modern">
  <button mat-icon-button
          (click)="export()"
          [class.spinner]="loading"
          [disabled]="loading">
    <mat-icon class="fa fa-download fa-lg"></mat-icon>
  </button>
</ng-container>
<ng-template #modern>
  <button mat-stroked-button color="primary"
          class="filter-item"
          [class.spinner]="loading"
          [disabled]="loading"
          (click)="export()"
  >
    Export
  </button>
</ng-template>
