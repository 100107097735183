import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import {
  latLng,
  LatLngBounds,
  MapOptions,
  tileLayer,
  circleMarker,
  CircleMarker,
  Map,
  control,
  LatLngTuple,
  LatLng,
  Polyline,
  polyline,
} from 'leaflet';
import { OvAutoService, OvAutoServiceMultipleParams } from '@ov-suite/services';
import { ActivatedRoute, Router } from '@angular/router';
import { getUpdate, getCreate, parseMutationDomains } from '@ov-suite/graphql-helpers';
import { PageReturn } from '@ov-suite/ov-metadata';
import { CdkDragDrop, CdkDragSortEvent, moveItemInArray } from '@angular/cdk/drag-drop';
import { MasterRoute } from '@ov-suite/models-warehouse';
import { CustomerModel, DomainModel, Factory } from '@ov-suite/models-admin';
import { DomainService } from '@ov-suite/helpers-angular';
import { AddressModel } from '@ov-suite/models-shared';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

enum Filter {
  selected,
  unselected,
  unassigned,
  all,
}

@Component({
  selector: 'ov-suite-process-definitions',
  templateUrl: './master-route-add-edit.component.html',
  styleUrls: ['./master-route-add-edit.component.scss'],
})
export class MasterRouteAddEditComponent implements OnInit {
  styles = {
    factorySize: 6,
    orderSize: 9,
    highlightedCustomerSize: 13,

    displayOpacity: 1,
    hiddenOpacity: 0.1,
    hoverOpacity: 0.2,

    sourceFactoryColor: '#50E3C2',
    destinationFactoryColor: '#5F59F7',
    unusedFactoryColor: 'grey',
    highlightedCustomerColor: '#bf73de',
    selectedCustomerColor: '#FCD861',
    unselectedCustomerColor: '#A9E5FF',
    noRouteCustomerColor: '#FC5153',
    greyButtonColor: '#888888',
  };

  // Leaflet Map
  map: Map;

  origin: MasterRoute;

  data: MasterRoute = new MasterRoute();

  // List of All Customers
  customersMap: Record<number, CustomerModel> = {};

  customersArray: CustomerModel[] = [];

  // List of All Displayed Customers
  displayedCustomersMap: Record<number, CustomerModel> = {};

  displayedCustomersArray: CustomerModel[] = [];

  // Filters
  filter: Filter = Filter.all;

  searchTerm = '';

  // List of All Factories
  factories: Factory[] = [];

  // Source Factory
  set source(input: Factory) {
    // A setter is used for better integration with ngModel
    this.setSource(input);
  }

  get source() {
    return this.data.source;
  }

  // Destination Factory
  set destination(input: Factory) {
    // A setter is used for better integration with ngModel
    this.setDestination(input);
  }

  get destination() {
    return this.data.destination;
  }

  // Map of Customers Ticked
  tickedCustomersMap: Record<string, CustomerModel> = {};

  allTicked = false;

  // Array and Corresponding Map of Selected Customers for current Route
  selectedCustomersMap: Record<string, CustomerModel> = {};

  selectedCustomersArray: CustomerModel[] = [];

  // Factory Pin Management
  factoryPinMap: Record<number, CircleMarker> = {};

  factoryPinArray: CircleMarker[] = [];

  // Customer Pin Management
  customerPinMap: Record<number, CircleMarker> = {};

  customerPinArray: CircleMarker[] = [];

  // Line Management
  lineArray: Polyline[] = [];

  previewArray: Polyline[] = null;

  // Used to control what the map shows, Set when factories are loaded
  bounds: LatLngBounds = null;

  dragArray: CustomerModel[] = [];

  dragData: CustomerModel = null;

  dragHover: CustomerModel = null;

  selectedDomains: DomainModel[] = [];

  // Leaflet Options
  options: MapOptions = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...', minZoom: 4 })],
    // center: latLng(0, 0),
    // zoom: 2,
    zoomControl: false,
    doubleClickZoom: false,
  };

  topBarStyle = {
    'background-color': 'red',
    'border-width': 1,
    'border-color': 'red',
  };

  constructor(
    private readonly ngZone: NgZone,
    private readonly cdRef: ChangeDetectorRef,
    public readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly domainService: DomainService,
  ) {}

  bringSourceAndDestinationToFront(): void {
    if (this.destination) {
      this.factoryPinMap[this.destination.id].bringToFront();
    }
    if (this.source) {
      this.factoryPinMap[this.source.id].bringToFront();
    }
  }

  ngOnInit() {
    this.initialize();
  }

  // This should be != and not !==. this is because null == undefined and null !== undefined
  hasCoordinates = (input: { defaultDeliveryAddress?: AddressModel }) =>
    !!input?.defaultDeliveryAddress?.geography?.coordinates?.[0] && !!input?.defaultDeliveryAddress?.geography?.coordinates?.[1];

  initialize(): void {
    const multipleParams: OvAutoServiceMultipleParams = {
      factory: {
        type: 'list',
        entity: Factory,
        limit: 10000,
        keys: [
          'id',
          'name',
          'address',
          // 'address.line1',
          // 'address.line2',
          // 'address.line3',
          // 'address.line4',
          // 'address.line5',
          // 'address.line6',
          // 'address.postalCode',
          // 'address.geography',
        ],
      },
      customer: {
        type: 'list',
        entity: CustomerModel,
        limit: 10000,
        keys: ['id', 'name', 'defaultDeliveryAddress', 'customerCode', 'masterRoutes.id'],
        orderColumn: 'name',
      },
    };

    this.route.queryParamMap.subscribe(params => {
      if (params.has('id')) {
        multipleParams.masterRoute = {
          type: 'get',
          entity: MasterRoute,
          id: Number(params.get('id')),
          keys: ['id', 'name', 'customerIds', 'sourceId', 'destinationId', 'domains'],
        };
      }
      this.ovAutoService.multipleFetch(multipleParams).then(response => {
        const customers =
          (response.customer as PageReturn<CustomerModel>).data?.filter(
            c => !!c?.defaultDeliveryAddress?.geography?.coordinates?.[0] && !!c?.defaultDeliveryAddress?.geography?.coordinates?.[1],
          ) ?? [];
        this.setCustomers(customers);
        this.displayedCustomersArray = [...this.customersArray];
        const factories =
          (response.factory as PageReturn<Factory>).data?.filter(
            f => !!f?.address?.geography?.coordinates?.[0] && !!f?.address?.geography?.coordinates?.[1],
          ) ?? [];
        this.setFactories(factories);
        if (response.masterRoute) {
          this.origin = { ...(response.masterRoute as MasterRoute) };
          this.setData(response.masterRoute as MasterRoute);
        } else {
          const data = new MasterRoute();
          data.domains = [this.domainService.currentDomain.value];
          this.setData(data);
        }
        this.sortSelected();
        this.updateBounds();
      });
    });
  }

  // Setters
  setData(input: MasterRoute): void {
    input.source = this.factories.find(i => i.id === input.sourceId);
    input.destination = this.factories.find(i => i.id === input.destinationId);
    input.customers = input.customerIds?.map(id => this.customersMap[id]).filter(this.hasCoordinates) ?? [];
    this.selectedCustomersMap = {};
    this.selectedCustomersArray = [...input.customers];
    this.selectedDomains = [...input.domains];
    input.customers.forEach(i => {
      this.selectedCustomersMap[i.id] = i;
    });
    this.data = input;
    this.updatePinsForCustomers();
  }

  setCustomers(input: CustomerModel[]): void {
    this.customersMap = {};
    input.forEach(i => {
      this.customersMap[i.id] = i;
    });
    this.customersArray = input;
    this.setupPinsForCustomers();
  }

  setFactories(input: Factory[]): void {
    this.factories = input;
    this.setupPinsForFactories();
  }

  setSource(input: Factory): void {
    if (this.data.source) {
      if (this.data.source === this.data.destination) {
        this.factoryPinMap[this.data.source.id].setStyle({ fillColor: this.styles.destinationFactoryColor });
      } else {
        this.factoryPinMap[this.data.source.id].setStyle({ fillColor: this.styles.unusedFactoryColor });
      }
    }
    this.data.source = input;
    this.data.sourceId = input?.id ?? null;
    if (input) {
      this.factoryPinMap[input.id].setStyle({ fillColor: this.styles.sourceFactoryColor });
    }
    this.bringSourceAndDestinationToFront();
    this.drawLines();
    this.updateBounds();
  }

  setDestination(input: Factory): void {
    if (this.data.destination && this.data.destination !== this.data.source) {
      this.factoryPinMap[this.data.destination.id].setStyle({ fillColor: this.styles.unusedFactoryColor });
    }
    this.data.destination = input;
    this.data.destinationId = input?.id ?? null;
    if (input !== this.data.source) {
      this.factoryPinMap[input.id].setStyle({ fillColor: this.styles.destinationFactoryColor });
    }
    this.bringSourceAndDestinationToFront();
    this.drawLines();
    this.updateBounds();
  }

  setSelectedCustomers(input: CustomerModel[]): void {
    this.selectedCustomersArray = input;
    this.selectedCustomersMap = {};
    input.forEach(i => {
      this.selectedCustomersMap[i.id] = i;
    });
    this.data.customers = input;
  }

  setSelectedDomains(input: DomainModel[]): void {
    this.data.domains = [...input];
  }

  // Selected Customers
  addSelectedCustomer(input: CustomerModel | CustomerModel[]): void {
    this.ngZone.run(() => {
      const evaluate = (customer: CustomerModel) => {
        if (!this.selectedCustomersMap[customer.id]) {
          this.selectedCustomersMap[customer.id] = customer;
          this.selectedCustomersArray.push(customer);
          this.data.customers = this.selectedCustomersArray;
          const color = this.getCustomerPinColor(customer.id);
          this.customerPinMap[customer.id].setStyle({ fillColor: color });
        }
      };

      if (Array.isArray(input)) {
        input.forEach(evaluate);
      } else {
        evaluate(input);
      }
      this.sortSelected();
      this.drawLines();
    });
  }

  removeSelectedCustomer(input: CustomerModel | CustomerModel[]): void {
    this.ngZone.run(() => {
      const evaluate = (customer: CustomerModel) => {
        if (this.selectedCustomersMap[customer.id]) {
          delete this.selectedCustomersMap[customer.id];
          this.selectedCustomersArray = this.selectedCustomersArray.filter(i => i.id !== customer.id);
          this.data.customers = this.selectedCustomersArray;
          const color = this.getCustomerPinColor(customer.id);
          this.customerPinMap[customer.id].setStyle({ fillColor: color });
        }
      };

      if (Array.isArray(input)) {
        input.forEach(evaluate);
      } else {
        evaluate(input);
      }
      this.sortSelected();
      this.drawLines();
    });
  }

  // Sets the boundary for the map to Either: Source + Destination + Selected Customers or All Factories
  updateBounds() {
    this.ngZone.run(() => {
      const setBounds = () => {
        const selectedPins: [number, number][] = this.selectedCustomersArray.map(i => {
          const [lat, lng] = i.defaultDeliveryAddress?.geography?.coordinates ?? [0, 0];
          return [lng, lat];
        });
        const [sLong, sLat] = this.source.address.geography.coordinates;
        if (this.source === this.destination) {
          this.bounds = new LatLngBounds([[sLat, sLong], ...selectedPins]);
        } else {
          const [dLong, dLat] = this.destination.address.geography.coordinates;
          this.bounds = new LatLngBounds([[sLat, sLong], [dLat, dLong], ...selectedPins]);
        }
      };

      if (this.source && this.destination) {
        setBounds();
      } else if (!this.source && !this.destination && this.factoryPinArray.length) {
        const bounds: [number, number][] = this.factoryPinArray.map(i => {
          const { lat, lng } = i.getLatLng();
          return [lat, lng];
        });
        this.bounds = new LatLngBounds(bounds);
      }
    });
  }

  // Pin Setup Functions
  setupPinsForCustomers() {
    this.customersArray.forEach(customer => {
      this.customerPinMap[customer.id] = this.createPinForCustomer(customer);
    });
    this.customerPinArray = Object.values(this.customerPinMap);
  }

  setupPinsForFactories() {
    this.factories.forEach(factory => {
      this.factoryPinMap[factory.id] = this.createPinForFactory(factory);
    });
    this.factoryPinArray = Object.values(this.factoryPinMap);
  }

  updatePinsForCustomers() {
    this.data.customers.forEach(i => {
      this.customerPinMap[i.id].setStyle({ fillColor: this.styles.selectedCustomerColor });
    });
  }

  // Individual Pin Setup Functions
  createPinForCustomer(customer: CustomerModel): CircleMarker {
    const color = this.getCustomerPinColor(customer.id, false);
    const [longitude, latitude] = customer?.defaultDeliveryAddress?.geography?.coordinates ?? [0, 0];
    return circleMarker([latitude, longitude], {
      color: 'black',
      fillColor: color,
      fillOpacity: this.styles.displayOpacity,
      opacity: 0.2,
      radius: this.styles.orderSize,
      weight: 1,
    })
      .bindTooltip(customer.name)
      .on('click', () => this.toggleCustomer(customer));
  }

  createPinForFactory(factory: Factory): CircleMarker {
    let color = this.styles.unusedFactoryColor;
    if (factory === this.source) color = this.styles.sourceFactoryColor;
    if (factory === this.destination) color = this.styles.destinationFactoryColor;
    const [longitude, latitude] = factory.address.geography.coordinates;
    return circleMarker([latitude, longitude], {
      color: 'white',
      fillColor: color,
      fillOpacity: this.styles.displayOpacity,
      radius: this.styles.factorySize,
      weight: 1,
    }).bindTooltip(factory.name);
  }

  // Fires when map is loaded
  onMapReady(map: Map): void {
    this.map = map;
    map.addControl(control.zoom({ position: 'bottomright' }));
  }

  // Fired on SidePanel Click
  focusCustomer(event: MouseEvent, customer: CustomerModel): void {
    event.stopPropagation();
    const [longitude, latitude] = customer.defaultDeliveryAddress.geography.coordinates ?? [0, 0];
    const target = latLng(latitude, longitude);
    this.map.panTo(target, { animate: true });
  }

  // Hover Actions
  customerOnHover(customer: CustomerModel) {
    this.customerPinMap[customer.id]
      .setStyle({
        className: 'pin-hover',
        weight: 5,
      })
      .setRadius(this.styles.highlightedCustomerSize);
  }

  customerOffHover(customer: CustomerModel) {
    this.customerPinMap[customer.id]
      .setStyle({
        weight: 1,
      })
      .setRadius(this.styles.orderSize);
  }

  // Click Pin or Double Click Table
  toggleCustomer(customer: CustomerModel): void {
    if (this.selectedCustomersMap[customer.id]) {
      this.removeSelectedCustomer(customer);
    } else {
      this.addSelectedCustomer(customer);
    }
  }

  // Styles
  getHoverOpacity(id: number, hovering = false): number {
    if (hovering) {
      return this.styles.hoverOpacity;
    }
    if (this.displayedCustomersMap[id]) {
      return this.styles.displayOpacity;
    }
    return this.styles.hiddenOpacity;
  }

  getCustomerPinColor(id: number, hovering = false): string {
    if (hovering) {
      return this.styles.highlightedCustomerColor;
    }
    if (this.selectedCustomersMap[id]) {
      return this.styles.selectedCustomerColor;
    }
    return this.styles.noRouteCustomerColor;
  }

  tickCustomer(customer: CustomerModel) {
    if (this.tickedCustomersMap[customer.id]) {
      this.removeSelectedCustomer(customer);
      delete this.tickedCustomersMap[customer.id];
    } else {
      this.tickedCustomersMap[customer.id] = customer;
      this.addSelectedCustomer(customer);
    }
    this.allTicked = Object.keys(this.tickedCustomersMap).length === this.customersArray.length;
  }

  customerCheckBoxClick(event: MouseEvent, customer: CustomerModel): void {
    event.stopPropagation();
    this.tickCustomer(customer);
  }

  customerDoubleClick(event: MouseEvent, customer: CustomerModel): void {
    event.stopPropagation();
    this.toggleCustomer(customer);
  }

  save() {
    const onSuccess = () => {
      this.router.navigate(['/master-route']);
    };
    const keys = ['id', 'name', 'customerIds', 'sourceId', 'destinationId'];
    if (this.data.id) {
      this.ovAutoService
        .update({ entity: MasterRoute, item: parseMutationDomains<MasterRoute>(this.data, getUpdate(this.data, this.origin)), keys })
        .then(onSuccess);
    } else {
      this.ovAutoService
        .create({ entity: MasterRoute, item: parseMutationDomains<MasterRoute>(this.data, getCreate(this.data)), keys })
        .then(onSuccess);
    }
  }

  bulkFocus() {
    const tickedPins: [number, number][] = Object.values(this.tickedCustomersMap).map(i => {
      const [lng, lat] = i.defaultDeliveryAddress?.geography?.coordinates ?? [0, 0];
      return [lat, lng];
    });
    this.bounds = new LatLngBounds(tickedPins);
  }

  bulkClear() {
    this.tickedCustomersMap = {};
  }

  resetDisplayedCustomers(): void {
    this.displayedCustomersArray = [...this.customersArray];
    this.sortSelected();
    this.displayedCustomersMap = {};
    this.displayedCustomersArray.forEach(customer => {
      this.displayedCustomersMap[customer.id] = customer;
    });
    Object.entries(this.customerPinMap).forEach(([id, customerPin]) => {
      const customer = this.customersMap[id];
      customerPin
        .setStyle({ fillOpacity: this.styles.displayOpacity })
        .off()
        .bindTooltip(customer.name)
        .on('click', () => this.toggleCustomer(customer));
    });
  }

  onSearch(): void {
    this.applyFilters();
  }

  applyFilters(): void {
    if (!this.searchTerm && this.filter === Filter.all) {
      this.updateBounds();
      this.tickedCustomersMap = {};
      this.allTicked = false;
      this.resetDisplayedCustomers();
      return;
    }
    const newBounds: LatLngTuple[] = [];
    this.displayedCustomersArray = this.customersArray.filter(customer => {
      let isFound = true;

      if (this.filter !== Filter.all) {
        if (this.selectedCustomersMap[customer.id]) {
          isFound = this.filter === Filter.selected;
        } else {
          isFound = this.filter === Filter.unassigned;
        }
      }

      if (this.searchTerm && isFound) {
        const searchTerm = this.searchTerm.toUpperCase();
        isFound = customer.name.toUpperCase().includes(searchTerm) || customer.customerCode.includes(searchTerm);
      }
      const pin = this.customerPinMap[customer.id];
      if (isFound) {
        const { lat, lng } = pin?.getLatLng();
        newBounds.push([lat, lng]);
        this.enablePin(pin, customer);
      } else {
        this.disablePin(pin);
      }
      return isFound;
    });
    this.sortSelected();
    if (newBounds.length) {
      this.map.panInsideBounds(new LatLngBounds(newBounds));
    }
    this.tickedCustomersMap = {};
    this.allTicked = false;
  }

  // Enable And Disable Customer Pins
  disablePin(pin: CircleMarker): CircleMarker {
    return pin.setStyle({ fillOpacity: this.styles.hiddenOpacity }).off();
  }

  enablePin(pin: CircleMarker, customer: CustomerModel): CircleMarker {
    return pin
      .setStyle({ fillOpacity: this.styles.displayOpacity })
      .off()
      .bindTooltip(customer.name)
      .on('click', () => this.toggleCustomer(customer));
  }

  // Filter Actions
  filterSelected(): void {
    this.filter = Filter.selected;
    this.applyFilters();
  }

  filterUnselected(): void {
    this.filter = Filter.unselected;
    this.applyFilters();
  }

  filterUnAssigned(): void {
    this.filter = Filter.unassigned;
    this.applyFilters();
  }

  filterClear(): void {
    this.filter = Filter.all;
    this.applyFilters();
  }

  toggleAll() {
    this.allTicked = !this.allTicked;
    if (this.allTicked) {
      this.displayedCustomersArray.forEach(customer => {
        this.tickedCustomersMap[customer.id] = customer;
        this.addSelectedCustomer(customer);
      });
    } else {
      this.displayedCustomersArray.forEach(customer => {
        this.removeSelectedCustomer(customer);
      });
      this.tickedCustomersMap = {};
    }
  }

  drop(event: CdkDragDrop<CustomerModel[]>) {
    const maxIndex = this.selectedCustomersArray.length - 1;
    const currentIndex = event.currentIndex > maxIndex ? maxIndex : event.currentIndex;
    if (currentIndex !== event.previousIndex) {
      moveItemInArray(this.displayedCustomersArray, event.previousIndex, currentIndex);
      this.selectedCustomersArray = this.displayedCustomersArray.slice(0, maxIndex + 1);
      this.data.customers = this.selectedCustomersArray;
    }
    this.drawLines();
  }

  drawLines(): void {
    const coords: LatLng[] = [];
    if (this.source) {
      coords.push(this.factoryPinMap[this.source.id].getLatLng());
    }

    this.data.customers?.forEach(customer => {
      coords.push(this.customerPinMap[customer.id].getLatLng());
    });

    if (this.destination) {
      coords.push(this.factoryPinMap[this.destination.id].getLatLng());
    }

    this.lineArray = [];
    coords.forEach((set, index) => {
      if (index < coords.length - 1) {
        this.lineArray.push(polyline([set, coords[index + 1]], { color: '#0091FF', opacity: 0.6 }));
      }
    });

    setTimeout(() => this.lineArray.forEach(i => i.bringToBack()), 1);
  }

  sortSelected(): void {
    this.ngZone.run(() => {
      const baseOrder = {};
      this.selectedCustomersArray.forEach((item, index) => {
        baseOrder[item.id] = index;
      });
      this.displayedCustomersArray.sort((a, b) => {
        const selectedA = this.selectedCustomersMap[a.id];
        const selectedB = this.selectedCustomersMap[b.id];
        if (selectedA && selectedB) {
          return baseOrder[a.id] - baseOrder[b.id];
        }
        if (selectedA) {
          return -1;
        }
        if (selectedB) {
          return 1;
        }
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      this.drawLines();
    });
  }

  onStartDrag(customer: CustomerModel): void {
    this.dragData = customer;
    this.dragArray = [...this.displayedCustomersArray.slice(0, this.data.customers.length)];
  }

  onEndDrag(): void {
    this.dragData = null;
    this.previewArray = null;
  }

  onDragEnter(event: CdkDragSortEvent<CustomerModel[]>): void {
    this.ngZone.run(() => {
      moveItemInArray(this.dragArray, event.previousIndex, event.currentIndex);

      const coords: LatLng[] = [];
      if (this.source) {
        coords.push(this.factoryPinMap[this.source.id].getLatLng());
      }

      this.dragArray?.forEach(customer => {
        coords.push(this.customerPinMap[customer.id].getLatLng());
      });

      if (this.destination) {
        coords.push(this.factoryPinMap[this.destination.id].getLatLng());
      }

      this.previewArray = [];
      coords.forEach((set, index) => {
        if (index < coords.length - 1) {
          this.previewArray.push(polyline([set, coords[index + 1]], { color: 'red', opacity: 0.3 }));
        }
      });
    });
  }

  openAdminLink() {
    // navigate to Admin Link - customers
    window.open(`${getUrl('admin')}/customer`, '_blank');
  }

  cancel(): void {
    window.history.back();
  }
}
