import { Component, Input } from '@angular/core';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { LoadDTO, OrderDTO } from '../../load-allocation.interface';
import { LoadAllocationVehicleLayoutService } from '../../services/load-allocation.vehicle-layout.service';
import { LoadAllocationDataService } from '../../services/load-allocation.data.service';
import { LoadAllocationActionService } from '../../services/load-allocation.action.service';
import { LoadAllocationOrderService } from '../../services/data-services/load-allocation.order.service';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAllocationEventBusService } from '../../services/event-bus/load-allocation.event-bus.service';
import { OrderMovedEvent } from '../../services/event-bus/load-allocation.events';

@Component({
  selector: 'ov-suite-load-allocation-vehicle-wave',
  templateUrl: './vehicle-wave.component.html',
  styleUrls: ['./vehicle-wave.component.scss', '../../load-allocation.shared.scss'],
})
export class VehicleWaveComponent {
  @Input() dto: LoadDTO;

  constructor(
    public base: LoadAllocationBaseService,
    public layout: LoadAllocationVehicleLayoutService,
    public action: LoadAllocationActionService,
    public data: LoadAllocationDataService,

    public orderService: LoadAllocationOrderService,
    public readonly viewService: LoadAllocationViewService,
    private readonly eventBus: LoadAllocationEventBusService,
  ) {}

  getId(): string {
    return `veh_${this.dto.getVehicleIdentifier()}`;
  }

  public stopPropagation(event: Event) {
    event.stopPropagation();
  }

  public resetOrder(dto: OrderDTO, event: Event) {
    this.eventBus.triggerEvent(new OrderMovedEvent(dto, this.dto, 'list', 0, 0));
    this.stopPropagation(event);
  }
}
