import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadManagementDataService } from '../load-management.data.service';

@Component({
  selector: 'ov-suite-load-detail-container',
  templateUrl: './load-detail-container.component.html',
  styleUrls: ['./load-detail-container.component.scss'],
})
export class LoadDetailContainerComponent implements OnInit {
  active = 1;

  highlightColor = '#009de6';

  constructor(private readonly route: ActivatedRoute, private readonly dataService: LoadManagementDataService) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const id = params.get('id');
      this.dataService.onInit(Number(id));
    });
  }
}
