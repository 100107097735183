import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { PickListComponent } from './list/pick-list.component';
import { PicksRoutes } from './picks.routing';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(PicksRoutes), FormsModule, UiModule],
  declarations: [PickListComponent],
})
export class PicksModule {}
