import { Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExternalVehicle } from '@ov-suite/models-warehouse';
import { getCreate } from '@ov-suite/graphql-helpers';
import moment from 'moment';
import { getFieldMetadata } from '@ov-suite/ov-metadata';
import { DomainService } from '@ov-suite/helpers-angular';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { LoadAllocationDataService } from '../../services/load-allocation.data.service';
import { LoadAllocationLoadAllocationService } from '../../services/data-services/load-allocation.load-allocation.service';

@Component({
  selector: 'ov-suite-external-vehicle-create',
  templateUrl: './external-vehicle-create.component.html',
  styleUrls: ['./external-vehicle-create.component.scss'],
})
export class ExternalVehicleCreateComponent implements OnInit {
  formClass = ExternalVehicle;

  active = 1;

  today: string;

  selected: ExternalVehicle[] = [];

  externalVehicle: ExternalVehicle;

  loading: boolean;

  constructor(
    public readonly ovAutoService: OvAutoService,
    public readonly activeModal: NgbActiveModal,
    public readonly base: LoadAllocationBaseService,
    public readonly data: LoadAllocationDataService,
    private readonly domainService: DomainService,
    private readonly loadAllocationService: LoadAllocationLoadAllocationService,
  ) {}

  ngOnInit() {
    this.today = moment().format('yyyy-MM-DD');

    const metadata = getFieldMetadata(ExternalVehicle);
    const domainSelector = metadata.fields.find(f => f.type === 'domain-selector');
    this.externalVehicle = new ExternalVehicle();
    if (domainSelector) {
      this.externalVehicle.domains = [this.domainService.currentDomain.value];
    }
  }

  onCancel() {
    this.activeModal.close();
  }

  onSelect(items) {
    this.selected = items;
  }

  onAddToLoad() {
    this.loadAllocationService.addExternalVehicles(this.selected);
    this.activeModal.close();
    // const vehicleAllocations = [];
    // this.selected.forEach(vehicle => {
    //   const load = LoadAllocation.fromExternalVehicle(vehicle, this.today);
    //   const vehicleAllocation = VehicleAllocation.fromLoadAllocation(load);
    //   vehicleAllocation.load = load;
    //   vehicleAllocations.push(vehicleAllocation);
    // });
    // // todo: Move this logic to Data Service
    // this.data.vehicleAllocation.all = this.data.vehicleAllocation.all.concat(...vehicleAllocations);
    // this.data.vehicleAllocation.value = this.data.vehicleAllocation.value.concat(...vehicleAllocations);
    // this.base.updateLists();
    // this.activeModal.close();
  }

  onSave(event: ExternalVehicle) {
    this.loading = true;
    const keys = ['id', 'model', 'make', 'vehicleClass', 'startTime', 'endTime', 'weightLimit', 'dimensions'];
    this.ovAutoService.create({ entity: ExternalVehicle, item: getCreate<ExternalVehicle>(event), keys }).then(response => {
      this.loading = false;
      event.id = response.id;
      this.selected = [event];
      // add to load
      this.onAddToLoad();
      this.activeModal.dismiss();
    });
  }
}
