import { Injectable } from '@angular/core';

/**
 * This service is used for shared logic between the vehicle and vehicle-wave components.
 * It mostly handles layout logic for the planner
 */

@Injectable()
export class LoadAllocationVehicleLayoutService {
}

// spacing 60, width 50
