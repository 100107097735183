import { Component, OnInit } from '@angular/core';
import { interval, withLatestFrom } from 'rxjs';
import { InvoiceModel, OrderItemModel, OrderModel } from '@ov-suite/models-order';
import { LoadAmountModel, LoadDetailModel } from '@ov-suite/models-warehouse';
import { MutationResult } from 'apollo-angular';
import { ConfirmationDialogService, OvAutoService } from '@ov-suite/services';
import { ActivatedRoute } from '@angular/router';
import { DocumentNode } from 'graphql';
import {
  InvoiceStatus,
  LoadAmountInvoice,
  LoadAmountInvoiceItem,
  LoadAmountOrder,
  LoadAmountOrderItem,
  LoadAmounts,
} from './load-amount-tab.types';
import { LoadManagementDataService } from '../load-management.data.service';
import { createOrderInvoice, reloadOrderInvoice } from './load-amount.graphql';

@Component({
  selector: 'ov-suite-load-amount-tab',
  templateUrl: './load-amount-tab.component.html',
  styleUrls: ['./load-amount-tab.component.css'],
})
export class LoadAmountTabComponent implements OnInit {
  loadOrderMap: Map<number, LoadAmountOrder> = new Map<number, LoadAmountOrder>();

  /**
   * key - productSkuId
   * value[0] - load amount and order quantity details[]
   * value[1] - total loaded amount on a truck
   */
  loadAmountMap: Map<number, [LoadAmounts[], number]> = new Map<number, [LoadAmounts[], number]>();

  // orderId,
  loadInvoiceMap: Map<number, LoadAmountInvoice> = new Map<number, LoadAmountInvoice>();

  loadDetailMap: Map<number, LoadDetailModel[]> = new Map<number, LoadDetailModel[]>();

  loadId: number;

  constructor(
    public loadManagementDataService: LoadManagementDataService,
    private readonly ovAutoService: OvAutoService,
    private readonly route: ActivatedRoute,
    private readonly dialogService: ConfirmationDialogService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.loadId = parseInt(params['id'], 10);
    });

    this.loadManagementDataService.amounts
      .pipe(
        withLatestFrom(
          this.loadManagementDataService.orders,
          this.loadManagementDataService.invoices,
          this.loadManagementDataService.loadDetails,
        ),
      )
      .subscribe(([amounts_, orders_, invoices_, details_]) => {
        this.prepareInvoiceAmounts(invoices_);
        this.prepareDisplayOrders(orders_);
        this.prepareLoadAmounts(amounts_);
        this.prepareLoadDetails(details_);
      });

    interval(30000).subscribe(() => {
      this.refreshData();
    });
  }

  prepareDisplayOrders(orders: OrderModel[]) {
    orders.forEach(order__ => {
      const tempOrderItems: Map<number, LoadAmountOrderItem[]> = new Map<number, LoadAmountOrderItem[]>();
      let quantity = 0;
      let loadOrderInvoice: LoadAmountInvoice;
      let loadOrderInvoiceItemMap: Map<number, LoadAmountInvoiceItem[]>;

      // Check if this has been invoiced:
      if (this.loadInvoiceMap.has(order__.id)) {
        loadOrderInvoice = this.loadInvoiceMap.get(order__.id);
        loadOrderInvoiceItemMap = loadOrderInvoice.loadAmountInvoiceItems;
      }

      order__.orderItems.forEach(orderItem_ => {
        this.prepareLoadAmountMap(order__, orderItem_);

        let invoiceItem: LoadAmountInvoiceItem;
        if (loadOrderInvoice && loadOrderInvoiceItemMap.has(orderItem_.productSku.id)) {
          const invoiceItems = loadOrderInvoiceItemMap.get(orderItem_.productSku.id);
          invoiceItem = invoiceItems.find(i => i.orderItemId === orderItem_.id);
        }

        const tempOrderItem: LoadAmountOrderItem = {
          id: orderItem_.id,
          product: orderItem_.productSku.name,
          sku: orderItem_.productSku.sku,
          ordered: orderItem_.quantity,
          // these two need to be set from the invoice stuff when it is gotten.
          loaded: invoiceItem?.quantity || 0,
          difference: (invoiceItem?.quantity || 0) - orderItem_.quantity,
          productSkuId: orderItem_.productSkuId,
          orderItemId: orderItem_.id,
        };

        quantity += orderItem_.quantity;

        if (tempOrderItems.has(tempOrderItem.productSkuId)) {
          const tempAssignedOrderItems = tempOrderItems.get(tempOrderItem.productSkuId);
          tempAssignedOrderItems.push(tempOrderItem);
        } else {
          tempOrderItems.set(tempOrderItem.productSkuId, [tempOrderItem]);
        }
      });

      const tempOrder: LoadAmountOrder = {
        id: order__.id,
        orderCode: order__.orderCode,
        orderedQuantity: quantity,
        loadedQuantity: 0,
        loadAmountOrderItems: tempOrderItems,
      };

      this.loadOrderMap.set(order__.id, tempOrder);
    });

    this.calculateLoadOrderTotals();
  }

  prepareLoadDetails(loadDetails: LoadDetailModel[]) {
    loadDetails.forEach(detail => {
      if (this.loadDetailMap.has(detail.orderId)) {
        const details = this.loadDetailMap.get(detail.orderId);
        details.push(detail);
      } else {
        this.loadDetailMap.set(detail.orderId, [detail]);
      }
    });
  }

  prepareLoadAmountMap(order: OrderModel, orderItem: OrderItemModel) {
    if (this.loadAmountMap.has(orderItem.productSkuId)) {
      const loadAmountDetails = this.loadAmountMap.get(orderItem.productSkuId);
      loadAmountDetails[0].push(prepareItem());
    } else {
      this.loadAmountMap.set(orderItem.productSkuId, [[prepareItem()], 0]);
    }

    function prepareItem(): LoadAmounts {
      return {
        productSkuId: orderItem.productSkuId,
        productSkuName: orderItem.productSku.name,
        productSku: orderItem.productSku.sku,
        quantityOrdered: orderItem.quantity,
        // eslint-disable-next-line max-len
        quantityLoaded: 0, // TODO set this value from the invoice amounts. Also add in a field to show its not editable if invoice is already created.
        orderId: order.id,
        orderCode: order.orderCode,
        orderItemId: orderItem.id,
      };
    }
  }

  prepareLoadAmounts(loadedAmounts: LoadAmountModel[]) {
    loadedAmounts.forEach(loadedAmount => {
      if (this.loadAmountMap.has(loadedAmount.productSkuId)) {
        const productLoaded = this.loadAmountMap.get(loadedAmount.productSkuId);
        productLoaded[1] = loadedAmount.amount;
      }
    });
  }

  prepareInvoiceAmounts(invoices: InvoiceModel[]) {
    invoices.forEach(invoice__ => {
      const tempInvoiceItems: Map<number, LoadAmountInvoiceItem[]> = new Map<number, LoadAmountInvoiceItem[]>();

      invoice__.invoiceItems.forEach(invoiceItem_ => {
        const tempInvoiceItem: LoadAmountInvoiceItem = {
          id: invoiceItem_.id,
          quantity: invoiceItem_.quantity,
          productSkuId: invoiceItem_.productSku.id,
          orderItemId: invoiceItem_.orderItemId,
        };

        if (tempInvoiceItems.has(invoiceItem_.productSku.id)) {
          const tempIntermediateInvoiceItem = tempInvoiceItems.get(invoiceItem_.productSku.id);
          tempIntermediateInvoiceItem.push(tempInvoiceItem);
        } else {
          tempInvoiceItems.set(invoiceItem_.productSku.id, [tempInvoiceItem]);
        }
      });

      const tempInvoice: LoadAmountInvoice = {
        id: invoice__.id,
        createDate: invoice__.createDate,
        commitDate: invoice__.commitDate,
        invNum: invoice__.invNum,
        invoiceException: invoice__.invoiceException,
        deliveryNote: invoice__.deliveryNote,
        orderId: invoice__.order.id,
        invoiceStatus: this.getInvoiceStatus(invoice__),
        loadAmountInvoiceItems: tempInvoiceItems,
      };

      this.loadInvoiceMap.set(tempInvoice.orderId, tempInvoice);
    });
  }

  calculateLoadOrderTotals() {
    Array.from(this.loadOrderMap.values()).forEach(loadOrder => {
      let totalLoaded = 0;

      Array.from(loadOrder.loadAmountOrderItems).forEach(loadOrderItems => {
        loadOrderItems.forEach(loadOrderItem => {
          if (typeof loadOrderItem !== 'number') {
            loadOrderItem.forEach(item => {
              totalLoaded += item.loaded;
            });
          }
        });
      });

      loadOrder.loadedQuantity = totalLoaded;
    });
  }

  getInvoiceStatus(invoice: InvoiceModel) {
    if (invoice.invoiceException) {
      return InvoiceStatus.EXCEPTION;
    }

    if (!invoice.invoiceException && !invoice.commitDate) {
      return InvoiceStatus.GENERATING;
    }

    if (invoice.commitDate && !invoice.invoiceException && invoice.invNum) {
      return InvoiceStatus.SUCCESS;
    }

    return null;
  }

  updateLoadAmounts(updatedLoadAmounts: [number, LoadAmounts[]]) {
    const [productSku, loadedAmounts] = updatedLoadAmounts;

    loadedAmounts.forEach(loadAmount => {
      if (this.loadOrderMap.has(loadAmount.orderId)) {
        const order = this.loadOrderMap.get(loadAmount.orderId);
        if (order.loadAmountOrderItems.has(productSku)) {
          const orderedItem = order.loadAmountOrderItems.get(productSku);

          const exactOrderedItem = orderedItem.filter(item => item.orderItemId === loadAmount.orderItemId)[0];

          exactOrderedItem.loaded = loadAmount.quantityLoaded;

          exactOrderedItem.difference = exactOrderedItem.ordered - exactOrderedItem.loaded;
        }
      }
    });

    this.calculateLoadOrderTotals();
  }

  async onGenerateInvoice(orderToInvoice: number) {
    const order = this.loadOrderMap.get(orderToInvoice);
    await this.onSaveInvoices([order]);
  }

  async onSaveInvoices(orders: LoadAmountOrder[]) {
    const totalInvoices = orders.length;
    const invoicePromises = [];

    if (!this.loadId) {
      return null;
    }

    orders.forEach(order => {
      const invoiceLines = [];

      // Formulate the invoice lines.
      Array.from(order.loadAmountOrderItems.values()).forEach(loadAmountOrderItems => {
        loadAmountOrderItems.forEach(loadAmountOrderItem => {
          if (loadAmountOrderItem.loaded > 0) {
            const { loaded, productSkuId, orderItemId } = loadAmountOrderItem;
            invoiceLines.push({
              quantity: loaded,
              skuId: productSkuId,
              orderItemId,
            });
          }
        });
      });

      // Verify invoice lines have quantities greater than 0;
      if (invoiceLines.length > 0) {
        let loadDetailIds = [];
        if (this.loadDetailMap.has(order.id)) {
          const loadDetails = this.loadDetailMap.get(order.id);
          loadDetailIds = loadDetails.map(ld => ld.id);
        }

        const payload = {
          data: {
            loadId: this.loadId,
            orderId: order.id,
            detailIds: loadDetailIds,
            invoiceLines,
          },
        };

        invoicePromises.push(this.executeGraphQL(createOrderInvoice(), payload));
      }
    });

    if (invoicePromises.length > 0) {
      const invoicesToCreate = invoicePromises.length;
      return Promise.all(invoicePromises).then(async () => {
        await this.refreshData();

        let invoiceGenerateText = `(${invoicesToCreate} / ${totalInvoices}) Will be processed. `;

        if (invoicesToCreate === totalInvoices) {
          invoiceGenerateText += `The process has been initiated.
          The invoice will be available soon.`;
        } else {
          invoiceGenerateText += `The process has been initiated.
          Please ensure load amounts have been allocated to all invoices.`;
        }

        this.dialogService.openConfirmDialog({
          title: 'Generate invoice',
          text: invoiceGenerateText,
          confirmButtonText: 'Okay',
        });
      });
    }

    this.dialogService.openConfirmDialog({
      title: 'Generate invoice Error!',
      text: `An error occurred while processing invoice.
      Please ensure that load amounts have been allocated
      to the invoice before attempting to process.`,
      confirmButtonText: 'Okay',
    });
    return null;
  }

  async onGenerateAllInvoices() {
    // Filter out orders that don't have invoices created.
    const ordersToInvoice = [];

    Array.from(this.loadOrderMap.keys()).forEach(orderId => {
      if (!this.loadInvoiceMap.has(orderId)) {
        ordersToInvoice.push(this.loadOrderMap.get(orderId));
      }
    });

    await this.onSaveInvoices(ordersToInvoice);
  }

  async onReloadInvoice(invoiceId) {
    const payload = {
      data: {
        id: invoiceId,
        invoiceException: null,
        commitDate: null,
      },
    };

    this.executeGraphQL(reloadOrderInvoice(), payload).then(async () => {
      await this.refreshData();
    });
  }

  async refreshData() {
    await this.loadManagementDataService.refresh();
    this.prepareInvoiceAmounts(this.loadManagementDataService.invoices.getValue());
  }

  executeGraphQL<T, U>(query: DocumentNode, data: T): Promise<MutationResult<U>> {
    return this.ovAutoService.apollo
      .mutate<U>({
        mutation: query,
        fetchPolicy: 'no-cache',
        variables: {
          ...data,
        },
      })
      .toPromise();
  }

  onExport() {
    console.log('GOING TO EXPORT ALL THE DATA EVER NEEDED');
  }
}
