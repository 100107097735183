/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { LoadAllocationVehicleService } from './data-services/load-allocation.vehicle.service';

/**
 * This Service is used for Fetching, Manipulating and Saving Data.
 *
 * This should be the only service on load-allocation with ovAutoService. Please be sure to manage observables correctly
 */

interface SortableColumn {
  name: string;
}

@Injectable()
export class LoadAllocationDataService {
  orderAllocationColumns: SortableColumn[] = [{ name: 'Order ID' }, { name: 'Customer ID' }, { name: 'Weight' }, { name: 'Volume' }];

  sortingData: { column: string; direction: 'ASC' | 'DESC' } = {
    column: 'id',
    direction: 'ASC',
  };

  constructor(private readonly vehicleService: LoadAllocationVehicleService) {}

  public startUp() {
    this.vehicleService.loadVehicles();
  }

  public flush() {
    this.orderAllocationColumns = [{ name: 'Order ID' }, { name: 'Customer ID' }, { name: 'Weight' }, { name: 'Volume' }];

    this.sortingData = {
      column: 'id',
      direction: 'ASC',
    };
  }
}
