import { Injectable } from '@angular/core';
import { CustomerModel, ProductCategory, ProductSkuModel } from '@ov-suite/models-admin';
import { OrderItemModel, OrderModel, Reason, ReturnItem } from '@ov-suite/models-order';
import { OvAutoService } from '../ov-auto-service/ov-auto.service';

export type ProductSkuDisplay = {
  id: number,
  name: string,
  sku: string,
  quantityFree: number,
  quantityOnHand: number,
  exclPrice: number,
  inclPrice: number,
  increment: number,
  category: ProductCategory,
  model: ProductSkuModel,
  quantity?: number,
}

export type MappedProducts = {
  category: ProductCategory,
  skus: ProductSkuDisplay[],
  categories: MappedProducts[],
}

@Injectable()
export class InventoryAccordionService<T extends OrderItemModel | ReturnItem> {
  customer: CustomerModel;

  products: MappedProducts[];

  constructor(private readonly ovAutoService: OvAutoService) {}

  setCustomer(customer: CustomerModel) {
    this.customer = customer;
  }

  mapCategory = (category: ProductCategory): MappedProducts => ({
    category: category,
    skus: [],
    categories: category.children ? category.children.map(this.mapCategory) : []
  });

  async getReasons(): Promise<Reason[]> {
    return this.ovAutoService.list({
      entity: Reason,
      orderColumn: 'reason',
    }).then(res => res.data);
  }

  async listProductCategories(id: number): Promise<MappedProducts[]> {
    return this.ovAutoService
      .list({
        entity: ProductCategory,
        orderColumn: 'name',
        keys: [
          'id',
          'name',
          'path',
          'children.id',
          'children.name',
          'children.path',
          'parent.path'
        ],
        query: { parentId: [ id ] },
      }).then(items => items.data.map(i => this.mapCategory(i)));
  }

  async listCategoryProducts(categoryId: number, path: string): Promise<ProductSkuDisplay[]> {
    let defaultPriceListId;

    if (this.customer?.defaultPriceListId) {
      defaultPriceListId = this.customer.defaultPriceListId;
    }

    const productSkuList = await this.ovAutoService.list({
      entity: ProductSkuModel,
      keys: [
        'id',
        'name',
        'sku',
        'category',
        'category.id',
        'category.path',
        'category.name',
        'quantityFree',
        'quantityOnHand',
        'inverseProductSkuConfigurations.id',
        'prices.exclPrice',
        'prices.inclPrice',
        'prices.priceList.id',
        'prices.priceList.default'
      ],
      query: {
        'category.id': [ categoryId ],
      },
    }).then(res => res.data);

    // Map return Object
    return productSkuList.map(product => ({
      id: product.id,
      name: product.name,
      sku: product.sku,
      quantityFree: product.quantityFree,
      quantityOnHand: product.quantityOnHand,
      exclPrice: product.getPriceExcl(defaultPriceListId),
      inclPrice: product.getPriceIncl(defaultPriceListId),
      increment: 1,
      category: product.category,
      model: product
    }));
  }

  async loadOrderItem(id: number) : Promise<OrderItemModel>{
    return this.ovAutoService.get({
      entity: OrderItemModel,
      id: id,
      keys: [
        'id',
        'orderId',
        'processedQuantity',
        'productSku.name',
        'productSku.sku',
        'productSkuId',
        'quantity',
        'unitPriceExcl',
        'unitPriceIncl',
      ]
    });
  }

  async listFlatProductCategories(id:number): Promise<ProductCategory[]> {
    const topLevelCategories = await this.ovAutoService.list({
      entity: ProductCategory,
      keys: ['id', 'name', 'path'],
      query: { parentId: [ id ] }, // Fetch only top-level categories
    }).then(res => res.data);

    // Fetch all categories without nesting
    const allCategories: ProductCategory[] = [];
    await this.fetchNestedCategories(topLevelCategories, allCategories);

    return allCategories;
  }

  async fetchNestedCategories(categories: ProductCategory[], allCategories: ProductCategory[]) {
    for (const category of categories) {
      allCategories.push(category);
      if (category.children && category.children.length > 0) {
        await this.fetchNestedCategories(category.children, allCategories);
      }
    }
  }

  categorySkusWithDiscounts(categorySkus: ProductSkuDisplay[], orderedItems: Map<number, T>) {
    categorySkus.forEach(sku => {
      if (orderedItems.has(sku.id)) {
        const orderedItem = orderedItems.get(sku.id);
        sku.exclPrice = orderedItem.unitPriceExcl;
        sku.inclPrice = orderedItem.unitPriceIncl;
      }
    })

    return categorySkus;
  }

  /** used by accordions, adjusts quantities for orders  */
  orderAdjustQuantity(skuInfo: ProductSkuDisplay, quantity: number, product: OrderItemModel): OrderItemModel {
    product.productSkuId = skuInfo.id;
    product.unitPriceExcl = skuInfo.exclPrice;
    product.unitPriceIncl = skuInfo.inclPrice;
    product.quantity = quantity;
    product.productSku = skuInfo.model;

    return product;
  }

  /** used by accordions, adjusts quantities for returns  */
  returnAdjustQuantity(skuInfo: ProductSkuDisplay, quantity: number, product: ReturnItem): ReturnItem {
    product.productSkuId = skuInfo.id;
    product.unitPriceExcl = skuInfo.exclPrice;
    product.unitPriceIncl = skuInfo.inclPrice;
    product.quantity = quantity;
    product.productSku = skuInfo.model;

    return product;
  }
}
