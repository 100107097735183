import { Component, Input } from '@angular/core';

@Component({
  selector: 'ov-suite-load-allocation-order-sub-label',
  template: `
    <div style="display: flex; flex-direction: column">
      <div>
        {{ label }}
      </div>
      <span style="font-size: 9px;">
        {{ subLabel }}
      </span>
    </div>
  `,
})
export class SubLabelComponent {
  @Input() label: string;

  @Input() subLabel: string;
}
