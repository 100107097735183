<ng-container *ngIf="enableRefresh">
  <ov-suite-table-refresh
    (refreshEmitter)="refresh($event)"
  ></ov-suite-table-refresh>
</ng-container>

<mat-card class="scrollable-mat-card">
  <ng-container
    *ngIf="hasFilters() || data.length > 0 || !emptyComponent || loading; else emptyComponent">
    <mat-card-title class="hierarchy-card-title">
      <div class="table-title-section">
        <span class="table-title" *ngIf="!titleReplace; else TitleReplace">{{ title }}</span>
        <ng-template #TitleReplace>
          <ng-container *ngComponentOutlet="titleReplace"></ng-container>
        </ng-template>
      </div>
      <div>
        <ng-container *ngTemplateOutlet="cardTableActions"></ng-container>
      </div>
    </mat-card-title>
    <mat-card-content>
      <ng-container *ngTemplateOutlet="cardTableContents"></ng-container>
    </mat-card-content>
  </ng-container>
</mat-card>

<ng-template #cardTableContents>
  <ov-suite-basic-table
    [striped]="true"
    [selectableRows]="selectableRows"
    [clickableRows]="clickableRows"
    [showFiller]="showFiller"
    [data]="data"
    [loading]="loading"
    [columnData]="overrideColumnData || metadata?.table"
    [rowData]="rowData"
    [totalCount]="totalCount"
    [pageSize]="pageSize"
    [currentPage]="page"
    (changePage)="changePage($event)"
    (itemSelect)="onItemSelected($event)"
    [select]="select"
    [back]="back"
    (clickRow)="onRowClick($event)"
    [hideColumnKeys]="hideColumnKeys"
    [keyStore]="metadata?.name"
    (orderChange)="orderChange($event)"
    [columnHider]="customizing"
    (itemEdited)="onItemEdit($event)"
    (rowItemClick)="onRowItemClick($event)"
    (rowItemButtonClick)="onRowItemButtonClick($event)"
    [dropdownData]="dropdownData"
    (changePageSize)="pageSizeInput($event)"
  ></ov-suite-basic-table>
</ng-template>

<ng-template #cardTableActions>
  <div class="table-actions-row">
    <ng-container *ngIf="!!searchMetadata">
      <ov-suite-advanced-search
        [dropdownData]="dropdownData"
        [advancedSearchFields]="advancedSearchFields"
        (filterChange)="filterChange($event)"
        [hideColumnKeys]="hideColumnKeys"
      >
      </ov-suite-advanced-search>
    </ng-container>
    <ng-container *ngIf="hasCreatePermission && !hideAddButton">
      <button mat-button (click)="add()" class="add-new-btn">
        <mat-icon class="fa fa-plus fa-md"></mat-icon>
        Add New
      </button>
    </ng-container>
    <ng-container>
      <button mat-icon-button (click)="customize()">
        <mat-icon *ngIf="!customizing; else customizingIcon"
                  class="fa fa-cog fa-lg"
        ></mat-icon>
        <ng-template #customizingIcon>
          <mat-icon class="fa fa-check fa-lg"></mat-icon>
        </ng-template>
      </button>
    </ng-container>
    <ng-container *ngIf="hasBulkExport">
      <ov-suite-excel-formatter class="filter-item"
                                [legacy]="true"
                                [query]="filter"
                                [formClass]="formClass"
                                [columnData]="overrideColumnData || metadata?.table"
      ></ov-suite-excel-formatter>
    </ng-container>
    <ng-container *ngIf="hasCreatePermission && (hasBulkUpload || hasBulkExport) && (!hasBulkExport && hasBulkUpload)">
      <ov-suite-bulk-upload
        class="ov-suite-bulk-upload"
        *ngIf="hasCreatePermission"
        [metadata]="metadata"
        [filterQuery]="filterMap"
        [searchQuery]="searchMap"
        (hasUploaded)="uploaded($event)"
        #uploadComponent
      ></ov-suite-bulk-upload>
    </ng-container>
    <ng-container *ngIf="bulkActionComponent && hasEditPermission">
      <div ngbDropdown display="dynamic" placement="left-top" class="d-inline-block">
        <button mat-stroked-button color="primary" id="bulkActionDropdown" ngbDropdownToggle>
          Bulk Actions &nbsp; &nbsp;
        </button>
        <div ngbDropdownMenu aria-labelledby="bulkActionDropdown">
          <ng-container *ngTemplateOutlet="bulkActionComponent"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
