import { LoadAllocation } from '@ov-suite/models-warehouse';
import { v4 } from 'uuid';
import { OrderModel } from '@ov-suite/models-order';
import { VehicleSimple } from '@ov-suite/models-admin';
import { EventEmitter } from '@angular/core';

export enum LoadFilter {
  All,
  Unplanned,
  Planning,
  Confirmed,
  Processed,
}

/** Object used to help render Loads */
export class LoadDTO {
  id = v4();

  status: LoadFilter;

  committed = false;

  loadId: number | string;

  vehicleId?: number;

  externalVehicleId?: number;

  vehicleSimple: VehicleSimple;

  orderDTOs: OrderDTO[];

  static fromLoadAllocation(loadAllocation: LoadAllocation, vehicleSimple: VehicleSimple): LoadDTO {
    const output = new LoadDTO();
    if (loadAllocation.releaseDate) {
      output.status = LoadFilter.Processed;
    } else if (loadAllocation.commit) {
      output.status = LoadFilter.Confirmed;
    } else if (loadAllocation.orderIds.length) {
      output.status = LoadFilter.Planning;
    } else {
      output.status = LoadFilter.Unplanned;
    }
    output.vehicleId = loadAllocation.vehicleId;
    output.externalVehicleId = loadAllocation.externalVehicleId;
    output.loadId = loadAllocation.id;
    output.orderDTOs = loadAllocation.orderIds.map(OrderDTO.fromOrderId);
    output.committed = !!loadAllocation.commitDate;
    output.vehicleSimple = vehicleSimple;
    return output;
  }

  getVehicleIdentifier(): string {
    if (this.vehicleId) {
      return `v${this.vehicleId}`;
    }
    return `e${this.externalVehicleId}`;
  }

  public updateStatus(): void {
    if (this.status === LoadFilter.Planning && !this.orderDTOs.length) {
      this.status = LoadFilter.Unplanned;
    } else if (this.status === LoadFilter.Unplanned && this.orderDTOs.length) {
      this.status = LoadFilter.Planning;
    }
  }
}

/** DTO Representing an order for angular rendering */
export class OrderDTO {
  id = v4();

  orderId: number;

  static fromOrderId(orderId: number): OrderDTO {
    const output = new OrderDTO();
    output.orderId = orderId;
    return output;
  }

  static fromOrder(order: OrderModel): OrderDTO {
    const output = new OrderDTO();
    output.orderId = order.id;
    return output;
  }
}

export interface SingleCombo<T> {
  value: T;
  observable: EventEmitter<T>;
}

export interface ListCombo<T> {
  value: T[];
  map?: Record<number, T>;
  all?: T[];
  observable: EventEmitter<T[]>;
}
