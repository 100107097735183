// import Auth from '@aws-amplify/auth';
import { CRUD, getCrud } from '@ov-suite/helpers-shared';
import { BaseUserToken } from '@ov-suite/helpers-shared/lib/user.interface';

type PermissionColumn = 1 | 2 | 4 | 8;
export enum PermissionAction {
  create = 8,
  read = 4,
  update = 2,
  delete = 1,
}

export async function getWebCrud(user: BaseUserToken, permissionId: number): Promise<CRUD> {
  const permissions = user?.permissions ?? {};
  return getCrud(permissions[permissionId] ?? 0);
}

export const verifyPermission = (value: number, column: PermissionColumn): boolean => {
  // eslint-disable-next-line no-bitwise
  return (value | column) === value;
};

export const hasPermissionByFeature = async (user: BaseUserToken, feature: number): Promise<number> => {
  const permissions = user?.permissions ?? {};
  return permissions[feature];
};

export const hasFeaturePermission = async (user: BaseUserToken, feature: number, action: PermissionAction): Promise<boolean> => {
  if (!user) {
    return false;
  }
  const hasPerm = user.permissions[feature];

  if (hasPerm !== undefined) {
    if (verifyPermission(hasPerm, action)) {
      return true;
    }
  }
  return false;
};
