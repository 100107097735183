import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ColumnData, parseParameters, QueryDateRange, QueryParam, QueryParams } from '@ov-suite/helpers-shared';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import { AbstractControl, FormGroup } from '@angular/forms';
import { camelCase } from 'lodash-es';
import {
  FilterChange,
  FormFieldData,
  getBasicFormField, getDateFormField,
  getDropdownFormField, getOtherFormField
} from './advanced-search.component.helpers';
import { tryParse } from '@ov-suite/helpers-angular/lib/try-parse.helper';

@Component({
  selector: 'ov-suite-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent<T = unknown> implements OnInit {

  @Input() advancedSearchFields: ColumnData<T>[];

  @Input() hideColumnKeys = [];

  @Input() dropdownData = {};

  currentFilter: FilterChange;

  formControls: FormFieldData[] = [];

  formGroup: FormGroup;

  simpleSearchInput = '';

  filterMap: Record<string, QueryParams[]> = {};

  queryMap: Record<string, QueryParams[]> = {};

  searchMap: Record<string, QueryParams[]> = {};

  // Acts as an array of all searchable fields
  parentFilter: Record<string, QueryParams[]> = {};

  @Output() filterChange = new EventEmitter<FilterChange>();

  @ViewChild('advancedSearchModal') advancedSearchModal: TemplateRef<any>;

  constructor(private readonly route: ActivatedRoute, private readonly confirmationDialogService: ConfirmationDialogService) {}

  ngOnInit() {
    if (!this.hideColumnKeys) {
      this.hideColumnKeys = [];
    }

    this.currentFilter = {
      filter: {},
      query: {},
      search: {}
    }

    this.route.queryParamMap.subscribe(params => {
      if (params) {
        this.prepareSearchFields(params);

        this.prepareAdvancedSearchableFields();

        this.prepareSimpleSearchFields(params);
      }
    });
  }

  prepareSearchFields(params: ParamMap) {
    // Reset form controls and start from scratch
    this.formControls = [];

    this.parentFilter = { ...parseParameters(QueryParam.filter, params), ...parseParameters(QueryParam.query, params) }

    this.advancedSearchFields.forEach(field => {
      if (!field.disableFiltering) {
        const formControl = this.generateFormControl(field);
        if (formControl) {
          this.formControls.push(formControl);
        }
      }
    });
  }

  prepareAdvancedSearchableFields() {
    this.formGroup = new FormGroup({});

    this.formControls.forEach(formControl => {
      this.formGroup.addControl(formControl.key, formControl.control)
    })
  }

  generateFormControl(field: ColumnData<T>): FormFieldData {
    if (!this.isFilterable(field)) {
      return null;
    }

    const defaultValue = this.getDefaultValue(field);
    const formControlMapKey = camelCase(field.title);

    switch (field.type) {
      case 'string':
      case 'deep-string':
      case 'number':
        return getBasicFormField(formControlMapKey, field.type, field.title, field.key as string, defaultValue);
      case 'dropdown':
        const options = this.dropdownData[field.displayKeys[0]];
        return getDropdownFormField(formControlMapKey, field.title, field.keys as unknown as string[], options, defaultValue as []);
      case 'other':
      case 'pills':
        const type = field.filterKey ? 'deep-string' : 'other';
        const keys = field.keys.length > 1 ? field.keys  : [field.keys[0]];
        return getOtherFormField(formControlMapKey, type, field.title, keys, defaultValue as QueryParams);
      case 'date':
        return getDateFormField(formControlMapKey, field.title, field.key as string, defaultValue as QueryDateRange);
      default:
        return null;
    }
  }

  prepareSimpleSearchFields(params: ParamMap) {
    // Simple Search Fields
    const searchFieldValues = parseParameters(QueryParam.search, params);

    try {
      const validValueField = this.formControls.find(control => control.metadata.type !== 'number' && control.metadata.key);
      const validValue = searchFieldValues[validValueField.metadata.key];
      this.simpleSearchInput = validValue[0].toString();
    } catch (e) {
      this.simpleSearchInput = '';
    }
  }

  getDefaultValue(field: ColumnData<T>): QueryParams[] | QueryDateRange | [] {
    if (field.type === 'dropdown') {
      return this.parentFilter[field.keys[0]] || [];
    }

    if (field.type === 'date') {
      const defaultValue = this.parentFilter[field['key'] as string] || [];
      return defaultValue.map(value => tryParse(value) as QueryDateRange);
    }

    if (field.keys) {
      return this.parentFilter[field.keys[0]] || null;
    }

    return this.parentFilter[field['key']] || null;
  }

  isFilterable(field: ColumnData<T>): boolean {
    if (field.keys) {
      return !field.keys.some(key => this.hideColumnKeys?.includes(key));
    }

    return !this.hideColumnKeys?.includes(field['key']);
  }

  onSimpleSearchChange(inputChange: string) {
    this.simpleSearchInput = inputChange.trim();

    if (!inputChange) {
      this.onChangeSearchFields(null);
    } else {
      this.onChangeSearchFields(inputChange.trim());
    }

    this.currentFilter = {
      filter: this.filterMap,
      query: this.queryMap,
      search: this.searchMap
    }

    this.filterChange.emit(this.currentFilter);
  }

  /**
   * All query param types will be a search
   * @param changeValue
   */
  onChangeSearchFields(changeValue: string) {
    const updateValue = changeValue ?? null;

    this.formControls.forEach(control => {
      switch (control.metadata.type) {
        case 'date': break;
        case 'number': {
          if (!updateValue) {
            this.setMapValue(QueryParam.search, control.metadata.key, null);
            break;
          }

          const valueChange = parseInt(updateValue);
          if (updateValue && !isNaN(valueChange)) {
            this.setMapValue(QueryParam.search, control.metadata.key, valueChange);
          }
          break;
        }
        default: {
          if (control.metadata.keys) {
            control.metadata.keys.forEach(key => {
              this.setMapValue(QueryParam.search, key, updateValue);
            });
            break;
          }

          this.setMapValue(QueryParam.search, control.metadata.key, updateValue);
        }
      }
    })
  }

  onClearSimpleSearch() {
    this.onSimpleSearchChange('');
  }

  onActivateAdvancedSearch() {
    const confirmationDialogData: ConfirmationDialogData = {
      component: this.advancedSearchModal,
      overrideActions: true,
      preventClose: true,
    }

    this.confirmationDialogService.openComponentDialog(confirmationDialogData);
  }

  clearAdvancedFilters() {
    this.formControls.forEach(control => this.clearField(control));
  }

  applyFilter() {
    this.formControls.forEach(control => {
      const controlField: AbstractControl = this.formGroup.get(control.key);

      if (controlField.dirty) {
        this.onFilterChange(control, controlField);
      }
    })

    this.currentFilter = {
      filter: this.filterMap,
      query: this.queryMap,
      search: this.searchMap
    }

    this.filterChange.emit(this.currentFilter);

    this.confirmationDialogService.forceClose();
  }

  onFilterChange(control: FormFieldData, controlField: AbstractControl) {
    const type = control.metadata.type;
    const changeValue = controlField.value ?? null;

    switch(type) {
      case 'string':
      case 'deep-string':
         this.setMapValue(QueryParam.filter, control.metadata.key, changeValue);
         break;
      case 'number': {
        const valueChange = parseInt(controlField.value);
        if (!isNaN(valueChange)) {
          this.setMapValue(QueryParam.filter, control.metadata.key, changeValue);
        }
        break;
      }
      case 'other':
      case 'dropdown':
        control.metadata.keys.forEach(key => {
          this.setMapValue(QueryParam.filter, key, changeValue);
        })
        break;
      case 'pills': {
        control.metadata.keys.forEach(key => {
          this.setMapValue(QueryParam.search, key, changeValue);
        })
        break;
      }
      case 'date': {
        const { start, end } = controlField.value;
        const dateRange: QueryDateRange = { type: 'date-range', from: start, to: end };
        this.setMapValue(QueryParam.query, control.metadata.key, dateRange);
        break;
      }
    }
  }

  clearField(control: FormFieldData) {
    if (control.metadata.keys) {
      control.metadata.keys.forEach(key => {
        switch (control.queryParamType) {
          case QueryParam.filter:
            this.setMapValue(QueryParam.filter, key, null);
            break;
          case QueryParam.search:
            this.setMapValue(QueryParam.search, key, null);
            break;
          case QueryParam.query:
            this.setMapValue(QueryParam.query, key, null);
            break;
        }
      })
    } else {
      switch (control.queryParamType) {
        case QueryParam.filter:
          this.setMapValue(QueryParam.filter, control.metadata.key, null);
          break;
        case QueryParam.search:
          this.setMapValue(QueryParam.search, control.metadata.key, null);
          break;
        case QueryParam.query:
          this.setMapValue(QueryParam.query, control.metadata.key, null);
          break;
      }
    }

    this.formGroup.get(control.key).reset();
  }

  setMapValue(param: QueryParam, key: string, value: string | number | QueryDateRange) {
    switch (param) {
      case QueryParam.filter: {
        this.filterMap[`${QueryParam.filter}:${key}`] = value ? [value as QueryParams] : null;
        break;
      }
      case QueryParam.search : {
        this.searchMap[`${QueryParam.search}:${key}`] = value ? [value as QueryParams] : null;
        break;
      }
      case QueryParam.query : {
        if (value && value.hasOwnProperty('type') && value['type'] === 'date-range') {
          const dateRange = value as unknown as QueryDateRange;
          if (dateRange.from && dateRange.to) {
            this.queryMap[`${QueryParam.query}:${key}`] = [value as QueryDateRange];
            break;
          }
        }
        this.queryMap[`${QueryParam.query}:${key}`] = null;
        break;
      }
    }
  }

  forceClose() {
    this.confirmationDialogService.forceClose();
  }
}
