import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { InventoryContainer } from '@ov-suite/models-warehouse';
import { UserModel } from '@ov-suite/models-idm';
import { Inventory } from './inventory.model';
import { StagingItemModel } from './staging-item.model';

@OVEntity({ name: 'StagingItemLog' })
export class StagingItemLogModel {
  @OVField({
    type: 'number',
    generated: true,
  })
  id: number;

  @OVField({
    type: () => StagingItemModel
  })
  stagingItem: StagingItemModel;

  @OVField({
    type: 'number',
  })
  quality: number;

  @OVField({
    type: 'date'
  })
  createdDate: Date;

  @OVField({
    type: 'date'
  })
  completedDate?: Date;

  @OVField({
    type: () => UserModel
  })
  handledByUser?: UserModel;

  @OVField({
    type: 'string',
  })
  exceptionMessage?: string;

  @OVField({
    type: () => InventoryContainer,
  })
  containerStaged: InventoryContainer;

  @OVField({
    type: () => [Inventory],
  })
  inventoryStaged: Inventory[];

  @OVField({
    type: () => InventoryContainer
  })
  source: InventoryContainer;

  @OVField({
    type: () => InventoryContainer
  })
  target: InventoryContainer;

  @OVField({
    type: 'string'
  })
  comment: string;
}
