<div class="refresh-button-group">
  <ng-container *ngIf="live; else liveButton">
    <button mat-flat-button color="warn" (click)="onLiveClick()">Live</button>
  </ng-container>
  <ng-template #liveButton>
    <button mat-stroked-button color="warn" (click)="onLiveClick()">Live</button>
  </ng-template>

  <button mat-stroked-button color="primary" (click)="refresh()">Refresh</button>
</div>
