<div class="main-content">
  <div class="outer">
    <div class="split-container-1">
      <div class="wave-allocation-header">
        <div class="wave-allocation-header-left">
          <span class="mr-2">Date Range</span>
          <ov-suite-input type="date-range" placeholder="Date Range" [(ngModel)]="dataService.dateRange"> </ov-suite-input>
        </div>
        <div>
          <button [disabled]="!baseService.permissions.create" (click)="onOpenLoadSheet()" class="btn btn-md">Print Load Sheet</button>
        </div>
      </div>
      <as-split direction="horizontal" restrictMove="true" [useTransition]="true" #vertical>
        <as-split-area size="70">
          <ov-suite-wave-allocation-planner></ov-suite-wave-allocation-planner>
        </as-split-area>

        <as-split-area size="30">
          <as-split direction="vertical" restrictMove="true" #rightSide>
            <as-split-area size="60">
              <ov-suite-wave-allocation-loads></ov-suite-wave-allocation-loads>
            </as-split-area>
            <as-split-area size="40" *ngIf="action.loadSelected.value">
              <ov-suite-wave-allocation-detail #details></ov-suite-wave-allocation-detail>
            </as-split-area>
          </as-split>
        </as-split-area>
      </as-split>
    </div>
  </div>
</div>
