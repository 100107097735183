import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { OvAutoService } from '@ov-suite/services';
import { GenericHierarchy } from '@ov-suite/ov-metadata';
import { HierarchyListComponent } from '../../hierarchy-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';

type GenericHierarchyType = GenericHierarchy;

@Component({
  selector: 'ov-suite-ceramic-hierarchy-list',
  templateUrl: './ceramic-hierarchy-list.component.html',
  styleUrls: ['./ceramic-hierarchy-list.component.scss'],
})
export class CeramicHierarchyListComponent<T extends GenericHierarchyType> extends HierarchyListComponent<T> {
  @Input() showTopBar = false;

  constructor(
    private readonly ceramicListRoute: ActivatedRoute,
    private readonly ceramicListRouter: Router,
    private readonly ceramicListLocation: Location,
    private readonly ceramicListAutoService: OvAutoService,
    private readonly authenticationService2: AuthenticationService,
    ngbModal: NgbModal,
  ) {
    super(ceramicListRoute, ceramicListRouter, ceramicListLocation, ceramicListAutoService, ngbModal, authenticationService2);
  }
}
