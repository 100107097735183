import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function createOrderInvoice(): DocumentNode {
  return gql(
    `mutation createOrderInvoice($data: InvoiceCreateInput!) {
            createOrderInvoice(data: $data) { id }
          }`,
  );
}

export function reloadOrderInvoice(): DocumentNode {
  return gql(
    `mutation reloadOrderInvoice($data: InvoiceUpdateInput!) {
            reloadOrderInvoice(data: $data) { id }
          }`,
  );
}
