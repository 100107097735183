<button mat-button
        mat-stroked-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
>
  {{ title }}
</button>
<mat-menu #menu="matMenu">
  <mat-selection-list role="list">
    <mat-list-option
      role="listitem"
      *ngFor="let line of list"
      class="dropdown-line"
      [selected]="selectionState.get(line.value)"
      (click)="itemClicked(line.value, $event)"
    >
        {{line.label}}
    </mat-list-option>
  </mat-selection-list>
</mat-menu>
