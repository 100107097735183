<ov-suite-clean-table
  [dataSource]="tableDataSource"
  [columnData]="tableColumnData"
  [disablePaging]="true"
  [disableSorting]="true"
  emptyMessage="No customer stage instructions"
>
</ov-suite-clean-table>

<ng-template #editStageInstruction>
  <ov-suite-stage-types-selector-dialog
    (emitter)="updateStageInstruction($event)"
    [stageTypes]="stageTypes"
    [stageInstruction]="selectedStageInstruction"
  >
  </ov-suite-stage-types-selector-dialog>
</ng-template>

<br>

<div class="add-btn">
  <button mat-button
          mat-stroked-button
          color="primary"
          (click)="add()"
  >
    Add
  </button>
</div>
