import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadEvent } from './load-allocation.events';

@Injectable()
export class LoadAllocationEventBusService {
  public readonly events$: Subject<LoadEvent> = new Subject<LoadEvent>();

  public triggerEvent(event: LoadEvent) {
    this.events$.next(event);
  }
}
