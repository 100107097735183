import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableTopTabMenuItem } from './table-top-tab-menu.model';
import { Router } from '@angular/router';

@Component({
  selector: 'ov-suite-table-top-tab-menu',
  templateUrl: './table-top-tab-menu.component.html',
  styleUrls: ['./table-top-tab-menu.component.scss'],
})
export class TableTopTabMenuComponent implements OnInit {
  constructor(private readonly router: Router) {}

  @Input() tabs: TableTopTabMenuItem[] = [];
  @Output() tabClick = new EventEmitter<string>();

  ngOnInit() {}

  onTabClick(key) {
    this.tabClick.emit(key);
  }

  isActive(path: string) {
    return path == this.router.url;
  }
}
