<ng-container *ngIf="viewService.selectedOrderDTO$ | async as orderDTO">
  <ng-container *ngIf="orderService.get$(orderDTO.orderId) | async as order">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Order Items</a>
        <ng-template ngbNavContent>
          <table class="table table-sm" id="order-list">
            <thead>
              <tr>
                <th>Name</th>
                <th>Stage Type</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody id="order-item-list">
              <tr *ngFor="let orderItem of order.orderItems" class="border">
                <td>
                  <ng-container *ngIf="productSkuService.get$(orderItem.productSkuId) | async as sku; else loader">
                    {{ sku.name }}
                  </ng-container>
                </td>
                <td>
                  <ng-container *ngIf="productSkuService.get$(orderItem.productSkuId) | async as sku;">
                    <ng-container *ngIf="sku.stageType; else noStageType">
                      {{ sku.stageType.name }}
                    </ng-container>
                    <ng-template #noStageType> - </ng-template>
                  </ng-container>
                </td>
                <td>{{ orderItem?.quantity }}</td>
              </tr>
            </tbody>
          </table>
          <div class="row" style="padding: 5%">
            <button class="btn btn-primary" (click)="splitOrder()">Split</button>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Order Info</a>
        <ng-template ngbNavContent>
          <div class="simple-table">
            <div class="simple-row">
              <div>Priority</div>
              <div>
                <span [class]="'badge ' + orderPriority[order?.priority]?.color">
                  {{ orderPriority[order.priority]?.text }}
                </span>
              </div>
            </div>
            <div class="simple-row">
              <div>Order ID</div>
              <div>{{ order?.orderCode }}</div>
            </div>
            <div class="simple-row">
              <div>Order Placement Date</div>
              <div>{{ order?.orderDate | date }}</div>
            </div>
            <div class="simple-row">
              <div>Delivery Date</div>
              <div>{{ order?.fulfilmentDate }}</div>
            </div>
            <div class="simple-row">
              <div>SLA Remaining</div>
              <div>
                {{
                  getSLA(order.orderDate, order?.fulfilmentDate)
                }}
              </div>
            </div>
            <div class="simple-row">
              <div>Total Weight</div>
              <ng-container *ngIf="orderService.getLoadTotalWeight$(order.id) | async as weight; else loader">
                <div>{{ weight }} kg</div>
              </ng-container>
            </div>
            <div class="simple-row">
              <div>Total Volume</div>
              <ng-container *ngIf="orderService.getLoadTotalVolume$(order.id) | async as volume; else loader">
                <div>{{ volume }} m³</div>
              </ng-container>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Customer Details</a>
        <ng-template ngbNavContent>
          <div class="simple-table">
            <ng-container *ngIf="customerService.get$(order.customerId) | async as customer; else loader">
              <div class="simple-row">
                <div>Name</div>
                <div>{{ customer.name }}</div>
              </div>
              <div class="simple-row">
                <div>Code</div>
                <div>{{ customer.customerCode }}</div>
              </div>
              <div class="simple-row">
                <div>Description</div>
                <div>{{ customer.description }}</div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </ng-container>
</ng-container>

<ng-template #loader>
  <ov-suite-load-loader></ov-suite-load-loader>
</ng-template>
