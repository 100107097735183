import { OVEntity, OVField, OVSearchable } from '@ov-suite/ov-metadata';
import { ProductSkuModel } from './product-sku.model';

@OVEntity({ name: 'ProductSkuPartial' })
export class ProductSkuPartial {
  @OVField({ type: 'number' })
  id: number;

  @OVSearchable()
  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'boolean' })
  storageUnit: boolean;

  @OVField({ type: 'string' })
  barcodeData: string;

  @OVField({ type: 'number' })
  salesLimit: number;
}

@OVEntity({ name: 'ProductSkuConfiguration', tableName: 'product_sku_configuration' })
export class ProductSkuConfiguration {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: () => ProductSkuPartial })
  productSku: ProductSkuPartial;

  // @OVField({ type: () => ProductSku })
  // origin: ProductSku;
  //
  // @OVField({ type: 'number' })
  // originId: number;
}
