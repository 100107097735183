import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InvoiceModel } from '@ov-suite/models-order';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DocumentNode } from 'graphql';
import { MutationResult } from 'apollo-angular';
import { OvAutoService } from '@ov-suite/services';
import { interval, Subscription } from 'rxjs';
import { reloadOrderInvoice } from '../load-amount-tab/load-amount.graphql';
import { InvoiceDownloadService } from '../invoice-download.service';

enum InvoiceStatus {
  'EXCEPTION' = 'EXCEPTION',
  'GENERATING' = 'GENERATING',
  'SUCCESS' = 'SUCCESS',
}

interface InvoiceDisplay {
  id: number;
  orderCode: string;
  invoiceNumber: string;
  exception?: string;
  invoiceStatus?: InvoiceStatus;
}

@Component({
  selector: 'ov-suite-invoice-download-dialog',
  templateUrl: './invoice-download-dialog.component.html',
  styleUrls: ['./invoice-download-dialog.component.css'],
})
export class InvoiceDownloadDialogComponent implements OnInit, OnDestroy {
  @Input() selectedOrderIds: number[] = [];

  @Input() selectedLoadId: number;

  displayColumns: string[] = ['select', 'orderCode', 'invoiceNumber', 'exception', 'action'];

  dataSource = new MatTableDataSource<InvoiceDisplay>();

  selection = new SelectionModel<InvoiceDisplay>(true, []);

  intervalChecker: Subscription;

  constructor(private readonly invoiceDownloadService: InvoiceDownloadService, private readonly ovAutoService: OvAutoService) {}

  ngOnInit(): void {
    this.fetchData();

    // We have to stay on the screen for any updates.
    this.intervalChecker = interval(30000).subscribe(() => {
      this.fetchData();
    });

    this.invoiceDownloadService.invoices.subscribe(data => {
      this.selection.clear();

      function getInvoiceStatus(invoiceItem: InvoiceModel) {
        if (invoiceItem.invoiceException) {
          return InvoiceStatus.EXCEPTION;
        }

        if (!invoiceItem.invoiceException && !invoiceItem.commitDate) {
          return InvoiceStatus.GENERATING;
        }

        if (invoiceItem.commitDate && !invoiceItem.invoiceException && invoiceItem.invNum) {
          return InvoiceStatus.SUCCESS;
        }

        return null;
      }

      this.dataSource.data = data?.map(i => ({
        id: i.id,
        orderCode: i.order.orderCode,
        invoiceNumber: i?.invNum ?? ' - ',
        exception: i.invoiceException,
        invoiceStatus: getInvoiceStatus(i),
      }));
    });
  }

  ngOnDestroy() {
    this.intervalChecker.unsubscribe();
  }

  fetchData() {
    this.invoiceDownloadService.fetchLoadInvoiceOrderList(this.selectedLoadId, this.selectedOrderIds);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: InvoiceDisplay): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  reloadInvoice(event, invoice: InvoiceModel) {
    event.stopPropagation();

    const payload = {
      data: {
        id: invoice.id,
        invoiceException: null,
        commitDate: null,
      },
    };

    this.executeGraphQL(reloadOrderInvoice(), payload).then(() => {
      this.fetchData();
    });
  }

  async onDownloadInvoices() {
    for (const selection of this.selection.selected) {
      // eslint-disable-next-line no-await-in-loop
      await this.downloadInvoice(selection.id, selection.orderCode, selection.invoiceNumber);
    }
  }

  executeGraphQL<T, U>(query: DocumentNode, data: T): Promise<MutationResult<U>> {
    return this.ovAutoService.apollo
      .mutate<U>({
        mutation: query,
        fetchPolicy: 'no-cache',
        variables: {
          ...data,
        },
      })
      .toPromise();
  }

  async downloadInvoice(invoiceId: number, orderCode: string, invoiceNumber: string) {
    await this.invoiceDownloadService.exportInvoice(invoiceId, orderCode, invoiceNumber);
  }
}
