import { Component } from '@angular/core';
import moment from 'moment/moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { LoadAllocationActionService } from '../../services/load-allocation.action.service';
import { LoadAllocationProductSkuService } from '../../services/data-services/load-allocation.product-sku.service';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAllocationOrderService } from '../../services/data-services/load-allocation.order.service';
import { LoadAllocationCustomerService } from '../../services/data-services/load-allocation.customer.service';
import { SplitComponent } from '../../components/split/split.component';

@Component({
  selector: 'ov-suite-load-allocation-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent {
  active = 1;

  orderPriority: Record<number, { text: string; color: string }> = {
    1: { text: 'Critical', color: 'badge-danger' },
    2: { text: 'High', color: 'badge-orange' },
    3: { text: 'Medium', color: 'badge-warning' },
    4: { text: 'Low', color: 'badge-success' },
  };

  constructor(
    public readonly base: LoadAllocationBaseService,
    public readonly action: LoadAllocationActionService,
    private readonly modalService: NgbModal,
    public readonly productSkuService: LoadAllocationProductSkuService,
    public readonly viewService: LoadAllocationViewService,
    public readonly orderService: LoadAllocationOrderService,
    public readonly customerService: LoadAllocationCustomerService,
    public dialog: MatDialog,
  ) {}

  getSLA(orderDate: string, fulfilDate: string): string {
    if (!orderDate || !fulfilDate) {
      return '0 days';
    }
    return `${moment(fulfilDate).diff(moment(orderDate), 'days')} days`;
  }

  splitOrder(): void {
    this.dialog.open(SplitComponent, {
      width: '1000px',
    });
  }
}
