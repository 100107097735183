<mat-card *ngIf="dataService.loading; else invoiceData" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner color="primary" mode="indeterminate">
  </mat-progress-spinner>
</mat-card>

<ng-template #invoiceData>
  <mat-card class="mat-elevation-z0">
    <mat-card-content>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0">
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
          <th mat-header-cell *matHeaderCellDef> {{columnsToDisplayName[column]}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row"
                    (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
              <i aria-hidden="true" *ngIf="expandedElement !== element" class="fa fa-chevron-down"></i>
              <i aria-hidden="true" *ngIf="expandedElement === element" class="fa fa-chevron-up"></i>
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
            <div class="example-element-detail" [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
              <div class="example-element-description" style="width: 100%">
                <ov-suite-extended-order-invoice-table [orderInvoice]="element"></ov-suite-extended-order-invoice-table>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
        <tr mat-row [ngClass]="{'highlight': element.hasConflict }"
            *matRowDef="let element; columns: columnsToDisplayWithExpand;"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="cancelDialog()">Cancel</button>
      <button mat-button color="primary"
              [class.spinner]="loading"
              [disabled]="loading"
              (click)="onSaveInvoice()"
              cdkFocusInitial
      >
        Submit
      </button>
    </mat-card-actions>
  </mat-card>
</ng-template>
