import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { DomainService } from '@ov-suite/helpers-angular';
import { DomainModel } from '@ov-suite/models-admin';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';
import { RouteInfo } from '../sidebar/sidebar.model';

interface INavbarDomains {
  id: number;
  title: string;
  url: string;
  hasPermission: boolean;
}

@Component({
  selector: 'ov-suite-navbar',
  templateUrl: 'navbar.component.html',
  styleUrls: ['navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() routes: RouteInfo[];

  listTitles: RouteInfo[];

  public open = false;

  navbarApps: INavbarDomains[] = [];

  domains: DomainModel[] = [];

  selectedDomainId: number;

  showNotifications = false;

  notificationCount = 0;

  @Output() openNotifications = new EventEmitter();

  @Input() set notifications(event: number) {
    this.notificationCount = event;
  }

  @Input() set isNotificationOpen(event: boolean) {
    this.showNotifications = event;
  }

  @Input() showDomains = true;

  selectedDomainName: string;

  @ViewChild('ov-suite-navbar', { static: false }) button;

  sidebarVisible = true;

  toggles = [false, false, false];

  subscriptions: Subscription[] = [];

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly domainService: DomainService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.subscriptions.push(
      domainService.allDomains.subscribe({
        next: () => this.updateDomainsList(),
      }),
    );
    this.subscriptions.push(
      domainService.currentDomain.subscribe({
        next: () => this.updateSelectedDomain(),
      }),
    );
    this.subscriptions.push(
      authenticationService.user.subscribe({
        next: () => this.updateApps(),
      }),
    );
  }

  ngOnInit() {
    this.listTitles = this.routes;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  updateDomainsList() {
    const user = this.authenticationService.user.value;
    const domains = this.domainService.getByIds(user?.domainIds ?? []);
    this.domains = [...domains].sort((a, b) => a.name.localeCompare(b.name));
  }

  updateSelectedDomain() {
    const selectedDomain = this.domainService.currentDomain.value;
    this.selectedDomainId = selectedDomain?.id;
    this.selectedDomainName = selectedDomain?.name;
  }

  updateApps() {
    const user = this.authenticationService.user.value;
    if (!user) {
      return;
    }
    const allApps = this.getDefaultApps();
    user.appIds.forEach(appId => {
      allApps.forEach(app => {
        if (app.id === appId) {
          app.hasPermission = true;
        }
      });
    });
    this.navbarApps = allApps;
  }

  generateNavbarDomains() {
    const user = this.authenticationService.user.value;

    if (!user) {
      return;
    }

    user.appIds.forEach(appId => {
      this.navbarApps.forEach(app => {
        if (app.id === appId) {
          app.hasPermission = true;
        }
      });
    });

    if (user.domainIds?.length) {
      const domains = this.domainService.getByIds(user.domainIds);

      this.domains = [...domains].sort((a, b) => a.name.localeCompare(b.name));
      const selectedDomain = this.domainService.currentDomain.value;

      this.selectedDomainId = selectedDomain?.id;
      this.selectedDomainName = selectedDomain?.name;
    }
  }

  getDefaultApps() {
    return [
      {
        id: environment.appId.admin,
        title: 'Admin Link',
        url: getUrl('admin'),
        hasPermission: false,
      },
      {
        id: environment.appId.idm,
        title: 'Account Link',
        url: getUrl('idm'),
        hasPermission: false,
      },
      {
        id: environment.appId.order,
        title: 'Order Link',
        url: getUrl('order'),
        hasPermission: false,
      },
      {
        id: environment.appId.warehouse,
        title: 'Warehouse Link',
        url: getUrl('warehouse'),
        hasPermission: false,
      },
    ];
  }

  minimizeSidebar() {
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  sidebarOpen() {
    const html = document.getElementsByTagName('html')[0];
    setTimeout(() => {}, 500);

    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  getTitle(): string {
    let title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(1);
    }

    for (const parent of this.listTitles) {
      if (parent.path === title) {
        return parent.title;
      }

      if (parent.children) {
        let childrenFromUrl = title.split('/')[1];
        if (childrenFromUrl.includes('?')) {
          const [path] = childrenFromUrl.split('?');
          childrenFromUrl = path;
        }

        for (const current of parent.children) {
          if (current.path === childrenFromUrl) {
            return `${parent.title || ''} - ${current.title}`;
          }
        }
      }
    }
    return 'dashboard';
  }

  navigate(url: string) {
    window.location.assign(url);
  }

  setDomain(path: DomainModel) {
    this.domainService.setDomain(path);
    window.location.reload();
  }

  navigateBack() {
    this.location.back();
  }

  toggle(index: number) {
    if (this.toggles[index]) {
      this.toggles[index] = false;
    } else {
      for (let i = 0; i < this.toggles.length; i++) {
        this.toggles[i] = false;
      }
      this.toggles[index] = true;
    }
    this.toggleNotifications();
  }

  toggleNotifications() {
    this.openNotifications.emit(this.toggles[2]);
  }
}
