import { HasId } from '@ov-suite/ov-metadata';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

export class MatCustomDataSource<T> extends MatTableDataSource<T> {
  getPageObserver(): BehaviorSubject<T[]> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._renderData;
  }
}
