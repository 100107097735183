<h2 mat-dialog-title>Change Wave</h2>
<mat-dialog-content class="mat-typography">

  <h5 class="my-1">Select Date</h5>
  <mat-form-field class="w-100" appearance="fill">
    <mat-label>Choose a date</mat-label>
    <input matInput (dateChange)="dataService.fetchWaveInstances($event.value)" [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <h5 class="my-1">Select Wave</h5>
  <mat-form-field class="w-100" appearance="fill">
    <mat-label>Waves</mat-label>
    <mat-select [(ngModel)]="selectedWave">
      <mat-option *ngFor="let wave of dataService.waveInstances.value" [value]="wave">
        {{wave.waveConfig.waveName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-button [disabled]="!selectedWave" (click)="onSave()" cdkFocusInitial>Set Wave</button>
</mat-dialog-actions>
