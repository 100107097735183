import { OVField, OVSearchable, OVEntity, OVTable } from '@ov-suite/ov-metadata';
import { OrderEventType } from '@ov-suite/helpers-shared';
import { OrderModel } from './order.model';

@OVEntity('OrderEvent', 'warehouselink')
@OVTable<ReturnEventModel>([
  {
    key: 'order.orderCode',
    title: 'Order Code',
    type: 'deep-string',
  },
  {
    key: 'action',
    title: 'Action',
    type: 'string',
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string',
  },
  {
    key: 'actionedBy',
    title: 'Actioned By',
    type: 'string',
  },
  {
    title: 'Timestamp',
    type: 'date',
    format: 'DD/MM/YYYY hh:mm:ss A',
    key: 'timestamp',
    filterKey: 'entryTime',
  },
])
export class ReturnEventModel {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: false,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Actioned By',
    placeholder: '',
    required: true,
  })
  actionedBy: string;

  @OVField({
    type: () => OrderModel,
    title: 'Order',
    selectionType: 'simple',
    keys: ['id', 'orderCode'],
    placeholder: '',
    required: false,
  })
  order?: OrderModel;

  @OVField({
    type: 'string',
    title: 'Description',
    tooltip: 'Description',
    placeholder: '',
    required: true,
  })
  description: string;

  @OVField({
    type: 'string',
    title: 'Action',
    tooltip: 'Action',
    placeholder: '',
    required: true,
  })
  action: OrderEventType;

  @OVField({
    type: 'date-time',
    title: 'Timestamp',
    placeholder: '',
    required: false,
  })
  timestamp: string;
}
