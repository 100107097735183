<ng-container *ngIf="seals.length">
  <mat-card-subtitle class="load-management-details-title">Seal Information</mat-card-subtitle>
  <mat-card-content>
    <ov-suite-clean-table
      [dataSource]="sealsDataSource"
      [columnData]="loadDetailSealColumnData"
      [disablePaging]="true"
      [disableSorting]="true"
      emptyMessage="No Users Recorded"
    >
    </ov-suite-clean-table>
  </mat-card-content>
</ng-container>
<ng-container *ngIf="wraps.length">
  <mat-card-subtitle class="load-management-details-title">Wrap Information</mat-card-subtitle>
  <mat-card-content>
    <ov-suite-clean-table
      [dataSource]="wrapsDataSource"
      [columnData]="loadDetailWrapColumnData"
      [disablePaging]="true"
      [disableSorting]="true"
      emptyMessage="No Users Recorded"
    >
    </ov-suite-clean-table>
  </mat-card-content>
</ng-container>

<ng-template #editSealModal>
  <mat-card class="dialog-card-content mat-elevation-z0">
    <mat-card-content>
      <form class="edit-load-detail" [formGroup]="editSealForm">
        <mat-form-field appearance="fill">
          <mat-label>Seal Number</mat-label>
          <input matInput
                 placeholder="Placeholder"
                 formControlName="sealNumber"
                 name="sealNumber"
                 id="sealNumber"
          >
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Order</mat-label>
          <mat-select
            id="orderId"
            formControlName="orderId"
          >
            <mat-option *ngFor="let order of dropdownData"
                        [value]="order.key"
            >
              {{ order.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
