<div class="main-content">
  <div class="main-container">
    <h4>Process Definitions</h4>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 cursor-pointer" *ngFor="let model of flowableModels" (click)="viewDetails(model)">
            <div class="card p-0">
              <div class="card-header position-absolute header-background w-100">
                <h5>{{ model.name }}</h5>
              </div>
              <div class="card-body workflow-image">
                <img
                  *ngIf="model?.diagramResource"
                  [src]="apiUrl + '/api/process-instance/deployment/url?value=' + model.diagramResource"
                  alt="Flow Chart"
                />
                <img *ngIf="!model?.diagramResource" src="../../../../assets/img/logo.png" alt="Flow Chart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
