<div class="outer-container">
  <div class="main-container">
    <mat-card>
      <div class="filter-row">
        <ov-suite-multi-select-dropdown
          #productFilter
          class="filter-item"
          title="Product"
          [list]="productList"
          (change)="setProductFilters($event)"
        ></ov-suite-multi-select-dropdown>
        <ov-suite-multi-select-dropdown
          #statusFilter
          class="filter-item"
          title="Status"
          [list]="statusList"
          (change)="setStatusFilter($event)"
        ></ov-suite-multi-select-dropdown>
        <button mat-stroked-button class="filter-item" (click)="clearFilters()">Clear Filters</button>
        <ng-container *ngIf="!(loadManagementDataService.load | async)?.completeDate">
          <button mat-stroked-button
                  color="primary"
                  class="filter-item"
                  (click)="openAddModal()"
          >
            Add
          </button>
          <button mat-stroked-button
                  color="warn"
                  class="filter-item"
                  [disabled]="!resettableStageItems$.value.size"
                  (click)="reset()"
          >
            Reset
          </button>
          <button mat-stroked-button
                  color="warn"
                  class="filter-item"
                  [disabled]="!cancellableStageItems$.value.size"
                  (click)="cancel()"
          >
            Cancel
          </button>
          <button mat-stroked-button
                  color="warn"
                  class="filter-item"
                  [disabled]="!pausableStageItems$.value.size"
                  (click)="pause()"
          >
            {{shouldUnpause() ? 'Unpause' : 'Pause'}}
          </button>
        </ng-container>
      </div>

      <ov-suite-clean-table
        #stagingTable
        [columnData]="columnData"
        [dataSource]="dataSource"
        [selectable]="!(loadManagementDataService.load | async)?.completeDate"
        (selected)="setSelected($event)"
        emptyMessage="No Picks Created"
      >
      </ov-suite-clean-table>
    </mat-card>
  </div>
</div>

<ng-template #cancellableStageTable>
  <ov-suite-clean-table
    [dataSource]="cancellableStageItemsDataSource"
    [columnData]="columnData"
    [disablePaging]="true"
    [disableSorting]="true"
  >
  </ov-suite-clean-table>
</ng-template>

<ng-template #resettableStageTable>
  <ov-suite-clean-table
    [dataSource]="resettableStageItemsDataSource"
    [columnData]="columnData"
    [disablePaging]="true"
    [disableSorting]="true"
  >
  </ov-suite-clean-table>
</ng-template>

<ng-template #pausableStageTable>
  <ov-suite-clean-table
    [dataSource]="pausableStageItemsDataSource"
    [columnData]="columnData"
    [disablePaging]="true"
    [disableSorting]="true"
  >
  </ov-suite-clean-table>
</ng-template>

<ng-template #stagingItemLogHistoryDialog>
  <ov-suite-staging-item-log-modal
    [loadManagementDataService]="loadManagementDataService"
  >
  </ov-suite-staging-item-log-modal>
</ng-template>
