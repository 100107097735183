import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { ColumnDataOther } from '@ov-suite/helpers-shared';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'ov-suite-other-cell',
  template: `
    <a
      *ngIf="col?.queryParams || col?.routerLink; else simple"
      [routerLink]="col?.routerLink(item)"
      [queryParams]="col?.queryParams(item)"
      [innerHTML]="getOtherData()"
    ></a>
    <ng-template #simple>
      <a *ngIf="col?.rowItemAction" [routerLink]="null" [innerHTML]="getOtherData()"></a>
      <div *ngIf="!col?.rowItemAction" [innerHTML]="getOtherData()"></div>
    </ng-template>
  `,
  // styleUrls: ['./basic-table.component.scss'],
})
export class OtherCellComponent<T> {
  @Input() item: T;

  @Input() col: ColumnDataOther<T>;

  @Output() itemChange = new EventEmitter<T>();

  @Output() itemClicked = new EventEmitter<T>();

  constructor(private readonly sanitizer: DomSanitizer) {}

  itemChanged(item: T): void {
    this.itemChange.emit(item);
  }

  itemClick(item: T): void {
    this.itemClicked.emit(item);
  }

  getOtherData() {
    return this.sanitizer.bypassSecurityTrustHtml(this.col?.action(this.item));
  }
}
