import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SseClientService {
  constructor(private readonly zone: NgZone) {}

  getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  getServerSentEvent(url: string): Observable<string | object> {
    return new Observable(observer => {
      if (environment.production) {
        const eventSource = this.getEventSource(url);

        eventSource.onmessage = event => {
          let { data } = event;
          if (typeof data === 'string' && data.length && data[0] === '{') {
            data = JSON.parse(event.data);
          }
          this.zone.run(() => {
            observer.next(data);
          });
        };

        eventSource.onerror = error => {
          this.zone.run(() => {
            observer.error(error);
          });
        };
      }
    });
  }
}
