import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { InventoryContainerConfigurationItemModel } from '@ov-suite/models-warehouse';
import { ProductSkuConfiguration } from './product-sku-configuration.model';
import { DomainModel } from './domain.model';
import { ProductCategory } from './product-category.model';
import { InventoryThreshold } from './inventory-threshold.model';
import { ProductSkuPriceModel } from './product-sku-price.model';
import { SalesConstraintModel } from './sales-constraint.model';
import { StageTypeModel } from './stage-type.model';

@OVEntity({ name: 'ProductSkuStatus', tableName: 'product_sku_status' })
export class ProductSkuStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'ProductSku', tableName: 'product_sku' })
@OVForm(
  [
    ['#Sku Details'],
    ['sku', 'name'],
    ['description'],
    ['stageType'],
    ['category'],
    ['salesUnit', 'storageUnit', 'reportingUnit'],
    // ['packingUnit', ],
    ['#Packing Measurements'],
    ['weight', ''],
    ['length', 'height', 'width'],
    ['#Sales Constraints'],
    ['salesLimit'],
    ['#Other'],
    // ['products'],
    // ['productSkuConfigurations'],
    ['domains'],
    ['barcodeData', ''],
    ['qrPreview'],
  ],
  [['sku', 'name'], ['description'], ['stageType'], ['weight', ''], ['length', 'height', 'width'], ['barcodeData', ''], ['qrPreview']],
)
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<ProductSkuModel>([
  {
    key: 'sku',
    title: 'SKU',
    type: 'string',
    detailModal: ({ id }) => ({
      id,
      entity: ProductSkuModel,
    }),
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
    detailModal: ({ id }) => ({
      id,
      entity: ProductSkuModel,
    }),
  },
  {
    title: 'Weight',
    type: 'other',
    action: item => {
      if (item.weight) {
        if (item.weight >= 1_000_000) {
          return `${toDecimal(item.weight / 1_000_000)} t`;
        }
        if (item.weight >= 1_000) {
          return `${toDecimal(item.weight / 1_000)} kg`;
        }
        if (item.weight >= 0) {
          return `${item.weight} g`;
        }
        return `${toDecimal(item.weight * 1_000)} mg`;
      }
      return '';
    },
    keys: ['weight'],
    disableFiltering: true,
  },
  {
    title: 'Dimensions',
    type: 'other',
    action: item => {
      if (!item.length) {
        return '';
      }
      let unit = 'cm';
      let unitFactor = 1;
      const minValue = Math.min(item.length, item.height, item.width); // in centimeters;
      if (minValue > 100) {
        unit = 'm';
        unitFactor = 100;
      } else if (minValue < 1) {
        unit = 'mm';
        unitFactor = 0.1;
      }

      const message =
        `${toDecimal(item.length / unitFactor)} ${unit}` +
        ` x ${toDecimal(item.height / unitFactor)} ${unit}` +
        ` x ${toDecimal(item.width / unitFactor)} ${unit}`;
      return message.includes('null') ? '' : message;
    },
    disableSorting: true,
    disableFiltering: true,
    keys: ['length', 'height', 'width'],
  },
  {
    title: 'Type',
    key: 'stageType.name',
    type: 'deep-string',
  },
  {
    title: 'Barcode',
    type: 'string',
    key: 'barcodeData',
  },
  {
    title: 'Container Configurations',
    type: 'other',
    action: p => {
      const output: string[] = [];
      p.containerConfigurationItems.forEach(item => {
        if (item?.inventoryContainerConfiguration?.name) {
          output.push(item.inventoryContainerConfiguration.name);
        }
      });
      return output.join(', ');
    },
    keys: [
      'containerConfigurationItems.inventoryContainerConfiguration.name',
    ],
  },
])
@OVSearch<ProductSkuModel>({
  searchKeys: ['sku', 'name', 'barcodeData', 'productSkuConfigurations.productSku.sku', 'productSkuConfigurations.productSku.name'],
})
export class ProductSkuModel {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'SKU',
    tooltip: 'Stock Keeping Unit.',
    placeholder: 'Required',
    required: true,
  })
  sku: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => ProductSkuStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: ProductSkuStatus;

  @OVField({
    type: () => StageTypeModel,
    selectionType: 'simple',
    flat: true,
    title: 'Type',
    required: false,
  })
  stageType: StageTypeModel;

  @OVField({
    type: 'number',
    title: 'Weight',
    step: 'any',
    min: 0,
    tooltip: 'grams',
  })
  weight: number;

  @OVField({
    type: 'number',
    title: 'Length',
    step: 'any',
    min: 0,
    tooltip: 'centimeters',
  })
  length: number;

  @OVField({
    type: 'number',
    title: 'Height',
    step: 'any',
    min: 0,
    tooltip: 'centimeters',
  })
  height: number;

  @OVField({
    type: 'number',
    title: 'Width',
    step: 'any',
    min: 0,
    tooltip: 'centimeters',
  })
  width: number;

  @OVField({
    type: 'boolean',
    title: 'Sales Unit',
    tooltip: 'This enables the sale of this product sku',
  })
  salesUnit: boolean;

  @OVField({
    type: 'boolean',
    title: 'Storage Unit',
    editable: false,
    tooltip: 'This sets this product sku as the base storage unit used. This is also used for warehouse metrics',
  })
  storageUnit: boolean;

  @OVField({
    type: 'boolean',
    title: 'Packing Unit',
    tooltip: 'This sets this product sku as a packing option for packers',
  })
  packingUnit: boolean;

  @OVField({
    type: 'boolean',
    title: 'Reporting Unit',
    tooltip: 'This sets this product sku as the base reporting unit used for sales metrics',
  })
  reportingUnit: boolean;

  // @OVField({
  //   type: () => [Product],
  //   title: 'Products',
  //   quantityKey: 'product',
  //   selectionType: 'multiple',
  //   flat: true,
  //   dropdownLimit: 1000,
  //   keys: ['id', 'name'],
  // })
  // products: Product[];

  // @OVField({
  //   type: () => [ProductSkuConfiguration],
  //   subType: () => 'ProductSku',
  //   title: 'SKUs',
  //   tooltip: 'Add multiple storage keeping units where necessary.',
  //   withQuantity: true,
  //   quantityKey: 'productSku',
  //   selectionType: 'multiple',
  //   flat: true,
  //   dropdownLimit: 1000,
  //   keys: ['id', 'quantity', 'productSku.id', 'productSku.name'],
  // })
  // productSkuConfigurations: ProductSkuConfiguration[];

  @OVField({
    type: 'string',
    title: 'Barcode Type',
  })
  barcodeType: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Value',
  })
  barcodeData: string;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: DomainModel[];

  @OVField({
    type: () => ProductCategory,
    tooltip: 'Add a single category for this product.',
    selectionType: 'single',
    title: 'Category',
    keys: ['id', 'name', 'path'],
  })
  category: ProductCategory;

  @OVField({
    type: () => InventoryThreshold,
    title: 'Inventory Threshold',
    keys: ['id', 'amberTriggerValue', 'redTriggerValue'],
  })
  threshold: InventoryThreshold;

  @OVField({ type: 'number' })
  quantityFree: number;

  @OVField({ type: 'number' })
  quantityOnHand: number;

  @OVField({ type: 'number' })
  quantityOnPurchaseOrder: number;

  @OVField({ type: 'number' })
  quantityOnSalesOrder: number;

  @OVField({ type: 'number' })
  quantityReserved: number;

  @OVField({ type: 'number' })
  quantityWIP: number;

  // @OVField({
  //   type: 'number',
  // })
  // sellingPrice: number;

  @OVField({ type: 'number' })
  taxRate: number;

  @OVField({ type: () => [ProductSkuPriceModel], keys: ['id'], readonly: true })
  prices: ProductSkuPriceModel[];

  @OVField({
    type: 'number',
    title: 'Sales Limit',
    step: 'any',
    min: 0,
    tooltip: 'Units',
  })
  salesLimit: number;

  @OVField({
    type: () => [InventoryContainerConfigurationItemModel],
    keys: ['id', 'inventoryContainerConfiguration.name'],
    generated: true,
    readonly: true,
  })
  containerConfigurationItems: InventoryContainerConfigurationItemModel[];

  @OVField({
    type: () => SalesConstraintModel,
    title: 'Sales Constraint',
    required: false,
    keys: ['id', 'name', 'minimum', 'maximum', 'constraints'],
  })
  salesConstraint: SalesConstraintModel;

  private priceMap: Map<number, ProductSkuPriceModel>;

  private populatePriceMap() {
    if (!this.priceMap) {
      this.priceMap = new Map();
      this.prices.forEach(price => {
        this.priceMap.set(price.priceList.id, price);
        if (price.priceList.default) {
          this.priceMap.set(0, price);
        }
      });
    }
  }

  public getPriceExcl(priceListId): number {
    this.populatePriceMap();

    const id = priceListId ?? 0;

    return this.priceMap.get(id)?.exclPrice;
  }

  public getPriceIncl(priceListId): number {
    this.populatePriceMap();

    const id = priceListId ?? 0;

    return this.priceMap.get(id)?.inclPrice;
  }
}

export function toDecimal(input: number, places = 2): number {
  const factor = Math.pow(10, 2);
  return Math.round(input * factor) / factor;
}
