import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatExpansionModule } from '@angular/material/expansion';
import { LoadManagementRoutes } from './load-management.routing';
import { ActiveLoadListComponent } from './active-load-list/active-load-list.component';
import { CompletedLoadListComponent } from './completed-load-list/completed-load-list.component';
import { LoadDetailTabComponent } from './load-detail-tab/load-detail-tab.component';
import { LoadManagementDataService } from './load-management.data.service';
import { LoadDetailContainerComponent } from './load-detail-container/load-detail-container.component';
import { PicksTabComponent } from './picks-tab/picks-tab.component';
import { AddModalComponent } from './picks-tab/add-modal/add-modal.component';
import { AddStagingItemModalComponent } from './stage-tab/add-staging-item-modal/add-staging-item-modal.component';
import { LoadManagementVehicleChangeComponent } from './load-detail-tab/load-management-vehicle-change/load-management-vehicle-change.component';
import { LoadManagementWaveChangeComponent } from './load-detail-tab/load-management-wave-change/load-management-wave-change.component';
import { LoadTabComponent } from './load-tab/load-tab.component';
import { LogTabComponent } from './log-tab/log-tab.component';
import { ConfirmationComponent } from './confirmation-dialog/confirmation.component';
import { LoadDetailLoadbayChangeComponent } from './load-detail-tab/load-detail-loadbay-change/load-detail-loadbay-change.component';
import { InvoiceResolutionModalComponent } from './invoice-resolution-modal/invoice-resolution-modal.component';
import { ExtendedOrderInvoiceTableComponent } from './invoice-resolution-modal/extended-order-invoice-table/extended-order-invoice-table.component';
import { InvoiceResolutionDataService } from './invoice-resolution-modal/invoice-resolution.data.service';
import { InvoiceOrderLineEditorComponent } from './invoice-resolution-modal/invoice-order-line-editor/invoice-order-line-editor.component';
import { PickingItemLogModalComponent } from './picks-tab/picking-item-log-modal/picking-item-log-modal.component';
import { StageTabComponent } from './stage-tab/stage-tab.component';
import { StagingItemLogModalComponent } from './stage-tab/staging-item-log-modal/staging-item-log-modal.component';
import { LoadManagementDetailsCardComponent } from './load-detail-tab/load-management-details-card/load-management-details-card.component';
import { InvoiceDownloadDialogComponent } from './invoice-download-dialog/invoice-download-dialog.component';
import { LoadAmountTabComponent } from './load-amount-tab/load-amount-tab.component';
import { LoadOrderAccordionComponent } from './load-amount-tab/load-order-accordion/load-order-accordion.component';
import { LoadAmountResolutionModalComponent } from './load-amount-tab/load-amount-resolution-modal/load-amount-resolution-modal.component';
import { LoadAmountInputComponent } from './load-amount-tab/load-amount-resolution-modal/load-amount-input/load-amount-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadManagementVehicleTruckDriverComponent } from './load-detail-tab/load-management-vehicle-truck-driver/load-management-vehicle-truck-driver.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(LoadManagementRoutes),
    FormsModule,
    UiModule,
    NgbNavModule,
    NgbDropdownModule,
    DragDropModule,
    NgbNavModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatDatepickerModule,
    MatTabsModule,
    MatProgressBarModule,
    MatDividerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatChipsModule,
    MatListModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSliderModule,
    MatExpansionModule,
    MatTooltipModule,
  ],
  declarations: [
    ActiveLoadListComponent,
    CompletedLoadListComponent,
    LoadDetailTabComponent,
    LoadDetailContainerComponent,
    PicksTabComponent,
    AddModalComponent,
    AddStagingItemModalComponent,
    ConfirmationComponent,
    LoadManagementVehicleChangeComponent,
    LoadManagementWaveChangeComponent,
    LoadTabComponent,
    LogTabComponent,
    LoadDetailLoadbayChangeComponent,
    InvoiceResolutionModalComponent,
    ExtendedOrderInvoiceTableComponent,
    InvoiceOrderLineEditorComponent,
    PickingItemLogModalComponent,
    StageTabComponent,
    StagingItemLogModalComponent,
    LoadManagementDetailsCardComponent,
    InvoiceDownloadDialogComponent,
    LoadAmountTabComponent,
    LoadOrderAccordionComponent,
    LoadAmountResolutionModalComponent,
    LoadAmountInputComponent,
    LoadManagementVehicleTruckDriverComponent,
  ],
  providers: [LoadManagementDataService, InvoiceResolutionDataService],
})
export class LoadManagementModule {}
