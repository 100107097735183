import { OVField, OVForm, OVSidebar, OVTable, OVEntity, OVSearchable, CompiledFieldData, OVSearch } from '@ov-suite/ov-metadata';
import { DomainModel } from '@ov-suite/models-admin';
import { UserType } from './user-type.model';

@OVEntity('UserStatus', 'idmlink')
export class UserStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'User', mapDomainsToIds: true })
@OVForm([
  ['username', ''],
  ['firstName', 'lastName'],
  ['email', 'phoneNumber'],
  // ['cognitoUser'],
  ['userPin', 'userPinGenBtn'],
  ['domains'],
  ['userTypes'],
])
@OVSidebar([
  ['avatarUrl'],
  // ['status'],
  ['id'],
])
@OVTable<UserModel>([
  {
    key: 'id',
    title: 'User ID',
    type: 'number',
  },
  {
    key: 'username',
    title: 'Username',
    type: 'string',
  },
  {
    key: 'firstName',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'lastName',
    title: 'Surname',
    type: 'string',
  },
  {
    key: 'email',
    title: 'Email',
    type: 'string',
  },
  {
    key: 'phoneNumber',
    title: 'Mobile',
    type: 'string',
  },
  {
    type: 'pills',
    title: 'User Types',
    limit: 5,
    action: item => item?.userTypes?.map(d => d.name) ?? [],
    keys: ['userTypes.name'],
    disableSorting: true,
  },
  {
    type: 'pills',
    title: 'Domains',
    limit: 5,
    action: item => item?.domains?.map(d => d.name) ?? [],
    keys: ['domains.name'],
    disableSorting: true,
  },
  {
    title: 'Fast Actions',
    type: 'button-bar',
    disableSorting: true,
    buttons: [{ actionType: 'edit' }, { actionType: 'delete' }],
    keys: [],
  }
])
@OVSearch<UserModel>({ searchKeys: ['firstName', 'lastName', 'userTypes.name', 'email', 'phoneNumber'] })
export class UserModel {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Username',
    required: true,
    validatorInjectionKey: 'USERNAME_VALIDATOR',
  })
  username: string;

  @OVField({
    type: 'string',
    title: 'Mobile',
    placeholder: '+27000000000',
    tooltip: `Add users's cell phone number`,
    validatorInjectionKey: 'PHONE_VALIDATOR',
  })
  phoneNumber: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'First Name',
    required: true,
    placeholder: 'Required',
    tooltip: `Add users's first name`,
  })
  firstName: string;

  @OVField({
    type: 'string',
    title: 'Last Name',
    required: true,
    placeholder: 'Required',
    tooltip: `Add users's surname`,
  })
  lastName: string;

  @OVField({
    type: 'string',
    title: 'User Pin',
    tooltip: `Add admin user's pin`,
    validatorInjectionKey: 'PIN_VALIDATOR',
    required: false,
  })
  userPin: string;

  @OVField({
    type: 'button',
    title: 'Generate Pin code',
    placeholder: 'Required',
    classes: ['btn', 'btn-primary', 'mt-4', 'btn-round', 'btn-block'],
    unnecessary: true,
    bulkIgnore: true,
    action: (data: CompiledFieldData[][]) => {
      let userPin: CompiledFieldData;
      for (let i = 0; i < data.length; i += 1) {
        if (!userPin) {
          userPin = data[i].find(item => item.propertyKey === 'userPin');
        }
      }
      if (!userPin) return;
      // generate random access code
      userPin.value = Math.floor(Math.random() * 999_999)
        .toString()
        .padStart(6, '0');
    },
  })
  userPinGenBtn: string;

  @OVField({
    type: 'string',
    title: 'Email Address',
    placeholder: 'mail@example.com',
    tooltip: `Add user's email address`,
    validatorInjectionKey: 'EMAIL_VALIDATOR',
  })
  email: string;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
    isAuthenticationService: true,
  })
  domains: DomainModel[];

  @OVField({
    type: () => [UserType],
    keys: ['id', 'name'],
    title: 'User Type',
    tooltip: 'Add multiple user types. This determines a users permission throughout the site.',
    selectionType: 'multiple',
    flat: true,
  })
  userTypes: UserType[];

  @OVField({
    type: 'image',
    sidebar: true,
    required: false,
    title: 'Image',
    tooltip: 'upload your profile picture',
  })
  avatarUrl?: string;

  @OVField({
    type: () => UserStatus,
    dropdown: true,
    sidebar: true,
  })
  status: UserStatus;
}
