<ng-container>
  <mat-card-content>
    <div class="list-space-between">
      <p><strong>Name</strong> {{ driverName }}</p>
    </div>
  </mat-card-content>
  <mat-divider inset></mat-divider>
  <mat-card-actions>
    <button (click)="updateTruckDriverClicked()" mat-button color="primary">Change</button>
  </mat-card-actions>
</ng-container>

<ng-template #vehicleDriver>
  <mat-card class="dialog-card-content mat-elevation-z0">
    <mat-card-content>
      <form class="edit-load-detail" [formGroup]="form">
        <mat-form-field appearance="fill">
          <mat-label>Driver Name</mat-label>
          <input matInput
                 placeholder="Truck Driver Name"
                 formControlName="driverName"
                 name="driverName"
                 id="driverName"
          >
        </mat-form-field>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
