import { Routes } from '@angular/router';

export const OrderRoutes: Routes = [
  {
    path: '',
    redirectTo: 'confirmed',
    pathMatch: 'full',
  },
  {
    path: '',
    children: [
      {
        path: 'completed',
        loadChildren: () => import('./completed-orders.module').then(m => m.CompletedOrderModule),
      },
      {
        path: 'confirmed',
        loadChildren: () => import('./confirmed-orders.module').then(m => m.ConfirmedOrdersModule),
      },
      {
        path: 'unconfirmed',
        loadChildren: () => import('./unconfirmed-orders.module').then(m => m.UnconfirmedOrdersModule),
      },
      {
        path: 'partial-processed',
        loadChildren: () => import('./partial-processed-orders.module').then(m => m.PartialProcessedOrdersModule),
      },
      {
        path: 'planned',
        loadChildren: () => import('./planned-orders.module').then(m => m.PlannedOrdersModule),
      },
    ],
  }
];
