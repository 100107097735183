import { PickStageStatus } from '@ov-suite/helpers-shared';

export interface UpdatedLoadBay {
  loadId: number;
  loadBayId: number;
  loadBayMinCapacity: number;
}

export enum PickState {
  Pending = 'Pending',
  Paused = 'Paused',
  Complete = 'Complete',
  'No Stock' = 'No Stock',
  // Exception = 'Exception',
}

const statusColor: Record<PickState, string> = {
  Pending: 'badge-info',
  Paused: 'badge-primary',
  Complete: 'badge-success',
  'No Stock': 'badge-warning',
  // Exception: 'badge-danger',
};

export function getPickStatusMap(picks: PickStageStatus[]): Record<PickState, number> {
  const statuses: Record<PickState, number> = {
    [PickState.Pending]: 0,
    [PickState.Paused]: 0,
    [PickState.Complete]: 0,
    [PickState['No Stock']]: 0,
    // [PickState.Exception]: 0,
  };
  picks?.forEach(pick => {
    switch (pick) {
      case PickStageStatus.IN_PROGRESS:
      case PickStageStatus.READY:
      case PickStageStatus.RESERVED:
        statuses[PickState.Pending] += 1;
        break;
      case PickStageStatus.COMPLETE:
        statuses[PickState.Complete] += 1;
        break;
      case PickStageStatus.NO_STOCK:
        statuses[PickState['No Stock']] += 1;
        break;
      case PickStageStatus.MANUALLY_PAUSED:
      case PickStageStatus.PAUSED:
        statuses[PickState.Paused] += 1;
        break;
      case PickStageStatus.RESET:
        // Exclude
        break;
      default:
        statuses['Exception'] += 1;
    }
  });
  return statuses;
}

export function renderPickStatuses(picks: PickStageStatus[]) {
  const statuses: Record<string, number> = getPickStatusMap(picks);
  const total = Object.values(statuses).reduce((p, c) => p + c, 0);

  let data = '';
  Object.entries(statuses).forEach(([status, count]) => {
    if (count) {
      const percentage = Math.round((count / total) * 100);
      data += `<span class="badge ${statusColor[status]} font-bold ml-1"> ${status} - ${count} (${percentage}%) </span>`;
    }
  });

  return data;
}
