import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { PickListComponent } from './list/pick-list.component';

export const PicksRoutes: Routes = [
  {
    canActivate: [AuthGuard],
    path: '',
    component: PickListComponent,
  },
];
