import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { InvoiceResolutionDataService, OrderInvoiceTotal } from './invoice-resolution.data.service';

/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'ov-suite-invoice-resolution-modal',
  styleUrls: ['invoice-resolution-modal.component.scss'],
  templateUrl: 'invoice-resolution-modal.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InvoiceResolutionModalComponent implements OnInit {
  @Output() dialogEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  dataSource;

  columnsToDisplay = ['orderNo', 'ordered', 'loadedQuantity'];

  loading = false;

  columnsToDisplayName: Record<string, string> = {
    orderNo: 'Order Number',
    ordered: 'Ordered Quantity',
    loadedQuantity: 'Loaded Quantity',
  };

  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];

  expandedElement: OrderInvoiceTotal | null;

  constructor(private readonly route: ActivatedRoute, public readonly dataService: InvoiceResolutionDataService) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      const id = params.get('id');
      this.dataService.onInit(Number(id));
    });

    this.dataService.$invoices.subscribe(data => {
      if (data) {
        this.dataSource = data;
        this.expandedElement = data[0];
      }
    });
  }

  onSaveInvoice() {
    this.loading = true;
    this.dataService
      .onSaveInvoices()
      .then(() => {
        this.dialogEmitter.emit(true);
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  cancelDialog() {
    this.dialogEmitter.emit(false);
  }
}
