import { Component } from '@angular/core';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';

@Component({
  selector: 'ov-suite-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  loginUrl = `${getUrl('idm')}/login`;

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  login() {
    this.authenticationService.logout();
    window.location.href = this.loginUrl;
  }
}
