import { Component, HostListener, OnInit } from '@angular/core';
import { FilterTab } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { ColumnData, QueryParams } from '@ov-suite/helpers-shared';
import { Notification } from '@ov-suite/models-shared';

@Component({
  selector: 'ov-suite-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  screenHeight: number;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
  }

  formClass = Notification;

  columns: ColumnData<any>[] = [
    {
      key: 'createDate',
      title: 'Date',
      type: 'date',
      format: 'DD-MM-yyyy',
    },
    {
      key: 'createDate',
      title: 'Time',
      type: 'date',
      format: 'HH:MM',
    },
    {
      key: 'message',
      title: 'Message',
      type: 'string',
    },
    {
      key: 'category',
      title: 'Category / Subject',
      type: 'string',
    },
  ];

  filter: Record<string, QueryParams[]> = null;

  currentData: Notification[] = [];

  selected: Notification[];

  bulkChanges: Record<string, unknown>;

  reFetch = 0;

  dropdownData: Record<string, unknown>;

  constructor(public readonly ovAutoService: OvAutoService) {
    this.onResize();
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.ovAutoService
      .list({
        entity: Notification,
        orderDirection: 'DESC',
        orderColumn: 'id',
      })
      .then(result => {
        this.currentData = result.data;
      })
      .catch(err => console.error(err));
  }

  setFilter(tab: FilterTab) {
    this.filter = tab.value ? { s: [tab.value] } : null;
  }
}
