import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { OrderModel, SplitParam } from '@ov-suite/models-order';
import { LoadOrderItemModel } from './load-order-item.model';

@OVEntity({ name: 'LoadOrder' })
export class LoadOrderModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => OrderModel })
  order: OrderModel;

  @OVField({ type: 'number' })
  index: number;

  @OVField({ type: 'string' })
  name = 'Test Name';

  @OVField({ type: () => [LoadOrderItemModel] })
  loadOrderItems: LoadOrderItemModel[];

  split(splitParams: SplitParam[]): LoadOrderModel {
    const output = Object.assign(new LoadOrderModel(), {
      order: this.order,
      index: this.index + 1,
      loadOrderItems: [],
    } as Partial<LoadOrderModel>);

    splitParams.forEach(param => {
      const found = this.loadOrderItems.find(l => l.orderItem === param.orderItem);
      if (found) {
        output.loadOrderItems.push(
          Object.assign(new LoadOrderItemModel(), {
            quantity: param.quantity,
            orderItem: param.orderItem,
          } as Partial<LoadOrderItemModel>),
        );
        found.quantity -= param.quantity;
      }
    });
    this.loadOrderItems = this.loadOrderItems.filter(o => o.quantity > 0);

    return output;
  }
}
