<div class="main-content">
  <div class="main-container">
    <h4>View Process</h4>

    <div class="table-sm">
      <table class="table table-striped" aria-describedby="View Process">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Started By</th>
            <th scope="col">Start Time</th>
            <th scope="col">Status</th>
            <th scope="col">Completed</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ data?.id }}</td>
            <td>{{ data?.name }}</td>
            <td>{{ data?.startUserId }}</td>
            <td>{{ data?.startTime }}</td>
            <td>
              {{ !data?.ended || !data?.completed ? 'Active' : 'Inactive' }}
            </td>
            <td>{{ data?.completed }}</td>
            <td>
              <button (click)="onDelete(data?.id)" class="btn btn-danger">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr />
    <h5>Flow Chart</h5>

    <img *ngIf="data?.id" [src]="apiUrl + '/api/process-instance/' + data?.id" alt="Flow Chart" />
  </div>
</div>
