import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { LazyService } from './load-allocation.lazy.service';

/**
 * Used to manage orders in various ways
 */

@Injectable()
export class LoadAllocationProductSkuService extends LazyService<ProductSkuModel> {
  constructor(private readonly ovAutoService: OvAutoService) {
    super(ProductSkuModel, ovAutoService, {
      keys: ['id', 'name', 'sku', 'height', 'weight', 'length', 'width', 'stageType', 'stageType.id', 'stageType.name'],
    });
  }
}
