import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { InvoiceModel, OrderItemModel } from '@ov-suite/models-order';
import { BehaviorSubject } from 'rxjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { DocumentNode } from 'graphql';
import { MutationResult } from 'apollo-angular';
import moment from 'moment';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { CustomerModel } from '@ov-suite/models-admin';
import { InvoiceLineModel } from '../../../../../../libs/models-order/src/lib/invoice-line.model';
import { listOrderInvoice, listOrderInvoiceLite } from './invoice-download.graphql';

/**
 * Section 1: Order number and basic Customer information.
 * Section 2: Invoicing from and to information.
 * Section 3: Invoice items information.
 * Section 4: Truck driver, license plate and load detail information.
 * Section 5: Banking, total, and signage position information.
 */

interface InvoiceItem {
  itemCode?: string;
  itemName?: string;
  ordered?: number;
  quantity?: number;
  priceExl?: string;
  priceInc?: string;
  vatRate?: string;
  tax?: string;
  totalExcl?: string;
  totalIncl?: string;
  note?: string;
}

interface GrandTotals {
  runningTotalIncl: string;
  runningTotalExcl: string;
  runningTotalTax: string;
}

interface InvoiceItemTotals {
  orderInvoiceItems: (string | number)[][];
  grandTotal: GrandTotals;
}

interface OrderInfo {
  orderCode?: string;
  externalOrderCode?: string;
  commitDate?: string;
  invoiceNum?: string;
  customerCode?: string;
  customerName?: string;
  customerTaxNumber?: string;
  terms?: string;
  address?: AddressInfo;
  deliveryAddress?: AddressInfo;
}

interface AddressInfo {
  line1?: string;
  line2?: string;
  line3?: string;
  line4?: string;
  postalCode?: string;
}

interface PDFPageParams {
  width: number;
  height: number;
  pageMargin: number;
  pageMiddle: () => number;
  pageOneThird: () => number;
  pageTwoThird: () => number;
  pageStartTop: () => number;
}

interface VehicleInfo {
  driver?: string;
  registration?: string;
}

interface AgentDetails {
  createdByAgent?: string;
  modifiedByAgent?: string;
}

interface DetailInfo {
  title?: string;
  value?: string;
}

const trudaPmbInfo = {
  name: 'Truda Foods (Pty) Ltd - PMB',
  addressLine1: 'Lester Brown Rd',
  addressLine2: 'Mkondeni',
  addressLine3: 'Pietermaritzburg',
  addressLine4: 'Kwazulu Natal',
  addressLine5: '',
  fax: '0333462935',
  website: 'www.trudafoods.co.za',
  taxRegistration: '455 021 5687',
  telephone: '033 346 1236',
  registration: '2013/036 303/07',
};

const invoiceBatchSize = 15;

@Injectable({ providedIn: 'root' })
export class InvoiceDownloadService {
  invoices: BehaviorSubject<InvoiceModel[]> = new BehaviorSubject<InvoiceModel[]>(null);

  loadId: number;

  orderId: number;

  constructor(private readonly ovAutoService: OvAutoService) {}

  async fetchLoadInvoiceOrderList(loadId: number, orderIds: number[]) {
    const loadData = { loadId, orderIds };
    this.executeGraphQL(listOrderInvoiceLite(), loadData).then(res => {
      this.invoices.next(res.data['listOrderInvoiceLite'] as unknown as InvoiceModel[]);
    });
  }

  executeGraphQL<T, U>(query: DocumentNode, data: T): Promise<MutationResult<U>> {
    return this.ovAutoService.apollo
      .query<U>({
        query,
        fetchPolicy: 'no-cache',
        variables: {
          ...data,
        },
      })
      .toPromise();
  }

  prepareInvoiceItems(invoiceItems: InvoiceLineModel[], orderItems: OrderItemModel[]): InvoiceItemTotals {
    let runningTotalIncl = 0;
    let runningTotalExcl = 0;

    const invoiceItemMap = new Map<number, InvoiceItem>();

    // First loop through the invoice as these items will be displayed on the invoice.
    invoiceItems.forEach(i => {
      const invoiceItem: InvoiceItem = {
        itemCode: i.productSku.sku,
        itemName: i.productSku.name,
        quantity: i.quantity,
        vatRate: '15%',
      };

      invoiceItemMap.set(i.orderItemId, invoiceItem);
    });

    // Then fetch the missing data
    orderItems.forEach(o => {
      if (invoiceItemMap.has(o.id)) {
        const item = invoiceItemMap.get(o.id);

        const priceExcl = o.discountedUnitPrice ?? o.inventoryItemUnitPrice ?? o.unitPriceExcl;
        const priceIncl = o.discountedUnitPrice ? o.discountedUnitPrice * 1.15 : o.inventoryItemUnitPriceIncl ?? o.unitPriceIncl;
        // const priceIncl = o.inventoryItemUnitPrice ?? o.unitPriceExcl;

        item.note = o.note;

        // Totals at bottom of invoice
        runningTotalIncl += priceIncl * item.quantity;
        runningTotalExcl += priceExcl * item.quantity;
        // runningTotalTax += runningTotalIncl - runningTotalExcl;

        item.priceExl = priceExcl.toFixed(2);
        item.priceInc = priceIncl.toFixed(2);
        item.totalExcl = (priceExcl * item.quantity).toFixed(2);
        item.totalIncl = (priceIncl * item.quantity).toFixed(2);
        item.tax = ((priceIncl - priceExcl) * item.quantity).toFixed(2);

        invoiceItemMap.set(o.id, item);
      }
    });

    const normalInvoiceItems = [];
    const specialInvoiceItems = [];

    Array.from(invoiceItemMap.values()).forEach(item => {
      if (!item.note) {
        normalInvoiceItems.push([item.itemCode, item.itemName, item.quantity, item.priceExl, item.tax, item.totalIncl]);
      } else {
        specialInvoiceItems.push([item.itemCode, item.itemName, item.quantity, item.priceExl, item.tax, item.totalIncl], [item.note]);
      }
    });

    const formattedItems: (string | number)[][] = [...normalInvoiceItems, ...specialInvoiceItems];

    return {
      orderInvoiceItems: formattedItems,
      grandTotal: {
        runningTotalIncl: runningTotalIncl.toFixed(2),
        runningTotalExcl: runningTotalExcl.toFixed(2),
        runningTotalTax: (runningTotalIncl - runningTotalExcl).toFixed(2),
      },
    };
  }

  prepareOrderInfo(invoice: InvoiceModel): OrderInfo {
    return {
      orderCode: invoice.order.orderCode,
      externalOrderCode: invoice.order.externalOrderNo,
      commitDate: moment(invoice.commitDate).format('YYYY-MM-DD'),
      invoiceNum: invoice.invNum,
      customerCode: invoice.order.customer.customerCode,
      customerName: invoice.order.customer.name,
      customerTaxNumber: invoice.order.customer.taxNumber,
      terms: `${invoice.order.customer.accountReceivableComment2}`, // ${invoice.order.customer.accountReceivableComment1},
      address: invoice.order.customer.postalAddress,
      deliveryAddress: invoice.order.deliveryAddress,
    };
  }

  async fetchInvoiceData(invoiceId: number) {
    const data = { invoiceId };
    return this.executeGraphQL(listOrderInvoice(), data).then(res => {
      const invoiceData = res.data['listOrderInvoice'] as unknown as InvoiceModel;
      this.orderId = invoiceData.order.id;
      this.loadId = invoiceData.loadId;
      return invoiceData;
    });
  }

  async fetchLoadData(): Promise<[LoadAllocation, VehicleInfo, DetailInfo[]]> {
    const loadInfo = await this.ovAutoService.get({
      entity: LoadAllocation,
      id: this.loadId,
      relations: ['details', 'vehicle', 'externalVehicle'],
    });

    const details: DetailInfo[] = loadInfo.details
      .filter(dets => dets.orderId === this.orderId)
      .map(det => ({ title: det.title, value: det.value }));

    const vehicle: VehicleInfo = {
      registration: (loadInfo.vehicle ?? loadInfo.externalVehicle)?.registration,
      driver: loadInfo.truckDriverName,
    };

    return [loadInfo, vehicle, details];
  }

  prepareAgentDetails(load: InvoiceModel): AgentDetails {
    let modifiedByAgent = '';
    let createdByAgent = '';

    if (load?.modifiedByUser) {
      modifiedByAgent = `${load?.modifiedByUser?.firstName} ${load?.modifiedByUser?.lastName}`;
    }

    if (load?.order?.createdByUser) {
      createdByAgent = `${load?.order?.createdByUser?.firstName} ${load?.order?.createdByUser?.lastName}`;
    }

    return {
      modifiedByAgent,
      createdByAgent,
    };
  }

  batchOrderInvoiceItems(itemsToBatch: (string | number)[][], batch: (string | number)[][][]) {
    // If there are no items left to batch, return the batch
    if (itemsToBatch.length === 0) {
      return batch;
    }

    // Determine the current batch size
    const currentBatch = itemsToBatch.splice(0, invoiceBatchSize);
    batch.push(currentBatch);

    // If there are more items to process, call the function recursively
    if (itemsToBatch.length > 0) {
      this.batchOrderInvoiceItems(itemsToBatch, batch);
    }

    return batch;
  }

  async fetchCustomerData(id: number) {
    return this.ovAutoService.get({
      entity: CustomerModel,
      id,
      relations: ['postalAddress'],
    });
  }

  async exportInvoice(invoiceId: number, orderNumber: string, invoiceNumber: string) {
    const invoiceData = await this.fetchInvoiceData(invoiceId);
    const invoiceItems = this.prepareInvoiceItems(invoiceData.invoiceItems, invoiceData.order.orderItems);

    const batchedOrderInvoiceItems = this.batchOrderInvoiceItems(invoiceItems.orderInvoiceItems, []);

    const [load, vehicle, details] = await this.fetchLoadData();

    invoiceData.order.customer = await this.fetchCustomerData(invoiceData.order.customer.id);

    const agentDetails = this.prepareAgentDetails(invoiceData);

    // const invoiceInfo = this.prepareInvoiceInfo(data);
    const orderInfo = this.prepareOrderInfo(invoiceData);

    const pageParams: PDFPageParams = {
      width: 600,
      height: 840,
      pageMargin: 10,
      pageMiddle: () => {
        return pageParams.width / 2;
      },
      pageOneThird: () => {
        return pageParams.width / 3;
      },
      pageTwoThird: () => {
        return pageParams.pageOneThird() * 2;
      },
      pageStartTop: () => {
        return pageParams.pageMargin + 20;
      },
    };

    const doc = new jsPDF('p', 'px', [pageParams.width, pageParams.height]); // [x - 595.28, y - 841.89]

    batchedOrderInvoiceItems.forEach((batch, index) => {
      this.populateDocTitle(doc);
      const sectionOneEnd = this.populateInvoiceSectionOne(doc, pageParams, orderInfo);
      const sectionTwoEnd = this.populateInvoiceSectionTwo(doc, sectionOneEnd, pageParams, orderInfo);

      const formattedBatch = this.formatBatchNumbers(batch);

      const sectionThreeEnd = this.populateInvoiceSectionThree(doc, sectionTwoEnd, pageParams, formattedBatch);

      if (batchedOrderInvoiceItems[index + 1]) {
        this.populateDocTitle(doc);
        doc.addPage();
      } else {
        this.populateInvoiceSectionFour(doc, sectionThreeEnd, pageParams, details, vehicle);
        this.populateInvoiceSectionFive(doc, 600, pageParams, invoiceItems.grandTotal, agentDetails);

        this.addPageNumbers(doc);

        doc.save(`${orderNumber}-${invoiceNumber}.pdf`);
      }
    });
  }

  // This is used for getting comma separated numbers for totals in the invoice item section.
  formatBatchNumbers(batch: (string | number)[][]) {
    batch.forEach(b => {
      if (b.length === 6) {
        b[3] = this.formatNumber(b[3]);
        b[4] = this.formatNumber(b[4]);
        b[5] = this.formatNumber(b[5]);
      }
    });

    return batch;
  }

  populateDocTitle(doc: jsPDF) {
    doc.setFontSize(18);
    doc.setFont(undefined, 'bold');
    doc.setTextColor('#00008B');
    doc.text('Tax Invoice', 250, 35);

    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.setTextColor('#000000');
  }

  getLongest(nums: number[]) {
    return nums.sort((a, b) => b - a)[0];
  }

  drawHorizontalLine(doc: jsPDF, lineStart: number) {
    doc.line(30, lineStart, 570, lineStart);
  }

  populateInvoiceSectionOne(doc: jsPDF, pageParams: PDFPageParams, order: OrderInfo): number {
    let topSectionTableEndLeft = 0;
    autoTable(doc, {
      head: [['', '']],
      margin: {
        left: 10,
        right: pageParams.pageTwoThird() + 30,
      },
      startY: pageParams.pageStartTop(),
      columnStyles: {
        0: { fontStyle: 'bold' },
        1: { halign: 'right' },
      },
      theme: 'plain',
      body: [
        ['Invoice No.', order.invoiceNum],
        ['Order No.', order.orderCode],
        ['External Order No.', order.externalOrderCode],
        ['Date', order.commitDate],
        ['Your Account No.', order.customerCode],
        ['Terms', order.terms],
      ],
      didDrawPage: data => {
        topSectionTableEndLeft = data.cursor.y;
      },
    });

    let topSectionTableEndRight = 0;
    autoTable(doc, {
      head: [['', '']],
      margin: {
        left: pageParams.pageMiddle() - 50,
        right: pageParams.pageOneThird(),
      },
      columnStyles: {
        0: { fontStyle: 'bold' },
        1: { halign: 'right' },
      },
      startY: pageParams.pageStartTop(),
      theme: 'plain',
      body: [
        ['Reg No.', trudaPmbInfo.registration],
        ['Vat No.', trudaPmbInfo.taxRegistration],
        ['Tel', trudaPmbInfo.telephone],
        ['Fax', trudaPmbInfo.fax],
        ['Website', trudaPmbInfo.website],
      ],
      didDrawPage: data => {
        topSectionTableEndRight = data.cursor.y;
      },
    });

    const img = new Image();
    img.src = '/assets/img/linksuite/warehouse/invoice-logo/truda-logo.png';
    doc.addImage(img, 'PNG', pageParams.pageTwoThird() + 30, pageParams.pageMargin + 50, 150, 50);

    const sectionOneLongest = this.getLongest([topSectionTableEndLeft, topSectionTableEndRight]);
    this.drawHorizontalLine(doc, sectionOneLongest + 5);

    return sectionOneLongest;
  }

  populateInvoiceSectionTwo(doc: jsPDF, startY: number, pageParams: PDFPageParams, order: OrderInfo): number {
    const sectionTwoStart = startY + 10;
    let sectionTwoTableStart = 0;
    let sectionTwoTableMiddle = 0;
    let sectionTwoTableEnd = 0;

    autoTable(doc, {
      margin: {
        left: 10,
        right: pageParams.pageTwoThird() + 35,
      },
      columnStyles: {
        1: { halign: 'right' },
      },
      startY: sectionTwoStart,
      theme: 'plain',
      body: [
        ['From:'],
        [trudaPmbInfo.name],
        [trudaPmbInfo.addressLine1],
        [trudaPmbInfo.addressLine2],
        [trudaPmbInfo.addressLine3],
        [trudaPmbInfo.addressLine4],
        [`Vat Registration: ${trudaPmbInfo.taxRegistration}`],
      ],
      willDrawCell: data => {
        switch (data.row.index) {
          case 0:
          case 1:
          case 6:
            doc.setFont(undefined, 'bold');
            break;
          default:
            doc.setFont(undefined, 'normal');
        }
      },
      didDrawPage: data => {
        sectionTwoTableStart = data.cursor.y;
      },
    });

    autoTable(doc, {
      margin: {
        left: pageParams.pageOneThird(),
        right: pageParams.pageOneThird() + 35,
      },
      columnStyles: {
        1: { halign: 'right' },
      },
      startY: sectionTwoStart,
      theme: 'plain',
      body: [
        ['Invoice To:'],
        [order.customerName],
        [order?.address?.line1],
        [order?.address?.line2],
        [order?.address?.line3],
        [order?.address?.line4],
        [`Vat Registration: ${order?.customerTaxNumber}`],
      ],
      willDrawCell: data => {
        switch (data.row.index) {
          case 0:
          case 1:
          case 6:
            doc.setFont(undefined, 'bold');
            break;
          default:
            doc.setFont(undefined, 'normal');
        }
      },
      didDrawPage: data => {
        sectionTwoTableMiddle = data.cursor.y;
      },
    });

    autoTable(doc, {
      margin: {
        left: pageParams.pageTwoThird(),
        right: 10,
      },
      columnStyles: {
        1: { halign: 'right' },
      },
      startY: sectionTwoStart,
      theme: 'plain',
      body: [
        ['Deliver To', ''],
        [order.customerName, ''],
        [order?.deliveryAddress?.line1, ''],
        [order?.deliveryAddress?.line2, ''],
        [order?.deliveryAddress?.line3, ''],
        [order?.deliveryAddress?.line4, ''],
      ],
      willDrawCell: data => {
        switch (data.row.index) {
          case 0:
          case 1:
            doc.setFont(undefined, 'bold');
            break;
          default:
            doc.setFont(undefined, 'normal');
        }
      },
      didDrawPage: data => {
        sectionTwoTableEnd = data.cursor.y;
      },
    });

    const sectionTwoLongest = this.getLongest([sectionTwoTableStart, sectionTwoTableEnd, sectionTwoTableMiddle]);
    this.drawHorizontalLine(doc, sectionTwoLongest + 5);

    return sectionTwoLongest;
  }

  populateInvoiceSectionThree(doc: jsPDF, startY: number, pageParams: PDFPageParams, invoiceLines: (string | number)[][]) {
    const sectionThreeStart = startY + 10;
    let invoiceItemLength = 0;

    autoTable(doc, {
      head: [['Item Code', 'Item Description', 'Quantity', 'Unit Price (Ex)', 'Tax', 'Total (Incl)']],
      headStyles: { fillColor: [238, 238, 238] }, // Cells in first column centered and green
      margin: {
        left: 10,
        right: 10,
      },
      startY: sectionThreeStart,
      theme: 'plain',
      body: invoiceLines,
      didParseCell: data => {
        const rows = data.table.body;

        rows.forEach(row => {
          const rowRawData = row.raw as HTMLTableRowElement[];

          if (rowRawData.length === 1) {
            row.cells[0].styles.textColor = [0, 0, 255];
          }
        });
      },
      didDrawPage: data => {
        invoiceItemLength = data.cursor.y;
      },
    });

    return invoiceItemLength;
  }

  populateInvoiceSectionFour(doc: jsPDF, startY: number, pageParams: PDFPageParams, details: DetailInfo[], vehicle: VehicleInfo) {
    let detailItemLength = startY;
    if (details.length > 0) {
      const formattedDetails = [];
      details.forEach(detail => {
        formattedDetails.push([`${detail.title}: ${detail.value}`]);
      });

      autoTable(doc, {
        margin: {
          left: 30,
          right: 370,
        },
        startY: detailItemLength,
        theme: 'plain',
        body: formattedDetails,
        didDrawPage: data => {
          detailItemLength = data.cursor.y;
        },
      });
    }

    let vehicleItemLength = detailItemLength;
    if (vehicle.registration) {
      autoTable(doc, {
        headStyles: { fillColor: [238, 238, 238] }, // Cells in first column centered and green
        margin: {
          left: 30,
          right: 370,
        },
        startY: vehicleItemLength,
        theme: 'plain',
        body: [[`Registration: ${vehicle.registration}`]],
        didDrawPage: data => {
          vehicleItemLength = data.cursor.y;
        },
      });
    }

    if (vehicle.driver) {
      autoTable(doc, {
        headStyles: { fillColor: [238, 238, 238] }, // Cells in first column centered and green
        margin: {
          left: 30,
          right: 370,
        },
        startY: vehicleItemLength,
        theme: 'plain',
        body: [[`Driver Name: ${vehicle.driver}`]],
        didDrawPage: data => {
          vehicleItemLength = data.cursor.y;
        },
      });
    }
  }

  populateInvoiceSectionFive(doc: jsPDF, startY: number, pageParams: PDFPageParams, totals: GrandTotals, agents: AgentDetails) {
    this.drawHorizontalLine(doc, startY);

    let sectionFourTableStart = 0;
    let sectionFourTableMiddle = 0;
    let sectionFourTableEnd = 0;

    autoTable(doc, {
      head: [['', '']],
      margin: {
        left: 10,
        right: pageParams.pageTwoThird() + 25,
      },
      columnStyles: {
        0: { fontStyle: 'bold' },
        1: { halign: 'left' },
      },
      startY,
      theme: 'plain',
      body: [
        ['Banking Details', ''],
        ['Company Name', 'Truda Food (Pty) Ltd'],
        ['Bank', 'First National Bank'],
        ['Account No.', '62070024759'],
        ['Branch Code', '221325'],
      ],
      didDrawPage: data => {
        sectionFourTableStart = data.cursor.y;
      },
    });

    autoTable(doc, {
      head: [['', '']],
      margin: {
        left: pageParams.pageOneThird() + 10,
        right: pageParams.pageOneThird() + 10,
      },
      columnStyles: {
        0: { halign: 'center' },
      },
      startY,
      theme: 'plain',
      body: [
        ['Message To Client'],
        ['_____________________________________'],
        ['_____________________________________'],
        ['_____________________________________'],
      ],
      willDrawCell: data => {
        switch (data.row.index) {
          case 0:
            doc.setFont(undefined, 'bold');
            break;
          default:
            doc.setFont(undefined, 'normal');
        }
      },
      didDrawPage: data => {
        sectionFourTableMiddle = data.cursor.y;
      },
    });

    autoTable(doc, {
      head: [['', '']],
      margin: {
        left: pageParams.pageTwoThird() + 25,
        right: 10,
      },
      columnStyles: {
        1: { halign: 'right' },
      },
      startY,
      theme: 'plain',
      body: [
        ['Total (Excl)', this.formatNumber(totals.runningTotalExcl)],
        ['Tax', this.formatNumber(totals.runningTotalTax)],
        ['Total (Incl)', this.formatNumber(totals.runningTotalIncl)],
      ],
      willDrawCell: data => {
        switch (data.row.index) {
          case 2:
            doc.setFont(undefined, 'bold');
            break;
          default:
            doc.setFont(undefined, 'normal');
        }
      },
      didDrawPage: data => {
        sectionFourTableEnd = data.cursor.y;
      },
    });

    const sectionFourTablesEnd = this.getLongest([sectionFourTableStart, sectionFourTableMiddle, sectionFourTableEnd]);
    doc.setFont(undefined, 'bold');
    doc.text('Thank you for your continued business, it is greatly appreciated', pageParams.pageMiddle(), sectionFourTablesEnd + 10, {
      align: 'center',
    });
    doc.setFont(undefined, 'normal');

    let signingTable = 0;
    autoTable(doc, {
      head: [['', '', '']],
      margin: {
        left: 25,
        right: 25,
      },
      columnStyles: {
        0: { halign: 'center' },
        1: { halign: 'center' },
        2: { halign: 'center' },
      },
      startY: sectionFourTablesEnd + 30,
      theme: 'plain',
      body: [
        [
          '__________________________________________',
          '__________________________________________',
          '__________________________________________',
        ],
        ['Received By', 'Signed', 'Date'],
      ],
      didDrawPage: data => {
        signingTable = data.cursor.y;
      },
    });

    this.drawHorizontalLine(doc, signingTable + 5);
    const tableFooterStart = signingTable - 5;
    autoTable(doc, {
      head: [['', '', '']],
      margin: {
        left: 25,
        right: 25,
      },
      columnStyles: {
        0: { halign: 'left', fontStyle: 'bold' },
        1: { halign: 'center', fontStyle: 'bold' },
        2: { halign: 'right' },
      },
      startY: tableFooterStart,
      theme: 'plain',
      body: [
        [
          `Created Agent Name: ${agents.createdByAgent}`,
          `Modified Agent Name: ${agents.modifiedByAgent}`,
          moment().format('YYYY/MM/DD HH:mm:ss'),
        ],
      ],
    });
  }

  addPageNumbers(doc: jsPDF) {
    const totalPages = doc.getNumberOfPages();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < totalPages; i++) {
      doc.setPage(i);
      const pageCurrent = doc.getCurrentPageInfo().pageNumber; // Current Page
      doc.setTextColor(163, 168, 174);
      doc.setFontSize(8);
      doc.text(`Page ${pageCurrent} of ${totalPages}`, doc.internal.pageSize.width - 60, 15);
    }
  }

  formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
