import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { gql } from 'apollo-boost';
import { LoadAuthenticationSessionModel } from '@ov-suite/models-warehouse';
import { OvAutoService } from '@ov-suite/services';

@Injectable()
export class LoadAuthenticationSessionsService {
  constructor(private readonly ovAutoService: OvAutoService) {}

  async getSessions(): Promise<LoadAuthenticationSessionModel[]> {
    const res = await firstValueFrom(
      this.ovAutoService.apollo.query({
        query: gql`
          query getActiveSession {
            getActiveSession {
              id
              userId
              user {
                firstName
                lastName
                email
              }
              createdDate
              updatedDate
            }
          }
        `,
      }),
      { defaultValue: null },
    );
    return res.data['getActiveSession'] as LoadAuthenticationSessionModel[];
  }

  async lockSession(id: string): Promise<LoadAuthenticationSessionModel> {
    const session = await firstValueFrom(
      this.ovAutoService.apollo.mutate({
        mutation: gql`
          mutation lockLoadAllocation($windowId: String!) {
            lockLoadAllocation(windowId: $windowId) {
              id
              userId
              user {
                firstName
                lastName
                email
              }
              createdDate
              updatedDate
            }
          }
        `,
        variables: {
          windowId: id,
        },
      }),
      { defaultValue: null },
    );

    return session.data['lockLoadAllocation'] as LoadAuthenticationSessionModel;
  }

  async unlockSession() {
    await firstValueFrom(
      this.ovAutoService.apollo.mutate({
        mutation: gql`
          mutation unlockLoadAllocation {
            unlockLoadAllocation
          }
        `,
      }),
      { defaultValue: null },
    );
  }

  async unlockSessionById(id: string) {
    await firstValueFrom(
      this.ovAutoService.apollo.mutate({
        mutation: gql`
          mutation unlockLoadAllocationById($windowId: String!) {
            unlockLoadAllocationById(windowId: $windowId)
          }
        `,
        variables: {
          windowId: id,
        },
      }),
      { defaultValue: null },
    );
  }
}
