<h1 mat-dialog-title>Add Pick</h1>
<form class="example-form" [formGroup]="formGroup" (submit)="submit($any($event))">
  <div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Product</mat-label>
      <input type="text"
             placeholder="Search"
             aria-label="Product"
             matInput
             required
             [formControlName]="'product'"
             [matAutocomplete]="auto">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let productSku of productOptions$ | async"
          [value]="productSku"
          (onSelectionChange)="select($event)"
        >
          {{productSku.name}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Configuration</mat-label>
      <mat-select [formControlName]="'config'">
        <mat-option *ngFor="let config of configurations$ | async" [value]="config">
          {{config.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field  class="example-full-width" appearance="fill">
      <mat-label>How Many are Needed?</mat-label>
      <input type="number"
             [min]="1"
             [max]="5000"
             required
             placeholder="Quantity"
             aria-label="How Many Picks"
             matInput
             [formControlName]="'quantity'">
    </mat-form-field>
  </div>

  <ng-container *ngIf="pickCount$ | async">
    {{ pickCount$.value }} pick{{pickCount$.value === 1 ? '' : 's'}} to be created ({{ palletSize$.value }} each)
  </ng-container>

  <div mat-dialog-actions class="float-right">
    <button mat-stroked-button color="warn" type="button" (click)="close()">Cancel</button>
    <button mat-button color="primary" type="submit" [disabled]="!formGroup.valid">Add</button>
  </div>
</form>
