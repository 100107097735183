import { Component, Input } from '@angular/core';
import { Constructor, GenericHierarchy } from '@ov-suite/ov-metadata';
import { ColumnData } from '@ov-suite/helpers-shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { OvAutoService } from '@ov-suite/services';
import { HasId } from '../ov-generic/ov-generic.types';
import { HierarchyListComponent } from '../hierarchy-list/hierarchy-list.component';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';

type GenericHierarchyType = GenericHierarchy;

export interface SubTableParams<T extends HasId, U extends HasId = { id: number }> {
  title: string;
  formClass: Constructor<U>;
  columnData: ColumnData<U>[];
  getData: (data: T) => Promise<U[]>;
}

interface ModalData<U extends HasId = { id: number }> {
  loading: boolean;
  allData: U[];
  data: U[];
  pageSize: number;
  totalPages: number;
  currentPage: number;
}

@Component({
  selector: 'ov-suite-popup-table',
  templateUrl: './popup-table.component.html',
  styleUrls: ['./popup-table.component.scss'],
})
export class PopupTableComponent<T extends GenericHierarchyType, U extends HasId = { id: number }> extends HierarchyListComponent<T> {
  @Input() subTableParams: SubTableParams<T, U>;

  modalData: U[] = [];

  filtering = false;

  modal: ModalData<U> = {
    loading: false,
    allData: [],
    data: [],
    currentPage: 0,
    pageSize: 10,
    totalPages: 0,
  };

  trigger = 0;

  @Input()
  set searchValue(text: string) {
    super.search(text);
  }

  constructor(
    private readonly popupRoute: ActivatedRoute,
    private readonly popupRouter: Router,
    private readonly popupLocation: Location,
    public readonly popupAutoService: OvAutoService,
    private readonly modalService: NgbModal,
    private readonly authenticationService2: AuthenticationService,
  ) {
    super(popupRoute, popupRouter, popupLocation, popupAutoService, modalService, authenticationService2);
  }

  async openModal(content: unknown, item: T) {
    if (this.subTableParams) {
      this.modal.loading = true;
      this.subTableParams.getData(item).then(data => this.setModalData(data));
      this.modalService.open(content, {
        size: 'xl',
      });
    }
  }

  setModalData(items: U[]) {
    this.modal.loading = false;
    this.modal.allData = items;
    this.modal.data = items.slice(0, this.modal.pageSize);
    this.modal.currentPage = 0;
    this.modal.totalPages = Math.floor(items.length / this.modal.pageSize + 1);
  }

  modalChangePage(page: number) {
    const start = page * this.modal.pageSize;
    this.modal.currentPage = page;
    this.modal.data = this.modal.allData.slice(start, start + this.modal.pageSize);
  }

  toggleFiltering() {
    this.filtering = !this.filtering;
  }
}
