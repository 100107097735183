<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Workflow Processes'"
      [formClass]="formClass"
      [hasBulkUpload]="false"
      [excludeColumns]="4"
      [filter]="filter"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
