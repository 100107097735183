<div class="user">
  <div class="photo">
    <img [src]="avatarUrl" alt="avatar" />
  </div>
  <div class="info">
    <a id="user-profile-link" (click)="navigate()">
      <p
        class="collapsed navbar-username"
        *ngIf="!!authenticationService.user.value"
        [ngClass]="{ hidden: !open && !hover }"
      >{{ authenticationService.user.value.firstName }} {{ authenticationService.user.value.lastName }}</p>
      <p
        class="collapsed navbar-username"
        *ngIf="!authenticationService.user.value"
        [ngClass]="{ hidden: !open && !hover }"
      >LOADING</p>
    </a>
    <div class="clearfix"></div>
    <div class="domain-switcher">
      <i class="ovico-horizontal-swap" aria-hidden="true"></i>
      <div class="domain-selected" *ngFor="let d of domains">{{ d.name }}</div>
    </div>
  </div>
</div>
