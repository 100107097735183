<div class="outer">
  <table class="table table-sm" [ngClass]="{ 'table-striped': striped }" aria-describedby="Link Table">
    <thead>
      <tr>
        <th class="table-checkbox" *ngIf="selectableRows && filteredColumnData.length" scope="col">
          <input [id]="'selectAll'" type="checkbox" [checked]="isAllSelected()" (change)="onSelectAll($event)" />
        </th>
        <th
          scope="col"
          *ngFor="let col of filteredColumnData; let i = index"
          [ngClass]="{ 'table-status-label': col.type === 'status' }"
          (click)="onColumnHeaderClick(col)"
        >
          {{ col?.title }}
          <i *ngIf="!col.disableSorting && order.column !== col.id" class="fa fa-fw fa-sort" aria-hidden="true"></i>
          <i
            *ngIf="order.column === col.id"
            class="fa fa-fw"
            [ngClass]="{
              'fa-sort-asc': order.direction === 'ASC',
              'fa-sort-desc': order.direction === 'DESC'
            }"
            aria-hidden="true"
          ></i>
          <input
            *ngIf="columnHider"
            type="checkbox"
            [checked]="isColVisible(col.id)"
            (click)="toggleCol(col.id, $event)"
            class="col-hider"
          />
        </th>
        <th *ngFor="let extraCol of extraColumns.data" scope="col">
          {{ extraCol }}
        </th>
        <!--To Cater for table cancel button.-->
        <!--        <th *ngIf="hasChanges()" scope="col"></th>-->
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of data; let i = index"
        [ngClass]="{ selected: i === selectedIndex, 'selectable-rows': clickableRows }"
        (click)="onClick(item, $event)"
        [ngStyle]="rowData?.style(item)"
      >
        <th class="table-checkbox" *ngIf="selectableRows && filteredColumnData.length" scope="row">
          <input
            [id]="'row' + i"
            type="checkbox"
            class="width-400"
            [checked]="selectedRows.has(item.id)"
            (change)="onSelect($event, item)"
          />
        </th>
        <td *ngFor="let col of filteredColumnData; let j = index">
          <ng-container [ngSwitch]="col?.type">
            <ov-suite-string-cell
              *ngSwitchCase="'string'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-string-cell>
            <ov-suite-deep-string-cell
              *ngSwitchCase="'deep-string'"
              [col]="col"
              [item]="item"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-deep-string-cell>
            <ov-suite-number-cell
              *ngSwitchCase="'number'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-number-cell>
            <ov-suite-boolean-cell
              *ngSwitchCase="'boolean'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-boolean-cell>
            <ov-suite-date-cell
              *ngSwitchCase="'date'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-date-cell>
            <ov-suite-date-time-cell
              *ngSwitchCase="'date-time'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-date-time-cell>
            <ov-suite-time-cell
              *ngSwitchCase="'time'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="openModal(col, item, $event)"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-time-cell>
            <ov-suite-dropdown-cell
              *ngSwitchCase="'dropdown'"
              [col]="col"
              [item]="item"
              [dropdownData]="dropdownData"
              (itemChange)="itemChanged(item)"
            ></ov-suite-dropdown-cell>
              <ov-suite-action-dropdown-cell
                *ngSwitchCase="'action-dropdown'"
                [col]="col"
                [item]="item"
                [modalDisplayPosition]="getModalDisplayPosition(i)"
                (click)="rowItemButtonClick.emit({ event: $event, data: item })"
              ></ov-suite-action-dropdown-cell>
            <ov-suite-other-cell
              *ngSwitchCase="'other'"
              [col]="col"
              [item]="item"
              (itemChange)="itemChanged(item)"
              (click)="!!col.detailModal ? openModal(col, item, $event) :rowItemClick.emit({ event: $event, data: item, col: col })"
              [ngStyle]="{'cursor': !!col.detailModal ? 'pointer' : 'auto' }"
            ></ov-suite-other-cell>
            <ov-suite-buttons-cell
              *ngSwitchCase="'buttons'"
              [col]="col"
              [item]="item"
              [modalDisplayPosition]="getModalDisplayPosition(i)"
              (click)="rowItemButtonClick.emit({ event: $event, data: item })"
            ></ov-suite-buttons-cell>
            <ov-suite-pills-cell
              *ngSwitchCase="'pills'"
              [col]="col"
              [item]="item"
              [columnIndex]="j"
              (itemChange)="itemChanged(item)"
            ></ov-suite-pills-cell>
          </ng-container>
        </td>

        <!--        <td *ngFor="let extraCol of extraColumns.data">-->
        <!--          <span *ngIf="!item[isEditable]">-->
        <!--            {{-->
        <!--              !!item['column_' + extraCol]-->
        <!--                ? extraColumns.columnData?.formatter(item['column_' + extraCol])-->
        <!--                : extraColumns.columnData?.formatter(extraColumns.columnData?.rowAction(item, extraCol))-->
        <!--            }}-->
        <!--          </span>-->
        <!--          <ov-suite-input-->
        <!--            *ngIf="item[isEditable]"-->
        <!--            [id]="item.id + 'row' + i + 'col' + extraCol"-->
        <!--            [type]="extraColumns.columnData?.rowReturnType"-->
        <!--            [(ngModel)]="item['column_' + extraCol]"-->
        <!--            (ngModelChange)="itemChanged(item)"-->
        <!--          ></ov-suite-input>-->
        <!--        </td>-->
      </tr>
      <tr *ngFor="let blank of filler; let i = index">
        <th *ngIf="selectableRows" scope="row"></th>
        <td *ngFor="let col of filteredColumnData; let j = index" style="opacity: 0">x</td>
      </tr>
    </tbody>
  </table>
  <div class="row pt-0" [ngClass]="{ 'table-footer': showFiller }">
    <div class="mt-0 col-md-6 pager" *ngIf="filteredColumnData.length">
      Showing {{ data.length }} of {{ totalCount }} Entries
      <!--      <div class="float-right" *ngIf="loading">-->
      <!--        <span-->
      <!--          class="spinner-border spinner-border-sm"-->
      <!--          role="status"-->
      <!--          aria-hidden="true"-->
      <!--        ></span>-->
      <!--      </div>-->
      &nbsp; &nbsp;
      <mat-form-field *ngIf="pageChangeEnabled">
        <mat-select [value]="pageSize.toString()" matNativeControl (valueChange)="updatePageSize($event)">
          <mat-option value="10">10 rows</mat-option>
          <mat-option value="20">20 rows</mat-option>
          <mat-option value="30">30 rows</mat-option>
          <mat-option value="40">40 rows</mat-option>
          <mat-option value="50">50 rows</mat-option>
          <mat-option value="100">100 rows</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="showPageSelect && filteredColumnData.length" class="col-md-6">
      <nav *ngIf="totalPages > 1" aria-label="Page navigation example" class="float-right">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" aria-label="Previous" (click)="pageFirst()">
              <span aria-hidden="true">FIRST</span>
              <span class="sr-only">FIRST</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Previous" (click)="pageBack()">
              <span aria-hidden="true">PREVIOUS</span>
              <span class="sr-only">PREVIOUS</span>
            </a>
          </li>
          <li *ngFor="let i of pages" class="page-item" [ngClass]="{ active: i === currentPage }" (click)="selectPage(i)">
            <a class="page-link" aria-current="page">{{ i + 1 }} <span class="sr-only">(current)</span></a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" (click)="pageForward()">
              <span aria-hidden="true">NEXT</span>
              <span class="sr-only">NEXT</span>
            </a>
          </li>
          <li class="page-item">
            <a class="page-link" aria-label="Next" (click)="pageLast()">
              <span aria-hidden="true">LAST</span>
              <span class="sr-only">LAST</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
