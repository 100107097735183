import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { InventoryContainerConfigurationModel } from '@ov-suite/models-warehouse';
import { FactoryArea } from './factory-area.model';
import { Factory } from './factory.model';

@OVEntity('InventoryLocationCategory')
export class InventoryLocationCategory {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  description: string;
}

@OVEntity('InventoryLocationStatus')
export class InventoryLocationStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('InventoryLocation')
@OVForm(
  [
    ['#Inventory Location Details'],
    ['name', 'description'],
    ['capacity', 'factory'],
    ['factoryArea'],
    ['inventoryContainerConfigurations'],
    ['category', 'isStorage'],
    ['inputPriority', 'outputPriority'],
    ['barcodeData', 'minCapacity'],
    ['qrPreview'],
  ],
  [
    ['#Inventory Location Details'],
    ['name', 'description'],
    ['capacity', 'factory'],
    ['category'],
    ['inputPriority', 'outputPriority'],
    ['barcodeData', 'minCapacity'],
    ['qrPreview'],
  ],
)
@OVSidebar([['status'], ['id']])
@OVTable<InventoryLocationModel>([
  {
    key: 'name',
    title: 'Name',
    type: 'string',
    detailModal: data => ({
      id: data.id,
      entity: InventoryLocationModel,
    }),
  },
  {
    key: 'description',
    title: 'Description',
    type: 'string',
  },
  {
    type: 'other',
    title: 'Number of Sub Storage',
    action: item => item?.childrenCount?.toString() ?? '0',
    routerLink: () => [],
    keys: ['childrenCount'],
    queryParams: (item: { id: number }) => ({ _parentId: item.id }),
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Configurations',
    type: 'pills',
    keys: [
      'children.name',
      'children.inventoryContainerConfigurations.name',
      'inventoryContainerConfigurations.name',
    ],
    action: item => {
      const pills: string[] = item.inventoryContainerConfigurations.map(icc => icc.name);

      item.children.forEach(child => {
        child.inventoryContainerConfigurations.forEach(config => {
          pills.push(`${child.name} | ${config.name}`);
        });
      });

      pills.sort((a, b) => {
        return a > b ? 1 : -1;
      });

      return pills;
    },
    limit: 5,
  },
])
@OVSearch<InventoryLocationModel>({
  searchKeys: ['name', 'description', 'productSkus.name'],
})
export class InventoryLocationModel {
  @OVField({
    type: 'number',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: () => InventoryLocationStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: InventoryLocationStatus;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'Description',
    required: false,
  })
  description?: string;

  @OVField({
    type: 'boolean',
    title: 'IsStorage',
    required: false,
  })
  isStorage?: boolean;

  @OVSearchable()
  @OVField({
    type: () => FactoryArea,
    keys: ['id', 'name', 'path'],
    selectionType: 'single',
    title: 'Factory Area',
    tooltip: 'Factory Area',
    placeholder: 'Required',
    required: false,
  })
  factoryArea: FactoryArea;

  @OVField({
    type: () => InventoryLocationCategory,
    selectionType: 'simple',
    title: 'Category',
    required: false,
  })
  category: InventoryLocationCategory;

  // @OVField({
  //   type: () => [ProductSkuModel],
  //   keys: ['id', 'name'],
  //   selectionType: 'multiple',
  //   title: 'Product SKUs',
  //   tooltip: 'Packing Units',
  //   flat: true,
  //   required: false,
  //   query: {
  //     packingUnit: [{ operator: '=', value: true }],
  //   },
  // })
  // productSkus: ProductSkuModel;

  @OVField({
    type: () => [InventoryContainerConfigurationModel],
    keys: ['id', 'name'],
    selectionType: 'multiple',
    title: 'Container Configurations',
    flat: true,
    required: false,
  })
  inventoryContainerConfigurations: InventoryContainerConfigurationModel[];

  @OVSearchable()
  @OVField({
    type: () => Factory,
    keys: ['id', 'name'],
    title: 'Factory',
    tooltip: 'Factory',
    selectionType: 'simple',
    placeholder: 'Required',
    required: false,
  })
  factory: Factory;

  @OVField({
    type: () => InventoryLocationModel,
    title: 'Parent',
    bulkDependency: 'id',
  })
  parent?: InventoryLocationModel;

  @OVField({ type: () => InventoryLocationModel })
  children?: InventoryLocationModel[];

  @OVField({
    type: 'number',
    generated: true,
    readonly: true,
    unnecessary: true,
  })
  childrenCount?: number;

  @OVField({
    type: 'number',
    title: 'Capacity',
    required: false,
  })
  capacity: number;

  @OVField({
    type: 'number',
    title: 'Minimum Capacity',
    required: false,
    tooltip: 'This will only affect load bays.'
  })
  minCapacity: number;

  @OVField({ type: 'string', generated: true, readonly: true })
  path: string;

  @OVField({
    type: 'string',
    title: 'Barcode Type',
  })
  barcodeType: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Barcode Value',
  })
  barcodeData: string;

  @OVField({
    type: 'code-preview',
    unnecessary: true,
    codeKey: 'barcodeData',
  })
  qrPreview: void;

  @OVField({
    type: 'number',
    title: 'Input Priority',
    required: false,
  })
  inputPriority = 1;

  @OVField({
    type: 'number',
    title: 'Output Priority',
    required: false,
  })
  outputPriority = 1;
}
