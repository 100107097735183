import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerStageInstructionModel, StageTypeModel } from '@ov-suite/models-admin';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ov-suite-stage-types-selector-dialog',
  templateUrl: './stage-types-selector-dialog.component.html',
  styleUrls: ['./stage-types-selector-dialog.component.css']
})
export class StageTypesSelectorDialogComponent implements OnInit {
  @Input() stageInstruction: CustomerStageInstructionModel;

  @Input() stageTypes: StageTypeModel[];

  @Output() emitter: EventEmitter<CustomerStageInstructionModel | boolean> = new EventEmitter<CustomerStageInstructionModel | boolean>();

  stageInstructionForm: FormGroup

  constructor(private fb: FormBuilder) {
    this.stageInstructionForm = this.fb.group({
      stageTypeId: ['', Validators.required],
      instruction: ['', Validators.required]
    })
  }

  ngOnInit() {
    if (this.stageInstruction.stageType && this.stageInstruction.instruction) {
      this.prepareStageInstruction();
    }

    this.stageInstructionForm.valueChanges.subscribe(updatedStageInstruction => {
      this.modelChange(updatedStageInstruction);
    })
  }

  prepareStageInstruction() {
    this.stageInstructionForm.patchValue({
      stageTypeId: this.stageInstruction?.stageType.id,
      instruction: this.stageInstruction?.instruction,
    })
  }

  modelChange(stageInstruction: CustomerStageInstructionModel) {
    if (this.stageInstructionForm.invalid) {
      this.emitter.emit(true);
      return;
    }

    if (stageInstruction.stageTypeId) {
      this.stageInstruction.stageType = this.stageTypes.find(item => item.id === stageInstruction.stageTypeId);
    }

    this.stageInstruction.instruction = stageInstruction.instruction;
    this.stageInstruction.stageTypeId = stageInstruction.stageTypeId;

    this.emitter.emit(this.stageInstruction);
  }
}
