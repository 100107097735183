import { Component, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { OvAutoService } from '@ov-suite/services';
import { getFieldMetadata, OVTag } from '@ov-suite/ov-metadata';
import { WaveConfig } from '@ov-suite/models-warehouse';

@OVTag('create-wave-popup', WaveConfig)
@Component({
  selector: 'ov-suite-create-wave-modal',
  templateUrl: './create-wave-modal.component.html',
  styleUrls: ['./create-wave-modal.component.scss'],
})
export class CreateWaveModalComponent implements OnInit {
  closeResult = '';
  data: WaveConfig[];
  loading: boolean;
  selectedTemplate = 0;

  constructor(public modalService: NgbModal, private readonly router: Router, private readonly ovAutoService: OvAutoService) {}

  ngOnInit() {
    const metadata = getFieldMetadata<WaveConfig>(WaveConfig);

    const keys: string[] = ['id', 'waveName'];

    this.ovAutoService
      .list({
        search: {},
        filter: {},
        query: {},
        entity: WaveConfig,
        limit: 1000,
        offset: null,
        orderDirection: 'DESC',
        orderColumn: 'id',
        keys,
      })
      .then(result => {
        this.data = result.data;
        this.loading = false;
      })
      .catch(() => (this.loading = false));
  }

  getDismissReason(reason: unknown): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onOpenCreateView() {
    if (this.selectedTemplate > 0) {
      this.router.navigate(['/waves/clone'], { queryParams: { id: this.selectedTemplate } });
    } else {
      this.router.navigate(['/waves/add']);
    }
    this.modalService.dismissAll('');
  }
}
