/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { DomainModel } from '@ov-suite/models-admin';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { getWebDomain } from '@ov-suite/helpers-angular/lib/get-url.helper';

@Injectable({
  providedIn: 'root',
})
export class DomainService {
  static domainPathKey = 'CURRENT_DOMAIN_PATH';

  private map: Record<number, DomainModel> = {};

  public readonly currentDomainPath: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public readonly allDomains: BehaviorSubject<DomainModel[]> = new BehaviorSubject<DomainModel[]>([]);

  public readonly currentDomain: BehaviorSubject<DomainModel> = new BehaviorSubject<DomainModel>(null);

  constructor(
    private readonly ovAutoService: OvAutoService,
    private readonly cookieService: CookieService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.currentDomainPath.next(cookieService.get(DomainService.domainPathKey));
    this.authenticationService.user.subscribe({
      next: user => {
        if (user) {
          if (!this.allDomains.value?.length) {
            this.loadDomains();
          }
        } else {
          this.clear();
        }
      },
    });
    this.allDomains.subscribe({
      next: domains => {
        this.map = {};
        if (!domains.length) {
          return;
        }
        domains.forEach(domain => {
          this.map[domain.id] = domain;
        });
        if (!this.currentDomain.value) {
          const cachedDomainPath = cookieService.get(DomainService.domainPathKey);
          const [firstDomain] = domains;
          let domain;
          if (cachedDomainPath) {
            domain = domains.find(d => d.path === cachedDomainPath);
          }
          domain ??= firstDomain;
          this.setDomain(domain);
        }
      },
    });
    this.currentDomain.subscribe({
      next: newDomain => {
        if (newDomain) {
          cookieService.set(DomainService.domainPathKey, newDomain.path, null, null, getWebDomain());
        }
      },
    });
  }

  public clear() {
    this.allDomains.next([]);
    this.cookieService.delete(DomainService.domainPathKey, null, getWebDomain());
    this.currentDomain.next(null);
    this.currentDomainPath.next(null);
  }

  private async loadDomains(): Promise<void> {
    await this.ovAutoService
      .list({
        entity: DomainModel,
        limit: 10000,
        keys: ['id', 'name', 'path', 'parent', 'children'],
      })
      .then(domainList => {
        this.allDomains.next(domainList.data);
        this.map = domainList.data.reduce<Record<string, DomainModel>>((p, c) => {
          p[c.id] = c;
          return p;
        }, {});
      })
      .catch(() => {
        this.allDomains.next([]);
      });
  }

  getParentDomains(): DomainModel[] {
    return this.allDomains.value.filter(domain => !domain.parent);
  }

  getById(id: number): DomainModel {
    return this.map[id];
  }

  getByPath(path: string): DomainModel {
    return this.allDomains.value.find(d => d.path === path);
  }

  getByIds(ids: number[]): DomainModel[] {
    const results: DomainModel[] = [];
    ids.forEach(id => {
      const domain = this.map[id];
      if (domain) {
        results.push(domain);
      }
    });
    return results;
  }

  getDomains(keyword?: string): DomainModel[] {
    return keyword ? this.allDomains.value.filter(x => x.name.includes(keyword)) : this.allDomains.value;
  }

  setDomain(domain: DomainModel) {
    this.currentDomain.next(domain);
    this.currentDomainPath.next(domain.path);
  }
}
