<h1 mat-dialog-title>Split Order</h1>
<div mat-dialog-content>
  <table mat-table [dataSource]="dataSource$ | async" class="mat-elevation-z0">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>

      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="productSkuService.get$(element.orderItem.productSkuId) | async as productSku">
          {{productSku.name}}
        </ng-container>
      </td>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="productSkuService.get$(element.orderItem.productSkuId) | async as sku;">
          <ng-container *ngIf="sku.stageType; else noStageType">
            {{ sku.stageType.name }}
          </ng-container>
          <ng-template #noStageType> - </ng-template>
        </ng-container>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef> Total Amount </th>
      <td mat-cell *matCellDef="let element">
        {{element.orderItem.quantity}}
      </td>
    </ng-container>


    <ng-container matColumnDef="split1">
      <th mat-header-cell *matHeaderCellDef>
        <ov-suite-input
          type="text"
          placeholder="Part 1"
          [(ngModel)]="firstAlias"
        >
        </ov-suite-input>
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.originalAmount - element.quantity}}
      </td>
    </ng-container>

    <ng-container matColumnDef="split2">
      <th mat-header-cell *matHeaderCellDef>
        <ov-suite-input
          type="text"
          placeholder="Part 2"
          [(ngModel)]="secondAlias"
        >
        </ov-suite-input>
      </th>
      <td mat-cell *matCellDef="let element">
        <ov-suite-input
          type="number"
          placeholder="amount"
          [(ngModel)]="element.quantity"
          (ngModelChange)="checkNum($event, element)"
          min="0"
          [max]="element.originalAmount"
        >
        </ov-suite-input>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button color="warn" mat-dialog-close (click)="split()">Split</button>
  <button mat-stroked-button color="primary" mat-dialog-close cdkFocusInitial>Cancel</button>
</div>
