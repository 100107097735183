import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularSplitModule } from 'angular-split';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { LoadAllocationRoutes } from './load-allocation.routing';
import { OrderComponent } from './quadrants/order/order.component';
import { VehicleComponent } from './quadrants/vehicle/vehicle.component';
import { MainComponent } from './quadrants/main/main.component';
import { MapComponent } from './quadrants/map/map.component';
import { DetailComponent } from './quadrants/detail/detail.component';
import { LoadAllocationBaseService } from './services/load-allocation.base.service';
import { VehicleWaveComponent } from './components/vehicle-wave/vehicle-wave.component';
import { ExternalVehicleCreateComponent } from './components/external-vehicle/external-vehicle-create.component';
import { PendingChangesGuard } from '../../guards/pending-changes-guard';
import { LoadAllocationDataService } from './services/load-allocation.data.service';
import { LoadAllocationActionService } from './services/load-allocation.action.service';
import { LoadAllocationVehicleLayoutService } from './services/load-allocation.vehicle-layout.service';
import { LoadAllocationPrintComponent } from './components/print/load-allocation-print.component';
import { SubLabelComponent } from './quadrants/order/sub-label/sub-label.component';
import { LoadAllocationOrderService } from './services/data-services/load-allocation.order.service';
import { LoadAllocationCustomerService } from './services/data-services/load-allocation.customer.service';
import { LoadAllocationProductSkuService } from './services/data-services/load-allocation.product-sku.service';
import { LoadAllocationMasterRouteService } from './services/data-services/load-allocation.master-route.service';
import { LoadAllocationVehicleService } from './services/data-services/load-allocation.vehicle.service';
import { LoadAllocationLoaderComponent } from './components/loader/load-allocation-loader.component';
import { LoadAllocationLoadAllocationService } from './services/data-services/load-allocation.load-allocation.service';
import { LoadAllocationViewService } from './services/view-services/load-allocation.view.service';
import { LoadAllocationDateService } from './services/load-allocation.date.service';
import { LoadAllocationEventBusService } from './services/event-bus/load-allocation.event-bus.service';
import { LoadAllocationExternalVehicleService } from './services/data-services/load-allocation.external-vehicle.service';
import { LoadAllocationVehicleHelperService } from './services/data-services/load-allocation.vehicle-helper.service';
import { SplitComponent } from './components/split/split.component';
import { LoadAuthenticationSessionsService } from './services/load-authentication-sessions.service';

@NgModule({
  imports: [
    AngularSplitModule.forRoot(),
    CommonModule,
    RouterModule.forChild(LoadAllocationRoutes),
    FormsModule,
    UiModule,
    DragDropModule,
    NgbDropdownModule,
    LeafletModule,
    NgbNavModule,
    QRCodeModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatButtonModule,
    MatTableModule,
    MatCardModule,
  ],
  declarations: [
    DetailComponent,
    MainComponent,
    MapComponent,
    OrderComponent,
    VehicleComponent,
    VehicleWaveComponent,
    ExternalVehicleCreateComponent,
    LoadAllocationPrintComponent,
    SplitComponent,
    SubLabelComponent,
    LoadAllocationLoaderComponent,
  ],
  providers: [
    LoadAllocationActionService,
    LoadAllocationBaseService,
    LoadAllocationDataService,

    LoadAllocationOrderService,
    LoadAllocationCustomerService,
    LoadAllocationProductSkuService,
    LoadAllocationMasterRouteService,
    LoadAllocationVehicleService,
    LoadAllocationExternalVehicleService,
    LoadAllocationLoadAllocationService,
    LoadAllocationVehicleHelperService,

    LoadAllocationViewService,
    LoadAllocationDateService,

    LoadAllocationVehicleLayoutService,
    PendingChangesGuard,

    LoadAllocationEventBusService,

    LoadAuthenticationSessionsService,
  ],
})
export class LoadAllocationModule {}
