import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LoadDetailModel } from '@ov-suite/models-warehouse';
import { ColumnData } from '@ov-suite/helpers-shared';
import { MatCustomDataSource } from '@ov-suite/ui';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import { FormBuilder, Validators } from '@angular/forms';
import { OrderModel } from '@ov-suite/models-order';

interface OrderDropdown {
  key: number;
  value: string;
}

@Component({
  selector: 'ov-suite-load-management-details-card',
  templateUrl: './load-management-details-card.component.html',
  styleUrls: ['./load-management-details-card.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LoadManagementDetailsCardComponent implements OnInit, OnChanges {
  @Input() details: LoadDetailModel[];

  @Input() orders: OrderModel[] = [];

  @Output() updateSealNumberEmitter: EventEmitter<LoadDetailModel[]> = new EventEmitter<LoadDetailModel[]>();

  @ViewChild('editSealModal') editSealModal: TemplateRef<unknown>;

  dropdownData: OrderDropdown[] = [];

  editSealForm = this.formBuilder.group({
    sealNumber: ['', Validators.required],
    orderId: [''],
  });

  selectedSeal: LoadDetailModel;

  seals: LoadDetailModel[] = [];

  wraps: LoadDetailModel[] = [];

  loadDetailSealColumnData: ColumnData<LoadDetailModel>[] = [
    {
      key: 'value',
      title: 'Seal Number',
      type: 'string',
    },
    {
      keys: ['order.orderCode'],
      title: 'Order',
      type: 'other',
      action: order => {
        return order.order?.orderCode ?? ' - ';
      },
    },
    {
      title: 'Actions',
      type: 'button-bar',
      disableSorting: true,
      buttons: [
        {
          icon: 'fa fa-edit',
          actionType: 'custom',
          classes: 'btn btn-dark btn-sm mx-1',
          tooltip: 'Edit Seal #',
          action: item => {
            this.onEditSealClick(item);
          },
        },
      ],
      keys: ['id'],
    },
  ];

  loadDetailWrapColumnData: ColumnData<LoadDetailModel>[] = [
    {
      key: 'title',
      title: 'Wrap',
      type: 'string',
    },
    {
      key: 'value',
      title: 'Wrap Count',
      type: 'string',
    },
  ];

  sealsDataSource: MatCustomDataSource<LoadDetailModel> = new MatCustomDataSource<LoadDetailModel>([]);

  wrapsDataSource: MatCustomDataSource<LoadDetailModel> = new MatCustomDataSource<LoadDetailModel>([]);

  constructor(private readonly dialogService: ConfirmationDialogService, private readonly formBuilder: FormBuilder) {}

  ngOnInit() {
    this.initializeLoadDetailData();
    this.initializeOrderDropdownData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.details) {
      this.initializeLoadDetailData();
    }

    if (changes.orders) {
      this.initializeOrderDropdownData();
    }
  }

  initializeLoadDetailData() {
    this.seals = [];
    this.wraps = [];

    this.details.forEach(detail => {
      switch (detail.title) {
        case 'Seal':
          this.seals.push(detail);
          break;
        case 'Wraps':
          this.wraps.push(detail);
          break;
        default:
          break;
      }
    });

    this.sealsDataSource.data = this.seals;
    this.wrapsDataSource.data = this.wraps;
  }

  initializeOrderDropdownData() {
    const tempDropdownData = this.orders.map(o => ({ key: o.id, value: o.orderCode }));
    tempDropdownData.unshift({ key: null, value: ' - ' });
    this.dropdownData = [...tempDropdownData];
  }

  onEditSealClick(detail: LoadDetailModel): void {
    this.selectedSeal = { ...detail };

    this.editSealForm.patchValue({ sealNumber: this.selectedSeal.value });
    this.editSealForm.patchValue({ orderId: this.selectedSeal.orderId });

    const confirmationDialogData: ConfirmationDialogData = {
      title: 'Edit Seal Number',
      confirmButtonText: 'Save',
      component: this.editSealModal,
    };

    this.dialogService.openComponentDialog(confirmationDialogData).subscribe(update => {
      if (update && this.editSealForm.status === 'VALID') {
        const { sealNumber, orderId } = this.editSealForm.value;

        if (orderId) {
          this.selectedSeal.orderId = orderId;
          this.selectedSeal.order = new OrderModel();
          this.selectedSeal.order.id = orderId;
          this.selectedSeal.order.orderCode = this.orders.find(o => o.id === orderId).orderCode;
        } else {
          this.selectedSeal.orderId = null;
          this.selectedSeal.order = null;
        }

        this.selectedSeal.value = sealNumber;
        this.updateSealNumberEmitter.emit([detail, this.selectedSeal]);
      }
    });
  }
}
