import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function listOrderInvoiceLite(): DocumentNode {
  return gql(
    `query listOrderInvoiceLite($loadId: Int!, $orderIds: [Int!]!) {
      listOrderInvoiceLite(loadId: $loadId, orderIds: $orderIds) {
          id
          commitDate
          invNum
          invoiceException
          order {
            id
            orderCode
          }
       }
      }`,
  );
}

export function listOrderInvoice(): DocumentNode {
  return gql(
    `query listOrderInvoice($invoiceId: Int!) {
      listOrderInvoice(invoiceId: $invoiceId) {
          id
          invNum
          deliveryNote
          invoiceException
          modifiedByUser {
            id
            firstName
            lastName
          }
          invoiceItems {
            id
            quantity
            orderItemId
            productSku {
              id
              name
              sku
            }
          }
          loadId
          order {
            id
            orderCode
            externalOrderNo
            createdByUser {
              id
              firstName
              lastName
            }
            customer {
              id
              name
              customerCode
              description
              taxNumber
              agingTerm
              accountReceivableComment1
              accountReceivableComment2
              postalAddress {
                line1
                line2
                line3
                line4
                line5
                line6
                postalCode
              }
            }
            orderItems {
              id
              quantity
              productSkuId
              unitPriceExcl
              unitPriceIncl
              inventoryItemUnitPrice
              inventoryItemUnitPriceIncl
              discountedUnitPrice
              note
            }
            deliveryAddress {
              line1
              line2
              line3
              line4
              line5
              line6
              postalCode
            }
          }
        }
      }`,
  );
}
