import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiOptions } from '@ov-suite/ui';

export interface UploadedFile {
  original: string;
  thumbnail: string;
  scaled: string;
}

@Component({
  selector: 'ov-suite-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input() id: string;

  @Input() imageUrl: string;

  @Input() size: 'large' | 'default';

  @Output() errors: EventEmitter<string> = new EventEmitter();

  @Output() imageUrlChange = new EventEmitter<string>();

  @Input() viewMode = false;

  @Input() base64 = false;

  currentImage: File;

  isUploaded: boolean;

  loading: boolean;

  constructor(private readonly httpClient: HttpClient, @Inject('OPTIONS') private readonly options: ApiOptions) {}

  ngOnInit() {}

  onFileInputTrigger() {
    document.getElementById('image-upload-input').click();
  }

  onFileChange(event: Event) {
    const target: DataTransfer = event.target as unknown as DataTransfer;
    if (target.files.length !== 1) {
      throw new Error('Cannot upload multiple files.');
    }

    this.isUploaded = false;
    this.currentImage = (event?.target as HTMLInputElement)?.files.item(0);
    this.convertToBase64(this.currentImage).then(results => {
      this.imageUrl = results;
    });
  }

  async onFileUpload() {
    // need to be able to upload to a specefic
    const imageFormatsAllowed = ['jpeg', 'gif', 'png'];
    this.loading = true;

    if (this.currentImage) {
      if (!imageFormatsAllowed.some(format => this.currentImage.type.includes(format))) {
        this.errors.emit('The file you provided is not supported, please use one of these supported formats: [.jpg .gif .png]');
        this.currentImage = null;
      } else {
        // upload image to a server
        (await this.uploadFile(this.currentImage)).subscribe(
          response => {
            this.imageUrl = response.body.original;
            this.imageUrlChange.emit(response.body.original);
            this.loading = false;
            this.isUploaded = true;
          },
          error => {
            this.loading = false;
            this.errors.emit(error);
          },
        );
      }
    }
  }

  async uploadFile(file: File): Promise<Observable<HttpResponse<UploadedFile>>> {
    const base64 = await this.convertToBase64(file);
    return this.httpClient.post<UploadedFile>(
      this.options.imageUploadUrl, // point to .env
      base64.replace(`data:${file.type};base64,`, ''),
      {
        observe: 'response',
        headers: {
          'content-type': file.type,
        },
      },
    );
  }

  async convertToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }
}
