import { Component, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { LoadPlan } from '../wave-allocation.interface';

@Component({
  selector: 'ov-suite-wave-allocation-load-sheet',
  templateUrl: './wave-allocation-load-sheet.component.html',
  styleUrls: ['./wave-allocation-load-sheet.component.scss'],
})
export class WaveAllocationLoadSheetComponent implements OnInit {
  loads: LoadPlan[] = [];
  active = 1;

  today: string;
  constructor(
    public readonly ovAutoService: OvAutoService,
    public readonly activeModal: NgbActiveModal,
    public readonly data: WaveAllocationDataService,
  ) {}

  ngOnInit() {
    this.loads = this.data.distributedLoads.all;
  }

  onCancel() {
    this.activeModal.close();
  }

  onPrint() {
    window.print();
  }
}
