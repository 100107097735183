import { Component, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationParams {
  title?: string;
  text: string;
  component?: TemplateRef<unknown>;
}

@Component({
  selector: 'ov-suite-confirmation-dialog',
  template: `
    <h1 mat-dialog-title>{{ params.title ? params.title : 'Are you sure?' }}</h1>
    <div mat-dialog-content>
      <p>{{ params.text }}</p>
      <ng-container *ngIf="params.component">
        <ng-container *ngTemplateOutlet="params.component">
        </ng-container>
      </ng-container>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-stroked-button [mat-dialog-close]="false" color="primary">No</button>
      <button mat-button [mat-dialog-close]="true" color="warn" cdkFocusInitial>Yes</button>
    </div>
  `,
})
export class ConfirmationComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public params: ConfirmationParams,
  ) {}
}
