import { OVField } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';

export class LoadAuthenticationSessionModel {
  @OVField({
    type: 'string',
  })
  id: string;

  @OVField({
    type: 'string',
  })
  createdDate: string;

  @OVField({
    type: 'string',
  })
  updatedDate?: string;

  @OVField({
    type: () => UserModel,
  })
  user: UserModel;
}
