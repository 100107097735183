import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { InventoryContainer } from '@ov-suite/models-warehouse';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: InventoryContainer,
      createTitle: 'Inventory Container',
      route: ['inventory-container'],
      tableTitle: 'Inventory Container',
      hideImport: true,
      permissionId: FeatureKey.RESOURCES,
    }),
  ],
})
export class InventoryContainerModule {}
