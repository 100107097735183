<div
  cdkDropList
  [id]="getId()"
  class="plan"
  [style]="{ left: '15px', width: '98%' }"
  cdkDropListOrientation="horizontal"
  [cdkDropListData]="dto"
  [cdkDropListDisabled]="dto.committed"
  (cdkDropListDropped)="action.dropDirty($event, dto)"
  [cdkDropListConnectedTo]="base.vehicleLists"
>
  <div
    *ngFor="let orderDTO of dto.orderDTOs"
    [cdkDragData]="orderDTO"
    class="plan-pin"
    cdkDrag
    [ngClass]="{ 'selected-customer': (viewService.selectedOrderDTO$ | async) === orderDTO }"
    (click)="action.selectOrder(orderDTO);"
    (mousedown)="stopPropagation($event)"
  >
    <span style="display: flex; flex-direction: row; align-items: center">
      <ng-container *ngIf="orderService.get$(orderDTO.orderId) | async as order; else loader">
        <span>
          {{ order.orderCode }}
        </span>
        <span *ngIf="order.alias" style="font-size: 9px">
         {{ order.alias }}
        </span>
      </ng-container>
    </span>
    <span *ngIf="!dto.committed" class="fa fa-times" (click)="resetOrder(orderDTO, $event)"></span>
  </div>
  <div class="line"></div>
  <div class="left-arrow"></div>
  <div class="right-arrow"></div>
</div>

<ng-template #loader>
  <ov-suite-load-loader></ov-suite-load-loader>
</ng-template>
