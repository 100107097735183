<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table [title]="'Active Loads'"
                              [formClass]="formClass"
                              [hideAddButton]="true"
                              [excludeColumns]="4"
                              [orderColumn]="'date'"
                              [defaultOrderDirection]="'DESC'"
                              [filter]="filter"
                              [defaultSearchFilter]="search"
                              [overrideColumnData]="columnData"
                              [hasBulkUpload]="false">
    </ov-suite-hierarchy-table>
  </div>
</div>
