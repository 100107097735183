import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { InventoryContainerEvent } from '@ov-suite/models-warehouse';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: InventoryContainerEvent,
      createTitle: 'Inventory Container Events',
      route: ['container-events'],
      tableTitle: 'Pallets Events',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      permissionId: FeatureKey.RESOURCES,
      defaultOrderColumn: 'timestamp',
      defaultOrderDirection: 'DESC',
    }),
  ],
})
export class InventoryContainerEventsModule {}
