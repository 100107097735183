<div class="outer-container">
  <div class="main-container">
    <mat-card>
      <div class="filter-row">
        <ng-container>
          <ov-suite-excel-formatter [formClass]="formClass"
                                    [columnData]="columnData"
                                    (overrideExportEmitter)="fetchData()"
                                    [overrideExportData]="fetchDownloadData"
                                    [overrideFetchQuery]="true"
                                    class="filter-item">
          </ov-suite-excel-formatter>
        </ng-container>
      </div>
      <ov-suite-clean-table
        #loadTable
        [columnData]="columnData"
        [dataSource]="dataSource"
        emptyMessage="No Log Entries"
      >
      </ov-suite-clean-table>
    </mat-card>
  </div>
</div>
