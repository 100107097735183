import { NgModule } from '@angular/core';
import { AgmMapComponent } from './agm-map.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AgmCoreModule } from '@agm/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AgmMapComponent],
  exports: [AgmMapComponent],
  imports: [
    AgmCoreModule,
    CommonModule,
    FormsModule,
    LeafletModule,
    NgbTooltipModule,
  ],
})
export class AgmMapModule {}
