import { OVField, OVForm, OVTable, OVSearchable, OVEntity, OVSidebar, OVSearch } from '@ov-suite/ov-metadata';
import { VehicleClass } from './vehicle-class.model';
import { DomainModel } from './domain.model';

export interface HasSimple {
  getSimple(): VehicleSimple;
}

export interface VehicleSimple {
  id: number;

  internalId: number;

  externalId: number;

  identifier: string;

  name: string;

  registration: string;

  classId?: number;

  className: string;

  length: number;

  width: number;

  height: number;

  weightLoadAllowed: number;

  volumeRedPercentage: number;
  volumeOrangePercentage: number;
  weightRedPercentage: number;
  weightOrangePercentage: number;
}

@OVEntity('VehicleStatus', 'shared')
export class VehicleStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('Vehicle', 'shared')
@OVSidebar([['status'], ['id']])
@OVForm([['#Vehicle Details'], ['code', 'name'], ['registration', 'class'], ['domains']])
@OVTable<Vehicle>([
  {
    key: 'id',
    title: 'Vehicle ID',
    type: 'number',
    disableFiltering: true,
  },
  {
    key: 'name',
    title: 'Name',
    type: 'string',
  },
  {
    key: 'code',
    title: 'Code',
    type: 'string',
  },
  {
    key: 'registration',
    title: 'Registration',
    type: 'string',
  },
  {
    title: 'Class',
    type: 'other',
    action: e => e?.class?.name,
    keys: ['class.name'],
  },
])
@OVSearch<Vehicle>({ searchKeys: ['code', 'name', 'registration', 'class.name', 'class.vehicleClassId', 'class.classType'] })
export class Vehicle implements HasSimple {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Vehicle Code',
    tooltip: 'Code',
    placeholder: 'Required',
    required: true,
  })
  code: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Registration',
    placeholder: 'Required',
    required: true,
    validatorInjectionKey: 'VEHICLE_REGISTRATION_VALIDATOR',
  })
  registration: string;

  @OVField({
    type: () => VehicleClass,
    title: 'Vehicle Class',
    placeholder: 'Required',
    required: false,
  })
  class: VehicleClass;

  @OVField({
    type: () => VehicleStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: VehicleStatus;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: DomainModel[];

  getSimple(): VehicleSimple {
    return {
      id: this.id,
      identifier: `v${this.id}`,
      internalId: this.id,
      externalId: null,
      name: this.name,
      className: this.class.name,
      classId: this.class.id,
      height: this.class.height,
      width: this.class.width,
      length: this.class.length,
      weightLoadAllowed: this.class.weightLoadAllowed,
      registration: this.registration,
      weightRedPercentage: this.class.weightRedPercentage,
      weightOrangePercentage: this.class.weightOrangePercentage,
      volumeRedPercentage: this.class.volumeRedPercentage,
      volumeOrangePercentage: this.class.volumeOrangePercentage,
    };
  }
}
