import { Component, Input, OnInit } from '@angular/core';
import { MatCustomDataSource } from '@ov-suite/ui';
import { ColumnData } from '@ov-suite/helpers-shared';
import { StagingItemLogModel } from '@ov-suite/models-admin';
import { LoadManagementDataService } from '../../load-management.data.service';

@Component({
  selector: 'ov-suite-staging-item-log-modal',
  template: `
    <ov-suite-clean-table
      [dataSource]="stagingItemLogDataSource"
      [columnData]="columnData"
      [disablePaging]="true"
      [disableSorting]="true"
    >
    </ov-suite-clean-table>
  `,
})
export class StagingItemLogModalComponent implements OnInit {
  @Input() loadManagementDataService: LoadManagementDataService;

  stagingItemLogDataSource = new MatCustomDataSource<StagingItemLogModel>([]);

  columnData: ColumnData<StagingItemLogModel>[] = [
    {
      type: 'date',
      title: 'Date Completed',
      key: 'completedDate',
    },
    {
      type: 'deep-string',
      title: 'Handled By',
      key: 'handledByUser.username',
    },
    {
      type: 'deep-string',
      title: 'Container',
      key: 'containerStaged.name',
    },
  ];

  ngOnInit(): void {
    this.loadManagementDataService.stagingItemLog.subscribe(res => {
      this.stagingItemLogDataSource.data = res;
    });
  }
}
