import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import {
  Constructor,
  FieldMetadata,
  FieldParamsConstructor,
  GenericHierarchy,
  getFieldMetadata,
  getSearchableMetadata,
  getTypeMetadata,
  SearchableMetadata,
} from '@ov-suite/ov-metadata';
import { HasId } from '@ov-suite/ui';
import { objectPath, QueryParams } from '@ov-suite/helpers-shared';
import { SalesRepModel } from '@ov-suite/models-order';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';

type GenericHierarchyType = GenericHierarchy;

export interface WithQuantity<T> {
  quantity: number;
  [key: string]: T | number;
}

@Component({
  selector: 'ov-suite-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [MakeProvider(TreeSelectComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TreeSelectComponent<T extends GenericHierarchyType>
  extends AbstractValueAccessor<T | T[] | WithQuantity<T> | WithQuantity<T>[] | (T | WithQuantity<T>)[]>
  implements OnInit
{
  @Input() title = '';

  @Input() tooltip = '';

  @Input() danger = false;

  @Input() nameColumnLabel = 'Name';

  @Input() nameColumnKey = 'name';

  @Input() flat = false;

  @Input() single = false;

  @Input() keys?: string[];

  @Input() search: Record<string, QueryParams[]>;

  @Input() filter?: Record<string, QueryParams[]>;

  @Input() query?: Record<string, QueryParams[]>;

  hasAll = false;

  _subItem: FieldParamsConstructor;

  @Input() set subItem(subItem: FieldParamsConstructor) {
    this._subItem = subItem;
    // this.limit = this._subItem.dropdownLimit;
  }

  private _withQuantity = false;

  @Input() set withQuantity(input: boolean) {
    this._withQuantity = !!input;
    if (this.metadata) {
      this.quantityKey = this.metadata.fields.find(item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id')?.propertyKey;
    }
  }

  get withQuantity() {
    return this._withQuantity;
  }

  get multiValue() {
    if (Array.isArray(this.val)) {
      return this.val;
    }
    if (!this.val) {
      return [];
    }
    return [this.val];
  }

  limit = 2000;

  name: string;

  _formClass: Constructor;

  quantityKey: string;

  @Input() set formClass(formClass: Constructor | Constructor[]) {
    this._formClass = Array.isArray(formClass) ? formClass[0] : formClass;
    if (formClass) {
      this.metadata = getFieldMetadata(this._formClass);
      this.name = this.metadata.name;
      if (this.withQuantity) {
        this.quantityKey = this.metadata.fields.find(item => item.propertyKey !== 'quantity' && item.propertyKey !== 'id').propertyKey;
      }
    }
    if (this.name) {
      this.searchableMetadata = getSearchableMetadata(this._formClass);
    }
  }

  get formClass() {
    return this._formClass;
  }

  metadata: FieldMetadata;

  searchableMetadata: SearchableMetadata;

  currentParentId: number | string = null;

  _currentData: T[] = [];

  set currentData(data: T[]) {
    this._currentData = data;
    if (data?.length) {
      this.currentTitle = data[0].parent?.name ?? 'All';
    }
    this.calculateSelection();
  }

  get currentData() {
    return this._currentData;
  }

  currentTitle = this.title;

  filteredPath: number[] = [];

  selectedData: (T | WithQuantity<T>)[] = [];

  searchValue: string;

  searchQuery: Record<string, string[]>;

  selectionMap: {
    [key: number]: 'selected' | 'not' | 'partial' | 'unavailable';
  } = {};

  _ovAutoService: OvAutoService;

  @Input() set ovAutoService(service: OvAutoService) {
    this._ovAutoService = service;
  }

  get ovAutoService() {
    return this._ovAutoService;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.reset();
  }

  calculateSelection() {
    this.currentData?.forEach(item => {
      this.selectionMap[item.id] = this.getSelectionType(item);
    });
  }

  getSelectionType(item: T): 'selected' | 'not' | 'partial' | 'unavailable' {
    if (this.flat) {
      return this.multiValue.some(entry => entry.id === item.id) ? 'selected' : 'not';
    }
    const path = item.path.slice(0, -1).split('.');
    path.pop();
    const unavailable = this.multiValue.some(entry => path.includes(entry.id.toString()));
    if (unavailable) {
      return 'unavailable';
    }
    const found = this.multiValue.filter(entry =>
      this.withQuantity
        ? ((entry as WithQuantity<T>)[this.quantityKey] as T).path.includes(`${item.id}.`)
        : (entry as T).path.includes(`${item.id}.`),
    );
    if (found?.length) {
      if (found.some(entry => entry.id === item.id)) {
        return 'selected';
      }
      return 'partial';
    }
    return 'not';
  }

  writeValue(value: T | T[] | WithQuantity<T> | WithQuantity<T>[] | (T | WithQuantity<T>)[]) {
    this.val = value;
    this.onChange(value);
    this.calculateSelection();
  }

  getSearch(input?: number | string): Record<string, QueryParams[]> {
    return this.flat ? this.query : { parentId: [input ? input.toString() : null], ...this.query };
  }

  async select(item: T) {
    if (!this.flat && this.selectionMap[item.id] === 'not') {
      this.filteredPath = item.path
        .slice(0, -1)
        .split('.')
        .map(entry => Number(entry));
      this.currentTitle = item.name;
      this.searchValue = '';
      this.searchQuery = {};
      this.ovAutoService
        .list({
          entity: this.formClass as Constructor<HasId>,
          limit: this.limit,
          query: this.getSearch(item.id),
          filter: this.filter,
          search: this.search,
          keys: this.keys,
        })
        .then(response => {
          this.currentData = response.data as T[];
          this.currentParentId = item.id;
        });
    }
  }

  async back() {
    if (this.flat) {
      return;
    }
    this.filteredPath.pop();
    const parentId = this.filteredPath.length ? this.filteredPath[this.filteredPath.length - 1] : undefined;
    this.ovAutoService
      .list({
        entity: this.formClass as Constructor<{ id: string | number }>,
        filter: this.filter,
        search: this.search,
        query: this.getSearch(parentId),
        limit: this.limit,
        offset: 0,
        keys: this.keys
      })
      .then(response => {
        this.currentData = response.data as T[];
        this.currentParentId = parentId ?? null;
      });
  }

  async reset() {
    if (this._subItem.withQuantity) {
      const subItemType = this._subItem.subType;

      const keys = this._subItem.keys.filter(key => key.startsWith(this._subItem.quantityKey)).map(key => key.slice(this._subItem.quantityKey.length + 1))

      const { entity } = getTypeMetadata(subItemType);
      this.ovAutoService
        .list({
          entity,
          search: { ...this.searchQuery },
          filter: this.filter,
          query: this.getSearch(),
          limit: this.limit,
          offset: 0,
          keys,
        })
        .then(response => {
          this.currentData = response.data as T[];
        });
    } else {
      const subItemType = this._subItem.type;
      const { entity } = getTypeMetadata(subItemType);
      this.ovAutoService
        .list({
          entity,
          search: { ...this.searchQuery },
          filter: this.filter,
          query: this.getSearch(),
          limit: this.limit,
          offset: 0,
          keys: this.keys,
        })
        .then(response => {
          this.currentData = response.data as T[];
        });
    }
  }

  onSearchChange(event: Event): void {
    if ((event?.target as HTMLInputElement)?.value) {
      this.searchText((event.target as HTMLInputElement).value);
    } else {
      this.searchQuery = {};
      this.reset();
    }
  }

  searchText(text: string): void {
    let newFilter = {};
    this.searchableMetadata.fields.forEach(field => {
      const key = `${field.propertyKey}`;
      if (newFilter) {
        newFilter = { ...newFilter, [key]: [text] };
      } else {
        newFilter = { [key]: [text] };
      }
    });

    if (text && Object.keys(newFilter).length < 1 && this.searchableMetadata.fields.length < 1) {
      const key = `${this.nameColumnKey || 'name'}`;
      newFilter = { [key]: [text] };
    }

    this.searchQuery = newFilter;
    this.reset();
  }

  async cautionAdd(event, item: T) {
    event?.stopPropagation();
    if (item && window.confirm('This will replace other items further down the tree')) {
      await this.add(event, item);
    }
  }

  async add(event, item: T) {
    event?.stopPropagation();
    let actualItem: T | WithQuantity<T> = item;

    if (this.withQuantity) {
      actualItem = { quantity: 1, [this.quantityKey]: item };
    }

    if (this.single) {
      this.writeValue(actualItem);
      return;
    }
    this.selectedData.push(actualItem);
    let val;
    if (!this.val) {
      val = [actualItem];
    } else if (this.flat) {
      val = [...this.multiValue, actualItem];
    } else {
      val = [
        ...this.multiValue.filter(existing =>
          this.withQuantity
            ? !existing[this.quantityKey].path.includes(`${actualItem.id}.`)
            : !(existing as T).path.includes(`${actualItem.id}.`),
        ),
        actualItem,
      ];
    }

    this.writeValue(val);
  }

  addAll() {
    if (this.flat) {
      let val;
      if (!this.hasAll) {
        this.selectedData = this.currentData;
        if (this.withQuantity) {
          val = [...this.currentData.map(item => ({ quantity: 1, [this.quantityKey]: item }))];
        } else {
          val = [...this.currentData];
        }
      } else {
        this.selectedData = [];
        val = [];
      }
      this.writeValue(val);
    }
    this.hasAll = !this.hasAll;
  }

  remove(event, item: T | WithQuantity<T>) {
    event?.stopPropagation();
    if (this.single) {
      this.writeValue(null);
      return;
    }
    this.writeValue(
      this.multiValue.filter(selected =>
        this.withQuantity
          ? ((selected as WithQuantity<T>)[this.quantityKey] as T)?.id !== ((item as WithQuantity<T>)[this.quantityKey] as T)?.id
          : (selected as T).id !== (item as T).id,
      ),
    );
  }

  forceUpdate() {
    this.onChange(this.value);
  }

  getLabel(object: T, path: string | string[]): string {
    if (this._subItem.nameColumnOverride) {
      return this._subItem.nameColumnOverride(object);
    }

    if (Array.isArray(path)) {
      return path.map(p => objectPath(object as Record<string, unknown>, p)).join(' ');
    }

    return `${objectPath(object as Record<string, unknown>, path)}`;
  }
}
