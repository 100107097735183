import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { CustomerModel } from '@ov-suite/models-admin';
import { LazyService } from './load-allocation.lazy.service';

/**
 * Used to manage orders in various ways
 */

@Injectable()
export class LoadAllocationCustomerService extends LazyService<CustomerModel> {
  constructor(private readonly ovAutoService: OvAutoService) {
    super(CustomerModel, ovAutoService, { relations: [] });
  }
}
