import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { ProductSkuModel } from '@ov-suite/models-admin';

@OVEntity({ name: 'InvoiceLine' })
export class InvoiceLineModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  quantity: number;

  // @OVField({ type: () => OrderItemModel })
  // orderItem: OrderItemModel;

  @OVField({ type: 'number' })
  orderItemId: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;
}
