<table mat-table class="mat-elevation-z0">
  <!-- Order Code -->
  <ng-container matColumnDef="orderCode">
    <th class="accordion-table-header" mat-header-cell *matHeaderCellDef> Order Number </th>
  </ng-container>

  <!-- Invoice Number -->
  <ng-container matColumnDef="orderedQuantity">
    <th class="accordion-table-header" mat-header-cell *matHeaderCellDef> Ordered Quantity </th>
  </ng-container>

  <!-- Invoice Number -->
  <ng-container matColumnDef="loadedQuantity">
    <th class="accordion-table-header" mat-header-cell *matHeaderCellDef> Loaded Quantity </th>
  </ng-container>

  <!-- Invoice Number -->
  <ng-container matColumnDef="action">
    <th class="accordion-table-header" mat-header-cell *matHeaderCellDef> Action </th>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="topBarDisplayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: topBarDisplayColumns;"></tr>
</table>

<mat-accordion>
  <mat-expansion-panel *ngFor="let order of orders | keyvalue">
    <mat-expansion-panel-header (click)="setSelectedOrder(order);">
      <table mat-table class="mat-elevation-z0">
        <!-- Order Code -->
        <ng-container matColumnDef="orderCode">
<!--          <th mat-header-cell *matHeaderCellDef> {{ order.value.orderCode }} </th>-->
          <td class="accordion-table-header" mat-cell *matHeaderCellDef> {{ order.value.orderCode }} </td>
        </ng-container>

        <!-- Invoice Number -->
        <ng-container matColumnDef="orderedQuantity">
<!--          <th mat-header-cell *matHeaderCellDef> Ordered Quantity </th>-->
          <td class="accordion-table-header" mat-cell *matHeaderCellDef> {{ order.value.orderedQuantity }}</td>
        </ng-container>

        <!-- Invoice Number -->
        <ng-container matColumnDef="loadedQuantity">
<!--          <th mat-header-cell *matHeaderCellDef> Loaded Quantity </th>-->
          <td class="accordion-table-header" mat-cell *matHeaderCellDef> {{ order.value.loadedQuantity }} </td>
        </ng-container>

        <!-- Invoice Number -->
        <ng-container matColumnDef="action">
<!--          <th mat-header-cell *matHeaderCellDef> Action </th>-->
          <td class="accordion-table-header" mat-cell *matHeaderCellDef>
            <ng-container *ngIf="loadInvoiceMap.has(order.value.id); else generateInvoiceTemplate">
              <ng-container [ngSwitch]="loadInvoiceMap.get(order.value.id).invoiceStatus">
              <span *ngSwitchCase="'EXCEPTION'">
                <button mat-stroked-button
                        [matTooltip]="loadInvoiceMap.get(order.value.id)?.invoiceException || 'Invoice Exception Occurred'"
                        matTooltipPosition="before"
                        (click)="onReloadInvoice($event, loadInvoiceMap.get(order.value.id)?.id)"
                >
                  RELOAD
                </button>
              </span>
                <span *ngSwitchCase="'GENERATING'">
                <button mat-stroked-button (click)="onGeneratingInvoice($event)">GENERATING</button>
                </span>
                <span *ngSwitchCase="'SUCCESS'">
                <button mat-stroked-button (click)="onViewInvoice($event, loadInvoiceMap.get(order.value.id))">VIEW INVOICE</button>
              </span>
              </ng-container>
            </ng-container>
            <ng-template #generateInvoiceTemplate>
              <span>
                <button mat-stroked-button (click)="generateInvoice($event, order)">GENERATE INVOICE</button>
              </span>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="topBarDisplayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: topBarDisplayColumns;"></tr>
      </table>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-container
        *ngTemplateOutlet="orderItemTableTemplate">
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #orderItemTableTemplate>
  <table mat-table [dataSource]="selectedOrderedItems">
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef> product</th>
      <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.product }} </td>
    </ng-container>

    <ng-container matColumnDef="sku">
      <th mat-header-cell *matHeaderCellDef> sku</th>
      <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.sku }} </td>
    </ng-container>

    <ng-container matColumnDef="ordered">
      <th mat-header-cell *matHeaderCellDef> ordered</th>
      <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.ordered }} </td>
    </ng-container>

    <ng-container matColumnDef="loaded">
      <th mat-header-cell *matHeaderCellDef> loaded</th>
      <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.loaded }} </td>
    </ng-container>

    <ng-container matColumnDef="difference">
      <th mat-header-cell *matHeaderCellDef> difference</th>
      <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.difference }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let orderItem">
        <button mat-stroked-button (click)="onAdjustAmounts(orderItem.productSkuId)">Adjust</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>

<ng-template #adjustAmountModal>
  <ov-suite-load-amount-resolution-modal
    [selectedLoadAmount]="cloneSelectedLoadAmount"
    [canAdjustAmounts]="canAdjustAmounts"
  >
  </ov-suite-load-amount-resolution-modal>
</ng-template>
