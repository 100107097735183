<div class="order-list-container">
  <ng-container *ngIf="viewService.displayedOrderDTOs$ | async as orders">
    <div class="header-bar">
      <div class="header-left">
        <h6>Orders</h6>
      </div>
      <div class="header-center">
        <ov-suite-input
          type="text"
          placeholder="Search"
          [(ngModel)]="generalSearchTerm"
          (change)="setGeneralSearchTerm()">
        </ov-suite-input>
      </div>
      <div class="header-right">
        <div ngbDropdown class="d-inline-block" placement="bottom-right" #filterDrop="ngbDropdown">
          <button id="load-allocation-filter-dropdown" class="fa fa-filter btn-primary" ngbDropdownToggle></button>
          <div ngbDropdownMenu class="filter-dropdown">
            <div>
              <div class="filter-box">
                <span class="ml-2">Customer</span>
                <ov-suite-input
                  class="customer-search"
                  type="text"
                  placeholder="Customer Search"
                  [(ngModel)]="customerSearchTerm"
                  (change)="setCustomerSearchTerm()"
                >
                </ov-suite-input>
              </div>
              <div class="filter-box">
                <span class="ml-2">Order ID</span>
                <ov-suite-input
                  class="customer-search"
                  type="text"
                  placeholder="Order Search"
                  [(ngModel)]="orderSearchTerm"
                  (change)="setOrderSearchTerm()"
                >
                </ov-suite-input>
              </div>
              <div class="filter-box">
                <span class="ml-2">Date Range</span>
                <ov-suite-input
                  type="date-range"
                  placeholder="Date Range"
                  [(ngModel)]="orderDateRange"
                  (change)="setDateRange()"
                  [hideClearButton]="true"
                  #dateRange
                >
                </ov-suite-input>
              </div>
<!--              <div class="form-group filter-box">-->
<!--                <span class="ml-2">Master Route</span>-->
<!--                <ov-suite-input-->
<!--                  [id]="'masterRoute'"-->
<!--                  name="masterRoute"-->
<!--                  [type]="'dropdown'"-->
<!--                  [label]="['name']"-->
<!--                  [data]="dataService.masterRoutes"-->
<!--                  [(ngModel)]="dataService.orderAllocationsFilter.masterRoute"-->
<!--                  [placeholder]="'Select master route'"-->
<!--                  (submit)="dataService.fetchOrders(); filterDrop.close()"-->
<!--                >-->
<!--                </ov-suite-input>-->
<!--              </div>-->
              <div class="filter-button-bar">
                <button (click)="clearOrderAllocationsFilter(); filterDrop.close()" class="btn-sm btn-secondary mb-2">Clear</button>
                <button (click)="fetchOrders(); filterDrop.close()" class="btn-sm btn-primary mb-2">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table class="table table-sm" *ngIf="!!orders.length">
      <thead>
        <tr>
          <th *ngFor="let col of dataService.orderAllocationColumns" scope="col" (click)="onColumnHeaderClick(col.name)">
            {{ col.name }}
            <i *ngIf="dataService.sortingData.column !== col.name" class="fa fa-fw fa-sort" aria-hidden="true"></i>
            <i
              *ngIf="dataService.sortingData.column === col.name"
              class="fa fa-fw"
              [ngClass]="{
                'fa-sort-asc': dataService.sortingData.direction === 'ASC',
                'fa-sort-desc': dataService.sortingData.direction === 'DESC'
              }"
              aria-hidden="true"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody
        cdkDropList
        id="order-list"
        [cdkDropListData]="'list'"
        [cdkDropListConnectedTo]="base.vehicleLists"
        (cdkDropListDropped)="actionService.drop($any($event))"
        [cdkDropListDisabled]="!base.permissions.create"
      >
      <ng-container *ngIf="viewService.displayedOrderDTOs$ | async as orders; else loader">
        <tr
          *ngFor="let dto of orders"
          class="border"
          cdkDrag
          [cdkDragData]="dto"
          (click)="actionService.selectOrder(dto)"
          (mouseenter)="actionService.orderHoverStart.emit(dto)"
          (mouseleave)="actionService.orderHoverEnd.emit(dto)"
          [ngClass]="{ 'selected-customer': (viewService.selectedOrderDTO$ | async ) === dto }"
        >
          <ng-container *ngIf="orderService.get$(dto.orderId) | async as order; else loader">
            <td>
              <div *ngIf="!order.alias; else sub">
                {{ order.orderCode }}
              </div>
              <ng-template #sub>
                <div style="display: flex; flex-direction: row">
                  <div style="width: 15px">
<!--                    <input-->
<!--                      type="checkbox"-->
<!--                      *ngIf="-->
<!--                        (viewService.selectedOrderDTO$ | async ).orderId === order.id-->
<!--                      "-->
<!--                      [checked]="dto == actionService.selectedOrderPart"-->
<!--                      (click)="selectOrderPart($event, dto)"-->
<!--                    />-->
                  </div>
                  <ov-suite-load-allocation-order-sub-label
                    [label]="order.orderCode"
                    [subLabel]="order.alias"
                  ></ov-suite-load-allocation-order-sub-label>
                </div>
              </ng-template>
            </td>
            <td>
              <ng-container *ngIf="customerService.get$(order.customerId) | async as customer; else loader">
                <ov-suite-load-allocation-order-sub-label
                  [label]="customer.customerCode"
                  [subLabel]="customer.name"
                ></ov-suite-load-allocation-order-sub-label>
              </ng-container>
            </td>
            <!--        <td>{{ allocation.order.location?.address }}</td>-->
            <td>
              <ng-container *ngIf="orderService.getLoadTotalWeight$(dto.orderId) | async as weight; else zeroWeight">
                {{ weight.toFixed(2) }} kg
              </ng-container>
            </td>
            <td>
              <ng-container *ngIf="orderService.getLoadTotalVolume$(dto.orderId) | async as volume; else zeroVolume">
                {{ volume.toFixed(2) }} m³
              </ng-container>
            </td>
            <div *cdkDragPlaceholder></div>
            <div *cdkDragPreview class="plan-pin">
              <span>{{ order.id }}</span>
              <ng-container *ngIf="customerService.get$(order.customerId) | async as customer; else loader">
                <span>{{ customer?.name }} </span>
              </ng-container>
              <span>
                <ng-container *ngIf="orderService.getLoadTotalWeight$(dto.orderId) | async as weight; else zeroWeight">
                  {{ weight.toFixed(2) }} kg
                </ng-container>
              </span>
              <span>
                <ng-container *ngIf="orderService.getLoadTotalVolume$(dto.orderId) | async as volume; else zeroVolume">
                  {{ volume.toFixed(2) }} m³
                </ng-container>
              </span>
            </div>
          </ng-container>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <div class="load-more" (click)="orderService.fetchMoreOrders()">
      <span *ngIf="!orderService.ordersLoading">Load More</span>
      <span *ngIf="orderService.ordersLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>

    <div *ngIf="!orders.length" class="no-orders">
      <div>No orders found for {{ dateService.date$ | async | date }}</div>
    </div>
  </ng-container>
</div>

<ng-template #loader>
  <ov-suite-load-loader></ov-suite-load-loader>
</ng-template>

<ng-template #zeroWeight>
  0 kg
</ng-template>

<ng-template #zeroVolume>
  0 m³
</ng-template>
