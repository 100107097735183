import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface DropdownItem<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'ov-suite-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.css'],
})
export class MultiSelectDropdownComponent<T> {
  @Input() title: string;

  @Input() list: DropdownItem<T>[];

  @Output() change = new EventEmitter<T[]>();

  selectionState = new Map<T, boolean>();

  collapsed = true;

  itemClicked(value: T, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.toggleValue(value, event);
  }

  toggleValue(value: T, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectionState.set(value, !this.selectionState.get(value));
    const selected: T[] = [...this.selectionState.entries()].filter(([, checked]) => checked).map(([val]) => val);
    this.change.emit(selected);
  }

  public clearSelection() {
    this.selectionState.clear();
  }
}
