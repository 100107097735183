import { Component, Inject, Input, OnInit } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { FormBuilder, FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatestWith, debounceTime, distinctUntilChanged, filter, from, map, Observable, switchMap } from 'rxjs';
import { PickingItemModel, ProductSkuModel } from '@ov-suite/models-admin';
import { MatOptionSelectionChange } from '@angular/material/core';
import { InventoryContainerConfigurationModel, LoadAllocation } from '@ov-suite/models-warehouse';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import gql from 'graphql-tag';

export interface LoadPlanningInfo {
  loadId: number;
  pickingItems: PickingItemModel[];
}

@Component({
  selector: 'ov-suite-add-pick',
  templateUrl: './add-modal.component.html',
  styleUrls: ['./add-modal.component.css'],
})
export class AddModalComponent implements OnInit {
  @Input() load: LoadAllocation;

  formGroup = this._formBuilder.group({
    product: new FormControl(),
    config: new FormControl(),
    quantity: new FormControl(),
  });

  productOptions$: Observable<ProductSkuModel[]>;

  productSelected$: BehaviorSubject<ProductSkuModel> = new BehaviorSubject<ProductSkuModel>(null);

  pickCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  palletSize$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  configurations$: Observable<InventoryContainerConfigurationModel[]> = this.getConfigurations();

  loadId: number;

  pickingItems: PickingItemModel[];

  editing = false;

  selectedPickingItem: PickingItemModel;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly ovAutoService: OvAutoService,
    public dialogRef: MatDialogRef<AddModalComponent>,
    @Inject(MAT_DIALOG_DATA) public loadPlanningInfo: LoadPlanningInfo,
  ) {
    this.loadId = this.loadPlanningInfo.loadId;
    this.pickingItems = this.loadPlanningInfo.pickingItems;
  }

  ngOnInit(): void {
    this.productSelected$.subscribe(product => {
      if (product) {
        const [first] = product.containerConfigurationItems.map(c => c.inventoryContainerConfiguration).filter(c => c);
        this.formGroup.get('config').setValue(first);
      }
    });

    this.formGroup
      .get('quantity')
      .valueChanges.pipe(combineLatestWith(this.productSelected$, this.formGroup.get('config').valueChanges))
      .subscribe(([quantityRaw, product, config]) => {
        const quantity = Number(quantityRaw);

        if (product) {
          const itemModel = product.containerConfigurationItems.find(c => c.inventoryContainerConfiguration === config);
          if (!itemModel) {
            return;
          }
          const pickCount = Math.ceil(quantity / itemModel.quantity);

          this.palletSize$.next(itemModel.quantity);
          this.pickCount$.next(pickCount);
        }
      });

    this.productOptions$ = this.formGroup.get('product').valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      filter(product => !!product),
      switchMap(product => {
        if (typeof product !== 'string') {
          product = product.name;
        }

        return from(
          this.ovAutoService
            .list({
              entity: ProductSkuModel,
              limit: 5,
              relations: ['containerConfigurationItems', 'containerConfigurationItems.inventoryContainerConfiguration'],
              search: {
                name: [product],
              },
              query: {
                'containerConfigurationItems.id': [{ operator: 'IS NOT', value: null }],
              },
            })
            .then(response => response.data),
        );
      }),
    );
  }

  select(input: MatOptionSelectionChange<ProductSkuModel>) {
    this.formGroup.get('product').setValue(input.source.getLabel());

    this.selectedPickingItem = this.pickingItems.find(item => item.productSku.id === input.source.value.id);

    if (this.selectedPickingItem) {
      this.formGroup.get('quantity').setValue(this.selectedPickingItem.quantity);
      this.editing = true;
      this.productSelected$.next(this.selectedPickingItem.productSku);
    } else {
      this.productSelected$.next(input.source.value);
    }
  }

  submit(event: SubmitEvent) {
    if (this.editing) {
      this.update();
    } else {
      this.create();
    }
  }

  getConfigurations(): Observable<InventoryContainerConfigurationModel[]> {
    return this.productSelected$.pipe(
      map(product => product?.containerConfigurationItems.map(c => c.inventoryContainerConfiguration).filter(config => config) ?? []),
    );
  }

  update() {
    const { id } = this.selectedPickingItem;
    const quantity = this.formGroup.get('quantity').value;

    this.ovAutoService.apollo
      .mutate({
        mutation: gql`
          mutation updatePickingItem($id: Int!, $quantity: Int!) {
            updatePickingItem(id: $id, quantity: $quantity)
          }
        `,
        variables: {
          id,
          quantity,
        },
      })
      .subscribe(() => {
        this.close(true);
      });
  }

  create() {
    const configId = this.productSelected$.value?.containerConfigurationItems[0].id;
    const { loadId } = this;
    const productSkuId = this.productSelected$.value?.id;
    const quantity = this.formGroup.get('quantity').value;
    // Add the item to the back based on the priority;
    const priority = 51 + this.pickingItems.length;

    if (!(configId && loadId && productSkuId && quantity)) {
      console.error('Invalid Request');
      return;
    }

    this.ovAutoService.apollo
      .mutate({
        mutation: gql`
          mutation createPickingItem($quantity: Int!, $configId: Int!, $loadId: Int!, $productSkuId: Int!, $priority: Int!) {
            createPickingItem(quantity: $quantity, configId: $configId, loadId: $loadId, productSkuId: $productSkuId, priority: $priority) {
              id
            }
          }
        `,
        variables: {
          configId,
          productSkuId,
          loadId: this.loadId,
          quantity,
          priority,
        },
      })
      .subscribe(() => {
        this.close(true);
      });
  }

  close(refresh: boolean = false) {
    this.dialogRef.close(refresh);
  }
}
