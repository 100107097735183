<div class="" style="width: 100%;">
  <br />

  <div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource">
      <caption>Order invoice line items table</caption>

      <!-- Sku Name Column -->
      <ng-container matColumnDef="itemName">
        <th mat-header-cell *matHeaderCellDef> Sku Name </th>
        <td mat-cell *matCellDef="let element"> {{element.itemName}} </td>
      </ng-container>

      <!-- Sku Code Column -->
      <ng-container matColumnDef="itemCode">
        <th mat-header-cell *matHeaderCellDef> Sku Code </th>
        <td mat-cell *matCellDef="let element"> {{element.itemCode}} </td>
      </ng-container>

      <!-- Quantity Loaded Column -->
      <ng-container matColumnDef="loadedQuantity">
        <th mat-header-cell *matHeaderCellDef> Quantity Loaded  </th>
        <td mat-cell *matCellDef="let element"> {{element.loadedQuantity}} </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button [disabled]="dataService.orders.value.length <= 1"  mat-button color="primary" (click)="onAdjustOrderLine(element)">Adjust</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row [ngClass]="{'highlight': row.hasConflict }" *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of invoice line items">
    </mat-paginator>
  </div>

  <mat-card
    *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center"
  >
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card>
</div>

