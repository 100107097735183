<div class="product-identification">
  <span class="product-sku-identification">{{ productSku }}</span>
  <br>
  <span class="product-name-identification">{{ productName }}</span>
</div>

<table mat-table [dataSource]="loadedAmounts">
  <ng-container matColumnDef="orderCode">
    <th mat-header-cell *matHeaderCellDef> Order Code </th>
    <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.orderCode }} </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="qty">
    <th mat-header-cell *matHeaderCellDef> Quantity </th>
    <td mat-cell *matCellDef="let orderItem" class="accordion-product-name"> {{ orderItem.quantityOrdered }} </td>
    <td mat-footer-cell *matFooterCellDef> Total: {{ totalLoaded }} </td>
  </ng-container>

  <ng-container matColumnDef="adjust">
    <th mat-header-cell *matHeaderCellDef> Adjust </th>
    <td mat-cell *matCellDef="let orderItem" class="accordion-product-name">
      <ov-suite-load-amount-input [loadAmount]="orderItem"
                                  [max]="totalLoaded"
                                  [canAdjustAmounts]="canAdjustAmounts"
                                  (quantityLoadedEmitter)="quantityLoadedUpdated($event, orderItem)"
      ></ov-suite-load-amount-input>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
</table>
