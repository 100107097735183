import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  control,
  latLngBounds,
  LatLngBounds,
  MapOptions,
  tileLayer,
  Map,
  CircleMarker,
  circleMarker,
  Polyline,
  latLng,
  LatLngExpression,
} from 'leaflet';
import { Memo } from '@ov-suite/helpers-shared';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { OrderDTO } from '../../load-allocation.interface';
import { LoadAllocationActionService } from '../../services/load-allocation.action.service';
import { LoadAllocationDataService } from '../../services/load-allocation.data.service';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAllocationOrderService } from '../../services/data-services/load-allocation.order.service';

const styles = {
  factorySize: 15,
  orderSize: 10,
  highlightedCustomerSize: 13,

  displayOpacity: 1,
  hiddenOpacity: 0.1,
  hoverOpacity: 0.2,

  sourceFactoryColor: '#50E3C2',
  destinationFactoryColor: '#5F59F7',
  unusedFactoryColor: 'grey',
  highlightedCustomerColor: '#bf73de',
  selectedCustomerColor: '#FCD861',
  unselectedCustomerColor: '#A9E5FF',
  noRouteCustomerColor: '#FC5153',
  greyButtonColor: '#888888',
};

// Bounds of South Africa. See: https://gist.github.com/graydon/11198540 for other countries
const DEFAULT_BOUNDS: LatLngExpression[] = [
  [-34.8191663551, 16.3449768409],
  [-22.0913127581, 32.830120477],
];

@Component({
  selector: 'ov-suite-load-allocation-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnDestroy {
  map: Map;

  bounds: LatLngBounds = latLngBounds(DEFAULT_BOUNDS);

  options: MapOptions = {
    layers: [tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...', minZoom: 4 })],
    center: latLng(-30.5595, 22.9375),
    // zoom: 2,
    zoomControl: false,
    doubleClickZoom: false,
  };

  pinState = {
    selected: {},
    highlighted: {},
  };

  orderPinsArray: CircleMarker[] = [];

  orderPinsMap: Record<number, CircleMarker> = {};

  previousSelectedOrder?: CircleMarker;

  lines: Polyline[] = [];

  constructor(
    private readonly ngZone: NgZone,
    public base: LoadAllocationBaseService,
    public data: LoadAllocationDataService,
    public action: LoadAllocationActionService,
    public viewService: LoadAllocationViewService,
    public orderService: LoadAllocationOrderService,
  ) {}

  ngOnInit(): void {
    this.subscribeToOrders();
    this.subscribeToVehicleSelection();
    this.subscribeToOrderSelection();
    this.subscribeToOrderHovers();
  }

  ngOnDestroy(): void {
    this.action.orderHoverStart.unsubscribe();
    this.action.orderHoverEnd.unsubscribe();
  }

  subscribeToOrders(): void {
    this.viewService.displayedOrderDTOs$.subscribe(dtos => {
      if (dtos) {
        const map: Record<number, CircleMarker> = {};
        dtos.forEach(dto => {
          const pin = this.getPin(dto);
          if (pin) {
            map[dto.orderId] = pin;
          }
        });
        this.orderPinsMap = map;
        this.orderPinsArray = Object.values(map);
        this.updateBounds();
      }
    });
  }

  subscribeToVehicleSelection(): void {
    // this.action.vehicleSelected.observable.subscribe((allocation: VehicleAllocation) => {
    //   if (allocation) {
    //     const lines: Polyline[] = [];
    //     const coords: LatLng[] = [];
    //
    //     allocation.slots.forEach(slot => {
    //       slot.pins.forEach(pin => {
    //         const { order } = pin;
    //         const [longitude, latitude] = order?.deliveryAddress?.geography?.coordinates ?? [0, 0];
    //         coords.push(new LatLng(latitude, longitude));
    //       });
    //     });
    //
    //     coords.forEach((set, index) => {
    //       if (index < coords.length - 1) {
    //         lines.push(polyline([set, coords[index + 1]], { color: '#0091FF', opacity: 0.6 }));
    //       }
    //     });
    //
    //     if (coords.length) {
    //       this.bounds = new LatLngBounds(coords.map(c => [c.lat, c.lng]));
    //     }
    //
    //     setTimeout(() => lines.forEach(i => i.bringToBack()), 1);
    //
    //     this.lines = lines;
    //   } else {
    //     this.lines = [];
    //   }
    // });
  }

  subscribeToOrderSelection(): void {
    this.viewService.selectedOrderDTO$.pipe().subscribe((dto: OrderDTO) => {
      if (this.previousSelectedOrder) {
        this.previousSelectedOrder.setStyle({
          fillColor: styles.unselectedCustomerColor,
        });
      }
      if (dto && this.orderPinsMap[dto.orderId]) {
        const pin = this.orderPinsMap[dto.orderId];
        pin
          .setStyle({
            fillColor: styles.selectedCustomerColor,
          })
          .bringToFront();
        this.previousSelectedOrder = pin;
      }
    });
  }

  subscribeToOrderHovers(): void {
    this.action.orderHoverStart.subscribe((dto: OrderDTO) => {
      if (dto && this.orderPinsMap[dto.orderId]) {
        this.orderPinsMap[dto.orderId]
          .setStyle({
            className: 'pin-hover',
            weight: 5,
          })
          .setRadius(styles.highlightedCustomerSize);
      }
    });

    this.action.orderHoverEnd.subscribe((dto: OrderDTO) => {
      if (dto && this.orderPinsMap[dto.orderId]) {
        this.orderPinsMap[dto.orderId]
          .setStyle({
            weight: 1,
          })
          .setRadius(styles.orderSize);
      }
    });
  }

  @Memo()
  getPins(dtos: OrderDTO[]): CircleMarker[] {
    return dtos.map(dto => this.getPin(dto)).filter(a => !!a);
  }

  getPin(dto: OrderDTO): CircleMarker {
    const order = this.orderService.get$(dto.orderId).value;
    const { customer } = order;
    const [longitude, latitude] = order?.deliveryAddress?.geography?.coordinates ?? [null, null];
    // const { latitude, longitude } = map ?? customer?.map ?? {};
    if (latitude == null && longitude == null) {
      return null;
    }
    return circleMarker([latitude, longitude], {
      color: 'black',
      fillColor: styles.unselectedCustomerColor,
      fillOpacity: styles.displayOpacity,
      opacity: 0.2,
      radius: styles.orderSize,
      weight: 1,
    })
      .bindTooltip(customer.name)
      .on('click', () => {
        this.ngZone.run(() => {
          this.action.selectOrder(dto);
        });
      });
  }

  updateBounds(): void {
    if (this.orderPinsArray.length) {
      const allPins = this.orderPinsArray.map(p => {
        const { lat, lng } = p.getLatLng();
        return [lat, lng] as [number, number];
      });
      this.bounds = new LatLngBounds(allPins);
    } else {
      // this.bounds = latLngBounds([
      //   [5, 5],
      //   [-5, -5],
      // ]);
      this.bounds = latLngBounds(DEFAULT_BOUNDS);
    }
  }

  public updateMap(): void {
    this.map.invalidateSize();
  }

  onMapReady(map: Map): void {
    this.map = map;
    map.addControl(control.zoom({ position: 'bottomright' }));
  }
}
