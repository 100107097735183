<div class="order-items-table-header">
  <div class="order-items-table-header-content">
    <h5 class="order-items-table-header-content-title">Order Items</h5>
    <button type="button" class="order-items-table-header-content-btn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="modal-body">
<!--  <ov-suite-basic-table-->
<!--    [striped]="true"-->
<!--    [showFiller]="false"-->
<!--    [data]="pageData"-->
<!--    [pageSize]="pageSize"-->
<!--    [currentPage]="currentPage"-->
<!--    [loading]="false"-->
<!--    [columnData]="columnData"-->
<!--    [totalCount]="item?.orderItems?.length"-->
<!--    [keyStore]="metadata?.name"-->
<!--    [pageChangeEnabled]="true"-->
<!--    (changePage)="onPageChange($event)"-->
<!--    (changePageSize)="onPageSizeChange($event)"-->
<!--  ></ov-suite-basic-table>-->
  <ov-suite-clean-table [formClass]="formClass" [query]="query"></ov-suite-clean-table>
</div>
<div class="modal-footer"></div>
