import { DateTimeComponent } from './date-time.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TimeComponent } from './time.component';

@NgModule({
  imports: [
    NgbDatepickerModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
  ],
  declarations: [DateTimeComponent, TimeComponent],
  exports: [DateTimeComponent, TimeComponent],
})
export class DateTimeModule {}
