import { Component } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { QueryParams } from '@ov-suite/helpers-shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryLocationCountModel } from '../models/inventory-location-count.model';
import { PalletModalComponent } from '../pallet-modal/pallet-modal.component';

@Component({
  selector: 'ov-suite-inventory-product-sku',
  templateUrl: './inventory-location-list.component.html',
  styleUrls: ['./inventory-location-list.component.scss'],
})
export class InventoryLocationListComponent {
  parentId?: number;

  // Class - Required
  formClass = InventoryLocationCountModel;

  filter: Record<string, QueryParams[]> = {
    exitTime: [{ operator: 'IS', value: null }],
  };

  constructor(private readonly ngbModal: NgbModal) {}

  tabs: HierarchyTab[] = [
    { title: 'Products', iconClass: '', path: '/inventory-product' },
    { title: 'Locations', iconClass: '', path: '/inventory-locations' },
  ];

  onRowTap(location: InventoryLocationCountModel) {
    const modalRef = this.ngbModal.open(PalletModalComponent, { size: 'xl' });
    modalRef.componentInstance.model = location;
  }
}
