<div class="main-content">
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Inventory Location: ' + model.inventoryLocation?.name"
      [formClass]="formClass"
      [hasBulkUpload]="false"
      [showTopBar]="true"
      [hideAddButton]="true"
      [defaultQuery]="filter"
      [overrideColumnData]="columns"
      [clickableRows]="true"
      (rowClick)="edit($event)"
      [orderColumn]="'inventoryLocationDate'"
      [defaultOrderDirection]="'DESC'"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
