import { Component } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { ColumnData, QueryParams } from '@ov-suite/helpers-shared';
import { ProductSkuModel } from '@ov-suite/models-admin';

@Component({
  selector: 'ov-suite-inventory-product-sku',
  templateUrl: './inventory-product-sku-list.component.html',
  styleUrls: ['./inventory-product-sku-list.component.scss'],
})
export class InventoryProductSkuListComponent {
  parentId?: number;

  // Class - Required
  formClass = ProductSkuModel;

  filter: Record<string, QueryParams[]> = {
    'category.active': ['true'],
  };

  tabs: HierarchyTab[] = [
    { title: 'Products', iconClass: '', path: '/inventory-product' },
    { title: 'Locations', iconClass: '', path: '/inventory-locations' },
  ];

  columns: ColumnData<ProductSkuModel>[] = [
    {
      title: 'Category',
      type: 'other',
      action: item => {
        if (item.category) {
          return item.category.name;
        }
        return ' - ';
      },
      filterKey: 'category.name',
      keys: ['category.name'],
    },
    {
      key: 'sku',
      title: 'SKU',
      type: 'string',
    },
    {
      key: 'name',
      title: 'Name',
      type: 'string',
    },
    {
      key: 'quantityOnHand',
      title: 'On Hand',
      type: 'string',
      disableFiltering: true,
      disableSorting: true,
    },
    {
      key: 'quantityReserved',
      title: 'Reserved',
      type: 'string',
      disableFiltering: true,
      disableSorting: true,
    },
    {
      key: 'quantityOnSalesOrder',
      title: 'Confirmed',
      type: 'string',
      disableFiltering: true,
      disableSorting: true,
    },
    {
      key: 'quantityOnSalesOrder',
      title: 'Planned Receipts',
      type: 'string',
      disableFiltering: true,
      disableSorting: true,
    },
    {
      key: 'quantityFree',
      title: 'Available to Promise',
      type: 'string',
      disableFiltering: true,
      disableSorting: true,
    },
  ];
}
