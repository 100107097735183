<table mat-table [dataSource]="dataSource" matSort>
  <ng-container [matColumnDef]="'select'">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox class="mt-2 mr-2" [checked]="allTicked | async" (click)="toggleAll($event)"></mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let item">
      <mat-checkbox class="mt-2 mr-2" [checked]="!!selectionSet$.value.has(item)"  (click)="toggle(item, $event)"></mat-checkbox>
    </td>
  </ng-container>
  <ng-container *ngFor="let col of columnData" [matColumnDef]="col?.id || col?.title || 'missing'">
      <th mat-header-cell
          [disabled]="disableSorting"
          *matHeaderCellDef
          [mat-sort-header]="!col.disableSorting ? col?.id || col?.title || 'missing' : null"
          [class]="columnData.length > 4 ? getColumnSize() : 'standardWidth'"
      >
        <ng-container>
          {{col.title}}
        </ng-container>
      </th>

    <td mat-cell
        *matCellDef="let item"
        [class]="columnData.length > 4 ? getColumnSize() : 'standardWidth'"
    >
      <ng-container [ngSwitch]="col.type">
        <ov-suite-string-cell
          *ngSwitchCase="'string'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-string-cell>
        <ov-suite-deep-string-cell
          *ngSwitchCase="'deep-string'"
          [item]="item"
          [col]="$any(col)"
        ></ov-suite-deep-string-cell>
        <ov-suite-number-cell
          *ngSwitchCase="'number'"
          [col]="$any(col)"
          [item]="item"
          (itemChange)="updateItem($event)"
        ></ov-suite-number-cell>
        <ov-suite-boolean-cell
          *ngSwitchCase="'boolean'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-boolean-cell>
        <ov-suite-date-cell
          *ngSwitchCase="'date'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-date-cell>
        <ov-suite-date-time-cell
          *ngSwitchCase="'date-time'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-date-time-cell>
        <ov-suite-time-cell
          *ngSwitchCase="'time'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-time-cell>
        <ov-suite-dropdown-cell
          *ngSwitchCase="'dropdown'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-dropdown-cell>
        <ov-suite-other-cell
          *ngSwitchCase="'other'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-other-cell>
        <ov-suite-buttons-cell
          *ngSwitchCase="'button-bar'"
          [col]="$any(col)"
          [item]="item"
          [handleClicks]="true"
          (requestRefresh)="refreshData()"
        ></ov-suite-buttons-cell>
        <ov-suite-pills-cell
          *ngSwitchCase="'pills'"
          [col]="$any(col)"
          [item]="item"
        ></ov-suite-pills-cell>
        <ng-container *ngSwitchDefault>
          {{ data[col.key] }}
        </ng-container>
      </ng-container>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" (click)="rowClick(myRowData, $event)"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">{{ emptyMessage }}</td>
  </tr>
</table>
<mat-paginator *ngIf="!disablePaging" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
