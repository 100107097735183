import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { InventoryContainerConfigurationModel } from '@ov-suite/models-warehouse';

@OVEntity('InventoryContainerConfigurationItem')
export class InventoryContainerConfigurationItemModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: () => InventoryContainerConfigurationModel })
  inventoryContainerConfiguration: InventoryContainerConfigurationModel
}
