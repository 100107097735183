import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import {
  LoadAllocation,
  WaveInstance,
  LoadAmountModel,
  LoadLogModel,
  VehicleOverride,
  ExternalVehicle,
  LoadUserModel,
  LoadDetailModel,
  InventoryContainer,
} from '@ov-suite/models-warehouse';
import * as _ from 'lodash';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  InventoryLocationModel,
  PickingItemLogModel,
  PickingItemModel,
  StagingItemLogModel,
  StagingItemModel,
  VehicleClass,
} from '@ov-suite/models-admin';
import { DocumentNode } from 'graphql';
import { MutationResult } from 'apollo-angular';
import moment from 'moment/moment';
import { gql } from 'apollo-boost';
import { InvoiceModel, OrderModel } from '@ov-suite/models-order';
import { getUpdate } from '@ov-suite/graphql-helpers';
import {
  cancelLoadGql,
  changeLoadBayGql,
  delayLoadGql,
  listOrderInvoiceByOrderIds,
  removeContainersFromLocationGql,
  restartLoadGql,
  updateTruckDriverGql,
} from './load-management.graphql';
import { UpdatedLoadBay } from './load-management.helpers';

/**
 * This Service is used for Fetching, Manipulating and Saving Data.
 *
 * This should be the only service on load-management with ovAutoService. Please be sure to manage observables correctly
 */

@Injectable()
export class LoadManagementDataService {
  loading = false;

  loadingWave = false;

  loadingLoadBay = false;

  loadingOrders = false;

  loadingVehicle = false;

  load: BehaviorSubject<LoadAllocation> = new BehaviorSubject<LoadAllocation>(null);

  wave: BehaviorSubject<WaveInstance> = new BehaviorSubject<WaveInstance>(null);

  inventoryLocation: BehaviorSubject<InventoryLocationModel> = new BehaviorSubject<InventoryLocationModel>(null);

  orders: BehaviorSubject<OrderModel[]> = new BehaviorSubject<OrderModel[]>(null);

  containers: BehaviorSubject<InventoryContainer[]> = new BehaviorSubject<InventoryContainer[]>(null);

  loadUsers: BehaviorSubject<LoadUserModel[]> = new BehaviorSubject<LoadUserModel[]>(null);

  vehicle: BehaviorSubject<VehicleOverride> = new BehaviorSubject<VehicleOverride>(null);

  picks = new BehaviorSubject<PickingItemModel[]>([]);

  stagingItems = new BehaviorSubject<StagingItemModel[]>([]);

  amounts = new BehaviorSubject<LoadAmountModel[]>([]);

  logs = new BehaviorSubject<LoadLogModel[]>([]);

  waveInstances: BehaviorSubject<WaveInstance[]> = new BehaviorSubject<WaveInstance[]>([]);

  loadBays: BehaviorSubject<InventoryLocationModel[]> = new BehaviorSubject<InventoryLocationModel[]>([]);

  lastOrderCommitDate = new BehaviorSubject<Date>(null);

  invoices = new BehaviorSubject<InvoiceModel[]>([]);

  pickingItemLog: BehaviorSubject<PickingItemLogModel[]> = new BehaviorSubject<PickingItemLogModel[]>([]);

  stagingItemLog: BehaviorSubject<StagingItemLogModel[]> = new BehaviorSubject<StagingItemLogModel[]>([]);

  loadDetails: BehaviorSubject<LoadDetailModel[]> = new BehaviorSubject<LoadDetailModel[]>([]);

  fetchData = _.debounce(this._fetchData, 500, { trailing: true });

  constructor(private readonly ovAutoService: OvAutoService) {}

  onInit(id: number) {
    this.fetchData(id);
  }

  async refresh() {
    await this.getInvoices(this.load.getValue().orderIds);
  }

  public async getContainers(loadBayId: number) {
    await this.ovAutoService
      .list({
        entity: InventoryContainer,
        limit: 1000,
        keys: ['id', 'name', 'inventories', 'inventories.productSku', 'inventories.productSku.name', 'inventories.quantity'],
        query: {
          'inventoryLocation.id': [loadBayId],
        },
      })
      .then(response => {
        this.containers.next(response.data);
      });
  }

  public async getPicks() {
    await this.ovAutoService
      .list({
        entity: PickingItemModel,
        limit: 1000,
        keys: [
          'id',
          'pickStatus',
          'priority',
          'quantityPicked',
          'quantityReserved',
          'quantity',
          'productSku.id',
          'productSku.name',
          'productSku.containerConfigurationItems.id',
          'productSku.containerConfigurationItems.name',
          'containerPicked',
          'containerPicked.id',
          'containerPicked.name',
        ],
        query: {
          loadId: [this.load.value.id],
        },
        orderColumn: 'priority',
        orderDirection: 'ASC',
      })
      .then(response => {
        this.picks.next(response.data);
      });
  }

  public async getStagingItems() {
    await this.ovAutoService
      .list({
        entity: StagingItemModel,
        limit: 1000,
        keys: [
          'id',
          'stageStatus',
          'priority',
          'quantityStaged',
          'quantityReserved',
          'quantity',
          'productSku.id',
          'productSku.name',
          'productSku.containerConfigurationItems.id',
          'productSku.containerConfigurationItems.name',
        ],
        query: {
          loadId: [this.load.value.id],
        },
        orderColumn: 'priority',
        orderDirection: 'ASC',
      })
      .then(response => {
        this.stagingItems.next(response.data);
      });
  }

  public setSelectedInventoryLocation(id: number) {
    const loadBay = this.loadBays.getValue().find(lb => lb.id == id);
    if (loadBay) {
      this.inventoryLocation.next(loadBay);
    }
  }

  public async getAmounts() {
    await this.ovAutoService
      .list({
        entity: LoadAmountModel,
        limit: 1000,
        relations: ['productSku'],
        query: {
          loadId: [this.load.value.id],
        },
      })
      .then(response => {
        this.amounts.next(response.data);
      });
  }

  public async getLogs(): Promise<LoadLogModel[]> {
    return this.ovAutoService
      .list({
        entity: LoadLogModel,
        limit: 1000,
        relations: ['createdBy'],
        query: {
          loadId: [this.load.value.id],
        },
      })
      .then(res => {
        return res.data;
      });
  }

  public async getInvoices(orderIds: number[]) {
    const invoices = (await this.executeGraphQL(listOrderInvoiceByOrderIds(), { orderIds })) as unknown as InvoiceModel[];
    this.invoices.next(invoices['data']['listOrderInvoiceByOrderIds']);
  }

  // public _fetchData(id: number): void {
  //   this.loading = true;
  //   // TODO This needs to be simplified into multiple queries.
  //   this.ovAutoService
  //     .get({
  //       id,
  //       entity: LoadAllocation,
  //       relations: [
  //         'vehicle',
  //         'vehicle.class',
  //         'externalVehicle',
  //         'orders',
  //         // 'orders.orderItems',
  //         // 'orders.orderItems.productSku',
  //         'orders.customer',
  //         'wave',
  //         'wave.waveConfig',
  //         'users',
  //         'users.user',
  //         'users.resource',
  //         'loadBay',
  //         'details',
  //       ],
  //     })
  //     .then(async response => {
  //       this.load.next(response);
  //       await this.getPicks();
  //       await this.getStagingItems();
  //       await this.getAmounts();
  //       await this.getLogs();
  //       await this.getInvoices(response.orders.map(x => x.id));
  //       this.loading = false;
  //     });
  // }

  public _fetchData(id: number): void {
    this.loading = true;
    this.ovAutoService
      .get({
        id,
        entity: LoadAllocation,
        keys: [
          'id',
          'date',
          'commitDate',
          'completeDate',
          'releaseDate',
          'stagingReleaseDate',
          'loadBayId',
          'loadBayMinCapacity',
          'orderIds',
          'waveId',
          'vehicleId',
          'externalVehicleId',
          'details.id',
          'details.title',
          'details.value',
          'details.orderId',
          'details.order.id',
          'details.order.orderCode',
          'users.id',
          'users.type',
          'users.user.id',
          'users.user.username',
          'truckDriverName',
        ],
      })
      .then(async response => {
        this.load.next(response);
        // Load up all the other items independently
        await this.loadDependentData(response);

        await this.getStagingItems();
        await this.getPicks();
        await this.getAmounts();
        this.loading = false;
      });
  }

  async loadDependentData(loadAllocation: LoadAllocation) {
    const { vehicleId, externalVehicleId } = loadAllocation;
    this.getVehicle(vehicleId, externalVehicleId);

    this.getWave(loadAllocation.waveId);

    this.getInventoryLocation(loadAllocation.loadBayId);

    this.getOrders(loadAllocation.orderIds);

    this.getInvoices(loadAllocation.orderIds);

    this.loadUsers.next(loadAllocation.users);

    this.loadDetails.next(loadAllocation.details);
  }

  async getVehicle(vehicleId: number, externalVehicleId: number) {
    this.loadingVehicle = true;

    if (vehicleId) {
      this.ovAutoService
        .get({
          id: vehicleId,
          entity: VehicleOverride,
          keys: ['id', 'name', 'registration', 'class.name'],
        })
        .then(res => {
          this.vehicle.next(res);
        });
    } else {
      this.ovAutoService
        .get({
          id: externalVehicleId,
          entity: ExternalVehicle,
          keys: ['id', 'make', 'model', 'vehicleClass', 'registration'],
        })
        .then(res => {
          const veh = new VehicleOverride();
          veh.id = res.id;
          veh.name = `${res.make} ${res.model}`;
          veh.class = new VehicleClass();
          veh.class.name = res.vehicleClass;
          veh.registration = res.registration;

          this.vehicle.next(veh);
        });
    }

    this.loadingVehicle = false;
  }

  async getWave(id: number) {
    this.loadingWave = true;
    this.ovAutoService
      .get({
        id,
        entity: WaveInstance,
        keys: ['id', 'startDate', 'waveConfig.startTime'],
      })
      .then(res => {
        this.wave.next(res);
      });
    this.loadingWave = false;
  }

  async getInventoryLocation(id: number) {
    this.loadingLoadBay = true;
    this.ovAutoService
      .get({
        id,
        entity: InventoryLocationModel,
        keys: ['id', 'name', 'minCapacity'],
      })
      .then(res => {
        this.inventoryLocation.next(res);
      });
    this.loadingLoadBay = false;
  }

  async getOrders(ids: number[]) {
    this.loadingOrders = true;
    this.ovAutoService
      .list({
        entity: OrderModel,
        keys: [
          'id',
          'customer.customerCode',
          'customer.name',
          'orderCode',
          'orderItems.id',
          'orderItems.quantity',
          'orderItems.productSkuId',
          'orderItems.unitPriceExcl',
          'orderItems.unitPriceIncl',
          'orderItems.productSku.id',
          'orderItems.productSku.sku',
          'orderItems.productSku.name',
          'orderItems.productSku.category.id',
          'orderItems.productSku.category.path',
        ],
        query: { id: ids },
      })
      .then(res => {
        this.orders.next(res.data);
      });
    this.loadingOrders = false;
  }

  async fetchWaveInstances(date: Date): Promise<void> {
    this.ovAutoService
      .list({
        entity: WaveInstance,
        relations: ['waveConfig'],
        query: {
          startDate: [moment(date).format('yyyy-MM-DD')],
        },
      })
      .then(response => {
        this.waveInstances.next(response.data);
      });
  }

  async fetchLoadBays(): Promise<void> {
    this.ovAutoService
      .list({
        entity: InventoryLocationModel,
        keys: ['id', 'name', 'category', 'category.name', 'minCapacity'],
        query: {
          isStorage: ['false'],
          'category.id': [5], // 5 = load bay
        },
      })
      .then(response => {
        this.loadBays.next(response.data);
      });
  }

  async restartLoadHandler() {
    const data = { loadId: this.load.value.id };
    return this.executeGraphQL<{ loadId: number }, { restartLoad: { id: number } }>(restartLoadGql(), data);
  }

  async removePallets(containerIds: number[]) {
    await this.executeGraphQL(removeContainersFromLocationGql(), { containerIds });
  }

  async cancelLoadHandler() {
    const data = { loadId: this.load.value.id };
    await this.executeGraphQL(cancelLoadGql(), data);
  }

  async delayLoadHandler(loadId, waveId, resetPicks) {
    const data = { loadId, waveId, shouldResetPicks: resetPicks };
    await this.executeGraphQL(delayLoadGql(), { data });
  }

  async changeLoadBayHandler(loadData: UpdatedLoadBay) {
    await this.executeGraphQL(changeLoadBayGql(), loadData);
  }

  executeGraphQL<T, U>(query: DocumentNode, data: T): Promise<MutationResult<U>> {
    return this.ovAutoService.apollo
      .mutate<U>({
        mutation: query,
        fetchPolicy: 'no-cache',
        variables: {
          ...data,
        },
      })
      .toPromise();
  }

  async completeLoad() {
    firstValueFrom(
      this.ovAutoService.apollo.mutate({
        mutation: gql`
          mutation ($loadId: Int!) {
            completeLoad(loadId: $loadId)
          }
        `,
        variables: {
          loadId: this.load.value.id,
        },
      }),
      { defaultValue: null },
    ).then(() => {
      return this.fetchData(this.load.value.id);
    });
  }

  async reopen(restartIncompletePicks: boolean) {
    firstValueFrom(
      this.ovAutoService.apollo.mutate({
        mutation: gql`
          mutation ($loadId: Int!, $openPicks: Boolean!) {
            reopenLoad(loadId: $loadId, openPicks: $openPicks)
          }
        `,
        variables: {
          loadId: this.load.value.id,
          openPicks: restartIncompletePicks,
        },
      }),
      { defaultValue: null },
    ).then(() => {
      return this.fetchData(this.load.value.id);
    });
  }

  fetchPickingItemLog(productItemId: number) {
    this.ovAutoService
      .list({
        entity: PickingItemLogModel,
        keys: ['id', 'completedDate', 'handledByUser.id', 'handledByUser.username', 'containerPicked.id', 'containerPicked.name'],
        query: {
          pickingItemId: [productItemId],
        },
        orderColumn: 'completedDate',
        orderDirection: 'DESC',
      })
      .then(res => this.pickingItemLog.next(res.data));
  }

  fetchStagingItemLog(stageItemId: number) {
    this.ovAutoService
      .list({
        entity: StagingItemLogModel,
        keys: ['id', 'completedDate', 'handledByUser.id', 'handledByUser.username', 'containerStaged.id', 'containerStaged.name'],
        query: {
          stagingItemId: [stageItemId],
        },
        orderColumn: 'completedDate',
        orderDirection: 'DESC',
      })
      .then(res => this.stagingItemLog.next(res.data));
  }

  async updateSealNumber(original: LoadDetailModel, updatedSeal: LoadDetailModel): Promise<LoadAllocation> {
    const detail = getUpdate(updatedSeal, original);

    const { order, ...rest } = detail;

    await this.ovAutoService.update({
      entity: LoadDetailModel,
      item: rest,
      refreshModel: true,
    });
    const load = this.load.getValue();

    load.details.forEach(dt => {
      if (dt.id === updatedSeal.id) {
        dt.value = updatedSeal.value;
        dt.order = updatedSeal.order;
        dt.orderId = updatedSeal.orderId;
      }
    });

    this.loadDetails.next(load.details);

    return load;
  }

  async updateDriverName(oldName: string, newName: string) {
    const data = { loadId: this.load.value.id, truckDriverName: newName };
    this.executeGraphQL(updateTruckDriverGql(), { data }).then(res => {
      const driverName = res.data['updateTruckDriver'].truckDriverName;
      const load = this.load.getValue();
      load.truckDriverName = driverName;
      this.load.next(load);
    });
  }
}
