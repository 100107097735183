import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';
import { CustomerModel } from './customer.model';

@OVEntity('CustomerGroupStatus', 'adminlink')
export class CustomerGroupStatus {
  @OVField({ type: 'number' })
  id: number;
  @OVField({ type: 'string' })
  name: string;
  @OVField({ type: 'string' })
  color: string;
}

@OVEntity('CustomerGroup', 'shared')
@OVForm([['CustomerGroup Details'], ['customerGroupId', 'name'], ['description'], ['customers']])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<CustomerGroup>([
  {
    key: 'customerGroupId',
    title: 'CustomerGroup ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'CustomerGroup Name',
    type: 'string',
  },
])
@OVSearch<CustomerGroup>({
  searchKeys: ['customerGroupId', 'name', 'customers.customerCode', 'customers.name', 'customers.mobileNumber', 'customers.primaryEmail'],
})
export class CustomerGroup {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'CustomerGroup Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    title: 'CustomerGroup ID',
    placeholder: 'Required',
    required: true,
  })
  customerGroupId: string;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({
    type: () => [CustomerModel],
    selectionType: 'multiple',
    title: 'Customers',
    flat: true,
    dropdownLimit: 10000,
    keys: ['id', 'name'],
  })
  customers: CustomerModel[];

  // @SidebarField({ type: 'dropdown', title: 'Status' })
  @OVField({
    type: () => CustomerGroupStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: CustomerGroupStatus;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;
}
