import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogService, InventoryAccordionService, OvAutoService } from '@ov-suite/services';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { QRCodeModule } from 'angularx-qrcode';
import { AgmCoreModule } from '@agm/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { TableComponent } from './table/table.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { CreateOrEditComponent } from './create-or-edit/create-or-edit.component';
import { FormComponent } from './form/form.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { HierarchyBreadcrumbComponent } from './hierarchy-breadcrumb/hierarchy-breadcrumb.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { HierarchyTabsComponent } from './hierarchy-tabs/hierarchy-tabs.component';
import { PermissionMatrixComponent } from './permission-matrix/permission-matrix.component';
import { UserProfileWidgetComponent } from './user-profile-widget/user-profile-widget.component';
import { DaySelectorComponent } from './day-selector/day-selector.component';
import { TableTopBarComponent } from './table-top-bar/table-top-bar.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { PillMultiSelectComponent } from './pill-multi-select/pill-multi-select.component';
import { DateTimeModule } from './date-time/date-time.module';
import { RecurrenceComponent } from './reccurence/recurrence.component';
import { CardTableComponent } from './card-table/card-table.component';
import { PopupTableComponent } from './popup-table/popup-table.component';
import { HierarchyListComponent } from './hierarchy-list/hierarchy-list.component';
import { TrudaHierarchyListComponent } from './hierarchy-list/sub-lists/truda-list/truda-hierarchy-list.component';
import { CeramicHierarchyListComponent } from './hierarchy-list/sub-lists/ceramic-list/ceramic-hierarchy-list.component';
import { TopFiltersComponent } from './top-filters/top-filters.component';
import { UiIconsModule } from './ui-icons.module';
import { DomainSelectorComponent } from './domain-selector/domain-selector.component';
import { FilterTabsComponent } from './filter-tabs/filter-tabs.component';
import { PredictiveTextDropdownComponent } from './predictive-text-dropdown/predictive-text-dropdown.component';
import { IncrementPickerComponent } from './increment-picker/increment-picker.component';
import { AccordionCategoryComponent } from './accordion-category/accordion-category.component';
import { TableDetailModalComponent } from './table-detail-modal/table-detail-modal.component';
import { CleanTableModule } from './clean-table/clean-table.module';
import { BasicTableModule } from './new-table/basic-table/basic-table.module';
import { InputModule } from './input/input.module';
import { MultiSelectDropdownModule } from './multi-select-dropdown/multi-select-dropdown.module';
import { AgmMapModule } from './agm-map/agm-map.module';
import { PipesModule } from './pipes/pipes.module';
import { DateRangeModule } from './date-time/date-range.module';
import { QuantityPickerComponent } from './accordion-category/quantity-picker/quantity-picker.component';
import { MatChipsModule } from '@angular/material/chips';
import { ExcelFormatterComponent } from './excel-formatter/excel-formatter.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { StageTypesSelectorComponent } from './stage-types-selector/stage-types-selector.component';
import {
  StageTypesSelectorDialogComponent
} from './stage-types-selector/stage-types-selector/stage-types-selector-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { TableRefreshComponent } from './hierarchy-list/table-refresh/table-refresh.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';

export interface ApiOptions {
  apiUrl?: string;
  imageUploadUrl?: string;
}

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    DateTimeModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    LeafletModule,
    QRCodeModule,
    UiIconsModule,
    CleanTableModule,
    BasicTableModule,
    InputModule,
    MultiSelectDropdownModule,
    AgmMapModule,
    AgmCoreModule,
    PipesModule,
    DateRangeModule,
    MatExpansionModule,
    MatTableModule,
    MatButtonModule,
    MatInputModule,
    MatChipsModule,
    MatDialogModule,
    MatCardModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  declarations: [
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    PopupTableComponent,
    PredictiveTextDropdownComponent,
    TreeSelectComponent,
    HierarchyBreadcrumbComponent,
    ImageUploadComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    RecurrenceComponent,
    CardTableComponent,
    CeramicHierarchyListComponent,
    HierarchyListComponent,
    TrudaHierarchyListComponent,
    TopFiltersComponent,
    DomainSelectorComponent,
    FilterTabsComponent,
    IncrementPickerComponent,
    AccordionCategoryComponent,
    QuantityPickerComponent,
    TableDetailModalComponent,
    ExcelFormatterComponent,
    ConfirmationDialogComponent,
    StageTypesSelectorComponent,
    StageTypesSelectorDialogComponent,
    AdvancedSearchComponent,
    TableRefreshComponent,
  ],
  exports: [
    TableComponent,
    NavbarComponent,
    SidebarComponent,
    CreateOrEditComponent,
    FormComponent,
    BulkUploadComponent,
    PredictiveTextDropdownComponent,
    PopupTableComponent,
    ImageUploadComponent,
    HierarchyBreadcrumbComponent,
    TreeSelectComponent,
    HierarchyTabsComponent,
    PermissionMatrixComponent,
    UserProfileWidgetComponent,
    PermissionMatrixComponent,
    DaySelectorComponent,
    TableTopBarComponent,
    FileUploadComponent,
    PillMultiSelectComponent,
    RecurrenceComponent,
    CardTableComponent,
    CeramicHierarchyListComponent,
    HierarchyListComponent,
    TrudaHierarchyListComponent,
    TopFiltersComponent,
    DomainSelectorComponent,
    FilterTabsComponent,
    IncrementPickerComponent,
    AccordionCategoryComponent,
    QuantityPickerComponent,
    InputModule,
    CleanTableModule,
    MultiSelectDropdownModule,
    AgmMapModule,
    AgmCoreModule,
    PipesModule,
    DateRangeModule,
    ExcelFormatterComponent,
    ConfirmationDialogComponent,
  ],
  providers: [OvAutoService, InventoryAccordionService, ConfirmationDialogService],
})
export class UiModule {
  static forRoot(options: ApiOptions): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [{ provide: 'OPTIONS', useValue: options }],
    };
  }

  static forChild() {
    return {
      ngModule: UiModule,
    };
  }
}

export { RouteInfo, ChildrenItems } from './sidebar/sidebar.model';
export { NavbarComponent } from './navbar/navbar.component';

export * from './form/form.component';
export * from './hierarchy-tabs/hierarchy-tabs.model';
