import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { PermissionType } from '@ov-suite/helpers-shared';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { Subscription } from 'rxjs';
import { OvGenericModuleParams } from './ov-generic.types';

export function verifyPermission(value: number, permission: PermissionType): boolean {
  if (value === null) {
    return false;
  }
  let column = 1;

  switch (permission) {
    case PermissionType.create:
      column = 8;
      break;
    case PermissionType.read:
      column = 4;
      break;
    case PermissionType.update:
      column = 2;
      break;
    case PermissionType.delete:
      column = 1;
      break;
    default:
  }
  // eslint-disable-next-line no-bitwise
  return (value | column) === value;
}

@Injectable()
export class OvGenericGuard implements CanActivate, CanActivateChild, OnDestroy {
  permissionMap: Record<number, number> = {};

  loaded = false;

  subscriptions: Subscription[] = [];

  constructor(
    @Inject('ENTITY_OPTIONS') public readonly options: OvGenericModuleParams,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.subscriptions.push(
      authenticationService.user.subscribe({
        next: () => this.updateAppsAndPermissions(),
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (!this.loaded) {
      await this.updateAppsAndPermissions();
      this.loaded = true;
    }
    if (this.options.permissionId) {
      return verifyPermission(this.permissionMap[this.options.permissionId] ?? null, route.data.permissionType);
    }
    return true;
  }

  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return true;
  }

  async updateAppsAndPermissions(): Promise<void> {
    const user = this.authenticationService.user.value;
    this.permissionMap = user?.permissions ?? {};
  }
}
