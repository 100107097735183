import { OVEntity, OVField, OVForm, OVSearch, OVTable } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';
import { Area, CustomerModel, CustomerGroup, DomainModel } from '@ov-suite/models-admin';

@OVEntity({ name: 'SalesRep' })
@OVTable<SalesRepModel>([
  {
    title: 'Name',
    type: 'other',
    action: sr => `${sr.user.firstName} ${sr.user.lastName}`,
    keys: ['user.firstName', 'user.lastName'],
  },
  {
    title: 'Username',
    key: 'user.username',
    type: 'deep-string',
  },
  {
    title: 'Email',
    key: 'user.email',
    type: 'deep-string',
  },
  {
    title: 'Phone Number',
    key: 'user.phoneNumber',
    type: 'deep-string'
  },
])
@OVForm([
  ['user'],
  ['customers'],
  ['customerGroups'],
  ['areas'],
  ['weeklyTarget', 'monthlyTarget'],
  ['quarterlyTarget', 'yearlyTarget'],
  ['domains', ''],
])
@OVSearch<SalesRepModel>({ searchKeys: ['id', 'customers.name', 'user.name'] })
export class SalesRepModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({
    type: () => UserModel,
    tooltip: 'Select the User',
    selectionType: 'single',
    title: 'User',
    flat: true,
    required: true,
    nameColumnKey: ['firstName', 'lastName'],
    keys: ['id', 'firstName', 'lastName'],
  })
  user: UserModel;

  @OVField({
    type: () => [CustomerModel],
    tooltip: 'Assign Customers',
    selectionType: 'multiple',
    title: 'Customers',
    flat: true,
    required: false,
    keys: ['id', 'name', 'customerCode'],
    nameColumnKey: ['name', 'customerCode']
  })
  customers: CustomerModel[];

  @OVField({
    type: () => [CustomerGroup],
    tooltip: 'Assign Customer Groups',
    selectionType: 'multiple',
    title: 'Customer Groups',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customerGroups: CustomerGroup[];

  @OVField({
    type: () => [Area],
    tooltip: 'Assign Areas',
    selectionType: 'multiple',
    title: 'Areas',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  areas: Area[];

  @OVField({
    type: 'number',
    title: 'Weekly Target',
  })
  weeklyTarget: number;

  @OVField({
    type: 'number',
    title: 'Monthly Target',
  })
  monthlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Quarterly Target',
  })
  quarterlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Yearly Target',
  })
  yearlyTarget: number;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: DomainModel[];
}
