import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { ProductSkuModel } from '@ov-suite/models-admin';

export interface Constraint {
  value: number;
  allowMultiples: boolean;
}

@OVEntity('SalesConstraint')
export class SalesConstraintModel {
  @OVField({ type: 'number', generated: true })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'number' })
  minimum?: number;

  @OVField({ type: 'number' })
  maximum?: number;

  @OVField({ type: 'json' })
  constraints?: Constraint[];

  @OVField({ type: () => [ProductSkuModel] })
  productSkus: ProductSkuModel[];
}
