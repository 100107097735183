import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BaseUserToken } from '@ov-suite/helpers-shared/lib/user.interface';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ApolloClient, gql } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/core';
import { getWebDomain, getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

/**
 * Primary services used to handle authentication of the user and token management
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public token: BehaviorSubject<string>;

  public user: BehaviorSubject<BaseUserToken>;

  constructor(private readonly router: Router, private readonly cookieService: CookieService) {
    const token = cookieService.get('session');
    this.token = new BehaviorSubject<string>(token);
    const user = AuthenticationService.getDecodedToken(token);
    this.user = new BehaviorSubject<BaseUserToken>(user);
  }

  private static getDecodedToken(token: string): BaseUserToken {
    if (!token) {
      return null;
    }
    const helper = new JwtHelperService();
    return helper.decodeToken<BaseUserToken>(token);
  }

  /**
   * Cannot use the Apollo Service as it would create a circular dependency
   */
  private getApollo(operationName: string, extraHeaders: Record<string, string> = {}) {
    let headers = {};
    if (this.token.value) {
      headers = {
        authorization: `Bearer ${this.token.value}`,
        ...extraHeaders,
      };
    }
    return new ApolloClient({
      uri: `${getUrl('api')}/graphql/graphql-${operationName}`,
      cache: new InMemoryCache(),
      headers,
    });
  }

  public async login(username: string, password: string): Promise<string> {
    const client = this.getApollo('login');
    return client
      .query({
        query: gql(`
          query login($request: LoginRequest!) {
            login(request: $request) {
              token
            }
          }
        `),
        variables: {
          request: {
            username,
            password,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        const { token } = res.data['login'];
        this.setToken(token);
        return token;
      })
      .finally(() => client.stop());
  }

  private async refreshToken(): Promise<string> {
    const client = this.getApollo('refreshToken');
    return client
      .query({
        query: gql(`
          query refreshToken {
            refreshToken {
              token
            }
          }
        `),
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        const { token } = res.data['refreshToken'];
        this.setToken(token);
        return token;
      })
      .finally(() => client.stop());
  }

  async getToken() {
    if (!this.token.value) {
      throw new Error('Missing Token');
    }
    const user = this.user.value;
    const current = Math.floor(new Date().valueOf() / 1000);
    if (current >= user.exp - 30) {
      return this.refreshToken();
    }
    return this.token.value;
  }

  public logout() {
    this.setToken(null);
    window.location.href = `${getUrl('idm')}/login`;
  }

  public async isSessionValid(): Promise<boolean> {
    return !!this.user.value;
  }

  private setToken(token: string): void {
    this.user.next(AuthenticationService.getDecodedToken(token));
    const domain = getWebDomain();
    if (token) {
      this.cookieService.set('session', token, null, null, domain);
    } else {
      this.cookieService.delete('session', '/', domain);
    }
    this.token.next(token);
  }

  public async setNewPassword(oldPassword: string, newPassword: string): Promise<boolean> {
    const username = this.user.value?.username;
    if (!username) {
      return false;
    }
    const client = this.getApollo('setNewPassword');
    return client
      .mutate({
        mutation: gql(`
          mutation setNewPassword($newPassword: String!, $oldPassword: String!) {
            setNewPassword(newPassword: $newPassword, oldPassword: $oldPassword)
          }
        `),
        variables: {
          oldPassword,
          newPassword,
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      })
      .then(response => {
        if (response.data) {
          this.setToken(null);
          return !!this.login(username, newPassword);
        }
        return false;
      });
  }

  public async forgotPassword(username: string): Promise<void> {
    const client = this.getApollo('forgotPassword');
    return client
      .mutate({
        mutation: gql(`
          mutation forgotPassword($username: String!) {
            forgotPassword(username: $username)
          }
        `),
        variables: {
          username,
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
      })
      .then(() => {});
  }
}
