import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleTemplate } from '@ov-suite/models-warehouse';
import { VehicleClass } from '@ov-suite/models-admin';
import { BehaviorSubject, combineLatest, filter, map, Observable, of } from 'rxjs';
import moment from 'moment/moment';
import swal from 'sweetalert2';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { ExternalVehicleCreateComponent } from '../../components/external-vehicle/external-vehicle-create.component';
import { LoadAllocationDataService } from '../../services/load-allocation.data.service';
import { LoadDTO, LoadFilter } from '../../load-allocation.interface';
import { LoadAllocationVehicleLayoutService } from '../../services/load-allocation.vehicle-layout.service';
import { LoadAllocationActionService } from '../../services/load-allocation.action.service';
import { LoadAllocationVehicleService } from '../../services/data-services/load-allocation.vehicle.service';
import { LoadAllocationLoadAllocationService } from '../../services/data-services/load-allocation.load-allocation.service';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAllocationOrderService } from '../../services/data-services/load-allocation.order.service';
import { LoadAllocationDateService } from '../../services/load-allocation.date.service';
import { LoadAllocationVehicleHelperService } from '../../services/data-services/load-allocation.vehicle-helper.service';

interface ClassFilter {
  id: number;
  name: string;
  value: VehicleClass;
}

interface StatusFilter {
  id: number;
  label: string;
  value: LoadFilter;
}

@Component({
  selector: 'ov-suite-load-allocation-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss'],
})
export class VehicleComponent {
  @ViewChild('leftTable') leftTable: ElementRef;

  public dateDisplay$: BehaviorSubject<string> = new BehaviorSubject(null);

  loaders: Record<string, boolean> = {};

  date: Date = new Date();

  filterStatuses: StatusFilter[] = [
    {
      id: 1,
      label: 'All',
      value: LoadFilter.All,
    },
    {
      id: 2,
      label: 'Unplanned',
      value: LoadFilter.Unplanned,
    },
    {
      id: 3,
      label: 'Planning',
      value: LoadFilter.Planning,
    },
    {
      id: 4,
      label: 'Confirmed',
      value: LoadFilter.Confirmed,
    },
    {
      id: 5,
      label: 'Processed',
      value: LoadFilter.Processed,
    },
  ];

  classFilter: ClassFilter;

  statusFilter: StatusFilter;

  templates: VehicleTemplate[] = [];

  template: VehicleTemplate;

  weightMap: Map<number | string, BehaviorSubject<number>> = new Map();

  volumeMap: Map<number | string, BehaviorSubject<number>> = new Map();

  vehicleClassData$: Observable<ClassFilter[]>;

  constructor(
    public base: LoadAllocationBaseService,
    private readonly calendar: NgbCalendar,
    private readonly modalService: NgbModal,
    public data: LoadAllocationDataService,
    public action: LoadAllocationActionService,
    public layout: LoadAllocationVehicleLayoutService,
    public readonly loadAllocationService: LoadAllocationLoadAllocationService,
    public readonly viewService: LoadAllocationViewService,
    public readonly vehicleService: LoadAllocationVehicleService,
    public readonly orderService: LoadAllocationOrderService,
    public dateService: LoadAllocationDateService,
    public vehicleHelperService: LoadAllocationVehicleHelperService,
  ) {
    dateService.date$.subscribe(date => this.setDateDisplay(date));
    this.vehicleClassData$ = vehicleService.list$().pipe(
      filter(vehicles => !!vehicles?.length),
      map(vehicles => {
        const classMap: Map<number, VehicleClass> = new Map();
        vehicles.forEach(vehicle => {
          classMap.set(vehicle.class.id, vehicle.class);
        });
        const classes: VehicleClass[] = [...classMap.values()];

        classes.sort((a, b) => (b.name > a.name ? -1 : 1));

        const output = classes.map<ClassFilter>((vehicleClass, index) => {
          return {
            id: index,
            name: vehicleClass.name,
            value: vehicleClass,
          };
        });

        return [{ id: 0, name: 'All', value: null }, ...output];
      }),
    );
  }

  setDateDisplay(date: Date) {
    moment.updateLocale('en', {
      calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: '[Last] dddd',
        nextWeek: '[Next] dddd',
        sameElse: 'L',
      },
    });
    const momentDate = moment(date);
    const dateString = momentDate.format('DD/MM/yyyy');
    const after = momentDate.isSameOrAfter(moment().subtract(7, 'days'));
    const before = momentDate.isSameOrBefore(moment().add(7, 'days'));
    if (after && before) {
      this.dateDisplay$.next(`${momentDate.calendar()}, ${dateString}`);
    } else {
      this.dateDisplay$.next(dateString);
    }
  }

  onDateChange(date: Date) {
    this.dateService.setDate(date);
  }

  onClassFilterChange() {
    this.viewService.setVehicleClassFilter(this.classFilter.value);
  }

  onStatusFilterChange() {
    this.viewService.setVehicleStatusFilter(this.statusFilter.value);
  }

  getWeightDisplay$(dto: LoadDTO): Observable<string> {
    const { weightLoadAllowed = 0 } = dto.vehicleSimple;
    if (!dto.orderDTOs?.length) {
      return of(`0 / ${weightLoadAllowed} kg`);
    }
    return combineLatest(dto.orderDTOs.map(o => this.orderService.getLoadTotalWeight$(o.orderId))).pipe(
      map(weights => {
        const reserved = weights.reduce((p, c) => p + c);
        return `${reserved.toLocaleString()} / ${weightLoadAllowed.toLocaleString()} kg`;
      }),
    );
  }

  getVolumeDisplay$(dto: LoadDTO): Observable<string> {
    const { height = 0, width = 0, length = 0 } = dto.vehicleSimple;
    const limit = height * width * length;
    if (!dto.orderDTOs?.length) {
      return of(`0 / ${limit.toLocaleString()} m³`);
    }
    return combineLatest(dto.orderDTOs.map(o => this.orderService.getLoadTotalVolume$(o.orderId))).pipe(
      map(volumes => {
        const reserved = volumes.reduce((p, c) => p + c);
        return `${reserved.toLocaleString()} / ${limit.toLocaleString()} m³`;
      }),
    );
  }

  getWeightStyle$(dto: LoadDTO): Observable<Record<string, unknown>> {
    return combineLatest(dto.orderDTOs.map(o => this.orderService.getLoadTotalWeight$(o.orderId))).pipe(
      map(weights => {
        const reserved = weights.reduce((p, c) => p + c);
        const percentage = (reserved / dto.vehicleSimple.weightLoadAllowed) * 100;

        let color: string;
        const { weightOrangePercentage, weightRedPercentage } = dto.vehicleSimple;

        if (percentage >= weightRedPercentage) {
          color = '#FBF2F1';
        } else if (percentage >= weightOrangePercentage) {
          color = '#fed8b1';
        } else {
          color = '#D0F0C0';
        }
        return {
          width: `${percentage > 100 ? 100 : percentage}%`,
          'background-color': color,
        };
      }),
    );
  }

  getVolumeStyle$(dto: LoadDTO): Observable<Record<string, unknown>> {
    return combineLatest(dto.orderDTOs.map(o => this.orderService.getLoadTotalVolume$(o.orderId))).pipe(
      map(weights => {
        const reserved = weights.reduce((p, c) => p + c);
        const limit = dto.vehicleSimple.height * dto.vehicleSimple.width * dto.vehicleSimple.length;
        const percentage = (reserved / limit) * 100;

        let color: string;
        const { volumeOrangePercentage, volumeRedPercentage } = dto.vehicleSimple;

        if (percentage >= volumeRedPercentage) {
          color = '#FFCCCB';
        } else if (percentage >= volumeOrangePercentage) {
          color = '#fed8b1';
        } else {
          color = '#CAE4F1';
        }

        return {
          width: `${percentage > 100 ? 100 : percentage}%`,
          'background-color': color,
        };
      }),
    );
  }

  getMouseUpHandler(element: HTMLDivElement, mouseMoveHandler: (e: MouseEvent) => void): () => void {
    const output = () => {
      element.style.cursor = 'grab';
      element.style.removeProperty('user-select');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', output);
    };
    return output;
  }

  dragRight(event: MouseEvent, yElement: HTMLDivElement, xElement: HTMLDivElement, xElementStatic: HTMLDivElement): void {
    xElement.style.cursor = 'grabbing';
    xElement.style.userSelect = 'none';

    const dragPos = {
      left: xElement.scrollLeft,
      top: yElement.scrollTop,
      x: event.clientX,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - dragPos.x;
      const dy = e.clientY - dragPos.y;

      yElement.scrollTop = dragPos.top - dy;
      xElement.scrollLeft = dragPos.left - dx;
      xElementStatic.scrollLeft = dragPos.left - dx;
    };

    const mouseUpHandler = this.getMouseUpHandler(xElement, mouseMoveHandler);

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  dragLeft(event: MouseEvent, element: HTMLDivElement, grabElement: HTMLDivElement): void {
    grabElement.style.cursor = 'grabbing';
    grabElement.style.userSelect = 'none';

    const dragPos = {
      top: element.scrollTop,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dy = e.clientY - dragPos.y;

      element.scrollTop = dragPos.top - dy;
    };

    const mouseUpHandler = this.getMouseUpHandler(grabElement, mouseMoveHandler);

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  commit(event: MouseEvent, loadDTO: LoadDTO): void {
    if (!loadDTO.orderDTOs.length) {
      swal.fire({
        title: 'Empty Load',
        text: 'It seems there are no orders allocated to this load. In order to release, please allocate orders to load.',
        type: 'warning',
      });
      return;
    }
    event.stopPropagation();
    if (loadDTO.committed) {
      this.loadAllocationService.uncommit(loadDTO.loadId);
    } else {
      this.loadAllocationService.commit(loadDTO.loadId);
    }
  }

  onAddExternalVehicle() {
    this.modalService.open(ExternalVehicleCreateComponent, { size: 'xl' });
  }
}
