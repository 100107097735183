import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { LoadAmounts } from '../load-amount-tab.types';

@Component({
  selector: 'ov-suite-load-amount-resolution-modal',
  templateUrl: './load-amount-resolution-modal.component.html',
  styleUrls: ['./load-amount-resolution-modal.component.css'],
})
export class LoadAmountResolutionModalComponent implements OnInit {
  @Input() selectedLoadAmount: [LoadAmounts[], number];

  @Input() canAdjustAmounts: boolean;

  displayedColumns = ['orderCode', 'qty', 'adjust'];

  totalLoaded: number;

  loadedAmounts: LoadAmounts[];

  productSku = '';

  productName = '';

  constructor() {}

  ngOnInit(): void {
    [this.loadedAmounts, this.totalLoaded] = this.selectedLoadAmount;

    const { productSku, productSkuName } = this.loadedAmounts[0];
    this.productSku = productSku;
    this.productName = productSkuName;
  }

  quantityLoadedUpdated(updateAmount: number, loadAmount: LoadAmounts) {
    // TODO adjust what the max for each input should be here.
    // console.log(updateAmount, loadAmount);
    // console.log(this.selectedLoadAmount);

    loadAmount.quantityLoaded = updateAmount;
  }
}
