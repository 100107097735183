import { OVEntity, OVField, OVSearch, OVTable } from '@ov-suite/ov-metadata';
import { InventoryLocationModel, PickModel, VehicleClass } from '@ov-suite/models-admin';
import { ExternalVehicle } from './external-vehicle.model';
import { VehicleOverride } from './vehicle-override.model';
import { VehicleResource } from './vehicle-resource.model';
import { WaveInstance } from './wave-instance.model';
import { PickStageStatus } from "@ov-suite/helpers-shared";

const statusColor: Record<string, string> = {
  'Pending': 'badge-info',
  'Complete': 'badge-success',
  'No Stock': 'badge-warning',
  'Exception': 'badge-danger'
}

const renderPickStatuses = (picks: PickModel[]) => {
  const statuses: Record<string, number> = {};
const allPicksCount = picks.length;
  picks.forEach(pick => {
    switch (pick.pickStatus) {
      case PickStageStatus.IN_PROGRESS:
      case PickStageStatus.READY:
      case PickStageStatus.RESERVED:
        statuses['Pending'] = (statuses['Pending'] || 0) + 1;
        break;
      case PickStageStatus.COMPLETE:
        statuses['Complete'] = (statuses['Complete'] || 0) + 1;
        break;
      case PickStageStatus.NO_STOCK:
        statuses['No Stock'] = (statuses['No Stock'] || 0) + 1;
        break;
      default:
        statuses['Exception'] = (statuses['Exception'] || 0) + 1;
    }
  });

  let data = '';
   Object.keys(statuses).forEach(statusName => {
     if(statuses[statusName]) {
       const percentage = Math.round(statuses[statusName]/allPicksCount * 100);
       data += `<span class="badge ${statusColor[statusName]} font-bold ml-1"> ${statusName} -(${statuses[statusName]}) ${percentage}% </span>`;
     }
  });

   return data;
}


@OVEntity({ name: 'LoadAllocation' })
@OVTable<LoadActiveList>([
  {
    type: 'number',
    key: 'id',
    title: 'Load ID',
  },
  {
    type: 'deep-string',
    key: 'loadBay.name',
    title: 'Load Bay',
  },
  {
    type: 'deep-string',
    key: 'wave.waveConfig.waveName',
    title: 'Wave',
  },
  {
    type: 'date',
    key: 'date',
    title: 'Load Date',
    format: 'DD/MM/YYYY',
  },
  {
    type: 'deep-string',
    key: 'vehicle.registration',
    title: 'Vehicle Registration',
    disableSorting: true,
    disableFiltering: true,
  },
  {
    type: 'other',
    title: 'Picking Status',
    action: (t) => renderPickStatuses(t.picks),
    keys: ['picks.pickStatus'],
    disableSorting: true,
    disableFiltering: true
  },
  {
    title: 'Fast Actions',
    type: 'buttons',
    disableSorting: true,
    buttons: [{ actionType: 'routeLink', classes: 'btn-sm', title: 'view',
      queryParams: (data, url)=> ({ 'id': data.id }),
      routerLink: (data, url)=> (['load-management/load'])
    }],
    keys: [],
  }
])
@OVSearch<LoadActiveList>({ searchKeys: ['vehicle.name', 'vehicle.registration', 'wave.name', 'loadBay.name'] })
export class LoadActiveList {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  date: string;

  @OVField({ type: () => ExternalVehicle })
  externalVehicle: ExternalVehicle;

  @OVField({ type: () => VehicleOverride })
  vehicle: VehicleOverride;

  @OVField({ type: () => WaveInstance })
  wave: WaveInstance;

  @OVField({ type: () => [PickModel] })
  picks: PickModel[];

  @OVField({ type: () => InventoryLocationModel })
  loadBay: InventoryLocationModel;

  @OVField({ type: 'number' })
  loadBayId: number;

  @OVField({ type: 'date-time' })
  releaseDate?: Date;

  @OVField({ type: 'date-time' })
  completedDate?: Date;

  getVehicle(): VehicleOverride {
    if (this.externalVehicle) {
      const output = new VehicleOverride();
      output.id = this.externalVehicle.id;
      output.name = `${this.externalVehicle.make} ${this.externalVehicle.model}`;
      output.class = {
        name: this.externalVehicle.vehicleClass,
        height: this.externalVehicle.height,
        width: this.externalVehicle.width,
        length: this.externalVehicle.length,
        weightLoadAllowed: this.externalVehicle.weightLimit,
        weightRedPercentage: 100,
        weightOrangePercentage: 90,
      } as VehicleClass;
      output.registration = this.externalVehicle.registration;
      output.resource = {
        startTime: this.externalVehicle.startTime,
        endTime: this.externalVehicle.endTime,
      } as unknown as VehicleResource;

      return output;
    }

    return this.vehicle;
  }
}
