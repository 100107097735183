import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import _ from 'lodash';
import { KeyValue } from '@angular/common';
import { LoadAmountInvoice, LoadAmountOrder, LoadAmountOrderItem, LoadAmounts } from '../load-amount-tab.types';
import { InvoiceDownloadService } from '../../invoice-download.service';

@Component({
  selector: 'ov-suite-load-order-accordion',
  templateUrl: './load-order-accordion.component.html',
  styleUrls: ['./load-order-accordion.component.css'],
})
export class LoadOrderAccordionComponent {
  @Input() orders: Map<number, LoadAmountOrder>;

  @Input() loadAmountMap: Map<number, [LoadAmounts[], number]>;

  @Input() loadInvoiceMap: Map<number, LoadAmountInvoice> = new Map<number, LoadAmountInvoice>();

  @ViewChild('adjustAmountModal') adjustAmountModal: TemplateRef<unknown>;

  // [0 - product sku id]
  // [1 - updated load amounts for this product sku id]
  @Output() loadAmountChanges: EventEmitter<[number, LoadAmounts[]]> = new EventEmitter<[number, LoadAmounts[]]>();

  @Output() generateInvoiceEmitter: EventEmitter<number> = new EventEmitter<number>();

  @Output() reloadInvoiceEmitter: EventEmitter<number> = new EventEmitter<number>();

  cloneSelectedLoadAmount: [LoadAmounts[], number];

  originalSelectedLoadAmount: [LoadAmounts[], number];

  selectedProductId: number;

  topBarDisplayColumns = ['orderCode', 'orderedQuantity', 'loadedQuantity', 'action'];

  displayedColumns = ['product', 'sku', 'ordered', 'loaded', 'difference', 'actions'];

  selectedOrderedItems: LoadAmountOrderItem[] = [];

  selectedOrder: KeyValue<number, LoadAmountOrder>;

  canAdjustAmounts = true;

  constructor(private readonly dialogService: ConfirmationDialogService, private readonly invoiceDownloadService: InvoiceDownloadService) {}

  onAdjustAmounts(productSkuId: number) {
    // find the item in the map
    if (this.loadAmountMap.has(productSkuId)) {
      this.selectedProductId = productSkuId;
      this.originalSelectedLoadAmount = _.cloneDeep(this.loadAmountMap.get(productSkuId));
      this.cloneSelectedLoadAmount = _.cloneDeep(this.loadAmountMap.get(productSkuId));

      // Ensure that this item has not been invoiced yet as well as that we have loaded this item into the truck.
      this.canAdjustAmounts = !this.loadInvoiceMap.has(this.selectedOrder.key) && this.loadAmountMap.get(productSkuId)[1] > 0;
    }

    const dialogData: ConfirmationDialogData = {
      title: 'Adjust Invoice Line Item',
      component: this.adjustAmountModal,
      width: 450,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Adjust',
      disableConfirm: !this.canAdjustAmounts,
    };

    this.dialogService.openComponentDialog(dialogData).subscribe(value => {
      if (value) {
        this.updateLoadAmounts(this.cloneSelectedLoadAmount);
      } else {
        this.loadAmountMap.set(this.selectedProductId, this.originalSelectedLoadAmount);
      }
    });
  }

  setSelectedOrder(selectedOrder: KeyValue<number, LoadAmountOrder>) {
    this.selectedOrder = selectedOrder;

    // Reset the selected Order Items as well.
    this.selectedOrderedItems = [];

    Array.from(selectedOrder.value.loadAmountOrderItems.values()).forEach(item => {
      this.selectedOrderedItems.push(...item);
    });
  }

  updateLoadAmounts(amounts: [LoadAmounts[], number]) {
    this.loadAmountMap.set(this.selectedProductId, amounts);

    // Emit only the changed item;
    this.loadAmountChanges.emit([this.selectedProductId, this.loadAmountMap.get(this.selectedProductId)[0]]);
  }

  generateInvoice(event: any, order: KeyValue<number, LoadAmountOrder>) {
    event.stopPropagation();

    this.generateInvoiceEmitter.emit(order.key);
  }

  onReloadInvoice(event: any, invoiceId: number) {
    event.stopPropagation();

    this.reloadInvoiceEmitter.emit(invoiceId);
  }

  onViewInvoice(event: any, invoice: LoadAmountInvoice) {
    event.stopPropagation();

    const order = this.orders.get(invoice.orderId);
    this.invoiceDownloadService.exportInvoice(invoice.id, order.orderCode, invoice.invNum);
  }

  onGeneratingInvoice(event: any) {
    event.stopPropagation();
  }
}
