<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Load Info</a>
    <ng-template ngbNavContent>
      <div class="simple-table">
        <div class="simple-row">
          <div>Priority</div>
          <div>
            <span [class]="'badge ' + orderPriority[action.loadSelected.value?.orders[1]?.priority]?.color">
              {{ orderPriority[action.loadSelected.value?.orders[1]?.priority]?.text }}
            </span>
          </div>
        </div>
        <div class="simple-row">
          <div>Load ID</div>
          <div>{{ action.loadSelected.value?.id }}</div>
        </div>
        <div class="simple-row">
          <div>Load Commit Date</div>
          <div>{{ action.loadSelected.value?.commitDate | date }}</div>
        </div>
        <div class="simple-row">
          <div>Vehicle Registration</div>
          <div>{{ action.loadSelected.value?.getVehicle()?.registration }}</div>
        </div>
        <div class="simple-row">
          <div>No. of Orders</div>
          <div>{{ action.loadSelected.value?.orders?.length }}</div>
        </div>

        <div class="simple-row">
          <div class="mt-2"><strong>Change Vehicle</strong></div>
          <div><button (click)="onChangeVehicle()" class="btn btn-sm">Change</button></div>
        </div>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Load Details</a>
    <ng-template ngbNavContent>
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="text-capitalize">Customer Code</th>
            <th class="text-capitalize">Customer Name</th>
            <th class="text-capitalize">Order Date</th>
          </tr>
        </thead>
        <tbody id="customer-item-list">
          <tr *ngFor="let order of action.loadSelected.value?.orders" class="border">
            <td>{{ order.customer.customerCode }}</td>
            <td>{{ order.customer.name }}</td>
            <td>{{ order.orderDate }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink>Load Items</a>
    <ng-template ngbNavContent>
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="text-capitalize">SKU Name</th>
            <th class="text-capitalize">Qty</th>
          </tr>
        </thead>
        <tbody id="order-item-list">
          <tr *ngFor="let orderItem of getOrderItems(action.loadSelected.value?.orders)" class="border">
            <td>{{ orderItem.productSku?.sku }}</td>
            <td>{{ orderItem?.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
