import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { Inventory, InventoryLocationModel } from '@ov-suite/models-admin';
import { InventoryContainer } from './inventory-container.model';

@OVEntity('InventoryLocation')
export class LoadBayLocationModel {
  @OVField({
    type: 'number',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: () => [InventoryContainer],
    keys: ['id', 'containers.name', 'containers.inventories', 'containers.inventories.id'],
    title: 'Inventory Containers',
    required: false,
  })
  containers: InventoryContainer[];

  @OVField({
    type: () => InventoryLocationModel,
    title: 'Parent',
    bulkDependency: 'id',
  })
  parent?: InventoryLocationModel;
}
