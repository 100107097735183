import { Routes } from '@angular/router';
import { ProcessDefinitionComponent } from './process-definition-component/process-definition.component';

export const ProcessDefinitionRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProcessDefinitionComponent,
      },
    ],
  },
];
