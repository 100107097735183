import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { MasterRoute } from '@ov-suite/models-warehouse';
import { LazyService } from './load-allocation.lazy.service';

/**
 * Used to manage orders in various ways
 */
@Injectable()
export class LoadAllocationMasterRouteService extends LazyService<MasterRoute> {
  constructor(private readonly ovAutoService: OvAutoService) {
    super(MasterRoute, ovAutoService, { relations: [] });
  }
}
