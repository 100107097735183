<div class="main-content">
  <ov-suite-hierarchy-tabs *ngIf="!!options.tabs" [tabs]="options.tabs"> </ov-suite-hierarchy-tabs>
  <ov-suite-top-filters
    *ngIf="options.topFilters"
    [entity]="options.entity"
    [filters]="options.topFilters"
    (filterChange)="onChangeFilter($event)"
  ></ov-suite-top-filters>
  <div class="main-container">
    <ov-suite-hierarchy-breadcrumb
      *ngIf="isHierarchy"
      [ovAutoService]="ovAutoService"
      [formClass]="options.entity"
      [itemToDelete]="itemToDelete"
    ></ov-suite-hierarchy-breadcrumb>
    <ov-suite-hierarchy-table
      #table
      [title]="options.tableTitle"
      [formClass]="options.entity"
      [hideAddButton]="options.hideAdd"
      [hasBulkUpload]="!options.hideImport"
      [excludeColumns]="options.hideEditDelete ? 4 : 1"
      [hideColumnKeys]="options.hideColumns"
      [showFiller]="options.showFiller"
      [hasCreatePermission]="canAdd"
      [hasEditPermission]="canUpdate"
      [hasDeletePermission]="canDelete"
      [orderColumn]="options.defaultOrderColumn"
      [defaultOrderDirection]="options.defaultOrderDirection"
      [titleReplace]="options.titleReplace"
      (rowClick)="onRowClick($event)"
      [clickableRows]="!!options.onClick"
      [defaultQuery]="options.defaultFilter"
      (onDeleteItem)="onDeleteItem($event)"
      [overrideColumnData]="options.table"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
