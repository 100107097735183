import { NgModule } from '@angular/core';
import { OvGenericModule, UiModule } from '@ov-suite/ui';
import { OrderModel } from '@ov-suite/models-order';
import { CommonModule } from '@angular/common';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';
import { OrderDetailsPopupComponent } from './order-details-popup/order-details-popup.component';

@NgModule({
  imports: [
    CommonModule,
    UiModule,
    OvGenericModule.forRoot({
      entity: OrderModel,
      createTitle: 'Order',
      route: ['planned'],
      tableTitle: 'Planned',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      hideColumns: ['completedDate'],
      permissionId: FeatureKey.INVENTORY_LIST,
      defaultOrderDirection: 'DESC',
      defaultOrderColumn: 'orderDate',
      defaultFilter: { status: ['PLANNED'] },
      onClick: (item, ngModal) => {
        const modalRef = ngModal.open(OrderDetailsPopupComponent, { size: 'xl' });
        modalRef.componentInstance.query = { orderId: [item.id] };
        modalRef.componentInstance.item = item;
      },
      tabs: [
        {
          path: '/order/confirmed',
          title: 'Confirmed',
        },
        {
          path: '/order/planned',
          title: 'Planned',
        },
        {
          path: '/order/partial-processed',
          title: 'Partially Processed',
        },
        {
          path: '/order/completed',
          title: 'Completed',
        },
        {
          path: '/order/unconfirmed',
          title: 'Unconfirmed',
        },
      ],
    }),
  ],
})
export class PlannedOrdersModule {}
