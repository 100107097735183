import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { InventoryLocationModel, PickingItemLogModel, PickingItemModel } from '@ov-suite/models-admin';
import { InventoryContainer, LoadAllocation } from '@ov-suite/models-warehouse';

@OVEntity({ name: 'PickingDetail' })
export class PickDetailModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => PickingItemModel })
  pickingItem?: PickingItemModel;

  @OVField({ type: () => PickingItemLogModel })
  pickLog: PickingItemLogModel;

  @OVField({ type: () => LoadAllocation })
  load: LoadAllocation;

  @OVField({ type: () => InventoryLocationModel })
  specificSourceLocation: InventoryLocationModel;

  @OVField({ type: () => InventoryLocationModel })
  specificTargetLocation: InventoryLocationModel;

  @OVField({ type: () => InventoryContainer })
  specificContainer: InventoryContainer;

  @OVField({ type: () => InventoryContainer })
  containerPicked: InventoryContainer;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'date-time' })
  createdDate: Date;
}
