<div class="margin-auto">
  <!-- Display Upload Image Block -->
  <div *ngIf="!currentImage && !imageUrl && !viewMode" class="row mb-1 justify-content-center">
    <div class="col-12">
      <div class="upload-drop font-weight-bold" [ngClass]="{ full: type === 'full' }" (click)="onFileInputTrigger()">
        <div class="upload-text">DRAG IMAGE</div>
      </div>
    </div>
  </div>

  <!-- Display Image if availale -->
  <div *ngIf="(!!currentImage || !!imageUrl) && !viewMode" class="row mb-1">
    <div class="col-12">
      <div
        class="upload-drop font-weight-bold"
        [ngClass]="{ 'upload-drop upload-drop-large': size == 'large' }"
        (click)="onFileInputTrigger()"
      >
        <span class="image-editable-pencil">
          <i aria-hidden="true" class="fa fa-pencil fa-2x"> </i>
        </span>
        <img id="uploadedImage" [src]="imageUrl" alt="..." />
      </div>
    </div>
  </div>

  <!-- Display Image if availale -->
  <div *ngIf="viewMode && base64" class="row">
    <img id="uploadedImage2" [src]="'data:image/png;base64,' + imageUrl" alt="..." />
<!--    <div class="col-12">-->
<!--      <div-->
<!--        class="font-weight-bold"-->
<!--        [ngClass]="{ 'upload-drop upload-drop-large': size == 'large' }"-->
<!--      >-->
<!--        <span class="image-editable-pencil">-->
<!--          <i aria-hidden="true" class="fa fa-pencil fa-2x"> </i>-->
<!--        </span>-->
<!--       -->
<!--      </div>-->
<!--    </div>-->
  </div>

  <input *ngIf="!viewMode" id="image-upload-input" class="hide-image-upload-input" type="file" (change)="onFileChange($event)" />

  <div *ngIf="!!currentImage && !isUploaded && !viewMode" class="row justify-content-center">
    <div class="col-3"></div>
    <div class="col-6">
      <button class="btn btn-primary btn-round" [disabled]="loading" (click)="onFileUpload()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        UPLOAD IMAGE
      </button>
    </div>
    <div class="col-3"></div>
  </div>
</div>
