import { getTaggedClass, OVEntity, OVField, OVSearchable, OVTable } from '@ov-suite/ov-metadata';
import { OrderPriorityConstant, PermissionType, Rag } from '@ov-suite/helpers-shared';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { InventoryProductSku } from './inventory-product-sku.model';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

@OVEntity('ProductSkuLevels', 'warehouselink')
@OVTable<ProductSkuLevelsModel>([
  {
    title: 'SKU',
    type: 'deep-string',
    key: 'productSku.sku',
  },
  {
    title: 'Name',
    type: 'deep-string',
    key: 'productSku.name',
  },
  {
    title: 'Count',
    type: 'number',
    key: 'quantity',
  },
  {
    title: 'Status',
    type: 'other',
    action: val => {
      switch (val.rag) {
        case Rag.amber:
          return `<span style="color: orange">●</span>`;
        case Rag.green:
          return `<span style="color: green">●</span>`;
        case Rag.red:
          return `<span style="color: red">●</span>`;
        default:
          return 'missing';
      }
    },
    keys: ['rag'],
  },
  {
    title: 'Actions',
    keys: ['productSku.id'],
    type: 'buttons',
    buttons: [
      {
        actionType: 'modal',
        classes: 'btn btn-primary btn-sm',
        action: (item, { ngbModal }) => {
          const modalRef = ngbModal.open(getTaggedClass('set-triggers-popup', InventoryProductSku), { size: 'lg' });
          modalRef.componentInstance.inventoryProductSku = item;
        },
        title: 'Set Triggers',
        permissionId: FeatureKey.INVENTORY_PRODUCT,
        permissionType: PermissionType.update,
      },
    ],
  },
])
export class ProductSkuLevelsModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'date' })
  enterTime?: Date;

  @OVField({ type: 'number' })
  rag: Rag;
}
