import { OVEntity, OVField, OVSearch, OVTable } from '@ov-suite/ov-metadata';
import {
  InventoryLocationModel,
  PickingItemModel,
  StagingItemModel,
  VehicleClass
} from '@ov-suite/models-admin';
import { ExternalVehicle } from './external-vehicle.model';
import { VehicleOverride } from './vehicle-override.model';
import { VehicleResource } from './vehicle-resource.model';
import { WaveInstance } from './wave-instance.model';
import { VehicleTemplate } from './vehicle-template.model';
import { OrderModel } from '@ov-suite/models-order';
import { LoadAmountModel } from './load-amount.model';
import { LoadUserModel } from './load-user.model';
import { LoadDetailModel } from './load-detail.model';
import { LoadLogModel } from './load-log.model';
import { InventoryContainer } from './inventory-container.model';

@OVEntity({ name: 'LoadAllocation' })
@OVTable<LoadAllocation>([
  {
    type: 'number',
    key: 'id',
    title: 'Load ID',
    disableFiltering: true,
  },
  {
    type: 'deep-string',
    key: 'vehicle.registration',
    title: 'Vehicle Registration',
    disableSorting: true,
    disableFiltering: true,
  },
  {
    type: 'deep-string',
    key: 'vehicle.class.name',
    title: 'Vehicle Class',
    disableSorting: true,
    disableFiltering: true,
  },
  {
    title: 'Wave',
    type: 'dropdown',
    key: 'wave',
    filterKey: 'wave.id',
    editable: true,
    disableSorting: true,
    disableFiltering: true,
    displayKeys: ['waveName'],
  },
  {
    type: 'string',
    key: 'id',
    title: 'Load Bay',
  },
  {
    type: 'date',
    key: 'date',
    title: 'Load Date',
    format: 'DD/MM/YYYY',
  },
  {
    type: 'string',
    key: 'id',
    title: 'Status',
  },
])
@OVSearch<LoadAllocation>({ searchKeys: ['vehicle.name', 'vehicle.registration', 'wave.name', 'loadBay.name'] })
export class LoadAllocation {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  date: string;

  @OVField({ type: 'number' })
  externalVehicleId: number;

  @OVField({ type: () => ExternalVehicle })
  externalVehicle: ExternalVehicle;

  @OVField({ type: 'number' })
  vehicleId: number;

  @OVField({ type: () => VehicleOverride })
  vehicle: VehicleOverride;

  @OVField({ type: () => [OrderModel] })
  orders: OrderModel[];

  @OVField({ type: 'numbers' })
  orderIds: number[];

  @OVField({ type: 'number' })
  waveId: number;

  @OVField({ type: () => WaveInstance })
  wave: WaveInstance;

  @OVField({ type: () => InventoryLocationModel })
  loadBay: InventoryLocationModel;

  @OVField({ type: 'number' })
  loadBayId: number;

  // If not null, this load bay is high priority. The lower the number, the higher the priority
  @OVField({ type: 'number' })
  loadBayMinCapacity: number;

  @OVField({ type: 'boolean' })
  commit: boolean;

  @OVField({ type: 'date-time' })
  commitDate?: Date;

  @OVField({ type: 'date-time' })
  releaseDate?: Date;

  @OVField({ type: 'date-time' })
  stagingReleaseDate?: Date;

  @OVField({ type: 'date-time' })
  completeDate?: Date;

  @OVField({ type: 'string' })
  pickSheetProcessId?: string;

  @OVField({ type: 'string' })
  truckDriverName?: string;

  @OVField({ type: 'domain-selector' })
  domainIds: string;

  @OVField({ type: () => VehicleTemplate })
  vehicleTemplate: VehicleTemplate;

  @OVField({ type: () => [LoadAmountModel] })
  amounts: LoadAmountModel[];

  @OVField({ type: () => [LoadUserModel] })
  users: LoadUserModel[];

  @OVField({ type: () => [LoadDetailModel] })
  details: LoadDetailModel[];

  @OVField({ type: () => [LoadLogModel] })
  logEntries: LoadLogModel[];

  @OVField({ type: () => [InventoryContainer] })
  inventoryContainers: InventoryContainer[]

  @OVField({ type: () => [PickingItemModel] })
  pickingItems: PickingItemModel[];

  @OVField( { type: () => [StagingItemModel] })
  stagingItems: PickingItemModel[];

  @OVField({ type: 'string', unnecessary: true })
  sessionId: string;

  @OVField({ type: 'string', unnecessary: true })
  source: string;

  static fromVehicle(vehicle: VehicleOverride, date: string): LoadAllocation {
    const output = new LoadAllocation();
    output.vehicle = vehicle;
    output.orders = [];
    output.date = date;
    return output;
  }

  static fromExternalVehicle(vehicle: ExternalVehicle, date: string): LoadAllocation {
    const output = new LoadAllocation();
    output.externalVehicle = vehicle;
    output.orders = [];
    output.date = date;
    return output;
  }

  setExternalVehicle(vehicle: ExternalVehicle) {
    this.externalVehicle = vehicle;
  }

  setVehicle(vehicleOverride: VehicleOverride) {
    this.vehicle = vehicleOverride;
  }

  isExternalVehicle(): boolean {
    return !!this.externalVehicle?.id;
  }

  getVehicle(): VehicleOverride {
    if (this.externalVehicle) {
      const output = new VehicleOverride();
      output.id = this.externalVehicle.id;
      output.name = `${this.externalVehicle.make} ${this.externalVehicle.model}`;
      output.class = {
        name: this.externalVehicle.vehicleClass,
        height: this.externalVehicle.height,
        width: this.externalVehicle.width,
        length: this.externalVehicle.length,
        weightLoadAllowed: this.externalVehicle.weightLimit,
        weightRedPercentage: 100,
        weightOrangePercentage: 90,
      } as VehicleClass;
      output.registration = this.externalVehicle.registration;
      output.resource = {
        startTime: this.externalVehicle.startTime,
        endTime: this.externalVehicle.endTime,
      } as unknown as VehicleResource;

      return output;
    }

    return this.vehicle;
  }

  getVehicleIdentifier(): string {
    if (this.vehicleId) {
      return `v${this.vehicleId}`;
    }
    return `e${this.externalVehicleId}`;
  }
}
