import { Component } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { OvAutoService } from '@ov-suite/services';
import { WaveConfig } from '@ov-suite/models-warehouse';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateWaveModalComponent } from '../create-wave-modal/create-wave-modal.component';

@Component({
  selector: 'ov-suite-wave-list',
  templateUrl: './waves-list.component.html',
  styleUrls: ['./waves-list.component.scss'],
})
export class WavesListComponent {
  formClass = WaveConfig;

  filter: Record<string, unknown[]>;

  currentData: WaveConfig[] = [];

  tabs: HierarchyTab[] = [
    { title: 'Picking Waves', path: '/waves' },
    { title: 'Vehicles', path: '/vehicles' },
    { title: 'Routes', path: '/master-route' },
  ];

  constructor(public readonly ovAutoService: OvAutoService, public readonly modalService: NgbModal) {}

  onAdd() {
    this.modalService.open(CreateWaveModalComponent);
  }
}
