import {
  OVField,
  OVSearchable,
  OVEntity
} from '@ov-suite/ov-metadata';
import { UserModel } from "@ov-suite/models-idm";

@OVEntity('Notification', 'shared')
export class Notification {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Message',
    placeholder: 'Required',
    required: true,
  })
  message: string;

  @OVField({ type: 'date-time' })
  readDate: string;

  @OVField({ type: 'date-time' })
  createDate: string;

  @OVField({type: 'string'})
  category: string;

  @OVField({type: 'string'})
  link: string;

  constructor(id: number, readDate: string) {
    this.id = id;
    this.readDate = readDate;
  }
}
