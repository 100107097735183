import { Component } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { DateRange } from '@ov-suite/ui';
import { WaveInstance } from '@ov-suite/models-warehouse';
import { MatDialogRef } from '@angular/material/dialog';
import { LoadManagementDataService } from '../../load-management.data.service';

@Component({
  selector: 'ov-suite-load-management-wave-change',
  templateUrl: './load-management-wave-change.component.html',
  styleUrls: ['./load-management-wave-change.component.scss'],
})
export class LoadManagementWaveChangeComponent {
  selectedWave: WaveInstance;

  dateRange: DateRange;

  constructor(
    public readonly ovAutoService: OvAutoService,
    public dataService: LoadManagementDataService,
    public dialogRef: MatDialogRef<LoadManagementWaveChangeComponent>,
  ) {}

  onSave() {
    this.dialogRef.close(this.selectedWave);
  }
}
