<div>
  <div class="modal-header text-left">
    <h4 class="modal-title" id="modal-basic-title">
      <i class="mr-1 fa fa-arrow-left card-close-icon" (click)="modalService.dismissAll('Cross click')" aria-hidden="true"> </i>
      Create New Wave
    </h4>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="text-muted" for="templateSelector">Please Select Template</label>
      <select [(ngModel)]="selectedTemplate" class="form-control" id="templateSelector">
        <option [value]="0">Create New Template</option>
        <option [value]="item.id" *ngFor="let item of data">{{ item.waveName }}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between pl-3 pr-3">
    <button type="button" class="btn btn-outline-primary float-left" (click)="modalService.dismissAll('cancel click')">Cancel</button>
    <button type="button" class="btn btn-outline-dark float-right" (click)="onOpenCreateView()">
      Let's Go <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
    </button>
  </div>
</div>
