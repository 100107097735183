<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Picking Waves'"
      [formClass]="formClass"
      [hasBulkUpload]="false"
      [excludeColumns]="4"
      [emptyComponent]="empty"
      [hideAddButton]="false"
      [overrideAddButtonClick]="true"
      (addButtonClick)="onAdd()"
    >
    </ov-suite-hierarchy-table>
    <div *ngIf="currentData.length > 0"></div>
    <ng-template #empty>
      <ov-suite-empty-wave-list> </ov-suite-empty-wave-list>
    </ng-template>
  </div>
</div>
