export const orderKeys = [
  'id',
  // 'name',
  'location',
  'priority',
  'customer.id',
  'customer.name',
  'customer.customerCode',
  'customer.description',
  'customer.map',
  'orderItems.id',
  'orderItems.quantity',
  'orderItems.productSku.id',
  'orderItems.productSku.sku',
  'orderItems.productSku.name',
  'orderItems.productSku.length',
  'orderItems.productSku.width',
  'orderItems.productSku.height',
  'orderItems.productSku.weight',
  'fulfilmentDate',
  'orderDate',
  'orderCode',
  'dueDate',
  'alias',
  'isSplit',
  'status',
];

export const vehicleKeys = [
  'id',
  'name',
  'registration',
  'class.id',
  'class.name',
  'class.weightLoadAllowed',
  'class.length',
  'class.width',
  'class.height',
  'class.volumeRedPercentage',
  'class.volumeOrangePercentage',
  'class.weightRedPercentage',
  'class.weightOrangePercentage',
  'resource.startTime',
  'resource.endTime',
  'exceptions',
];

export const loadKeys = [
  'id',
  'date',
  'commit',
  'commitDate',
  'releaseDate',
  'vehicleId',
  'externalVehicleId',
  // 'externalVehicle.id',
  // 'externalVehicle.vehicleClass',
  // 'externalVehicle.model',
  // 'externalVehicle.make',
  // 'externalVehicle.weightLimit',
  // 'externalVehicle.width',
  // 'externalVehicle.length',
  // 'externalVehicle.height',
  // 'externalVehicle.startTime',
  // 'externalVehicle.endTime',
  // 'externalVehicle.registration',
  'orderIds',
  'vehicleTemplate',
  'vehicleTemplate.vehicleLines',
];
