import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { ColumnData } from '@ov-suite/helpers-shared';
import { LoadAmountModel } from '@ov-suite/models-warehouse';
import { BehaviorSubject, Observable, withLatestFrom } from 'rxjs';
import { OrderModel } from '@ov-suite/models-order';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import { LoadManagementDataService } from '../load-management.data.service';

interface LoadTotal {
  productSkuId: number;
  productSku: ProductSkuModel;
  orderAmount: number;
  loadAmount: number;
  difference: number;
}

@Component({
  selector: 'ov-suite-load-tab',
  templateUrl: './load-tab.component.html',
  styleUrls: ['./load-tab.component.scss'],
})
export class LoadTabComponent implements AfterViewInit {
  @ViewChild('InvoiceDialogComponent') invoiceDialogComponent: TemplateRef<unknown>;

  invoiceDialogObservable: Observable<unknown>;

  dataSource: MatTableDataSource<LoadTotal> = new MatTableDataSource<LoadTotal>([]);

  formClass = LoadAmountModel;

  fetchDownloadData: BehaviorSubject<LoadTotal[]> = new BehaviorSubject<LoadTotal[]>(null);

  columnData: ColumnData<LoadTotal>[] = [
    {
      title: 'Product',
      type: 'deep-string',
      key: 'productSku.name',
    },
    {
      title: 'SKU',
      type: 'deep-string',
      key: 'productSku.sku',
    },
    {
      title: 'Ordered',
      type: 'number',
      key: 'orderAmount',
    },
    {
      title: 'Loaded',
      type: 'number',
      key: 'loadAmount',
    },
    {
      title: 'Difference',
      type: 'other',
      action: total => {
        if (total.difference > 0) {
          return `+${total.difference}`;
        }
        return total.difference.toString();
      },
      keys: [],
    },
  ];

  constructor(public loadManagementDataService: LoadManagementDataService, private readonly dialogService: ConfirmationDialogService) {}

  ngAfterViewInit(): void {
    this.dataSource.sortData = (data, matSort) => {
      if (matSort.direction) {
        const up = matSort.direction === 'asc' ? 1 : -1;
        switch (matSort.active) {
          case 'Product':
            data.sort((a, b) => (a.productSku.name.toLowerCase() > b.productSku.name.toLowerCase() ? up : -up));
            break;
          case 'SKU':
            data.sort((a, b) => (a.productSku.sku.toLowerCase() > b.productSku.sku.toLowerCase() ? up : -up));
            break;
          case 'Ordered':
            data.sort((a, b) => (a.orderAmount > b.orderAmount ? up : -up));
            break;
          case 'Loaded':
            data.sort((a, b) => (a.loadAmount > b.loadAmount ? up : -up));
            break;
          case 'Difference':
            data.sort((a, b) => (a.difference > b.difference ? up : -up));
            break;
          default:
        }
      }
      return data;
    };
    this.loadManagementDataService.amounts
      .pipe(withLatestFrom(this.loadManagementDataService.orders, this.loadManagementDataService.invoices))
      .subscribe(([amounts, orders, invoices]) => {
        this.changeAmount(amounts, orders);
      });
  }

  changeAmount(amounts: LoadAmountModel[], orders: OrderModel[]) {
    if (!orders) {
      return;
    }
    const totals: LoadTotal[] = [];
    const totalsMap = new Map<number, LoadTotal>();

    // <ProductSku, amount>
    const orderAmountMap = new Map<number, number>();
    const productSkuMap = new Map<number, ProductSkuModel>();
    orders.forEach(order => {
      order.orderItems.forEach(item => {
        if (orderAmountMap.has(item.productSkuId)) {
          orderAmountMap.set(item.productSkuId, orderAmountMap.get(item.productSkuId) + item.quantity);
        } else {
          orderAmountMap.set(item.productSkuId, item.quantity);
        }
        productSkuMap.set(item.productSkuId, item.productSku);
      });
    });

    amounts.forEach(amount => {
      if (totalsMap.has(amount.productSkuId)) {
        const total = totalsMap.get(amount.productSkuId);
        total.loadAmount += amount.amount;
        total.difference = total.loadAmount - total.orderAmount;
      } else {
        const orderAmount = orderAmountMap.get(amount.productSkuId) ?? 0;
        orderAmountMap.delete(amount.productSkuId);
        const total: LoadTotal = {
          productSkuId: amount.productSkuId,
          productSku: amount.productSku,
          orderAmount,
          loadAmount: amount.amount,
          difference: amount.amount - orderAmount,
        };
        totalsMap.set(amount.productSkuId, total);
        totals.push(total);
      }
    });

    [...orderAmountMap.entries()].forEach(([key, value]) => {
      const productSku = productSkuMap.get(key);
      const total: LoadTotal = {
        productSkuId: productSku.id,
        productSku,
        orderAmount: value,
        loadAmount: 0,
        difference: -value,
      };
      totalsMap.set(key, total);
      totals.push(total);
    });

    this.dataSource.data = totals;
  }

  // No longer in use
  // onClickGenerate() {
  //   const invoiceDialog: ConfirmationDialogData = {
  //     title: 'Orders Invoice',
  //     component: this.invoiceDialogComponent,
  //     overrideActions: true,
  //   };
  //
  //   this.invoiceDialogObservable = this.dialogService.openComponentDialog(invoiceDialog);
  // }

  handleDialogEvents(success: boolean) {
    if (success) {
      const orderIds = this.loadManagementDataService.load.value?.orderIds;
      this.loadManagementDataService.getInvoices(orderIds);
    }

    this.dialogService.forceClose();
  }

  async fetchData() {
    this.fetchDownloadData.next(this.dataSource.data);
  }
}
