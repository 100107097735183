import { OVField, OVForm, OVSidebar, OVTable, OVSearchable, OVEntity, OVSearch } from '@ov-suite/ov-metadata';

@OVEntity({ name: 'ProductCategoryStatus', tableName: 'product_category_status' })
export class ProductCategoryStatus {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;

  @OVField({ type: 'string' })
  color: string;
}

@OVEntity({ name: 'ProductCategory', tableName: 'product_category' })
@OVForm([['#Product Category Details'], ['categoryId', 'name'], ['description','priority']])
@OVSidebar([['imageUrl'], ['status'], ['id']])
@OVTable<ProductCategory>([
  {
    key: 'categoryId',
    title: 'Category ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'Category Name',
    type: 'string',
  },
  {
    type: 'other',
    title: 'Number of Sub Categories',
    action: item => item?.childrenCount?.toString() ?? '0',
    routerLink: () => [],
    keys: ['childrenCount'],
    queryParams: (item: { id: number }) => ({ _parentId: item.id }),
    disableFiltering: true,
  },
  {
    key: 'priority',
    title: 'Priority',
    type: 'number',
  },
])
@OVSearch<ProductCategory>({ searchKeys: ['categoryId', 'name'] })
export class ProductCategory {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Category Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Category ID',
    tooltip: 'Used as a unique key for identification throughout the site.',
    placeholder: 'Required',
    required: true,
  })
  categoryId: string;

  @OVField({
    type: () => ProductCategory,
    title: 'Parent',
    bulkDependency: 'categoryId',
  })
  parent?: ProductCategory;

  @OVField({ type: () => ProductCategory })
  children?: ProductCategory[];

  @OVField({ type: 'number', generated: true, readonly: true, unnecessary: true, })
  childrenCount?: number;

  @OVField({ type: 'string', title: 'Description', required: false })
  description?: string;

  @OVField({ type: 'image', sidebar: true, required: false, title: 'Image' })
  imageUrl?: string;

  @OVField({
    type: () => ProductCategoryStatus,
    dropdown: true,
    selectionType: 'simple',
    title: 'Status',
    sidebar: true,
    generated: true,
  })
  status: ProductCategoryStatus;

  @OVField({ type: 'string', generated: true, readonly: true })
  path: string;

  @OVField({
    type: 'number',
    title: 'Priority',
    required: false,
  })
  priority?: number;
}
