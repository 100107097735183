export enum PickStageStatus {
  PROCESSING = 'PROCESSING', // error
  AWAITING_ACTIVATION = 'AWAITING_ACTIVATION', //
  PICK_COMPLETE = 'PICK_COMPLETE',
  STAGE_PALLET_READY = 'STAGE_PALLET_READY',
  INITIALIZED = 'INITIALIZED', // When stage item is created
  READY = 'READY', // in-progress #display
  RESERVED = 'RESERVED', // in-progress
  IN_PROGRESS = 'IN_PROGRESS', //
  COMPLETE = 'COMPLETE', //
  NOT_COMPLETE = 'NOT_COMPLETE', // Ended but not completed //cancel
  PAUSED = 'PAUSED', //
  ERROR = 'ERROR', // Ended with Error
  CANCELLED = 'CANCELLED', //
  NOT_CONFIGURED = 'NOT_CONFIGURED',// ignore for now
  NO_STOCK = 'NO_STOCK', //

  RESET = 'RESET', // Same as CANCELLED
  MANUALLY_PAUSED = 'MANUALLY_PAUSED', // Same as PAUSED but not automatic
}

/**
 * Pending (Ready, Reserved, in-progress)
 * Complete (Complete)
 * No Stock (No_stock)
 * Exception (the rest....)
 */
