import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryLocationListComponent } from './list/inventory-location-list.component';
import { InventoryProductSkuRoutes } from './inventory-location.routing';
import { SetTriggersComponent } from './modals/set-triggers/set-triggers.component';
import { OvAutoService } from '@ov-suite/services';
import { PalletModalComponent } from './pallet-modal/pallet-modal.component';

// TODO temporary popup eager loading fix
const load = SetTriggersComponent;

@NgModule({
  imports: [CommonModule, RouterModule.forChild(InventoryProductSkuRoutes), FormsModule, UiModule, NgbAlertModule],
  declarations: [InventoryLocationListComponent, SetTriggersComponent, PalletModalComponent],
  providers: [OvAutoService],
})
export class InventoryLocationModule {}
