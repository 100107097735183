import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { ActiveLoadListComponent } from './active-load-list/active-load-list.component';
import { CompletedLoadListComponent } from './completed-load-list/completed-load-list.component';
import { LoadDetailContainerComponent } from "./load-detail-container/load-detail-container.component";

export const LoadManagementRoutes: Routes = [
  {
    path: '',
    children: [
      { path: '', redirectTo: '/load-management/active', pathMatch: 'full' },
      {
        canActivate: [AuthGuard],
        path: 'completed',
        component: CompletedLoadListComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'active',
        component: ActiveLoadListComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'load',
        component: LoadDetailContainerComponent,
      },
    ],
  },
];
