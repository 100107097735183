import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { OrderModel } from '@ov-suite/models-order';
import { InvoiceLineModel } from './invoice-line.model';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { User } from '@sentry/types';
import { UserModel } from '@ov-suite/models-idm';

@OVEntity({ name: 'Invoice' })
export class InvoiceModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: 'date-time' })
  commitDate: Date;

  @OVField({ type: 'string' })
  invNum: string;

  @OVField({ type: 'string' })
  invoiceException: string;

  @OVField({ type: 'string' })
  deliveryNote: string;

  @OVField({ type: () => [InvoiceLineModel] })
  invoiceItems: InvoiceLineModel[];

  @OVField({ type: 'number' })
  loadId: number;

  @OVField({ type: () => UserModel })
  modifiedByUser: UserModel;

  @OVField({ type: () => OrderModel })
  order: OrderModel;
}
