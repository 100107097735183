import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CleanTableComponent, DropdownItem, MatCustomDataSource, MultiSelectDropdownComponent } from '@ov-suite/ui';
import { StagingItemModel, ProductSkuModel } from '@ov-suite/models-admin';
import { ColumnData, PickStageStatus } from '@ov-suite/helpers-shared';
import { ConfirmationDialogData, ConfirmationDialogService, OvAutoService } from '@ov-suite/services';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import gql from 'graphql-tag';
import { LoadManagementDataService } from '../load-management.data.service';
import { AddStagingItemModalComponent } from './add-staging-item-modal/add-staging-item-modal.component';
import { ConfirmationComponent } from '../confirmation-dialog/confirmation.component';
import { filterEvery, filterMap, filterSet } from '../../../helpers/pick-stage-tab.helpers';

function cleanEnum(input: string) {
  const output = input.toLowerCase();
  return output
    .split('_')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

@Component({
  selector: 'ov-suite-stage-tab',
  templateUrl: './stage-tab.component.html',
  styleUrls: ['./stage-tab.component.css'],
})
export class StageTabComponent implements OnInit, AfterViewInit {
  @ViewChild('cancellableStageTable') cancellableStageTable: TemplateRef<unknown>;

  @ViewChild('resettableStageTable') resettableStageTable: TemplateRef<unknown>;

  @ViewChild('pausableStageTable') pausableStageTable: TemplateRef<unknown>;

  @ViewChild('stagingItemLogHistoryDialog') stagingItemLogHistoryDialog: TemplateRef<unknown>;

  statusList: DropdownItem<PickStageStatus>[] = [];

  productList: DropdownItem<ProductSkuModel>[] = [];

  statusCounts: Record<string, number> = {};

  productCounts: Record<number, number> = {};

  productMap = new Map<number, ProductSkuModel>();

  columnData: ColumnData<StagingItemModel>[] = [
    {
      title: 'Product',
      type: 'deep-string',
      key: 'productSku.name',
    },
    {
      type: 'number',
      title: 'Priority',
      key: 'priority',
    },
    {
      type: 'number',
      title: 'Quantity Staged',
      key: 'quantityStaged',
    },
    {
      type: 'number',
      title: 'Quantity Reserved',
      key: 'quantityReserved',
    },
    {
      type: 'number',
      title: 'Total Quantity',
      key: 'quantity',
    },
    {
      title: 'Status',
      type: 'other',
      action: stageItem => {
        switch (stageItem.stageStatus) {
          default:
            return cleanEnum(stageItem.stageStatus);
        }
      },
      keys: ['stageStatus'],
    },
    {
      title: 'Actions',
      type: 'button-bar',
      disableSorting: true,
      buttons: [
        {
          icon: 'fa fa-eye',
          actionType: 'custom',
          classes: 'btn btn-dark btn-sm mx-1',
          action: item => {
            this.viewStagingItemLogDialog(item.id);
          },
        },
      ],
      keys: [],
    },
  ];

  selected: Set<StagingItemModel> = new Set();

  stageSubscription: Subscription;

  currentStatusFilters: Record<string, boolean> = {};

  currentProductFilters: Record<number, boolean> = {};

  dataSource: MatCustomDataSource<StagingItemModel> = new MatCustomDataSource<StagingItemModel>([]);

  cancellableStageItems$ = new BehaviorSubject<Set<StagingItemModel>>(new Set());

  cancellableStageItemsDataSource = new MatCustomDataSource<StagingItemModel>([]);

  resettableStageItems$ = new BehaviorSubject<Set<StagingItemModel>>(new Set());

  resettableStageItemsDataSource = new MatCustomDataSource<StagingItemModel>([]);

  pausableStageItems$ = new BehaviorSubject<Set<StagingItemModel>>(new Set());

  pausableStageItemsDataSource = new MatCustomDataSource<StagingItemModel>([]);

  @ViewChild('stagingTable') stagingTable: CleanTableComponent<StagingItemModel>;

  @ViewChild('productFilter') productFilter: MultiSelectDropdownComponent<ProductSkuModel>;

  @ViewChild('statusFilter') statusFilter: MultiSelectDropdownComponent<PickStageStatus>;

  constructor(
    public loadManagementDataService: LoadManagementDataService,
    private readonly ovAutoService: OvAutoService,
    private readonly dialogService: ConfirmationDialogService,
    public dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
    this.initData();

    this.cancellableStageItems$.subscribe(stagingItems => {
      this.cancellableStageItemsDataSource.data = [...stagingItems];
    });
    this.resettableStageItems$.subscribe(stagingItems => {
      this.resettableStageItemsDataSource.data = [...stagingItems];
    });
    this.pausableStageItems$.subscribe(stagingItems => {
      this.pausableStageItemsDataSource.data = [...stagingItems];
    });
  }

  initData() {
    this.stageSubscription?.unsubscribe();

    this.dataSource.sortData = (unused, matSort) => {
      if (matSort.active) {
        return this.filterData(this.loadManagementDataService.stagingItems.value, matSort);
      }

      return unused;
    };
    this.stageSubscription = this.loadManagementDataService.stagingItems.subscribe(value => {
      this.dataSource.data = this.filterData(value);
    });
  }

  ngOnInit(): void {
    this.loadManagementDataService.stagingItems.subscribe(stagingItems => {
      this.setupFilters(stagingItems);
    });
  }

  filterData(stagingItems: StagingItemModel[], sort?: MatSort): StagingItemModel[] {
    const output = this.applyFilters(stagingItems);

    if (sort && sort.direction) {
      const up = sort.direction === 'asc' ? 1 : -1;
      switch (sort.active) {
        case 'Product':
          output.sort((a, b) => (a.productSku.name.toLowerCase() > b.productSku.name.toLowerCase() ? up : -up));
          break;
        case 'Priority':
          output.sort((a, b) => (a.priority > b.priority ? up : -up));
          break;
        case 'Quantity Staged':
          output.sort((a, b) => (a.quantityStaged > b.quantityStaged ? up : -up));
          break;
        case 'Quantity Reserved':
          output.sort((a, b) => (a.quantityReserved > b.quantityReserved ? up : -up));
          break;
        case 'Total Quantity':
          output.sort((a, b) => (a.quantity > b.quantity ? up : -up));
          break;
        case 'Status':
          output.sort((a, b) => (a.stageStatus > b.stageStatus ? up : -up));
          break;
        default:
      }
    }

    return output;
  }

  applyFilters(stagingItems: StagingItemModel[]): StagingItemModel[] {
    return stagingItems.filter(stageItem => {
      let shouldFilter = true;
      if (Object.values(this.currentStatusFilters).length) {
        shouldFilter = shouldFilter && !!this.currentStatusFilters[stageItem.stageStatus];
      }
      if (Object.values(this.currentProductFilters).length) {
        shouldFilter = shouldFilter && !!this.currentProductFilters[stageItem.productSku.id];
      }
      return shouldFilter;
    });
  }

  openAddModal() {
    const dialogRef = this.dialog.open(AddStagingItemModalComponent, {
      data: {
        loadId: this.loadManagementDataService.load.value.id,
        stagingItems: this.dataSource.data,
      },
    });
    dialogRef.afterClosed().subscribe(async refresh => {
      if (refresh) {
        await this.loadManagementDataService.getStagingItems();
      }
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Cancel',
        text: `Are you sure you want to cancel the selected stage items? (${this.cancellableStageItems$.value.size} Total)`,
        component: this.cancellableStageTable,
      },
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const stageIds = filterMap(this.cancellableStageItems$.value, stage => stage.id);

        this.ovAutoService.apollo
          .mutate({
            mutation: gql`
              mutation cancelStagingItems($stageIds: [Int!]!) {
                cancelStagingItems(stageIds: $stageIds)
              }
            `,
            variables: {
              stageIds: [...stageIds],
            },
          })
          .subscribe(async () => {
            this.stagingTable.clearSelection();
            await this.loadManagementDataService.getStagingItems();
          });
      }
    });
  }

  reset() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Reset',
        text: `Are you sure you want to reset the selected stage items? (${this.resettableStageItems$.value.size} Total)`,
        component: this.resettableStageTable,
      },
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const stageIds = filterMap(this.resettableStageItems$.value, stage => stage.id);
        this.ovAutoService.apollo
          .mutate({
            mutation: gql`
              mutation resetStagingItems($stageIds: [Int!]!) {
                resetStagingItems(stageIds: $stageIds)
              }
            `,
            variables: {
              stageIds: [...stageIds],
            },
          })
          .subscribe(async () => {
            this.stagingTable.clearSelection();
            await this.loadManagementDataService.getStagingItems();
          });
      }
    });
  }

  pause() {
    const shouldUnpause = this.shouldUnpause();
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: shouldUnpause ? 'Unpause' : 'Pause',
        text: `Are you sure you want to ${shouldUnpause ? 'unpause' : 'pause'} the selected stage items? (${
          this.pausableStageItems$.value.size
        } Total)`,
        component: this.pausableStageTable,
      },
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const stageIds = filterMap(this.pausableStageItems$.value, stagingItem => stagingItem.id);
        this.ovAutoService.apollo
          .mutate({
            mutation: gql`
            mutation pauseStagingItems($stageIds: [Int!]!) {
              ${shouldUnpause ? 'unpauseStagingItems' : 'pauseStagingItems'}(stageIds: $stageIds)
            }
          `,
            variables: {
              stageIds: [...stageIds],
            },
          })
          .subscribe(async () => {
            this.stagingTable.clearSelection();
            await this.loadManagementDataService.getStagingItems();
          });
      }
    });
  }

  shouldUnpause(): boolean {
    if (!this.pausableStageItems$.value.size) {
      return false;
    }
    return filterEvery(
      this.pausableStageItems$.value,
      stage => stage.stageStatus === PickStageStatus.PAUSED || stage.stageStatus === PickStageStatus.MANUALLY_PAUSED,
    );
  }

  setupFilters(stageItems: StagingItemModel[]) {
    this.statusCounts = {};
    this.productCounts = {};
    stageItems.forEach(stageItem => {
      this.statusCounts[stageItem.stageStatus] ??= 0;
      this.statusCounts[stageItem.stageStatus] += 1;

      this.productCounts[stageItem.productSku.id] = stageItem.quantity;

      this.productMap.set(stageItem.productSku.id, stageItem.productSku);
    });
    this.statusList = Object.entries(this.statusCounts).map(([status, count]) => {
      return { label: `${cleanEnum(status)} (${count})`, value: status as PickStageStatus };
    });
    this.productList = Object.entries(this.productCounts).map(([productId, count]) => {
      const product = this.productMap.get(Number(productId));
      return { label: `${product.name} (${count})`, value: product };
    });
  }

  setStatusFilter(statuses: PickStageStatus[]) {
    this.currentStatusFilters = {};
    statuses.forEach(status => {
      this.currentStatusFilters[status] = true;
    });
    this.initData();
  }

  setProductFilters(productSkus: ProductSkuModel[]) {
    this.currentProductFilters = {};
    productSkus.forEach(sku => {
      this.currentProductFilters[sku.id] = true;
    });
    this.initData();
  }

  clearFilters() {
    this.currentStatusFilters = {};
    this.currentProductFilters = {};
    this.productFilter.clearSelection();
    this.statusFilter.clearSelection();
    this.initData();
  }

  setSelected(stagingItems: Set<StagingItemModel>) {
    const cancellableStagingItems = filterSet(
      stagingItems,
      stageItem => stageItem.stageStatus === 'READY' || stageItem.stageStatus === 'IN_PROGRESS',
    );
    const resettableStagingItems = filterSet(
      stagingItems,
      stageItem => stageItem.stageStatus !== 'READY' && stageItem.stageStatus !== 'IN_PROGRESS' && stageItem.stageStatus !== 'RESET',
    );
    const pausableStagingItems = filterSet(stagingItems, stagingItem => {
      switch (stagingItem.stageStatus) {
        case PickStageStatus.READY:
        case PickStageStatus.IN_PROGRESS:
        case PickStageStatus.PAUSED:
        case PickStageStatus.MANUALLY_PAUSED:
          return true;
        default:
          return false;
      }
    });
    this.selected = stagingItems;
    this.cancellableStageItems$.next(cancellableStagingItems);
    this.resettableStageItems$.next(resettableStagingItems);
    this.pausableStageItems$.next(pausableStagingItems);
  }

  viewStagingItemLogDialog(stagingId: number) {
    this.loadManagementDataService.fetchStagingItemLog(stagingId);

    const confirmationDialogData: ConfirmationDialogData = {
      title: 'Staging Item Log',
      component: this.stagingItemLogHistoryDialog,
      width: 500,
    };

    this.dialogService.openComponentDialog(confirmationDialogData);
  }
}
