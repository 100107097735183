import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { PickStageStatus } from '@ov-suite/helpers-shared';
import { InventoryContainer, LoadAllocation } from '@ov-suite/models-warehouse';
import { OrderItemModel } from '@ov-suite/models-order';
import { Inventory, InventoryLocationModel, ProductSkuModel } from '@ov-suite/models-admin';
import { UserModel } from '@ov-suite/models-idm';

@OVEntity({ name: 'PickingItem' })
export class PickingItemModel {
  @OVField({
    type: 'number',
    generated: true,
  })
  id: number;

  @OVField({ type: 'string' })
  pickStatus: PickStageStatus;

  @OVField({ type: 'number' })
  priority: number; // Lower = more urgent

  @OVField({ type: 'number' })
  loadId: number;

  @OVField({ type: () => LoadAllocation })
  load: LoadAllocation;

  @OVField({ type: () => OrderItemModel })
  orderItem: OrderItemModel;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'number' })
  quantityPicked: number;

  @OVField({ type: 'number' })
  quantityReserved: number;

  @OVField({ type: 'number' })
  productSkuId: number;

  @OVField({ type: () => ProductSkuModel })
  productSku?: ProductSkuModel;

  @OVField({ type: 'date' })
  createdDate: Date;

  @OVField({ type: 'date' })
  startDate?: Date;

  @OVField({ type: 'date' })
  completedDate?: Date;

  @OVField({ type: 'date' })
  reservationDate?: Date;

  @OVField({ type: 'date' })
  pauseDate?: Date;

  @OVField({ type: 'string' })
  pickProcessId?: string;

  @OVField({ type: () => UserModel })
  createdByUser?: UserModel;

  @OVField({ type: () => UserModel })
  handledByUser?: UserModel;

  @OVField({ type: 'string' })
  exceptionMessage?: string;

  @OVField({ type: 'boolean' })
  generated?: boolean;

  @OVField({ type: () => InventoryContainer })
  containerPicked?: InventoryContainer;

  @OVField({ type: () => Inventory })
  inventoryPicked?: Inventory;

  @OVField({ type: 'number' })
  sourceId: number;

  @OVField({ type: () => InventoryLocationModel })
  source: InventoryLocationModel;

  @OVField({ type: 'number' })
  targetId: number;

  @OVField({ type: () => InventoryLocationModel })
  target: InventoryLocationModel;
}
