import { Component } from '@angular/core';

@Component({
  selector: 'ov-suite-empty-wave-list',
  templateUrl: './empty-wave-list.component.html',
  styleUrls: ['./empty-wave-list.component.scss'],
})
export class EmptyWaveListComponent {
  constructor() {}
}
