import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SplitComponent } from 'angular-split';
import { Subscription } from 'rxjs';
import { OvAutoService } from '@ov-suite/services';
import { v4 } from 'uuid';
import swal from 'sweetalert2';
import { ColumnData } from '@ov-suite/helpers-shared';
import { MatCustomDataSource } from '@ov-suite/ui';
import { LoadAuthenticationSessionModel } from '@ov-suite/models-warehouse';
import { MapComponent } from '../map/map.component';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { LoadAllocationActionService } from '../../services/load-allocation.action.service';
import { LoadAllocationDataService } from '../../services/load-allocation.data.service';
import { LoadAllocationVehicleLayoutService } from '../../services/load-allocation.vehicle-layout.service';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAuthenticationSessionsService } from '../../services/load-authentication-sessions.service';

@Component({
  selector: 'ov-suite-load-allocation-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements AfterViewInit, OnInit, OnDestroy {
  pageInUse = false;

  windowId: string;

  sessionIdleExpiry = 900000; // 15 minutes

  @ViewChild('leftSide', { static: false }) leftSide: SplitComponent;

  @ViewChild('rightSide', { static: false }) rightSide: SplitComponent;

  @ViewChild('vertical', { static: false }) vertical: SplitComponent;

  @ViewChild('map') mapComponent: MapComponent;

  horizontalSplit = 70;

  leftSideSplit = 50;

  rightSideSplit = 50;

  sub: Subscription;

  constructor(
    public base: LoadAllocationBaseService,
    private readonly data: LoadAllocationDataService,
    private readonly vehicleLayout: LoadAllocationVehicleLayoutService,
    public action: LoadAllocationActionService,
    public viewService: LoadAllocationViewService,
    private readonly ovAutoService: OvAutoService,
    private readonly sessionsService: LoadAuthenticationSessionsService,
  ) {
    this.data.startUp();
    this.action.startUp();
    this.base.startUp();
  }

  dataSource: MatCustomDataSource<LoadAuthenticationSessionModel> = new MatCustomDataSource<LoadAuthenticationSessionModel>([]);

  columnData: ColumnData<LoadAuthenticationSessionModel>[] = [
    {
      key: 'user.firstName',
      title: 'First Name',
      type: 'deep-string',
    },
    {
      key: 'user.lastName',
      title: 'Last Name',
      type: 'deep-string',
    },
    {
      key: 'createdDate',
      title: 'Logged In',
      type: 'date-time',
    },
    {
      key: 'updatedDate',
      title: 'Last Used',
      type: 'date-time',
    },
  ];

  async ngOnInit() {
    await this.validateSession();

    const horizontalSplit = localStorage.getItem('la_horizontalSplit');
    this.horizontalSplit = horizontalSplit ? Number(horizontalSplit) : 70;

    const leftSideSplit = localStorage.getItem('la_leftSideSplit');
    this.leftSideSplit = leftSideSplit ? Number(leftSideSplit) : 50;

    const rightSideSplit = localStorage.getItem('la_rightSideSplit');
    this.rightSideSplit = rightSideSplit ? Number(rightSideSplit) : 50;
  }

  ngAfterViewInit() {
    this.vertical.dragEnd.subscribe(prog => {
      this.horizontalSplit = prog.sizes[0] as number;
      localStorage.setItem('la_horizontalSplit', this.horizontalSplit.toString());
    });
    this.leftSide.dragEnd.subscribe(prog => {
      this.leftSideSplit = prog.sizes[0] as number;
      localStorage.setItem('la_leftSideSplit', this.leftSideSplit.toString());
    });
    this.rightSide.dragEnd.subscribe(prog => {
      this.rightSideSplit = prog.sizes[0] as number;
      localStorage.setItem('la_rightSideSplit', this.rightSideSplit.toString());
    });
  }

  async ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
    this.data.flush();
    this.action.flush();
    this.base.flush();

    window.name = '';

    // eslint-disable-next-line no-prototype-builtins
    if (sessionStorage.hasOwnProperty('lockedSession')) {
      await this.sessionsService.unlockSessionById(this.windowId);
    }

    await this.ovAutoService.apollo.client.cache.reset();
  }

  async validateSession() {
    if (window.name) {
      this.windowId = window.name;
    } else {
      this.windowId = v4();
    }

    window.name = this.windowId;

    const activatedSession = await this.sessionsService.getSessions();

    // There are no sessions.
    if (activatedSession.length === 0) {
      await this.createSession();
    } else {
      // There is a session.
      const aSession = activatedSession[0];
      const curTime = new Date().getTime();
      const lastUpdateTime = new Date(aSession.updatedDate).getTime();

      // Check is session belongs to this user:
      if (aSession.id === window.name) {
        // Session belongs to this user
        sessionStorage.setItem('lockedSession', this.windowId);
      } else if (curTime - lastUpdateTime > this.sessionIdleExpiry) {
        // Old users session expired. Give session to this user.
        await this.createSession();
      } else {
        // Old users session is still active.
        this.pageInUse = true;
        this.dataSource.data = activatedSession;
      }
    }
  }

  async createSession() {
    const session = await this.sessionsService.lockSession(window.name);
    sessionStorage.setItem('lockedSession', session.id);
  }

  async takeControl() {
    swal
      .fire({
        title: 'Are you sure?',
        text: 'This will kick out the active user!',
        type: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
      })
      .then(async isConfirm => {
        if (isConfirm.value) {
          await this.sessionsService.unlockSession();
          window.location.reload();
        }
      });
  }
}
