import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { HierarchyTab } from '@ov-suite/ui';
import { OvAutoService, OvAutoServiceMultipleMutationParams } from '@ov-suite/services';
import { Router } from '@angular/router';
import { VehicleOverride, VehicleResource } from '@ov-suite/models-warehouse';
import { Factory } from '@ov-suite/models-admin';
import { TableTopTabMenuItem } from '../../../components/table-top-tab-menu/table-top-tab-menu.model';
import { getWebCrud } from '@ov-suite/authguard-angular';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

@Component({
  selector: 'ov-suite-vehicle-resource-list',
  templateUrl: './vehicle-resource-list.component.html',
  styleUrls: ['./vehicle-resource-list.component.scss'],
})
export class VehicleResourceListComponent implements OnInit {
  formClass = VehicleOverride;

  filter: Record<string, unknown[]>;

  currentData: VehicleOverride[] = [];

  selected: VehicleOverride[];

  bulkChanges: Record<string, unknown>;

  reFetch = 0;

  dropdownData: Record<string, unknown>;

  tabs: HierarchyTab[] = [
    { title: 'Picking Waves', path: '/waves' },
    { title: 'Vehicles', path: '/vehicles', ignoreSubPath: true },
    { title: 'Routes', path: '/master-route' },
  ];

  tableTabs: TableTopTabMenuItem[] = [
    { title: 'All', path: '/vehicles', key: 'all' },
    { title: 'Templates', path: '/vehicles/templates', key: 'templates' },
    { title: 'Exceptions', path: '/vehicles/exception', key: 'exception' },
  ];

  canUpdate = true;

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    public readonly ovAutoService: OvAutoService,
    private readonly ngZone: NgZone,
    private readonly route: Router,
    private readonly authenticationService: AuthenticationService,
  ) {
    const user = authenticationService.user.value;
    getWebCrud(user, FeatureKey.VEHICLE_RESOURCES).then(crud => {
      this.canUpdate = crud.update;
    });
  }

  ngOnInit(): void {
    // editable table, dropdowns information
    this.ovAutoService
      .list({
        search: {},
        filter: {},
        query: {},
        entity: Factory,
        limit: 1000,
        offset: null,
        orderDirection: 'DESC',
        orderColumn: 'id',
        keys: ['id', 'name'],
      })
      .then(result => {
        this.dropdownData = {
          endLocation: result.data,
          startLocation: result.data,
        };
      })
      .catch(err => console.error(err));
  }

  editChanges(event) {
    this.currentData = event;
  }

  transformData(data: VehicleOverride[]): VehicleResource[] {
    const changedData = [];
    data.forEach(row => {
      const newObject = {
        available: row?.resource?.available,
        endLocationId: row?.resource?.endLocation?.id,
        startLocationId: row?.resource?.startLocation?.id,
        startTime: row?.resource?.startTime,
        endTime: row?.resource?.endTime,
        id: row?.resource?.id,
        vehicleId: row?.id,
      };

      changedData.push(newObject);
    });

    return changedData;
  }

  onSelectItem(event) {
    this.selected = event;
  }

  onBulkDataChanges(changes) {
    this.bulkChanges = changes;
  }

  onApplyBulk() {
    this.ngZone.run(() => {
      this.selected &&
        this.selected.forEach(vehicle => {
          vehicle['resource']['startTime'] = this.bulkChanges['startTime']
            ? (this.bulkChanges['startTime'] as string)
            : vehicle['resource']['startTime'];
          vehicle['resource']['endTime'] = this.bulkChanges['endTime']
            ? (this.bulkChanges['endTime'] as string)
            : vehicle['resource']['endTime'];
          vehicle['resource']['startLocation'] = this.bulkChanges['startLocation']
            ? (this.bulkChanges['startLocation'] as Factory)
            : vehicle['resource']['startLocation'];
          vehicle['resource']['endLocation'] = this.bulkChanges['endLocation']
            ? (this.bulkChanges['endLocation'] as Factory)
            : vehicle['resource']['endLocation'];
          vehicle['isSelected'] = false;
        });
    });
  }

  onSaveChanges() {
    const result = this.transformData(this.currentData);
    let createVehicleResources: OvAutoServiceMultipleMutationParams = {};
    let updateVehicleResources: OvAutoServiceMultipleMutationParams = {};

    result.forEach(resource => {
      const { vehicle, ...vehicleResource } = resource;

      if (resource.id) {
        updateVehicleResources = {
          ...updateVehicleResources,
          [`vehicleResource${resource['vehicleId']}`]: {
            type: 'update',
            entity: VehicleResource,
            item: vehicleResource,
            keys: ['startTime', 'endTime'],
          },
        };
      } else {
        createVehicleResources = {
          ...createVehicleResources,
          [`vehicleResource${resource['vehicleId']}`]: {
            type: 'create',
            entity: VehicleResource,
            item: vehicleResource,
            keys: ['startTime', 'endTime'],
          },
        };
      }
    });

    if (Object.keys(createVehicleResources).length > 0 || Object.keys(updateVehicleResources).length > 0) {
      this.ovAutoService.multipleMutation({ ...createVehicleResources, ...updateVehicleResources }).then(response => {
        if (response) {
          this.reFetch++;
          this.currentData = [];
        }
      });
    }
  }

  onTabClick(key) {
    const tab = this.tableTabs.find(t => t.key === key);
    this.route.navigate([tab.path]);
  }
}
