import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';
import { MainComponent } from './quadrants/main/main.component';
import { LoadAllocationPrintComponent } from './components/print/load-allocation-print.component';

export const LoadAllocationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: FeatureKey.LOAD_ALLOCATION } },
        canActivate: [AuthGuard],
        path: '',
        component: MainComponent,
      },
      {
        path: 'print/:id',
        component: LoadAllocationPrintComponent,
      },
    ],
  },
];
