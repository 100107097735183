import process from 'process';

const host = process.env.SUPERVISOR_ENABLED ? 'host.docker.internal' : 'localhost';
const isDev = !!(!process.env.ENVIRONMENT || !(process.env.DNS_ALIAS || process.env.CLOUD_MAP_NAMESPACE_NAME));
const devInventory = isDev ? 'https://sqs.af-south-1.amazonaws.com/750942652675/linksuite-dev-inventory-report-service.fifo' : null;
const devProductSku = isDev ? 'https://sqs.af-south-1.amazonaws.com/750942652675/linksuite-dev-inventory-report-service.fifo' : null;
const devInventoryLocation = isDev ? 'https://sqs.af-south-1.amazonaws.com/750942652675/linksuite-dev-inventory-report-service.fifo' : null;
const devInventoryThreshold = isDev
  ? 'https://sqs.af-south-1.amazonaws.com/750942652675/linksuite-dev-inventory-report-service.fifo'
  : null;
const devInventoryReport = isDev ? 'https://sqs.af-south-1.amazonaws.com/750942652675/linksuite-dev-inventory-report-service.fifo' : null;
// Todo: Remove unused Keys
export const apiEnvironment = {
  environment: process.env.ENVIRONMENT ?? 'local',
  dev: isDev,
  isLambda: !!process.env.AWS_LAMBDA_FUNCTION_NAME,
  dnsAlias: process.env.DNS_ALIAS,
  customer: process.env.CUSTOMER ?? 'ov',
  sentryDSN: process.env.SENTRY_DSN,
  jwt: {
    secret: process.env.JWT_SECRET ?? 'dev-secret',
  },
  port: {
    idm: process.env.IDM_API_PORT ? Number(process.env.IDM_API_PORT) : 3332,
    admin: process.env.ADMIN_API_PORT ? Number(process.env.ADMIN_API_PORT) : 3333,
    warehouse: process.env.WAREHOUSE_API_PORT ? Number(process.env.WAREHOUSE_API_PORT) : 3334,
    yard: process.env.YARD_API_PORT ? Number(process.env.YARD_API_PORT) : 3335,
    execution: process.env.EXECUTION_API_PORT ? Number(process.env.EXECUTION_API_PORT) : 3336,
    ceramicPortal: process.env.CERAMIC_PORTAL_API_PORT ? Number(process.env.CERAMIC_PORTAL_API_PORT) : 3337,
    order: process.env.ORDER_API_PORT ? Number(process.env.ORDER_API_PORT) : 3338,
  },

  microServicePort: {
    idm: process.env.IDM_MICRO_PORT ? Number(process.env.IDM_MICRO_PORT) : 2332,
    admin: process.env.ADMIN_MICRO_PORT ? Number(process.env.ADMIN_MICRO_PORT) : 2333,
    warehouse: process.env.WAREHOUSE_MICRO_PORT ? Number(process.env.WAREHOUSE_MICRO_PORT) : 2334,
    yard: process.env.YARD_MICRO_PORT ? Number(process.env.YARD_MICRO_PORT) : 2335,
    execution: process.env.EXECUTION_MICRO_PORT ? Number(process.env.EXECUTION_MICRO_PORT) : 2336,
    ceramic: process.env.CERAMIC_PORTAL_MICRO_PORT ? Number(process.env.CERAMIC_PORTAL_MICRO_PORT) : 2337,
    order: process.env.ORDER_MICRO_PORT ? Number(process.env.ORDER_MICRO_PORT) : 2338,
  },
  database: {
    host: process.env.DATABASE_HOST ?? `${host}`,
    username: process.env.DATABASE_USERNAME ?? 'postgres',
    password: process.env.DATABASE_PASSWORD ?? 'postgres',
    idmLinkDatabase: process.env.DATABASE_IDM ?? 'idmlink',
    adminLinkDatabase: process.env.DATABASE_ADMIN ?? 'adminlink',
    warehouseLinkDatabase: process.env.DATABASE_WAREHOUSE ?? 'warehouselink',
    yardLinkDatabase: process.env.DATABASE_YARD ?? 'yardlink',
    executionLinkDatabase: process.env.DATABASE_EXECUTION ?? 'executionlink',
    ceramicPortalDatabase: process.env.DATABASE_CERAMIC ?? 'ceramicportal',
    orderLinkDatabase: process.env.DATABASE_ORDER ?? 'orderlink',
    prefix: process.env.DATABASE_PUB_PREFIX ?? process.env.DATABASE_PREFIX ?? 'local',
  },
  flowable: {
    warehouse: {
      username: process.env.FLOWABLE_WAREHOUSE_USERNAME ?? 'devops-warehouse',
      password: process.env.FLOWABLE_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_WAREHOUSE_URL ?? 'https://dev.flowable.ovsuite.com/flowable-ui',
    },
    yard: {
      username: process.env.FLOWABLE_YARD_USERNAME ?? 'devops-yard',
      password: process.env.FLOWABLE_PASSWORD ?? 'OpenV104',
      url: process.env.FLOWABLE_YARD_URL ?? 'https://dev.flowable.ovsuite.com/flowable-ui',
    },
  },
  url: {
    idm: process.env.IDM_URL ?? `http://${host}:3332`,
    admin: process.env.ADMIN_URL ?? `http://${host}:3333`,
    warehouse: process.env.WAREHOUSE_URL ?? `http://${host}:3334`,
    yard: process.env.YARD_URL ?? `http://${host}:3335`,
    execution: process.env.EXECUTION_URL ?? `http://${host}:3336`,
    ceramicPortal: process.env.CERAMIC_PORTAL_URL ?? `http://${host}:3337`,
    order: process.env.ORDER_URL ?? `http://${host}:3338`,
  },
  cognito: {
    federated: process.env.COGNITO_FEDERATED ? process.env.COGNITO_FEDERATED === 'true' : false,
    region: process.env.COGNITO_REGION ?? `eu-central-1`,
    identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID ?? 'eu-central-1:3a3ebdc4-c8b7-4aac-839f-2336f4c43578',
    userPoolId: process.env.COGNITO_USER_POOL_ID ?? `eu-central-1_lH5BYgGC6`,
    accessKey: process.env.COGNITO_ACCESS_KEY_ID ?? `AKIA25V5LREB2UOALDPO`,
    secretAccessKey: process.env.COGNITO_SECRET_ACCESS_KEY ?? `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`,
    temporaryPassword: process.env.TEMPORARY_PASSWORD ?? 'Password123',
    minimumPasswordLength: process.env.MINIMUMN_PASSWORD_LENGTH ?? 12,
    emailDelivery: process.env.COGNITO_EMAIL_DELIVERY ? process.env.COGNITO_EMAIL_DELIVERY === 'true' : true,
    smsDelivery: process.env.COGNITO_SMS_DELIVERY ? process.env.COGNITO_SMS_DELIVERY === 'true' : true,
    clientId: process.env.COGNITO_CLIENT_ID ?? '1iuorn2s7t84iaeeds5d6hnkkh',
    bucket: process.env.COGNITO_BUCKET ?? 'ovsuitebucketdevelop-develop',
    bucketRegion: process.env.COGNITO_BUCKET_REGION ?? 'https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev',
  },
  other: {
    awsLambdaTokenSecretKey: process.env.LAMBDA_TOKEN_SECRET_KEY ?? 'the!super-classified-secret3#code',
    awsCeramicDBSyncApi: process.env.CERAMIC_DBSYNC_SERVICE_API ?? `${host}/dev/sync`,
    awsCeramicDBSyncApiKey: process.env.CERAMIC_DB_SYNC_API_KEY ?? '',
    googleMapApiKey: process.env.MAP_API_KEY ?? 'AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs',
    redis: {
      url: process.env.REDIS_URL ?? `redis://${host}:6379`,
    },
  },
  workflow: {
    pickSlip: process.env.WORKFLOW_PICKSLIP ?? 'wh-pick-sheet',
  },
  aws: {
    region: process.env.AWS_REGION ?? `af-south-1`,
    accessKey: process.env.COGNITO_ACCESS_KEY_ID ?? `AKIA25V5LREB2UOALDPO`,
    secretAccessKey: process.env.COGNITO_SECRET_ACCESS_KEY ?? `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`,
  },
  cloudMap: {
    namespaceId: process.env.CLOUD_MAP_NAMESPACE_ID ?? 'ns-w2jx7wsjwcnbif5b',
    namespaceName: process.env.CLOUD_MAP_NAMESPACE_NAME ?? 'dev',
  },
  gatewayURL: process.env.GATEWAY_URL ?? 'http://c41d-102-130-109-37.ngrok-free.app',
  // gatewayURL: process.env.GATEWAY_URL ?? 'http://localhost:3300',
  lambda: {
    format: process.env.LAMBDA_FORMAT ?? 'linksuite-dev-*-service',
  },
  sqs: {
    tableTemplate: process.env.SQS_TABLE_TEMPLATE ?? 'linksuite-dev-*',
    inventory: process.env.SQS_INVENTORY ?? devInventory,
    productSku: process.env.SQS_PRODUCT_SKU ?? devProductSku,
    inventoryLocation: process.env.SQS_INVENTORY_LOCATION ?? devInventoryLocation,
    inventoryThreshold: process.env.SQS_INVENTORY_THRESHOLD ?? devInventoryThreshold,
    inventoryReport: process.env.SQS_INVENTORY_REPORT ?? devInventoryReport,
  },
  mapApiKey: process.env.MAP_API_KEY ?? `AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs`,
  sendGrid: {
    apiKey: process.env.SENDGRID_API_KEY ?? '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',
    fromEmail: process.env.SENDGRID_FROM_EMAIL ?? 'noreply@openvantage.co.za',
    mobileAppIssueEmailList: process.env.MOBILE_APP_ISSUE_EMAIL_LIST ?? 'sameer@openvantage.co.za'
  },
  sage: {
    seals: process.env.CAPTURE_SAGE_SEALS ?? true,
    wraps: process.env.CAPTURE_SAGE_WRAPS ?? true,
  }
};
