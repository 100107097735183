import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { ExternalVehicle } from '@ov-suite/models-warehouse';
import { LazyService } from './load-allocation.lazy.service';

/**
 * Used to manage External Vehicle Templates in various ways
 */
@Injectable()
export class LoadAllocationExternalVehicleService extends LazyService<ExternalVehicle> {
  constructor(private readonly ovAutoService: OvAutoService) {
    super(ExternalVehicle, ovAutoService, { relations: [] });
  }
}
