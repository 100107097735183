import { Injectable } from '@angular/core';
import { OrderModel } from '@ov-suite/models-order';

@Injectable({
  providedIn: 'root',
})
export class NgOutletModalService {
  public data: OrderModel;
  constructor() {}
}
