import { Component } from '@angular/core';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

@Component({
  selector: 'ov-suite-empty-vehicle-list',
  templateUrl: './empty-vehicle-list.component.html',
  styleUrls: ['./empty-vehicle-list.component.scss'],
})
export class EmptyVehicleListComponent {
  OpenAdminLink() {
    // navigate to Admin Link - vehicles
    window.open(`${getUrl('admin')}/vehicle`, '_blank');
  }
}
