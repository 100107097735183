import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { Geography } from '@ov-suite/helpers-shared';

@OVEntity('CustomerDeliveryAddress', 'shared')
export class DeliveryAddressModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  line1: string;

  @OVField({ type: 'string' })
  line2: string;

  @OVField({ type: 'string' })
  line3: string;

  @OVField({ type: 'string' })
  line4: string;

  @OVField({ type: 'string' })
  line5: string;

  @OVField({ type: 'string' })
  line6: string;

  @OVField({ type: 'string' })
  postalCode: string;

  @OVField({ type: 'boolean' })
  isDefault: boolean;

  private _geography: Geography;

  @OVField({ type: 'string' })
  set geography(input: Geography) {
    if (typeof input === 'string') {
      this._geography = JSON.parse(input);
    } else {
      this._geography = input;
    }
  }

  get geography(): Geography {
    return this._geography;
  }

  getOneLiner(noneLabel = 'Address not set'): string {
    const lines = [this.line1, this.line2, this.line3, this.line4, this.line5, this.line6, this.postalCode];
    if (!lines.length) {
      return noneLabel;
    }
    return lines.filter(l => !!l).join(', ');
  }
}
