export class Quantities {
  constructor(
    public low: number,
    public current: number,
    public high: number,
    public valid: boolean,
    public incrementValue: number,
    public increment: boolean,
  ) {}
}
