import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { OvAutoService, OvAutoServiceListParams } from '@ov-suite/services';
import { Constructor, HasId } from '@ov-suite/ov-metadata';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { QueryParams } from '@ov-suite/helpers-shared';

interface OvAutoDataSourceParams<T> {
  keys: (keyof T | string)[];
  searchMap?: Record<string, QueryParams[]>;
  filterMap?: Record<string, QueryParams[]>;
  queryMap?: Record<string, QueryParams[]>;
}

export class OvAutoDataSource<T extends HasId> implements DataSource<T> {
  private readonly _dataStream = new ReplaySubject<T[]>();

  public current = new BehaviorSubject<T[]>([]);

  private pageEvent: PageEvent = {
    pageSize: 10,
    pageIndex: 0,
    length: 0,
  };

  private sortEvent: Sort = {
    active: undefined,
    direction: undefined,
  };

  private readonly params: OvAutoDataSourceParams<T>;

  private sort: MatSort | null = null;

  private paginator: MatPaginator = null;

  constructor(
    private readonly model: Constructor<T>,
    private readonly ovAutoService: OvAutoService,
    params: Partial<OvAutoDataSourceParams<T>> = {},
  ) {
    this._dataStream.next([]);
    // Params
    this.params = {
      keys: params.keys ?? [],
      queryMap: params.queryMap,
      filterMap: params.filterMap,
      searchMap: params.searchMap,
    };
  }

  public init(sort: MatSort, paginator: MatPaginator, startPage = 0) {
    this.sort = sort;
    this.paginator = paginator;
    if (startPage) {
      this.paginator.pageIndex = startPage;
    }
    // Paginator
    this.pageEvent = {
      pageIndex: startPage ?? 0,
      length: 0,
      pageSize: paginator.pageSize,
    };
    paginator.page.subscribe(pageEvent => {
      this.pageEvent = pageEvent;
      this.getData();
    });
    // Sort
    sort.sortChange.subscribe(sortEvent => {
      this.sortEvent = sortEvent;
      this.getData();
    });
    this.getData();
  }

  async getData() {
    let orderDirection: 'ASC' | 'DESC';
    if (this.sortEvent.direction === 'asc') orderDirection = 'ASC';
    else if (this.sortEvent.direction === 'desc') orderDirection = 'DESC';

    const baseParams: OvAutoServiceListParams<T> = {
      entity: this.model,
      limit: this.pageEvent.pageSize,
      offset: this.pageEvent.pageIndex * this.pageEvent.pageSize,
      orderColumn: this.sortEvent.active,
      orderDirection,
      keys: this.params.keys as string[],
      search: this.params.searchMap,
      filter: this.params.filterMap,
      query: this.params.queryMap,
    };

    const { data, totalCount } = await this.ovAutoService.list(baseParams);
    this.paginator.length = totalCount;
    this._dataStream.next(data);
    this.current.next(data);
  }

  connect(): Observable<T[]> {
    return this._dataStream;
  }

  disconnect(): void {
    // Required by material Tables, called when table is destroyed
  }

  async setKeys(keys: string[], skipRefresh = false) {
    this.params.keys = keys;
    if (!skipRefresh) {
      await this.getData();
    }
  }

  async setSearchMap(map: Record<string, QueryParams[]>, skipRefresh = false) {
    this.params.searchMap = map;
    if (!skipRefresh) {
      await this.getData();
    }
  }

  async setFilterMap(map: Record<string, QueryParams[]>, skipRefresh = false) {
    this.params.filterMap = map;
    if (!skipRefresh) {
      await this.getData();
    }
  }

  async setQueryMap(map: Record<string, QueryParams[]>, skipRefresh = false) {
    this.params.queryMap = map;
    if (!skipRefresh) {
      await this.getData();
    }
  }

  public async refresh() {
    await this.getData();
  }
}
