<div class="main-content">
  <ov-suite-hierarchy-tabs [tabs]="tabs"> </ov-suite-hierarchy-tabs>
  <div class="main-container">
    <ov-suite-hierarchy-table
      [title]="'Inventory by SKU'"
      [formClass]="formClass"
      [overrideColumnData]="columns"
      [filter]="filter"
      [orderColumn]="'sku'"
      [filterEnabled]="true"
      [hasBulkUpload]="false"
      [showTopBar]="true"
      [editableRows]="true"
      [hideAddButton]="true"
      [enableRefresh]="true"
    >
    </ov-suite-hierarchy-table>
  </div>
</div>
