/* eslint-disable no-console */
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { LogDetail } from '@ov-suite/helpers-shared/lib/remote-log.interface';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { AdminLayoutParams } from '@ov-suite/helpers-angular/lib/admin-layout.interface';
import axios from 'axios';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private readonly authenticationService: AuthenticationService,
    @Inject('SIDEBAR_OPTIONS') private readonly options: AdminLayoutParams,
  ) {}

  /**
   * Catches the error and sends it to the log-service
   * @param error
   */
  handleError(error: Error) {
    console.error(error);
    try {
      const user = this.authenticationService.user.value;
      const detail: LogDetail = {
        type: 'error',
        user: user?.username ?? 'unauthenticated',
        timeStamp: new Date(),
        source: `${this.options.link}link-web`,
        message: error?.message ?? JSON.stringify(error),
      };

      axios.post(`${getUrl('api')}/api/log/remote-log`, detail).catch(() => {
        // do nothing
      });
    } catch (e) {
      // do nothing
    }
  }
}
