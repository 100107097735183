<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Vehicles List</a>
    <ng-template ngbNavContent>
      <div>
        <ov-suite-hierarchy-table
          [title]="'Selected Vehicle: ' + (selectedInternalVehicle?.registration || '')"
          [formClass]="internalVehicleFormClass"
          [hasBulkUpload]="false"
          [clickableRows]="true"
          [excludeColumns]="4"
          (rowClick)="onClickInternalVehicle($event)"
          [hideAddButton]="true"
          [hasBulkExport]="false"
        >
        </ov-suite-hierarchy-table>
        <button type="button" (click)="activeModal.close()" class="btn btn-outline-primary ml-4 mb-4 float-left">Close</button>
        <button type="button" (click)="onAddInternalVehicle()" class="btn btn-outline-dark mr-4 mb-4 float-right">
          Replace Vehicle <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
        </button>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>External Vehicles List</a>
    <ng-template ngbNavContent>
      <div>
        <ov-suite-hierarchy-table
          [title]="'Selected Vehicle: ' + (selectedExternalVehicle?.registration || '')"
          [formClass]="externalVehicleFormClass"
          [hasBulkUpload]="false"
          [clickableRows]="true"
          (rowClick)="onClickExternalVehicle($event)"
          [excludeColumns]="4"
          [hideAddButton]="true"
          [hasBulkExport]="false"
        >
        </ov-suite-hierarchy-table>
        <button type="button" (click)="activeModal.close()" class="btn btn-outline-primary ml-4 mb-4 float-left">Close</button>
        <button type="button" (click)="onAddExternalVehicle()" class="btn btn-outline-dark mr-4 mb-4 float-right">
          Replace Vehicle <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
        </button>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
