<ng-template #content let-modal>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 class="modal-title" id="modal-basic-title">Domain Selector</h5>
  </div>
  <div class="modal-body p-2">
    <div class="margin-auto">
      <div class="card-body">
        <label class="mt-2" for="tree-select">
          Domains
          <span aria-hidden="true" class="fa fa-info-circle" placement="right-top" [ngbTooltip]="'Domains'"></span>
        </label>
        <div>
          <div id="tree-select" class="row ts" [ngClass]="{ 'border-danger': danger }">
            <div class="col-4 ts-left">
              <input class="form-control" placeholder="Search" [(ngModel)]="searchValue" (change)="onSearchChange($event)" />
              <div class="ts-header">
                <button *ngIf="filteredPath.length" class="ts-back" (click)="back()">
                  <i aria-hidden="true" class="fa fa-chevron-left"></i>
                </button>
                <div class="ts-title">Domains Title</div>
              </div>
              <table class="table table-hover" [attr.aria-describedby]="'Domain Title'">
                <thead>
                  <tr class="ts-row-header">
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">
                      <ng-container>
<!--                        <button *ngIf="flat" (click)="addAll()" class="float-right ts-button text-center">-->
<!--                          <ov-suite-icon *ngIf="!hasAll" class="text-dark" [name]="'plus'"> </ov-suite-icon>-->
<!--                          <ov-suite-icon *ngIf="hasAll" class="text-danger" [name]="'minus'"> </ov-suite-icon>-->
<!--                        </button>-->
                        <small class="float-right">(All)&nbsp;</small>
                      </ng-container>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let item of currentData"
                    (click)="select(item)"
                    [ngClass]="{ 'ts-selectable': selectionMap[item.id] !== 'selected' }"
                  >
                    <th scope="row">{{ item.id }}</th>
                    <td>{{ item.name }}</td>
                    <td>
                      <ng-container [ngSwitch]="selectionMap[item.id]">
                        <button *ngSwitchCase="'not'" class="float-right ts-button text-center" (click)="add($event, item)">
                          <ov-suite-icon class="text-dark" [name]="'plus-circle'"> </ov-suite-icon>
                        </button>
                        <button *ngSwitchCase="'partial'" class="float-right ts-button text-center" (click)="cautionAdd($event, item)">
                          <ov-suite-icon class="text-warning" [name]="'plus-circle'"> </ov-suite-icon>
                        </button>
                        <button *ngSwitchCase="'selected'" class="float-right ts-button text-center" (click)="remove($event, item)">
                          <ov-suite-icon class="text-info" [name]="'plus-circle'"> </ov-suite-icon>
                        </button>
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-8 ts-right">
              <table *ngIf="multiValue.length; else noItems" class="table table-striped" aria-describedby="Link Table">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of multiValue">
                    <th scope="row">
                      {{ item?.id }}
                    </th>
                    <td>{{ item?.name }}</td>
                    <td>
                      <button class="float-right ts-button text-center" (click)="remove($event, item)">
                        <ov-suite-icon class="text-dark" [name]="'minus-circle'"> </ov-suite-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <ng-template #noItems>
                <div class="ts-no-items-container">
                  <span class="ts-no-items-text">No Items Selected</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <hr />
        <div class="">
          <button class="btn btn-primary" (click)="close()">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="domain-selector-container">
  <div class="domain-selector-container-header">
    <span class="mt-2 my-1 text-muted domain-selector-title-text">Domains</span>
    <a (click)="open(content)" class="badge badge-primary text-light">edit</a>
  </div>
  <div class="features">
    <span *ngFor="let domain of value" class="badge badge-pill badge-dark domain-selector-pill ml-1">{{ domain?.name }}</span>
  </div>
</div>
