import { Component, Input, OnInit } from '@angular/core';
import { WaveInstance } from '@ov-suite/models-warehouse';
import { InventoryLocationModel } from '@ov-suite/models-admin';
import { Memo } from '@ov-suite/helpers-shared';
import { transferArrayItem } from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { LoadPlan } from '../wave-allocation.interface';
import { WaveAllocationBaseService } from '../wave-allocation.base.service';
import { WaveAllocationActionService } from '../wave-allocation.action.service';
import { WaveAllocationDataService } from '../wave-allocation.data.service';
import { WaveAllocationDragService } from '../wave-allocation.drag.service';

@Component({
  selector: 'ov-suite-wave-allocation-wave-bay',
  templateUrl: './wave-allocation-wave-bay.component.html',
  styleUrls: ['./wave-allocation-wave-bay.component.scss'],
})
export class WaveAllocationWaveBayComponent implements OnInit {
  @Input() loadBay: InventoryLocationModel;

  @Input() wave: WaveInstance;

  waveBay: LoadPlan[] = [];

  pinId = `load_${Math.random()}`;

  customers: string;

  constructor(
    public baseService: WaveAllocationBaseService,
    public actionService: WaveAllocationActionService,
    private readonly dataService: WaveAllocationDataService,
    public readonly dragService: WaveAllocationDragService,
    private readonly router: Router,
  ) {
    actionService.conflictCheck.subscribe((event: LoadPlan) => {
      this.waveBay.forEach(waveBay => {
        if (waveBay !== event) {
          if (waveBay.wave === event.wave && waveBay.load.getVehicle()?.id === event.load.getVehicle()?.id) {
            actionService.logConflict([waveBay, event]);
          }
        }
      });
    });

    dataService.loads.observable.subscribe((loads: LoadPlan[]) => {
      this.initialize(loads);
    });

    dataService.releaseWaveCall.subscribe((wave: WaveInstance) => {
      if (wave === this.wave) {
        if (this.waveBay.length) {
          dataService.accumulateRelease(this.waveBay);
        }
      }
    });

    dataService.releaseForStagingWaveCall.subscribe((wave: WaveInstance) => {
      if (wave === this.wave) {
        if (this.waveBay.length) {
          dataService.accumulateReleaseForStaging(this.waveBay);
        }
      }
    });

    dataService.distributedLoads.observable.subscribe((loads: LoadPlan[]) => {
      this.initialize(loads);
    });
  }

  initialize(loads: LoadPlan[]): void {
    const currentLoads = loads.filter(i => i.load.wave?.id === this.wave.id && i.load.loadBayId === this.loadBay.id);
    currentLoads.forEach(load => {
      load.wave = this.wave;
      load.loadBay = this.loadBay;
      this.waveBay.push(load);
    });
  }

  ngOnInit(): void {
    this.pinId = `load_${this.wave.id}_${this.loadBay.id}`;
    this.initialize(this.dataService.distributedLoads.all);
  }

  onRelease(loadPlan: LoadPlan) {
    this.dataService.release(loadPlan);
  }

  onReleaseForStaging(loadPlan: LoadPlan) {
    this.dataService.releaseForStaging(loadPlan);
  }

  onRemove(index: number) {
    const item = this.waveBay[index];
    transferArrayItem(this.waveBay, this.dataService.loads.value, index, 0);
    item.wave = null;
    item.loadBay = null;
    this.actionService.cleanConflict(item);
    this.actionService.checkConflicts(item);

    this.dataService.save(item);
  }

  manage(index: number) {
    const item = this.waveBay[index];
    this.router.navigate(['load-management/load'], { queryParams: { id: item.load.id } });
  }

  hasBeenReleased(loadPlans: LoadPlan[]) {
    return loadPlans.some(lp => lp.load.releaseDate);
  }

  hasBeenReleasedForStaging(loadPlans: LoadPlan[]) {
    return loadPlans.some(lp => lp.load.stagingReleaseDate);
  }

  @Memo()
  getCustomerString(waveBay: LoadPlan): string {
    const customerSet = new Set<string>();
    waveBay.load?.orders?.forEach(order => {
      if (order.customer) {
        customerSet.add(order.customer.customerCode);
      }
    });
    return customerSet?.size ? [...customerSet].join(', ') : 'Empty Load - No orders allocated';
  }
}
