import { OVEntity, OVField, OVForm, OVTable } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';
import { FormModel, MobileUserConfigModel } from '@ov-suite/models-shared';

@OVEntity({ name: 'MobileReport' })
@OVTable<MobileReportModel>([
  {
    title: 'ID',
    type: 'number',
    key: 'id',
    detailModal: data => ({ id: data.id, entity: MobileReportModel }),
  },
  {
    type: 'deep-string',
    title: 'user',
    key: 'user.username',
  },
  {
    title: 'Instance ID',
    type: 'string',
    key: 'processInstanceId',
  },
  {
    title: 'Date',
    type: 'date-time',
    key: 'createDate',
  },
])
@OVForm([], [
  ['id', 'processDefinitionId'],
  ['taskId', 'processInstanceId'],
  ['comment', ''],
  ['image'],
])
export class MobileReportModel {
  @OVField({
    type: 'number',
    sidebar: true,
    generated: true,
    readonly: true,
    title: 'ID',
  })
  id: number;

  @OVField({ type: () => MobileUserConfigModel })
  mobileUserConfig: MobileUserConfigModel;

  @OVField({ type: () => FormModel })
  form: FormModel;

  @OVField({ type: 'string', title: 'Process Instance ID' })
  processInstanceId: string;

  @OVField({ type: 'string', title: 'Task ID' })
  taskId: string;

  @OVField({ type: 'string', title: 'Process Definition ID' })
  processDefinitionId: string;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: () => UserModel })
  user: UserModel;

  @OVField({ type: 'string', title: 'Comment' })
  comment: string;

  @OVField({ type: 'json' })
  variables: unknown;

  @OVField({ type: 'image', title: 'Image' })
  image: string;

  @OVField({ type: 'string', title: 'Version' })
  version: string;
}
