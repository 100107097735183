import { OVEntity, OVField, OVForm, OVSidebar, OVTable } from '@ov-suite/ov-metadata';
import { UserTypeStatus } from './user-type-status.model';
import { UserTypeFeature } from './user-type-feature.model';

@OVEntity({ name: 'UserType', mapDomainsToIds: true })
@OVForm([['#User Type Details'], ['User Type Details'], ['name'], ['description'], ['userTypeFeatures'], ['test']])
@OVSidebar([
  // ['image'],
  ['status'],
  ['id'],
])
@OVTable<UserType>([
  {
    key: 'id',
    title: 'User ID',
    type: 'string',
  },
  {
    key: 'name',
    title: 'User Type Name',
    type: 'string',
  },
  {
    key: 'description',
    title: 'Description Name',
    type: 'string',
  },
])
export class UserType {
  // @SidebarField({ type: 'string', title: 'Unique ID', readonly: true })
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  // @Searchable()
  @OVField({
    type: 'string',
    title: 'User Type Name',
    tooltip: 'Create a User Type Name',
    placeholder: 'Required',
    required: true,
  })
  name: string;

  @OVField({
    type: 'string',
    tooltip: 'Add a description to this User Type',
    title: 'Description',
    required: false,
  })
  description?: string;

  // @SidebarField({ type: 'image', title: 'Factory Image' })
  imageUrl?: string;

  // @SidebarField({ type: 'dropdown', title: 'Status' })
  @OVField({
    type: () => UserTypeStatus,
    dropdown: true,
    title: 'Status',
    sidebar: true,
  })
  status: UserTypeStatus;

  @OVField({
    type: 'permission',
    title: 'Permissions',
  })
  userTypeFeatures: UserTypeFeature[];
}
