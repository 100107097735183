import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadAllocationDateService {
  public date$: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

  public setDate(date: Date): void {
    this.date$.next(date);
  }
}
