import { OVField, OVEntity, OVTable, OVSearch } from '@ov-suite/ov-metadata';
import { Product, ProductSkuModel } from '@ov-suite/models-admin';
import { ReturnModel } from './return.model';
import { Reason } from './reason.model';

@OVEntity({ name: 'ReturnItem' })
@OVTable<ReturnItem>([
  {
    key: 'productSku.id',
    title: 'Product',
    type: 'deep-string',
  },
  {
    key: 'productSku.name',
    title: 'Product Variant',
    type: 'deep-string',
  },
  {
    key: 'productSku.sku',
    title: 'SKU',
    type: 'deep-string',
  },
  {
    key: 'quantity',
    title: 'Quantity',
    type: 'number',
    editable: true,
  },
  {
    title: 'Reason',
    type: 'dropdown',
    key: 'reason',
    filterKey: 'reason.reason',
    editable: true,
    displayKeys: ['reason'],
  },
  {
    key: 'price',
    title: 'Amount',
    type: 'number',
  },
  {
    title: 'Actions',
    type: 'buttons',
    hideColumnKey: 'actions',
    disableSorting: true,
    disableFiltering: true,
    keys: [],
    buttons: [
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-check',
        action: (item, { apiService: OvAutoService }) => {
          OvAutoService.update({
            entity: ReturnItem,
            item: { id: item.id, quantity: item.quantity, productSkuId: item.productSku.id, reasonId: item.reason.id },
          }).then(() => {
            alert('Return Item Updated');
          });
        },
      },
      {
        classes: 'btn btn-dark btn-sm mx-1',
        icon: 'fa fa-trash',
        actionType: 'delete',
      },
    ],
  },
])
@OVSearch<ReturnItem>({ searchKeys: ['id', 'productSku.name'] })
export class ReturnItem {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'number' })
  productSkuId: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;

  @OVField({ type: () => ReturnModel })
  return: ReturnModel;

  @OVField({ type: 'number' })
  unitPriceExcl: number;

  @OVField({ type: 'number' })
  unitPriceIncl: number;

  @OVField({ type: 'number' })
  reasonId: number;

  @OVField({
    type: () => Reason,
    title: 'Reason',
    keys: ['id', 'reason'],
    dropdownKeys: ['id', 'reason'],
    placeholder: 'Reason',
    dropdown: true,
    selectionType: 'single',
    required: false,
  })
  reason: Reason;

  // @OVField({
  //   type: () => Reason,
  //   dropdown: true,
  //   selectionType: 'simple',
  //   title: 'Reason',
  //   sidebar: true,
  //   generated: true
  // })
  // reason: Reason;
}
