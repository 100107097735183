/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, map, Observable } from 'rxjs';
import { DomainService } from '@ov-suite/helpers-angular';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly domainService: DomainService, private readonly authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const domainPath = this.domainService.currentDomainPath.value;
    const standardHeaders = {
      domainid: domainPath ?? '',
    };

    if (request.url.includes('graphql')) {
      // Token is handled by Apollo
      const clone = request.clone({
        setHeaders: standardHeaders,
      });
      return next.handle(clone);
    }

    return new Observable<HttpEvent<unknown>>(observer => {
      const session = from(this.authenticationService.getToken());
      session
        .pipe(
          map(token => {
            if (token) {
              return request.clone({
                setHeaders: {
                  Authorization: token,
                  ...standardHeaders,
                },
              });
            }
            return request.clone({
              setHeaders: standardHeaders,
            });
          }),
        )
        .subscribe({
          next: httpRequest => {
            next.handle(httpRequest).subscribe(
              httpEvent => observer.next(httpEvent),
              err => observer.error(err),
            );
          },
          error: () => {
            next.handle(request).subscribe(
              httpEvent => observer.next(httpEvent),
              err => observer.error(err),
            );
          },
        });
    });
  }
}
