import { Routes } from '@angular/router';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';
import { InventoryProductSkuListComponent } from './list/inventory-product-sku-list.component';

export const InventoryProductSkuRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: FeatureKey.INVENTORY_PRODUCT } },
        canActivate: [AuthGuard],
        path: '',
        component: InventoryProductSkuListComponent,
      },
    ],
  },
];
