<div
  cdkDropList
  [id]="pinId"
  class="wave-bay-plan"
  cdkDropListOrientation="horizontal"
  [cdkDropListData]="waveBay"
  [cdkDropListConnectedTo]="baseService.lists"
  (cdkDropListDropped)="actionService.drop($event, wave, loadBay)"
  [cdkDropListDisabled]="!baseService.permissions.create"
  (mousedown)="!waveBay.length && dragService.drag($event)"
>
  <div
    *ngFor="let pin of waveBay; let i = index"
    [cdkDragData]="pin"
    (click)="actionService.selectLoad(pin.load)"
    class="wave-bay-pin"
    [ngClass]="{
      'conflict-overbooked': waveBay.length > 1,
      'conflict-vehicle': actionService.vehicleConflicts[pin.load.id],
      locked: !!pin.load.releaseDate,
      stageLocked: !!pin.load.stagingReleaseDate
    }"
    cdkDrag
    [cdkDragDisabled]="!!pin.load.stagingReleaseDate || !!pin.load.releaseDate"
    [ngbTooltip]="actionService.vehicleConflicts[pin.load.id] ? 'This vehicle has already been planned for this wave' : null"
  >
    <div class="wave-bay-pin-left">
      <span class="detail-line detail-line-title">Load ID: {{ pin.load.id }}</span>
      <span class="detail-line">Vehicle: {{ pin.load.getVehicle()?.registration }}</span>
      <span class="detail-line">{{ getCustomerString(pin) }}</span>
      <ng-container *ngIf="waveBay.length <= 1">
        <ng-container *ngIf="pin.load.releaseDate; else stageReleaseDate">
          <span class="detail-line">{{ pin.load.releaseDate | date }}</span>
        </ng-container>
        <ng-template #stageReleaseDate>
          <span class="detail-line">{{ pin.load.stagingReleaseDate | date }}</span>
        </ng-template>
      </ng-container>
      <span class="badge badge-pill badge-warning text-dark detail-line"
            *ngIf="pin.error"
      >
        Error: {{ pin.error }}
      </span>
    </div>
    <i
      *ngIf="waveBay?.length"
      class="fa fa-ellipsis-v custom-wave-release-pin"
      placement="right"
      [ngbPopover]="loadSettingsContent"
      aria-hidden="true"
    ></i>

    <ng-template #loadSettingsContent>
      <div class="pop-menu">
        <button
          *ngIf="!hasBeenReleasedForStaging(waveBay)"
          [disabled]="!baseService.permissions.create || hasBeenReleasedForStaging(waveBay)"
          (click)="onReleaseForStaging(pin)"
          class="btn btn-sm"
        >
          Release for Staging
        </button>
        <button
          *ngIf="!hasBeenReleased(waveBay)"
          [disabled]="!baseService.permissions.create || hasBeenReleased(waveBay)"
          (click)="onRelease(pin)"
          class="btn btn-sm"
        >
          Release for Picking
        </button>
        <button
          *ngIf="!hasBeenReleased(waveBay)"
          [disabled]="!baseService.permissions.create || hasBeenReleased(waveBay)"
          (click)="onRemove(i)"
          class="btn btn-sm btn-danger"
        >
          Remove
        </button>
        <button
          *ngIf="hasBeenReleased(waveBay)"
          class="btn btn-sm"
          (click)="manage(i)"
        >
          Manage
        </button>
      </div>
    </ng-template>
  </div>
</div>
