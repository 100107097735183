import { OVEntity, OVField, OVForm, OVTable } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';
import { Area, CustomerModel, CustomerGroup, DomainModel } from '@ov-suite/models-admin';
import { SalesRepModel } from '@ov-suite/models-order';

@OVEntity({ name: 'SalesRepGroup' })
@OVTable<SalesRepGroupModel>([
  {
    key: 'name',
    title: 'Group Name',
    type: 'deep-string',
  },
  {
    title: 'Customer Count',
    type: 'other',
    action: srg => srg?.customers?.length.toString() ?? '0',
    keys: ['customers'],
  },
  {
    title: 'Customer Groups',
    keys: ['customerGroups.name'],
    type: 'pills',
    limit: 5,
    action: srg => {
      if (srg?.customerGroups?.length > 0) {
        return srg.customerGroups?.map(d => d.name);
      }
      return ['N/A'];
    },
  },
  {
    title: 'Areas',
    keys: ['areas.name'],
    type: 'pills',
    limit: 10,
    action: srg => {
      if (srg?.areas?.length > 0) {
        return srg.areas.map(d => d.name);
      }
      return ['N/A'];
    },
  },
])
@OVForm([
  ['name', ''],
  ['salesReps'],
  ['customers'],
  ['customerGroups'],
  ['areas'],
  ['weeklyTarget', 'monthlyTarget'],
  ['quarterlyTarget', 'yearlyTarget'],
  ['domains', ''],
])
export class SalesRepGroupModel {
  @OVField({
    type: 'number',
    generated: true,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Name',
    required: true,
  })
  name: string;

  @OVField({
    type: () => [SalesRepModel],
    selectionType: 'multiple',
    title: 'Sales Reps',
    flat: true,
    required: false,
    nameColumnKey: 'user.email',
    nameColumnOverride: (sr: SalesRepModel) => `${sr.user.firstName} ${sr.user.lastName}`,
    keys: ['id', 'user.firstName', 'user.lastName'],
  })
  salesReps: SalesRepModel[];

  @OVField({
    type: () => [CustomerModel],
    tooltip: 'Assign Customers',
    selectionType: 'multiple',
    title: 'Customers',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customers: CustomerModel[];

  @OVField({
    type: () => [CustomerGroup],
    tooltip: 'Assign Customer Groups',
    selectionType: 'multiple',
    title: 'Customer Groups',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  customerGroups: CustomerGroup[];

  @OVField({
    type: () => [Area],
    tooltip: 'Assign Areas',
    selectionType: 'multiple',
    title: 'Areas',
    flat: true,
    required: false,
    keys: ['id', 'name'],
  })
  areas: Area[];

  @OVField({
    type: 'number',
    title: 'Weekly Target',
  })
  weeklyTarget: number;

  @OVField({
    type: 'number',
    title: 'Monthly Target',
  })
  monthlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Quarterly Target',
  })
  quarterlyTarget: number;

  @OVField({
    type: 'number',
    title: 'Yearly Target',
  })
  yearlyTarget: number;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: false,
    readonly: true,
    generated: true,
  })
  domains: DomainModel[];
}
