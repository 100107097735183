<div class="card">
  <div class="row m-0 flex-row align-items-center pb-2 pl-2">
    <div class="btn-group btn-group-toggle">
      <button
        *ngFor="let item of tabs; let i = index"
        (click)="onTabClick(item.key)"
        class="btn pl-6 pr-6 ml-1 top-tab-btn"
        [style.background-color]="isActive(item.path) ? '#FCD861 ' : 'transparent'"
        [class]="item.iconClass"
      >
        {{ item?.title }}
      </button>
    </div>
  </div>
</div>
