import gql from "graphql-tag";

export const splitOrderGql = gql(`
      mutation ($request: OrderSplitInput!) {
        orderSplit(request: $request) {
          baseOrder {
            id
          }
          firstOrder {
            id
            deliveryAddress {
              geography
            }
            priority
            customerId
            orderItems {
              id
              quantity
              productSkuId
            }
            fulfilmentDate
            orderDate
            orderCode
            dueDate
            alias
            isSplit
          }
          secondOrder {
            id
            deliveryAddress {
              geography
            }
            priority
            customerId
            orderItems {
              id
              quantity
              productSkuId
            }
            fulfilmentDate
            orderDate
            orderCode
            dueDate
            alias
            isSplit
          }
        }
      }
`)
