import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { Inventory } from '@ov-suite/models-admin';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: Inventory,
      createTitle: 'Inventory',
      route: ['inventory'],
      tableTitle: 'Inventory',
      hideAdd: true,
      hideImport: true,
      hideEditDelete: true,
      permissionId: FeatureKey.INVENTORY_LIST,
    }),
  ],
})
export class InventoryModule {}
