<mat-form-field appearance="outline" class="simple-search-input">
  <mat-label>Search</mat-label>
  <input [(ngModel)]="simpleSearchInput"
         (ngModelChange)="onSimpleSearchChange($event)"
         matInput
         type="text"
  >
  <button *ngIf="simpleSearchInput"
          mat-icon-button
          matSuffix
          (click)="onClearSimpleSearch()"
  >
    <mat-icon class="fa fa-times-circle"></mat-icon>
  </button>

  <button mat-icon-button
          matSuffix
          (click)="onActivateAdvancedSearch()"
  >
    <mat-icon class="fa fa-filter"></mat-icon>
  </button>
</mat-form-field>

<ng-template #advancedSearchModal>
  <mat-card class="mat-elevation-z0">
    <mat-card-title>
      <ng-container *ngTemplateOutlet="advancedSearchCardTitle"></ng-container>
    </mat-card-title>
    <mat-card-content>
      <ng-container *ngTemplateOutlet="advancedSearchCardContent"></ng-container>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #advancedSearchCardTitle>
  <div class="advanced-search-title">
    <span class="advanced-search-modal-title">Advanced Search</span>
    <button mat-button
            color="primary"
            (click)="clearAdvancedFilters()"
    >
      Clear All
    </button>
  </div>
</ng-template>

<ng-template #advancedSearchCardContent>
  <form [formGroup]="formGroup" (ngSubmit)="applyFilter()">
    <div class="row">
      <div *ngFor="let control of formControls"
           class="col-6"
      >
        <!-- Standard Input -->
        <ng-container
          *ngIf="
            control.metadata.type === 'string' ||
            control.metadata.type === 'deep-string' ||
            control.metadata.type === 'number' ||
            control.metadata.type === 'other'
          "
        >
          <mat-form-field appearance="outline"
                          class="advanced-search-input-field"
          >
            <mat-label>{{ control.metadata.title }}</mat-label>
            <input type="text"
                   matInput
                   [formControl]="control.control"
                   [id]="control.key"
                   [name]="control.key"
            >
            <button matSuffix type="button" mat-icon-button (click)="clearField(control)">
              <mat-icon class="fa fa-times-circle"></mat-icon>
            </button>
          </mat-form-field>
        </ng-container>

        <!-- Date Input -->

        <ng-container *ngIf="control.metadata.type === 'date'">
          <mat-form-field appearance="outline"
                          class="advanced-search-input-field">
            <mat-label>{{ control.metadata.title }} Range</mat-label>
            <mat-date-range-input [formGroup]="control.control"
                                  [rangePicker]="picker">
              <input matStartDate
                     formControlName="start"
                     placeholder="MM/DD/YYYY [Start Date]">
              <input matEndDate
                     formControlName="end"
                     placeholder="MM/DD/YYYY [End Date]">
            </mat-date-range-input>
            <div class="date-picker-actions" matSuffix>
              <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
              <button type="button" mat-icon-button (click)="clearField(control)">
                <mat-icon class="fa fa-times-circle"></mat-icon>
              </button>
            </div>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </ng-container>

        <!-- Drop Down Input -->
        <ng-container *ngIf="control.metadata.type === 'dropdown'">
          <mat-form-field appearance="outline"
                          class="advanced-search-input-field">
            <mat-label>{{ control.metadata.title }}</mat-label>
            <mat-select [formControl]="control.control">
              <mat-option [value]="null">--- Selected None ---</mat-option>
              <mat-option *ngFor="let option of control.metadata.options" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <div class="advanced-search-actions">
      <button mat-button color="warn" (click)="forceClose()">Close</button>
      <button mat-button color="primary" type="submit">Apply</button>
    </div>
  </form>
</ng-template>
