import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Constructor, FieldMetadata, GenericHierarchy, getFieldMetadata, PageReturn } from '@ov-suite/ov-metadata';
import { OvAutoService } from '@ov-suite/services';
import { ColumnData, QueryParams } from '@ov-suite/helpers-shared';
import { Workbook } from 'exceljs';
import { BehaviorSubject, filter } from 'rxjs';

@Component({
  selector: 'ov-suite-excel-formatter',
  templateUrl: './excel-formatter.component.html',
  styleUrls: ['./excel-formatter.component.css'],
})
export class ExcelFormatterComponent<T extends GenericHierarchy> implements OnInit {
  @Input() formClass: Constructor<T>;

  @Input() columnData: ColumnData<T>[];

  // Holds data returned from the parent component.
  @Input() overrideExportData: BehaviorSubject<T[]>;

  // Whether it uses functionality from this component, or from parent component.
  @Input() overrideFetchQuery: boolean;

  @Input() legacy = false;

  @Input() query: Record<string, QueryParams[]>;

  // Emitter for asking parent to generate the data to be exported.
  @Output() overrideExportEmitter = new EventEmitter<string>();

  metadata: FieldMetadata<T>;

  keys: string[] = [];

  // Used for the batch download.
  limit = 200;

  // Used for button spinner.
  loading = false;

  constructor(private readonly ovAutoService: OvAutoService) {}

  ngOnInit() {
    this.metadata = getFieldMetadata(this.formClass);

    this.keys = this.getColumnKeys();
  }

  async export(): Promise<void> {
    this.loading = true;

    let resultSet: T[] = [];

    if (this.overrideFetchQuery) {
      // Used when data is more complicated, relies on data being formatted in parent component.
      // Or data is already loaded in parent and doesn't need to be called again
      this.overrideExportEmitter.emit('export');
      this.overrideExportData.pipe(filter(res => res !== null)).subscribe(res => {
        resultSet = res;
        const formattedData = this.flattenJSONArray(resultSet);
        this.generateExcel(formattedData);
        this.loading = false;
      });
    } else {
      resultSet = await this.fetchPagedData(0, this.limit, []);

      const formattedData = this.flattenJSONArray(resultSet);
      this.generateExcel(formattedData);
      this.loading = false;
    }
  }

  /**
   * recursively fetches data for a particular model
   * @param offset
   * @param limit
   * @param resultSet
   */
  async fetchPagedData(offset: number, limit: number, resultSet: T[]): Promise<T[]> {
    const { data } = await this.fetchData(offset);

    resultSet.push(...data);
    offset += this.limit;
    // If there is more data, recursively batch fetch it
    if (data.length > 0) {
      return this.fetchPagedData(offset, limit, resultSet);
    } else {
      return resultSet;
    }
  }

  /**
   * Loops through JSON Object Array to flatten it.
   * @param dataArray
   */
  flattenJSONArray(dataArray: T[]): T[] {
    return dataArray.map(item => this.flattenJSONObject(item, {} as T, ''))
  }

  /**
   * Recursive function for flattening an object.
   * @param data
   * @param res
   * @param extraKey
   */
  flattenJSONObject(data: T, res: T, extraKey: string): T {
    if (data) {
      Object.keys(data).forEach(key => {
        if (typeof data[key] !== 'object') {
          res[extraKey + key] = data[key] ?? '';
        } else if (data[key] !== undefined) {
          this.flattenJSONObject(data[key], res, `${extraKey}${key}.`);
        }
      });
    }

    return res;
  }

  /**
   * Generates and exports the Excel spreadsheet.
   * @param data
   */
  generateExcel(data: T[]): void {
    const title = this.formClass.name;

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`${this.formClass.name}`);

    worksheet.addRow([title]);
    worksheet.addRow([]);

    // Set header columns
    worksheet.columns = this.keys.map(key => ({
      header: key.toUpperCase().split('.').reverse()[0],
      key,
      width: 35
    }));

    // Populate row data
    data.forEach(item => {
      worksheet.addRow(item);
    });

    workbook.xlsx.writeBuffer().then((excelData: BlobPart) => {
      const blob = new Blob([excelData], { type: 'document/xlsx' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.href = url;
      a.download = `${this.formClass.name}_dump_${new Date().toDateString()}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  /**
   * Fetches data from the database, using ovAutoService.
   * @param offset
   */
  async fetchData(offset: number): Promise<PageReturn<T>> {
    return this.ovAutoService.list({
      entity: this.formClass,
      keys: this.keys,
      orderColumn: 'id',
      orderDirection: 'DESC',
      limit: this.limit,
      query: this.query,
      offset,
    });
  }

  /**
   * generates the keys used for fetching data from the ovAutoService.
   * Also used for the keys displayed on the Excel spreadsheet.
   */
  getColumnKeys(): string[] {
    let columns: ColumnData<T>[];

    if (this.columnData && this.columnData.length > 0) {
      columns = this.columnData;
    } else {
      columns = this.metadata.table;
    }

    const keys: Set<string> = new Set();

    columns.forEach(col => {
      if (col.keys) {
        col.keys.forEach(key => keys.add(key));
      } else {
        keys.add(col['key']);
      }
    });

    return Array.from(keys);
  }
}
