<h2 mat-dialog-title>Adjust invoice line item</h2>
<mat-dialog-content class="mat-typography">

<div class="p-2">

  <table mat-table [dataSource]="sliders" class="mat-elevation-z1">
    <!-- Item Column -->
    <ng-container matColumnDef="orderNo">
      <th mat-header-cell *matHeaderCellDef> Order No </th>
      <td mat-cell *matCellDef="let sliderOption"> {{sliderOption.orderNo}} </td>
    </ng-container>

    <!-- Ordered Quantity Column -->
    <ng-container matColumnDef="orderedQuantity">
      <th mat-header-cell *matHeaderCellDef> Ordered Qty </th>
      <td mat-cell *matCellDef="let sliderOption"> {{sliderOption.orderedQuantity}} </td>
    </ng-container>

    <!-- Slider Column -->
    <ng-container matColumnDef="slider">
      <th mat-header-cell *matHeaderCellDef> Slider </th>
      <td mat-cell *matCellDef="let sliderOption">
        <mat-slider
          [disabled]="sliderOption.isLocked"
          thumbLabel
          tickInterval="auto"
          step="1"
          min="0"
          [max]="sliderOption.max"
          [(ngModel)]="sliderOption.value"
          (input)="onSliderChange($event, sliderOption)"
          aria-label="units"></mat-slider>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Lock </th>
      <td mat-cell *matCellDef="let sliderOption">
        <i aria-hidden="true" (click)="onToggleLock(sliderOption)" *ngIf="sliderOption.isLocked && sliders.length > 2" class="fa fa-lock fa-2x"></i>
        <i aria-hidden="true" (click)="onToggleLock(sliderOption)" *ngIf="!sliderOption.isLocked && sliders.length > 2" class="fa fa-unlock fa-2x"></i>
      </td>
    </ng-container>

    <!-- Value Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let sliderOption">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Quantity</mat-label>
          <input matInput type="number" placeholder="Loaded" [(ngModel)]="sliderOption.value" [max]="sliderOption.max">
        </mat-form-field>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" (click)="onSave()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
