import { OVField, OVEntity } from '@ov-suite/ov-metadata';
import { PriceListModel } from './price-list.model';
import { ProductSkuModel } from './product-sku.model';

@OVEntity({ name: 'ProductSkuPrice', tableName: 'product_sku_price' })
export class ProductSkuPriceModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: () => PriceListModel })
  priceList: PriceListModel;

  @OVField({ type: 'number' })
  exclPrice: number;

  @OVField({ type: 'number' })
  inclPrice: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;
}
