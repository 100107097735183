import { Component, OnInit, ViewChild } from '@angular/core';
import { DateRange } from '@ov-suite/ui';
import { LoadAllocationBaseService } from '../../services/load-allocation.base.service';
import { LoadAllocationDataService } from '../../services/load-allocation.data.service';
import { LoadAllocationActionService } from '../../services/load-allocation.action.service';
import { LoadAllocationCustomerService } from '../../services/data-services/load-allocation.customer.service';
import { LoadAllocationOrderService } from '../../services/data-services/load-allocation.order.service';
import { OrderDTO } from '../../load-allocation.interface';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAllocationDateService } from '../../services/load-allocation.date.service';

@Component({
  selector: 'ov-suite-load-allocation-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss', '../../load-allocation.shared.scss'],
})
export class OrderComponent implements OnInit {
  @ViewChild('dateRange', { static: false }) dateRange;

  generalSearchTerm: string;

  customerSearchTerm: string;

  orderSearchTerm: string;

  orderDateRange: DateRange;

  constructor(
    public base: LoadAllocationBaseService,
    public dataService: LoadAllocationDataService,
    public actionService: LoadAllocationActionService,
    public data: LoadAllocationDataService,
    public orderService: LoadAllocationOrderService,
    public customerService: LoadAllocationCustomerService,
    public viewService: LoadAllocationViewService,
    public dateService: LoadAllocationDateService,
  ) {}

  ngOnInit(): void {}

  onColumnHeaderClick(column: string) {}

  clearOrderAllocationsFilter() {
    this.customerSearchTerm = null;
    this.orderSearchTerm = null;
    this.dateRange = null;
    this.setCustomerSearchTerm();
    this.setOrderSearchTerm();
    this.setDateRange();
  }

  selectOrderPart(event: Event, dto: OrderDTO) {
    event.stopPropagation();
    // this.actionService.selectOrderPart(orderAllocation);
  }

  fetchOrders() {
    this.setCustomerSearchTerm();
    this.setOrderSearchTerm();
    this.setDateRange();
  }

  public setGeneralSearchTerm() {
    this.orderService.setGeneralSearchTerm(this.generalSearchTerm);
    this.customerSearchTerm = null;
    this.orderSearchTerm = null;
    this.dateRange = null;
  }

  public setCustomerSearchTerm(): void {
    this.orderService.setCustomerSearchTerm(this.customerSearchTerm);
    this.generalSearchTerm = null;
  }

  public setOrderSearchTerm(): void {
    this.orderService.setOrderSearchTerm(this.orderSearchTerm);
    this.generalSearchTerm = null;
  }

  public setDateRange(): void {
    this.orderService.setDateRange(this.dateRange);
    this.generalSearchTerm = null;
  }
}
