import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ColumnDataButton, ColumnDataButtons } from '@ov-suite/helpers-shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Constructor, HasId } from "@ov-suite/ov-metadata";
import { OvAutoService } from "@ov-suite/services";

@Component({
  selector: 'ov-suite-action-dropdown-cell',
  template: `
    <button mat-button [matMenuTriggerFor]="menu"><mat-icon class="fa fa-ellipsis-v title-button"></mat-icon></button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let button of col?.buttons">
        <button mat-button (click)="onClick(button)" [disabled]="button.disabled?.(item) || false" type="button" class="dropdown-buttons" [style.display]="button.hide ? (button.hide(item) ? 'none' : 'block') : 'block'" [ngClass]="getClasses(button)">
          <div class="dropdown-button-wrapper"><mat-icon *ngIf="button?.icon" [class]="button.icon" aria-hidden="true"></mat-icon>
          <span *ngIf="getObjectType(button?.title) === 'function'">{{ button?.title(item) }}</span>
          <span *ngIf="getObjectType(button?.title) !== 'function'">{{ button?.title }}</span>
          <span *ngIf="button.tooltip" class="action-btn-tooltip-text" [class]="button.tooltipClass">{{ button.tooltip }}</span>
          </div>
        </button>
      </ng-container>
    </mat-menu>
  `,
  styleUrls: ['action-dropdown-cell.component.scss'],
})
export class ActionDropdownCellComponent<T extends HasId> implements OnInit{
  @Input() item: T;

  @Input() col: ColumnDataButtons<T>;

  @Input() modalDisplayPosition: string;

  @Input() handleClicks = false;

  @Output() click = new EventEmitter<ColumnDataButton<T>>();

  @Output() requestRefresh = new EventEmitter<void>();

  myInjector: Injector;

  constructor(
    private readonly injector: Injector,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ovAutoService: OvAutoService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.myInjector = Injector.create({
      parent: this.injector,
      providers: [{ provide: 'item', useValue: this.item }],
    });
  }

  getClasses(button: ColumnDataButton<T>): string {
    if (!button.classes) {
      switch (button.actionType) {
        case 'edit':
          return 'btn-primary btn-sm fa pt-1 pb-1 fa-pencil';
        case 'delete':
          return 'btn-primary ml-1 btn-sm fa fa-trash-o';
        default:
          return null;
      }
    }
    return typeof button.classes === 'function' ? button.classes?.(this.item) : button.classes;
  }

  getObjectType(type: unknown): string {
    return typeof type;
  }

  onClick(button: ColumnDataButton<T>): void {
    if (!this.handleClicks) {
      this.click.emit(button);
      return;
    }
    switch (button.actionType) {
      case 'routeLink':
        this.routerLink(button);
        break;
      case 'modal':
      case 'custom':
        this.custom(button);
        break;
      case 'edit':
        this.edit();
        break;
      case 'delete':
        this.delete();
        break;
      default:
    }
  }

  routerLink(button: ColumnDataButton<T>) {
    this.activatedRoute.url
      .subscribe(([url]) => {
        const params = button.queryParams?.(this.item, url.path);
        const routerLink = button.routerLink?.(this.item, url.path);
        this.router.navigate(routerLink, { queryParams: params });
      })
      .unsubscribe();
  }

  custom(button: ColumnDataButton<T>) {
    button.action(this.item, { ngbModal: this.modalService, apiService: null });
  }

  edit(): void {
    const options: NavigationExtras = {
      queryParams: { id: this.item.id },
    };
    this.activatedRoute.queryParamMap
      .subscribe(paramMap => {
        if (paramMap.has('_page')) {
          options.queryParams['_prevPage'] = paramMap.get('_page');
        }
        this.router.navigate([this.router.url.slice(1).split('?')[0], 'edit'], options);
      })
      .unsubscribe();
  }

  delete(): void {
    if (window.confirm('Are you sure you want to delete this?')) {
      this.ovAutoService.delete(this.item.constructor as Constructor<T>, this.item.id).then(() => {
        this.requestRefresh.emit();
      });
    }
  }
}
