import { Routes } from '@angular/router';
import { WaveAllocationMainComponent } from './wave-allocation-main/wave-allocation-main.component';
import { AuthGuard } from '@ov-suite/authguard-angular';
import { FeatureKey } from '@ov-suite/helpers-shared/lib/feature.enum';

export const WaveAllocationRoutes: Routes = [
  {
    path: '',
    children: [
      {
        data: { feature: { id: FeatureKey.WAVE_ALLOCATION } },
        canActivate: [AuthGuard],
        path: '',
        component: WaveAllocationMainComponent,
      },
    ],
  },
];
