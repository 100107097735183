import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { OrderItemModel } from '@ov-suite/models-order';

@OVEntity({ name: 'LoadOrderItem' })
export class LoadOrderItemModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: () => OrderItemModel })
  orderItem: OrderItemModel;
}
