import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'ov-suite-load-management-vehicle-truck-driver',
  templateUrl: './load-management-vehicle-truck-driver.component.html',
  styleUrls: ['./load-management-vehicle-truck-driver.component.css'],
})
export class LoadManagementVehicleTruckDriverComponent implements OnInit {
  @Input() driverName: string;

  @Output() updateTruckDriver: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();

  @ViewChild('vehicleDriver') vehicleDriver: TemplateRef<any>;

  form = this.formBuilder.group({
    driverName: [],
  });

  constructor(private readonly dialogService: ConfirmationDialogService, private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.form.patchValue({ driverName: this.driverName });
  }

  updateTruckDriverClicked() {
    const confirmationDialogData: ConfirmationDialogData = {
      title: 'Edit Truck Driver',
      confirmButtonText: 'Save',
      component: this.vehicleDriver,
    };

    this.dialogService.openComponentDialog(confirmationDialogData).subscribe(update => {
      if (update) {
        const newName = this.form.get('driverName').value;
        this.updateTruckDriver.emit([this.driverName, newName]);

        this.driverName = newName;
      }
    });
  }
}
