import { Variable } from './variable.interface';
import { FormioModel } from './formioModel';

export interface ListTasksParams {
  name?: string;
  nameLike?: string;
  description?: string;
  priority?: number;
  minimumPriority?: number;
  maximumPriority?: number;
  assignee?: string;
  assigneeLike?: string;
  owner?: string;
  ownerLike?: string;
  unassigned?: boolean;
  delegationState?: string;
  candidateUser?: string;
  candidateGroup?: string;
  candidateGroups?: string; // comma separated list
  involvedUser?: string;
  taskDefinitionKey?: string;
  taskDefinitionKeyLike?: string;
  processInstanceId?: string;
  processInstanceBusinessKey?: string;
  processInstanceBusinessKeyLike?: string;
  processDefinitionId?: string;
  processDefinitionKey?: string;
  processDefinitionKeyLike?: string;
  processDefinitionName?: string;
  processDefinitionNameLike?: string;
  executionId?: string;
  createdOn?: Date;
  createdBefore?: Date;
  createdAfter?: Date;
  dueOn?: Date;
  dueBefore?: Date;
  dueAfter?: Date;
  withoutDueDate?: boolean;
  excludeSubTasks?: boolean;
  active?: boolean;
  includeTaskLocalVariables?: boolean;
  includeProcessVariables?: boolean;
  tenantId?: string;
  tenantIdLike?: string;
  withoutTenantId?: boolean;
  candidateOrAssigned?: string;
  category?: string;
}

export class TaskActionsParams {
  action: 'complete' | 'claim' | 'delegate' | 'resolve';
  assignee?: string;
  variables?: Variable[];
}

export interface ListTasksResponse {
  data: Partial<Task>[];
  total: number;
  start: number;
  sort: string;
  order: string;
  size: number;
}

export interface Task {
  id: string;
  url: string;
  owner: string;
  assignee: string;
  delegationState: string;
  name: string;
  description?: string;
  createTime: Date;
  dueDate: Date;
  priority: number;
  suspended: boolean;
  claimTime: Date;
  taskDefinitionKey: string;
  scopeDefinitionId: string;
  scopeId: string;
  scopeType: string;
  tenantId: string;
  category: string;
  formKey: string;
  parentTaskId: string;
  parentTaskUrl: string;
  executionId: string;
  executionUrl: string;
  processDefinition: string;
  processInstanceId: string;
  processInstanceUrl: string;
  processDefinitionId: string;
  processDefinitionUrl: string;
  variables: Variable[];

  formId?: number;
  form?: FormioModel | string;
}
