import { Routes } from '@angular/router';
import { PermissionType } from '@ov-suite/helpers-shared';
import { OvGenericListComponent } from './list/ov-generic-list.component';
import { OvGenericAddOrEditComponent } from './add-or-edit/ov-generic-add-or-edit.component';
import { OvGenericGuard } from './ov-generic.guard';

export const OvGenericRoutes: Routes = [
  {
    data: { permissionType: PermissionType.read },
    path: '',
    canActivate: [OvGenericGuard],
    children: [
      {
        data: { permissionType: PermissionType.read },
        path: '',
        canActivate: [OvGenericGuard],
        component: OvGenericListComponent,
      },
      {
        data: { permissionType: PermissionType.create },
        path: 'add',
        canActivate: [OvGenericGuard],
        component: OvGenericAddOrEditComponent,
      },
      {
        data: { permissionType: PermissionType.update },
        path: 'edit',
        canActivate: [OvGenericGuard],
        component: OvGenericAddOrEditComponent,
      },
    ],
  },
];
