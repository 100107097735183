import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainModel } from '@ov-suite/models-admin';
import { OvAutoService } from '@ov-suite/services';
import { DomainService } from '@ov-suite/helpers-angular';
import { FieldMetadata, FieldParamsConstructor, getFieldMetadata, getSearchableMetadata, SearchableMetadata } from '@ov-suite/ov-metadata';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';
import { WithQuantity } from '../tree-select/tree-select.component';

@Component({
  selector: 'ov-suite-domain-selector',
  templateUrl: './domain-selector.component.html',
  styleUrls: ['./domain-selector.component.scss'],
  providers: [MakeProvider(DomainSelectorComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DomainSelectorComponent extends AbstractValueAccessor<DomainModel[]> implements OnInit {
  @Input() danger = false;

  @Input() single = false;

  @Input() disabled = false;

  hasAll = false;

  _subItem: FieldParamsConstructor;

  filteredPath: number[] = [];

  _currentData: DomainModel[] = [];

  metadata: FieldMetadata<DomainModel>;

  searchableMetadata: SearchableMetadata;

  name: string;

  currentParentId: number | string = null;

  searchValue: string;

  selectionMap: {
    [key: number]: 'selected' | 'not' | 'partial' | 'unavailable';
  } = {};

  set currentData(data: DomainModel[]) {
    this._currentData = data;
    this.calculateSelection();
  }

  get currentData() {
    return this._currentData;
  }

  @Input() set subItem(subItem: FieldParamsConstructor) {
    this._subItem = subItem;
  }

  @Input() ovAutoService: OvAutoService;

  @ViewChild('content') content;

  closeResult: string;

  @Input() selectedDomains: DomainModel[] = [];

  get multiValue() {
    if (Array.isArray(this.val)) {
      return this.val.filter(b => !!b);
    }
    if (!this.val) {
      return [];
    }
    return [this.val];
  }

  constructor(private readonly modalService: NgbModal,private readonly domainService: DomainService) {
    super();
  }

  ngOnInit() {
    this.metadata = getFieldMetadata(DomainModel);
    this.name = this.metadata.name;

    if (this.name) {
      this.searchableMetadata = getSearchableMetadata(DomainModel);
    }

    this.domainService.allDomains.subscribe({
      next: domains => {
        if (this.selectedDomains?.length) {
          this.setSelectedDomains(this.selectedDomains);
        }
        this.setCurrentData(domains);
      },
    });
  }

  setCurrentData(domains: DomainModel[]): void {
    this.currentData = domains;
    if (!this.val?.length && this.domainService.currentDomain.value) {
      this.writeValue([this.domainService.currentDomain.value], 'setCurrentData');
    }
  }


  getDismissReason(reason: unknown): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    }
    if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    }
    return `with: ${reason}`;
  }

  open(content: unknown) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
      })
      .result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }


  writeValue(value: DomainModel[], source = 'unknown') {
    if (Array.isArray(value)) {
      this.val = value;
      this.onChange(value);
      this.calculateSelection();
    }
  }

  valueChange(item: DomainModel[]) {
    this.writeValue(item, 'valueChange');
  }

  setSelectedDomains(domains: DomainModel[]) {
    this.selectedDomains = domains;
    this.writeValue(domains, 'setSelectedDomains');
  }

  calculateSelection() {
    this.currentData?.forEach(item => {
      this.selectionMap[item.id] = this.getSelectionType(item);
    });
  }

  getSelectionType(item: DomainModel): 'selected' | 'not' | 'partial' | 'unavailable' {
    const path = item.path.slice(0, -1).split('.');
    path.pop();
    const unavailable = this.multiValue.some(entry => path.includes(entry.id.toString()));
    if (unavailable) {
      return 'unavailable';
    }
    const found = this.multiValue.filter(entry => (entry as DomainModel).path.includes(`${item.id}.`));
    if (found?.length) {
      if (found.some(entry => entry.id === item.id)) {
        return 'selected';
      }
      return 'partial';
    }
    return 'not';
  }

  close() {
    this.modalService.dismissAll();
  }

  getSearch(input?: number | string): Record<string, string[]> {
    return { parentId: [input ? input.toString() : null] };
  }

  async select(item: DomainModel) {
    if (this.selectionMap[item.id] === 'not') {
      this.filteredPath = item.path
        .slice(0, -1)
        .split('.')
        .map(entry => Number(entry));
      this.searchValue = '';

      // get domains
      this.currentData = this.domainService.getById(item.id).children;
      this.currentParentId = item.id;
    }
  }

  async back() {
    this.filteredPath.pop();
    const parentId = this.filteredPath.length ? this.filteredPath[this.filteredPath.length - 1] : undefined;
    if (parentId) {
      this.currentData = this.domainService.getById(parentId).children;
    } else {
      this.currentData = this.domainService.getParentDomains();
    }

    this.currentParentId = parentId ?? null;
  }

  async reset() {
    this.currentData = this.domainService.getParentDomains();
  }

  onSearchChange(event: Event): void {
    if ((event?.target as HTMLInputElement)?.value) {
      this.search((event.target as HTMLInputElement).value);
    } else {
      this.reset();
    }
  }

  search(text: string): void {
    this.currentData = this.domainService.getDomains(text);
  }

  async cautionAdd(event, item: DomainModel) {
    event?.stopPropagation();
    if (item && window.confirm('This will replace other items further down the tree')) {
      await this.add(event, item);
    }
  }

  async add(event, item: DomainModel) {
    event?.stopPropagation();
    const actualItem: DomainModel = item;

    if (this.single) {
      return;
    }
    if (this.selectedDomains) {
      this.selectedDomains.push(actualItem);
    } else {
      this.selectedDomains = [actualItem];
    }
    let val: DomainModel[];
    if (!this.val) {
      val = [actualItem];
    } else {
      val = [...this.multiValue.filter(existing => !(existing as DomainModel).path.includes(`${actualItem.id}.`)), actualItem];
    }

    this.writeValue(val, 'add');

  }

  addAll() {
    this.hasAll = !this.hasAll;
  }

  remove(event, item: DomainModel | WithQuantity<DomainModel>) {
    event?.stopPropagation();

    this.currentData = this.currentData.filter(x => x.id !== item.id);

    if (this.single) {
      this.writeValue(null, 'removeSingle');
      return;
    }

    const updatedValue = this.multiValue.filter(selected => (selected as DomainModel).id !== (item as DomainModel).id);
    this.writeValue(updatedValue, 'remove');
  }

  forceUpdate() {
    this.onChange(this.value);
  }
}
