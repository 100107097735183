<div class="main-content">
  <ng-container *ngIf="pageInUse; else pageFree">
    <div class="page-in-use">
      <mat-card>
        <mat-card-title class="locked-header">Load allocation is currently locked</mat-card-title>
        <mat-card-content>
          <ov-suite-clean-table
            [columnData]="columnData"
            [dataSource]="dataSource"
            disablePaging="true"
          >
          </ov-suite-clean-table>
        </mat-card-content>
        <mat-card-actions align="end">
          <button mat-stroked-button color="primary" (click)=takeControl()>Take Control</button>
        </mat-card-actions>
      </mat-card>
    </div>

  </ng-container>
  <ng-template #pageFree>
    <div class="outer">
      <div class="split-container-1">
        <div class="split-container-2">
          <as-split direction="horizontal" restrictMove="true" [useTransition]="true" #vertical>
            <as-split-area [size]="horizontalSplit">
              <as-split direction="vertical" restrictMove="true" #leftSide>
                <as-split-area [size]="leftSideSplit">
                  <ov-suite-load-allocation-map #map></ov-suite-load-allocation-map>
                </as-split-area>
                <as-split-area [size]="100-leftSideSplit">
                  <ov-suite-load-allocation-vehicle #vehicles></ov-suite-load-allocation-vehicle>
                </as-split-area>
              </as-split>
            </as-split-area>
            <as-split-area [size]="100 - horizontalSplit">
              <as-split direction="vertical" restrictMove="true" #rightSide>
                <as-split-area [size]="rightSideSplit" class="orders-container">
                  <ov-suite-load-allocation-order #orders></ov-suite-load-allocation-order>
                </as-split-area>
                <as-split-area [size]="100-rightSideSplit" *ngIf="viewService.selectedOrderDTO$ | async">
                  <ov-suite-load-allocation-detail #details></ov-suite-load-allocation-detail>
                </as-split-area>
              </as-split>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  </ng-template>
</div>
