import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { InputComponent } from './input.component';
import { DateTimeModule } from '../date-time/date-time.module';
import { PipesModule } from '../pipes/pipes.module';
import { DateRangeModule } from '../date-time/date-range.module';
import { AgmMapModule } from '../agm-map/agm-map.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    DateTimeModule,
    FormsModule,
    DateRangeModule,
    AgmMapModule
  ],
  declarations: [InputComponent],
  exports: [InputComponent],
})
export class InputModule {}
