import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData } from '@ov-suite/services';
@Component({
  selector: 'ov-suite-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  data: ConfirmationDialogData;

  allowClose: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public confirmationDialogData: ConfirmationDialogData) {
    this.data = confirmationDialogData;

    if (this.data.overrideConfirm) {
      this.data.overrideConfirm.subscribe(value => {
        if (value !== null) {
          this.allowClose = value;
        }
      })
    }
  }
}
