import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceResolutionDataService, OrderInvoiceLineTotal, OrderInvoiceTotal } from '../invoice-resolution.data.service';
import { InvoiceOrderLineEditorComponent } from '../invoice-order-line-editor/invoice-order-line-editor.component';

/**
 * @title Extended Invoice Order Table
 */
@Component({
  selector: 'ov-suite-extended-order-invoice-table',
  styleUrls: ['extended-order-invoice-table.component.scss'],
  templateUrl: 'extended-order-invoice-table.component.html',
})
export class ExtendedOrderInvoiceTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['itemName', 'itemCode', 'loadedQuantity', 'actions'];

  dataSource = new MatTableDataSource<OrderInvoiceLineTotal>();

  isLoading = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() orderInvoice: OrderInvoiceTotal;

  constructor(public dataService: InvoiceResolutionDataService, public dialog: MatDialog) {}

  ngOnInit(): void {
    this.isLoading = false;

    if (this.orderInvoice.lineItems) {
      this.dataSource = new MatTableDataSource<OrderInvoiceLineTotal>(this.orderInvoice.lineItems);
    }
  }

  onAdjustOrderLine(orderItem: OrderInvoiceLineTotal) {
    this.dialog.open(InvoiceOrderLineEditorComponent, {
      minWidth: 800,
      minHeight: 400,
      data: {
        itemCode: orderItem.itemCode,
        quantityLoaded: 100,
      },
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
}
