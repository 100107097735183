import { OVField, OVEntity, OVTable, OVSearch } from '@ov-suite/ov-metadata';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { doubleToRand, doubleToRandString } from '@ov-suite/helpers-shared/lib/currency.helper';

@OVEntity({ name: 'OrderItem' })
@OVTable<OrderItemModel>([
  {
    key: 'productSku.name',
    title: 'Product Name',
    type: 'deep-string',
  },
  {
    key: 'productSku.sku',
    title: 'SKU',
    type: 'deep-string',
  },
  {
    key: 'quantity',
    title: 'Quantity',
    type: 'number',
    // editable: true,
  },
  {
    key: 'processedQuantity',
    title: 'Processed',
    type: 'number',
    // editable: true,
  },
  {
    keys: ['unitPriceExcl'],
    title: 'Unit Price',
    type: 'other',
    action: item => `${doubleToRandString(item.unitPriceExcl)} `,
  },
  {
    keys: ['quantity', 'unitPriceExcl'],
    title: 'Total',
    type: 'other',
    action: item => doubleToRandString(item.quantity * item.unitPriceExcl),
  },
  // {
  //   title: 'Actions',
  //   type: 'buttons',
  //   hideColumnKey: 'actions',
  //   disableSorting: true,
  //   disableFiltering: true,
  //   keys: [],
  //   buttons: [
  //     {
  //       classes: 'btn btn-dark btn-sm mx-1',
  //       icon: 'fa fa-check',
  //       action: (item, { apiService }) => {
  //         apiService
  //           .update({ entity: OrderItemModel, item: { id: item.id, quantity: item.quantity } })
  //       },
  //     },
  //     {
  //       classes: 'btn btn-dark btn-sm mx-1',
  //       icon: 'fa fa-trash',
  //       actionType: 'delete',
  //     },
  //   ],
  // },
])
@OVSearch<OrderItemModel>({ searchKeys: ['id', 'productSku.name'] })
export class OrderItemModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: 'number' })
  processedQuantity: number;

  @OVField({ type: 'number' })
  productSkuId: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;

  @OVField({ type: 'number' })
  orderId: number;

  @OVField({ type: 'number' })
  unitPriceExcl: number;

  @OVField({ type: 'number' })
  unitPriceIncl: number;

  @OVField({ type: 'number' })
  inventoryItemUnitPrice: number;

  @OVField({ type: 'number' })
  inventoryItemUnitPriceIncl: number;

  @OVField({ type: 'number' })
  discountedUnitPrice: number;

  @OVField({ type: 'boolean' })
  split: boolean;

  @OVField({ type: 'string' })
  note: string;

  getTotalPriceExcl(): number {
    return doubleToRand(this.quantity * this.unitPriceExcl);
  }

  getTotalPriceIncl(): number {
    return doubleToRand(this.quantity * this.unitPriceExcl);
  }
}
