import { EventEmitter, Injectable } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { LoadDTO, OrderDTO } from '../load-allocation.interface';
import { LoadAllocationOrderService } from './data-services/load-allocation.order.service';
import { LoadAllocationEventBusService } from './event-bus/load-allocation.event-bus.service';
import { OrderMovedEvent, OrderSelectedEvent } from './event-bus/load-allocation.events';

/**
 * This service is used to manage interaction between panels on load-allocation.
 *
 * This typically means user actions like hovering and selecting
 */

@Injectable()
export class LoadAllocationActionService {
  orderHoverStart = new EventEmitter<OrderDTO>();

  orderHoverEnd = new EventEmitter<OrderDTO>();

  constructor(private readonly orderService: LoadAllocationOrderService, private readonly eventBus: LoadAllocationEventBusService) {}

  public startUp() {}

  selectOrder(dto: OrderDTO) {
    this.eventBus.triggerEvent(new OrderSelectedEvent(dto));
  }

  dropDirty(event: CdkDragDrop<LoadDTO | 'list'>, dto: LoadDTO) {
    if (dto.committed) {
      return;
    }
    this.eventBus.triggerEvent(
      new OrderMovedEvent(event.item.data, event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex),
    );
  }

  drop(event: CdkDragDrop<LoadDTO | 'list'>) {
    this.eventBus.triggerEvent(
      new OrderMovedEvent(event.item.data, event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex),
    );
  }

  flush() {
    this.orderHoverStart = new EventEmitter<OrderDTO>();

    this.orderHoverEnd = new EventEmitter<OrderDTO>();
  }
}
