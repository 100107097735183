import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { ConfirmationDialogData, ConfirmationDialogService, OvAutoService } from '@ov-suite/services';
import { CustomerStageInstructionModel, StageTypeModel } from '@ov-suite/models-admin';
import { MatCustomDataSource } from '@ov-suite/ui';
import { ColumnData } from '@ov-suite/helpers-shared';
import { AbstractValueAccessor, MakeProvider } from '../input/abstruct-value-accessor';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'ov-suite-stage-types-selector',
  templateUrl: './stage-types-selector.component.html',
  styleUrls: ['./stage-types-selector.component.css'],
  providers: [MakeProvider(StageTypesSelectorComponent)],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StageTypesSelectorComponent extends AbstractValueAccessor<CustomerStageInstructionModel[]> {
  @ViewChild('editStageInstruction') editStageInstruction;

  selectedStageInstruction: CustomerStageInstructionModel;

  dataSource_: CustomerStageInstructionModel[];

  stageTypes: StageTypeModel[];

  tableDataSource: MatCustomDataSource<CustomerStageInstructionModel> = new MatCustomDataSource<CustomerStageInstructionModel>([]);

  overrideConfirmDialog: BehaviorSubject<boolean>;

  constructor(private readonly dialogService: ConfirmationDialogService, private readonly ovAutoService :OvAutoService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.stageTypes = await this.ovAutoService.list({
      entity: StageTypeModel
    }).then(res => res.data)
  }

  tableColumnData: ColumnData<CustomerStageInstructionModel>[] = [
    {
      title: 'Stage Type',
      type: 'other',
      keys: ['stageType.name'],
      action: item => item.stageType.name,
    },
    {
      title: 'Stage Instruction',
      type: 'other',
      keys: ['instruction'],
      action: item => {
        if (item.instruction.length < 20) {
          return item.instruction;
        }

        return `${item.instruction.substr(0, 20)} ...`;
      }
    },
    {
      title: 'Actions',
      type: 'button-bar',
      keys: [],
      buttons: [
        {
          icon: 'fa fa-edit',
          actionType: 'custom',
          classes: 'btn btn-dark btn-sm mx-1',
          tooltip: 'Edit instruction',
          action: item => {
            this.edit(item, this.dataSource.indexOf(item))
          }
        },
        {
          icon: 'fa fa-trash',
          actionType: 'custom',
          classes: 'btn btn-dark btn-sm mx-1',
          tooltip: 'Delete instruction',
          action: item => {
            this.delete(item, this.dataSource.indexOf(item))
          }
        }
      ],
    }
  ]

  @Input()
  set dataSource(source: CustomerStageInstructionModel[]) {
    this.dataSource_ = source || [];
    this.tableDataSource.data = this.dataSource_;
  }

  get dataSource(): CustomerStageInstructionModel[] {
    return this.dataSource_;
  }

  override set value(data: CustomerStageInstructionModel[]) {
    this.dataSource = data;
    this.onChange(data);
  }

  override get value(): CustomerStageInstructionModel[] {
    return this.dataSource;
  }

  openDialog(confirmationDialogData: ConfirmationDialogData): Observable<boolean> {
    confirmationDialogData.width = 500;
    return this.dialogService.openComponentDialog(confirmationDialogData);
  }

  add() {
    this.selectedStageInstruction = new CustomerStageInstructionModel();

    this.overrideConfirmDialog = new BehaviorSubject<boolean>(null);

    const dialogData: ConfirmationDialogData = {
      title: 'Add Stage Instruction',
      component: this.editStageInstruction,
      confirmButtonText: 'Add',
      overrideConfirm: this.overrideConfirmDialog,
    }

    this.openDialog(dialogData).subscribe(result => {
      if (result) {
        this.dataSource.push(this.selectedStageInstruction);
        this.tableDataSource.data = [...this.dataSource];
        this.writeValue(this.tableDataSource.data);
      }
    })
  }

  edit(editItem: CustomerStageInstructionModel, index: number): void {
    this.selectedStageInstruction = editItem;

    this.selectedStageInstruction.stageTypeId = this.selectedStageInstruction.stageType.id;

    this.overrideConfirmDialog = new BehaviorSubject<boolean>(null);

    const dialogData: ConfirmationDialogData = {
      title: 'Edit Stage Instruction',
      component: this.editStageInstruction,
      confirmButtonText: 'Update',
      overrideConfirm: this.overrideConfirmDialog,
    }

    this.openDialog(dialogData).subscribe(result => {
      if (result) {
        this.dataSource[index] = this.selectedStageInstruction;
        this.tableDataSource.data = this.dataSource;
        this.writeValue(this.tableDataSource.data);
      }
    })
  }

  delete(deleteItem: CustomerStageInstructionModel, index: number): void {
    this.selectedStageInstruction = deleteItem;

    this.dialogService.openConfirmRemovalDialog().subscribe(result => {
      if (result) {
        this.dataSource.splice(index, 1);
        this.tableDataSource.data = this.dataSource;
        this.writeValue(this.tableDataSource.data);
      }
    })
  }

  updateStageInstruction($updateInstruction: CustomerStageInstructionModel | boolean): void {
    // Disable confirm dialog
    if (typeof $updateInstruction === 'boolean') {
      this.overrideConfirmDialog.next(true);
      return;
    }

    this.selectedStageInstruction = $updateInstruction;
    // Enable the confirm button in the dialog
    this.overrideConfirmDialog.next(false);

  }
}
