import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function commitLoadAllocationGql(): DocumentNode {
  return gql(
    `mutation CommitLoadAllocation($load: LoadAllocationUpdateInput!) {
      commitLoadAllocation(load: $load) { id }
      }`,
  );
}

export function unCommitLoadAllocationGql(): DocumentNode {
  return gql(
    `mutation UnCommitLoadAllocationGql($load: LoadAllocationUpdateInput!) {
      unCommitLoadAllocation(load: $load) { id }
      }`,
  );
}

export function upsertLoadAllocationGql(): DocumentNode {
  return gql(
    `mutation upsertLoadAllocation($loads: [LoadAllocationUpsertInput!]!, $source: String!, $sessionId: String!) {
        upsertLoadAllocation(loads: $loads, source: $source, sessionId: $sessionId) {
          type
          id
          tempId
        }
      }`,
  );
}
