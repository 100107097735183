export function tryParse(input: unknown): unknown {
  try {
    if (typeof input === 'string') {
      return JSON.parse(input);
    }
    return input;
  } catch (e) {
    return input;
  }
}
