import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MultiSelectDropdownComponent } from './multi-select-dropdown.component';

@NgModule({
  declarations: [MultiSelectDropdownComponent],
  exports: [MultiSelectDropdownComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatListModule,
  ],
})
export class MultiSelectDropdownModule {}
