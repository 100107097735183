import { AfterViewInit, Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnData } from '@ov-suite/helpers-shared';
import { LoadLogModel } from '@ov-suite/models-warehouse';
import { BehaviorSubject } from 'rxjs';
import { LoadManagementDataService } from '../load-management.data.service';

@Component({
  selector: 'ov-suite-log-tab',
  templateUrl: './log-tab.component.html',
  styleUrls: ['./log-tab.component.scss'],
})
export class LogTabComponent implements AfterViewInit {
  dataSource: MatTableDataSource<LoadLogModel> = new MatTableDataSource<LoadLogModel>([]);

  formClass = LoadLogModel;

  fetchDownloadData: BehaviorSubject<LoadLogModel[]> = new BehaviorSubject<LoadLogModel[]>(null);

  columnData: ColumnData<LoadLogModel>[] = [
    {
      title: 'Timestamp',
      type: 'date-time',
      key: 'createDate',
    },
    {
      title: 'Event',
      type: 'string',
      key: 'message',
    },
    {
      title: 'User',
      type: 'deep-string',
      key: 'createdBy.username',
    },
  ];

  constructor(public loadManagementDataService: LoadManagementDataService) {}

  async ngAfterViewInit() {
    this.dataSource.sortData = (data, matSort) => {
      if (matSort.direction) {
        const up = matSort.direction === 'asc' ? 1 : -1;
        switch (matSort.active) {
          case 'Timestamp':
            data.sort((a, b) => (a.createDate > b.createDate ? up : -up));
            break;
          case 'Event':
            data.sort((a, b) => (a.message.toLowerCase() > b.message.toLowerCase() ? up : -up));
            break;
          case 'User':
            data.sort((a, b) => (a.createdBy.username.toLowerCase() > b.createdBy.username.toLowerCase() ? up : -up));
            break;
          default:
        }
      }
      return data;
    };

    this.dataSource.data = await this.loadManagementDataService.getLogs();
  }

  async fetchData() {
    this.fetchDownloadData.next(this.dataSource.data);
  }
}
