import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ColumnData, ColumnDataColumns, RowData, RowHighlight } from '@ov-suite/helpers-shared';
import { HasId } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableDetailModalComponent } from '../../table-detail-modal/table-detail-modal.component';
import { ActivatedRoute, Router } from '@angular/router';

export interface FilterChange {
  filter: Record<string, string[]>;
  query: Record<string, string[]>;
  search: Record<string, string[]>;
}

/**
 * Table
 * This is a simple business-logic free table
 * It requires data and columnData at minimum
 * Data provided should only include items you wish to display, this must exclude data from other pages
 * Page Control must be handled at a higher layer using: currentPage, pageSize, totalCount and pageChange.
 * Permissions, routing and other business logic must be applied at a higher layer
 */
@Component({
  selector: 'ov-suite-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss'],
})
export class BasicTableComponent<T extends HasId> implements OnInit {
  constructor(public ngbModal: NgbModal, private route: ActivatedRoute, private router: Router) {}

  /**
   * Core Variables
   * Inputs:
   * data - Array of Table Data
   * columnData - Data used to render columns
   *
   * Outputs:
   * itemEdited - Emits single item when its edited via ColumnData.editable
   */

  @Input() columnData: ColumnData<T>[];

  _data: T[];

  @Input() set data(data: T[]) {
    this._data = data;
    if (this.showFiller && data?.length < this.pageSize) {
      this.filler = new Array(this.pageSize - data.length).fill(null);
    } else {
      this.filler = [];
    }
    this.getExtraColumns();
  }

  get data() {
    return this._data;
  }

  // Todo: Implement Loader, currently does nothing
  @Input() loading = false;

  @Output() itemEdited = new EventEmitter<T>();

  filteredColumns: string[] = [];

  filteredColumnData: ColumnData<T>[];

  /**
   * End of Core
   */

  /**
   * To be Refactored
   */

  @Input() dropdownData = {};

  /**
   * Investigation
   */

  // Todo: Investigate Use
  @Input()
  set hideColumnKeys(event: string[]) {
    this.defaultHiddenKeys = event;
    if (!!event && event.length) {
      this.columnData = this.columnData.filter(col => !this.defaultHiddenKeys.some(dhk => dhk === col.hideColumnKey || dhk === col.id));
    }
  }

  defaultHiddenKeys = [];

  /**
   * End where is this used?
   */

  extraColumns: { data: string[]; columnData: ColumnDataColumns<T> } = {
    data: [],
    columnData: null,
  };

  /**
   * General Common Items
   */

  ngOnInit() {
    if (!this.data) {
      throw new TypeError("'data' is required");
    }
    if (!this.columnData) {
      throw new TypeError("'columnData' is required");
    }

    this.columnHiderOnInit();
  }

  itemChanged(item: T): void {
    this.itemEdited.emit(item);
  }

  itemClicked(): void {}

  getExtraColumns(): void {
    const column = this.columnData?.find(d => d.type === 'column') as ColumnDataColumns<T>;
    if (column) {
      this.extraColumns.columnData = column;
      const mappedData = this.data.map(d => column.mapData(d)).filter(i => !!i);
      this.extraColumns.data = (
        Array.isArray(mappedData[0])
          ? Array.from(new Set(mappedData.reduce((prev, cur) => (Array.isArray(cur) ? [...prev, ...cur] : [...prev, cur]), [])))
          : mappedData
      ) as string[];
    }
  }

  /**
   * Feature: Styling
   * Inputs:
   * striped - shows striped rows per bootstrap tables
   * showFiller - shows empty rows when current page has less data than page size
   */

  @Input() striped = true;

  // todo: Investigate why this inverts itself on storybook
  @Input() showFiller = true;

  filler: null[] = [];

  /**
   * Feature: Keyboard Navigation
   * Inputs:
   * select - Action invoked when spacebar or enter is pressed
   * back - Action invoked when backspace is pressed
   */

  // Todo: Refactor to Outputs
  @Input() select: (item: T) => void = () => {};

  @Input() back: (item?: T) => void = () => {};

  selectedIndex = -1;

  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent & { target: { localName: string } }) {
    if (event.target?.localName === 'input') {
      return;
    }

    switch (event.key) {
      case 'ArrowRight':
        event.stopPropagation();
        event.preventDefault();
        this.pageForward();
        break;
      case 'ArrowLeft':
        event.stopPropagation();
        event.preventDefault();
        this.pageBack();
        break;
      case 'ArrowDown':
        event.stopPropagation();
        event.preventDefault();
        this.selectedDown();
        break;
      case 'ArrowUp':
        event.stopPropagation();
        event.preventDefault();
        this.selectedUp();
        break;
      case ' ':
      case 'Enter':
        event.stopPropagation();
        event.preventDefault();
        if (this.selectedIndex >= 0 && this.selectedIndex < this.pageSize) {
          this.select(this.data[this.selectedIndex]);
        }
        break;
      case 'Backspace':
        event.stopPropagation();
        event.preventDefault();
        this.back();
    }
  }

  selectedDown() {
    if (this.selectedIndex < Math.min(this.pageSize, this.data?.length ?? 0) - 1) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex = -1;
    }
  }

  selectedUp() {
    if (this.selectedIndex > -1) {
      this.selectedIndex -= 1;
    } else {
      this.selectedIndex = Math.min(this.pageSize, this.data?.length ?? 0) - 1;
    }
  }

  /**
   * Feature: Column Hider
   * Inputs:
   * columnHider - Toggles for display of column hider
   * keyStore - Unique string for storing column settings in local storage. Required if columnHider is to be used
   */
  public _columnHider = false;

  @Input()
  set columnHider(val: boolean) {
    this.toggleColumnHider();
    if (val && !this.keyStore) {
      throw new TypeError("'keyStore' is required when 'columnHider' = true");
    }
    this._columnHider = val;
  }

  get columnHider(): boolean {
    return this._columnHider;
  }

  @Input() keyStore: string;

  columnHiderOnInit(): void {
    if (this.keyStore) {
      try {
        this.filteredColumns = JSON.parse(localStorage.getItem(this.keyStore)) ?? this.getDefaultFilteredColumns();
        if (this.filteredColumns.length) {
          this.filteredColumnData = this.columnData
            .filter(col => !this.filteredColumns.includes(col.id))
            .filter(col => col.type !== 'button-bar');
        } else {
          this.filteredColumnData = this.columnData.filter(col => col.type !== 'button-bar');
        }
      } catch (e) {
        localStorage.removeItem(this.keyStore);
        this.filteredColumnData = this.columnData.filter(col => col.type !== 'button-bar');;
      }
    } else {
      this.filteredColumnData = this.columnData.filter(col => col.type !== 'button-bar');;
    }
  }

  getDefaultFilteredColumns(): string[] {
    return this.columnData.filter(col => col.startHidden || this.defaultHiddenKeys.includes(col.hideColumnKey)).map(col => col.id);
  }

  toggleColumnHider() {
    if (this.columnHider) {
      this.filteredColumnData = this.columnData.filter(col => !this.filteredColumns.includes(col.id));
    } else {
      this.filteredColumnData = this.columnData;
    }
  }

  isColVisible(id: string) {
    return !this.filteredColumns.includes(id);
  }

  /**
   * Feature: Row Item Actions
   *  * rowItemButtonClick - emits when row item button clicked
   */

  @Output() rowItemButtonClick = new EventEmitter();

  /**
   * Feature: Row Item Actions
   *  * rowItemClick - emits when row item is clicked
   */

  @Output() rowItemClick = new EventEmitter();

  /**
   * Feature: Pagination
   * Inputs:
   * showPageSelect - Display pagination tool on lower right. Default: true
   * currentPage - current page index starting from 0. Default: 0
   * pageSize - current page size. Default: 10
   * totalCount - Total number of items for pagination: Default: 0
   * pageChangeEnabled - Display dropdown for changing page size. Default: true
   *
   * Outputs:
   * pageChange - emits when page changes via pagination tool
   * changePageSize - emits when page size changes
   * orderChange - emits when column ordering changes
   */
  @Input() showPageSelect = true;

  @Input() currentPage = 0;

  @Input() rowData: RowData<T>;

  // todo: Rename this to PageSizeChangeEnabled to more accurately display what it does
  @Input() pageChangeEnabled = true;

  private _pageSize = 10;

  @Input()
  set pageSize(num: number) {
    this._pageSize = num;
    this.updatePages();
  }

  get pageSize() {
    return this._pageSize;
  }

  private _totalCount = 0;

  @Input()
  set totalCount(num: number) {
    this._totalCount = num;
    this.updatePages();
  }

  get totalCount() {
    return this._totalCount;
  }

  @Input() order: {
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<T>;
  } = {
    column: 'id',
    direction: 'ASC',
    data: {
      key: 'id',
      type: 'string',
      title: '',
    },
  };

  // Todo: Rename to pageChange to be more consistent
  @Output() changePage = new EventEmitter();

  @Output() changePageSize = new EventEmitter();

  @Output() orderChange = new EventEmitter<{
    column: string;
    direction: 'ASC' | 'DESC';
    data: ColumnData<T>;
  }>();

  pages: number[] = [];

  totalPages = 1;

  public updatePageSize(value: string) {
    this.changePageSize.emit(Number(value));
    this.pageFirst();
  }

  private updatePages(): void {
    const pagesRaw = this._totalCount / this._pageSize;
    let pages = Math.floor(pagesRaw);
    if (pages !== pagesRaw) {
      pages += 1;
    }
    this.totalPages = pages;
    if (pages > 5) {
      this.pages = new Array(5).fill('test').map((x, i) => i + this.currentPage);
    } else {
      this.pages = new Array(pages).fill('test').map((x, i) => i);
    }
  }

  public pageForward(): void {
    this.selectPage(this.currentPage + 1);
  }

  public pageLast(): void {
    this.selectPage(this.totalPages - 1);
  }

  public pageBack(): void {
    this.selectPage(this.currentPage - 1);
  }

  public pageFirst(): void {
    this.selectPage(0);
  }

  public selectPage(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.changePage.emit(page);
      this.currentPage = page;
      this.shiftPages();
      this.selectedIndex = -1;

      let queryParams = {};
      this.route.queryParams.subscribe(params => {
        queryParams = { ...params };
      });

      const parentId = this.route.snapshot.queryParams['_parentId'];
      if (parentId) {
        queryParams['_parentId'] = parentId;
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ...queryParams, _page: page },
        queryParamsHandling: 'merge',
      });
    }
  }

  private shiftPages() {
    if (this.totalPages > 5) {
      if (this.pages[0] >= 0 && this.pages[4] <= this.totalPages + 1) {
        let factor = 0;
        if (this.currentPage - 2 <= 0) {
          factor = 2 - this.currentPage;
        } else if (this.currentPage >= this.totalPages - 2) {
          factor = this.totalPages - this.currentPage - 3;
        }
        this.pages = [
          this.currentPage - 2 + factor,
          this.currentPage - 1 + factor,
          this.currentPage + factor,
          this.currentPage + 1 + factor,
          this.currentPage + 2 + factor,
        ];
      }
    }
  }

  onColumnHeaderClick(column: ColumnData<T>) {
    if (!this.columnHider) {
      if (!column.disableSorting) {
        if (this.order.column === column.id) {
          this.order.direction = this.order.direction === 'ASC' ? 'DESC' : 'ASC';
        } else {
          this.order.column = column.id;
          this.order.direction = 'ASC';
          this.order.data = column;
        }
        this.orderChange.emit(this.order);
      }
    } else {
      this.toggleCol(column.id);
    }
  }

  toggleCol(id: string, event?: Event) {
    event?.stopPropagation();
    if (this.filteredColumns.includes(id)) {
      this.filteredColumns = this.filteredColumns.filter(item => item !== id);
    } else {
      this.filteredColumns.push(id);
    }
    this.persistColumnFilters();
  }

  persistColumnFilters() {
    if (this.keyStore) {
      localStorage.setItem(this.keyStore, JSON.stringify(this.filteredColumns));
    }
  }

  /**
   * Feature: Clickable Rows
   * Inputs:
   * clickableRows - allows rows to be click which fires output
   *
   * Outputs:
   * clickRow - emits when row is clicked and clickableRows = true
   */

  @Input() clickableRows = false;

  @Output() clickRow = new EventEmitter<T>();

  onClick(item: T, event) {
    if (this.clickableRows) {
      this.clickRow.emit(item);
    }
  }

  /**
   * Feature: Selectable Rows
   * Inputs:
   * selectableRows - Enables checkboxes for selecting rows
   *
   * Outputs:
   * itemSelect - Emits array of all selected items each time a new item is selected
   */

  selectedRows: Map<number, T> = new Map<number, T>();

  @Input() selectableRows = false;

  @Output() itemSelect = new EventEmitter<T[]>();

  onSelect(event: Event, item?: T): void {
    event.stopPropagation();
    if (this.selectedRows.has(item.id as number)) {
      this.selectedRows.delete(item.id as number);
    } else {
      this.selectedRows.set(item.id as number, item);
    }
    this.emitSelectedRows();
  }

  onSelectAll(event: Event): void {
    event.stopPropagation();
    const allSelected = this.isAllSelected();
    this.selectedRows.clear();
    if (!allSelected) {
      this.data.forEach(item => {
        this.selectedRows.set(item.id as number, item);
      });
    }

    this.emitSelectedRows();
  }

  emitSelectedRows(): void {
    this.itemSelect.emit(Array.from(this.selectedRows.values()));
  }

  isAllSelected(): boolean {
    return this.data.every(item => this.selectedRows.has(item.id as number));
  }

  getModalDisplayPosition(i: number): string {
    if (i < 6 && i > 4) {
      return 'left';
    }
    if (i < 6) {
      return 'left-top';
    }
    return 'left-bottom';
  }

  openModal(data: ColumnData<unknown>, item: T, event: Event) {
    if (data.detailModal) {
      const { id, entity } = data.detailModal?.(item);
      console.log('modal clicked', id, entity);
      if (id) {
        event.stopPropagation();
        const modal = this.ngbModal.open(TableDetailModalComponent, { size: 'xl' });
        modal.componentInstance.id = id;
        modal.componentInstance.entity = entity;
      }
    }
  }

  /**
   * End of File
   */
}
