<div class="outer-container">
  <div class="main-container">
    <mat-card>
      <mat-card-header class="load-amount-card-header">
        <mat-card-title>Load Details</mat-card-title>
        <div class="load-detail-actions">
          <button mat-flat-button
                  (click)="onGenerateAllInvoices()"
                  color="primary"
                  class="load-detail-action"
          >
            <mat-icon fontSet="fa" fontIcon="fa-files-o" class="button-icons"></mat-icon>
            Generate All Invoices
          </button>
<!--          <button mat-stroked-button-->
<!--                  (click)="onExport()"-->
<!--                  class="load-detail-action"-->
<!--          >-->
<!--            <mat-icon fontSet="fa" fontIcon="fa-download" class="button-icons"></mat-icon>-->
<!--            Export-->
<!--          </button>-->
        </div>
      </mat-card-header>
      <mat-card-content>
        <ov-suite-load-order-accordion
          [orders]="loadOrderMap"
          [loadAmountMap]="loadAmountMap"
          [loadInvoiceMap]="loadInvoiceMap"
          (loadAmountChanges)="updateLoadAmounts($event)"
          (generateInvoiceEmitter)="onGenerateInvoice($event)"
          (reloadInvoiceEmitter)="onReloadInvoice($event)"
        ></ov-suite-load-order-accordion>
      </mat-card-content>
    </mat-card>
  </div>
</div>
