import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'ov-suite-table-refresh',
  templateUrl: './table-refresh.component.html',
  styleUrls: ['./table-refresh.component.css']
})
export class TableRefreshComponent implements OnInit, OnDestroy {
  timeObs: Subscription = new Subscription();

  live = false;

  @Output() refreshEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    this.timeObs = interval(30000).subscribe(() => {
      if (this.live) {
        this.refresh();
      }
    })
  }

  ngOnDestroy(): void {
    this.timeObs.unsubscribe();
  }

  onLiveClick() {
    this.live = !this.live;
  }

  refresh() {
    this.refreshEmitter.emit(true);
  }
}
