import { NgModule } from '@angular/core';
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { CleanTableComponent } from './clean-table.component';
import { BasicTableModule } from '../new-table/basic-table/basic-table.module';

@NgModule({
  imports: [MatTableModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, CommonModule, BasicTableModule],
  declarations: [CleanTableComponent],
  exports: [CleanTableComponent],
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions }],
})
export class CleanTableModule {}
