// @ts-ignore
import type { ParamMap } from '@angular/router';
import { QueryParams } from '@ov-suite/helpers-shared/lib/query-params.interface';

export function toTitleCase(text: string): string {
  return text
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function camelCaseToTitleCase(text: string): string {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function kebabCaseToNormalCase(text: string): string {
  return text.replace(/([_-])/g, ' ');
}

export function slashToNormalCase(text: string): string {
  return text.replace(/([`/])/g, ' ');
}

export function propKeyToTitle(text: string): string {
  return toTitleCase(text.replace('.', ' '));
}

export function mapDeepObjects(key: string, object: Record<string, unknown> = {}): Record<string, unknown> | unknown | unknown[] {
  return key.split('.').reduce<Record<string, unknown> | unknown | unknown[]>((prev, curr) => {
    if (!prev) {
      return null;
    }
    if (typeof prev === 'object') {
      return (prev as Record<string, unknown>)[curr];
    }
    return null;
  }, object);
}

export enum QueryParam {
  pageSize = '_pageSize',
  parentId = '_parentId',
  page = '_page',
  orderColumn = '_column',
  orderDirection = '_direction',
  filter = '_filter',
  query = '_query',
  search = '_search',
}

export const queryPlaceHolder = 'q;';

export function getUrlQueryMap(params: ParamMap): {
  filterKeys: Record<string, string[]>;
  queryKeys: Record<string, string[]>;
} {
  let queryKeys = {}; // Number and Date types
  let filterKeys = {}; // All other types

  getNotExcludedParamKeys(params).forEach(key => {
    if (key.includes(queryPlaceHolder)) {
      const queryKey = key.replace(queryPlaceHolder, '');
      if (params.get(key)?.includes('date-range')) {
        queryKeys = {
          ...queryKeys,
          [queryKey]: [JSON.parse(params.get(key) ?? '{}')],
        };
      } else {
        queryKeys = {
          ...queryKeys,
          [queryKey]: [params.get(key)],
        };
      }
    } else {
      filterKeys = {
        ...filterKeys,
        [key]: [params.get(key)],
      };
    }
  });
  return { filterKeys, queryKeys };
}

function keysOf<T extends Object>(input: T): (keyof T)[] {
  return Object.keys(input) as unknown as (keyof T)[];
}

function getNotExcludedParamKeys(params: ParamMap): string[] {
  return params.keys.filter(key => !(keysOf(QueryParam).map(queryKey => QueryParam[queryKey]) as string[]).includes(key));
}

export function parseParameters(param: QueryParam, paramMap: ParamMap): Record<string, QueryParams[]> {
  const keys = paramMap.keys.filter(key => key.startsWith(param)).map(param => param.split(':').slice(1).join());

  const parseValue = (input: string): QueryParams => {
    if (input === 'true')
      return {
        operator: '=',
        value: true,
      };
    if (input === 'false')
      return {
        operator: '=',
        value: false,
      };
    if (!Number.isNaN(Number(input))) return Number(input);
    return input;
  };

  const outputMap: Record<string, QueryParams[]> = {};

  keys.forEach(key => {
    const value = paramMap.get(`${param}:${key}`);
    if (!value) {
      return;
    }
    if (outputMap[key]?.length) {
      outputMap[key].push(parseValue(value));
    } else {
      outputMap[key] = [parseValue(value)];
    }
  });
  return outputMap;
}
