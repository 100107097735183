import { Injectable } from '@angular/core';

@Injectable()
export class WaveAllocationDragService {
  body: HTMLDivElement;
  leftColumn: HTMLDivElement;
  topRow: HTMLDivElement;

  setElements(body: HTMLDivElement, leftColumn: HTMLDivElement, topRow: HTMLDivElement) {
    this.body = body;
    this.leftColumn = leftColumn;
    this.topRow = topRow;
  }

  drag(event: MouseEvent) {
    this.body.style.cursor = 'grabbing';
    this.body.style.userSelect = 'none';

    const dragPos = {
      left: this.body.scrollLeft,
      top: this.body.scrollTop,
      x: event.clientX,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - dragPos.x;
      const dy = e.clientY - dragPos.y;

      this.body.scrollTop = dragPos.top - dy;
      this.body.scrollLeft = dragPos.left - dx;
      this.leftColumn.scrollTop = dragPos.top - dy;
      this.topRow.scrollLeft = dragPos.left - dx;
    };

    const mouseUpHandler = () => {
      this.body.style.cursor = 'grab';
      this.body.style.removeProperty('user-select');
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  dragY(event: MouseEvent) {
    this.body.style.cursor = 'grabbing';
    this.body.style.userSelect = 'none';

    const dragPos = {
      top: this.body.scrollTop,
      y: event.clientY,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dy = e.clientY - dragPos.y;
      this.body.scrollTop = dragPos.top - dy;
      this.leftColumn.scrollTop = dragPos.top - dy;
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }

  dragX(event: MouseEvent) {
    this.body.style.cursor = 'grabbing';
    this.body.style.userSelect = 'none';

    const dragPos = {
      left: this.body.scrollLeft,
      x: event.clientX,
    };

    const mouseMoveHandler = (e: MouseEvent) => {
      const dx = e.clientX - dragPos.x;
      this.body.scrollLeft = dragPos.left - dx;
      this.topRow.scrollLeft = dragPos.left - dx;
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  }
}
