import { OVForm, OVEntity, OVField } from '@ov-suite/ov-metadata';
import { BaseUserToken } from '@ov-suite/helpers-shared/lib/user.interface';

@OVEntity('Profile')
@OVForm([
  ['email', 'phoneNumber'],
  ['firstName', 'lastName'],
  ['password', 'newPassword', 'confirmPassword'],
])
export class Profile {
  id: number;

  @OVField({ type: 'string', title: 'Mobile' })
  phoneNumber: string;

  @OVField({ type: 'string', title: 'First Name' })
  firstName: string;

  @OVField({ type: 'string', title: 'Last Name' })
  lastName: string;

  @OVField({
    tooltip: 'Enter your current password',
    type: 'password',
    title: 'Current Password',
  })
  password?: string;

  @OVField({
    type: 'password',
    title: 'New Password',
  })
  newPassword?: string;

  @OVField({
    tooltip: 'Has to match new password.',
    type: 'password',
    title: 'Confirm Password',
  })
  confirmPassword?: string;

  @OVField({
    type: 'string',
    tooltip: 'Requires a valid email address.',
    title: 'Email Address',
  })
  email: string;

  static fromUser(user: BaseUserToken): Profile {
    return Object.assign(new Profile(), {
      id: user.id,
      phoneNumber: user.phoneNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  }
}
