import { Component, OnInit } from '@angular/core';
import { FieldMetadata, getFieldMetadata, OVTag } from '@ov-suite/ov-metadata';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OvAutoService } from '@ov-suite/services';
import { VehicleException, VehicleOverride } from '@ov-suite/models-warehouse';

@OVTag('get-vehicle-exception-view-popup', VehicleException)
@Component({
  selector: 'ov-suite-vehicle-exception-view-modal',
  templateUrl: './vehicle-exception-view-modal.component.html',
})
export class VehicleExceptionViewModalComponent implements OnInit {
  page = 0;

  pageSize = 10;

  vehicle: VehicleOverride;

  data: VehicleException[] = [];

  // Class - Required
  formClass = VehicleException;

  metadata: FieldMetadata<VehicleException>;

  constructor(public autoService: OvAutoService, protected modalService: NgbModal) {
    this.metadata = getFieldMetadata<VehicleException>(this.formClass);
  }

  ngOnInit(): void {
    this.getData();
  }

  changePage(page: number): void {
    this.page = page;
    this.getData();
  }

  getData(): void {
    this.autoService
      .list<VehicleException>({
        keys: ['startTime', 'endTime', 'startDate', 'endDate', 'reason', 'vehicles', 'otherReason'],
        offset: 0,
        orderDirection: 'ASC',
        orderColumn: 'id',
        entity: VehicleException,
        query: { 'vehicles.id': [this.vehicle?.id] },
      })
      .then(result => {
        this.data = result.data;
      });
  }

  close = () => {
    this.modalService.dismissAll();
  };
}
