import { BehaviorSubject, filter, Observable, zip } from 'rxjs';
import * as _ from 'lodash';
import { OvAutoService } from '@ov-suite/services';
import { Constructor, HasId } from '@ov-suite/ov-metadata';

interface CreateLazyServiceParams {
  keys?: string[];
  relations?: string[];
}

/**
 * Generic Lazy Service handles the lazy loading of entities from the backend by wrapping them in an observable
 */

export abstract class LazyService<T extends HasId> {
  protected readonly $itemList = new BehaviorSubject<T[]>(null);

  protected readonly draftItemMap = new Map<string, BehaviorSubject<T>>([[null, new BehaviorSubject<T>(null)]]);

  protected readonly itemMap = new Map<number | string, BehaviorSubject<T>>([[0, new BehaviorSubject<T>(null)]]);

  private readonly idQueue: Set<number | string> = new Set();

  private readonly debounceFetchQueue: Function = _.debounce(() => this.fetchQueue(), 200, {
    leading: false,
    trailing: true,
    maxWait: 3000,
  });

  private readonly debounceListEmit: Function = _.debounce(() => this.emitList(), 200, {
    leading: false,
    trailing: true,
    maxWait: 3000,
  });

  protected constructor(
    protected readonly entity: Constructor<T>,
    private readonly autoService: OvAutoService,
    protected readonly params: CreateLazyServiceParams,
  ) {}

  /** Gets all the items in the queue and emits their values to the Map */
  private async fetchQueue(): Promise<void> {
    const listToGet: number[] = [...this.idQueue].filter(id => typeof id === 'number') as number[];
    this.idQueue.clear();
    if (!listToGet.length) {
      return;
    }
    this.autoService
      .getByIds({
        entity: this.entity,
        ids: listToGet,
        relations: this.params.relations,
        keys: this.params.keys,
      })
      .then(response => {
        response.forEach(item => {
          this.loadItem(item);
        });
      });
  }

  private emitList() {
    this.$itemList.next([...this.itemMap.values(), ...this.draftItemMap.values()].map(subject => subject.value).filter(value => !!value));
  }

  protected loadItem(item: T): BehaviorSubject<T> {
    if (!this.itemMap.has(item.id as number)) {
      this.itemMap.set(item.id as number, new BehaviorSubject<T>(null));
    }
    const subject = this.itemMap.get(item.id as number);
    subject.next(item);
    this.debounceListEmit();
    return subject;
  }

  protected loadItems(items: T[]): void {
    items.forEach(item => {
      if (!this.itemMap.has(item.id as number)) {
        this.itemMap.set(item.id as number, new BehaviorSubject<T>(null));
      }
      const subject = this.itemMap.get(item.id as number);
      subject.next(item);
    });
    this.debounceListEmit();
  }

  protected loadDraftItem(item: T): void {
    if (!this.draftItemMap.has(item.id.toString())) {
      this.draftItemMap.set(item.id.toString(), new BehaviorSubject<T>(null));
    }
    const subject = this.draftItemMap.get(item.id.toString());
    subject.next(item);
    this.debounceListEmit();
  }

  protected loadDraftItems(items: T[]): void {
    items.forEach(item => {
      if (!this.draftItemMap.has(item.id.toString())) {
        this.draftItemMap.set(item.id.toString(), new BehaviorSubject<T>(null));
      }
      const subject = this.draftItemMap.get(item.id.toString());
      subject.next(item);
    });
    this.debounceListEmit();
  }

  public get$(id: number | string): BehaviorSubject<T> {
    id ??= 0;
    let existing: BehaviorSubject<T>;
    if (typeof id === 'string') {
      existing = this.draftItemMap.get(id);
    } else {
      existing = this.itemMap.get(id);
    }
    if (existing) {
      return existing;
    }
    const newItem = new BehaviorSubject<T>(null);
    if (typeof id === 'string') {
      // No nothing for now
    } else {
      this.itemMap.set(id, newItem);
      this.idQueue.add(id);
      this.debounceFetchQueue();
    }

    return newItem;
  }

  public getMany$(ids: number[]): Observable<T[]> {
    return zip(ids.map(id => this.get$(id).pipe(filter(item => !!item))));
  }

  public list$(): BehaviorSubject<T[]> {
    return this.$itemList;
  }

  /**
   * Shifts the item from draft to full item
   */
  protected promoteDraft(draftId: string, newItem: T) {
    const subject = this.draftItemMap.get(draftId);
    this.draftItemMap.delete(draftId);
    this.itemMap.set(newItem.id, subject);
    subject.next(newItem);
    this.debounceListEmit();
  }

  /**
   * Shifts the several items from draft to full item
   */
  protected promoteDrafts(input: Record<string, T>): void {
    if (!Object.keys(input).length) {
      return;
    }
    Object.entries(input).forEach(([draftId, newItem]) => {
      const subject = this.draftItemMap.get(draftId);
      this.draftItemMap.delete(draftId);
      this.itemMap.set(newItem.id, subject);
      subject.next(newItem);
    });
    this.debounceListEmit();
  }

  protected clear() {
    this.itemMap.clear();
    this.draftItemMap.clear();
    this.idQueue.clear();
    this.emitList();
  }
}
