import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LoadAmounts } from '../../load-amount-tab.types';

@Component({
  selector: 'ov-suite-load-amount-input',
  templateUrl: './load-amount-input.component.html',
  styleUrls: ['./load-amount-input.component.css']
})
export class LoadAmountInputComponent implements OnInit {
  @Input() loadAmount: LoadAmounts;

  @Input() max: number;

  @Input() canAdjustAmounts: boolean;

  @Output() quantityLoadedEmitter: EventEmitter<number> = new EventEmitter<number>();

  form: FormGroup;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.createForm();

    if (this.canAdjustAmounts) {
      this.form.get('quantityLoaded').enable();
    } else {
      this.form.get('quantityLoaded').disable();
    }

    this.form.patchValue({ quantityLoaded: this.loadAmount.quantityLoaded });

    // this.listenForChanges();

    this.form.get('quantityLoaded').valueChanges.subscribe(changes => {
      // if (this.validateQuantityLoaded(this.form.get('quantityLoaded'))) {
      //   console.log(changes);
      // }
      // console.log(changes);
      if (changes) {
        this.quantityLoadedEmitter.emit(changes);
      }
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      quantityLoaded: [],
    });
  }

  validateQuantityLoaded(formControl: AbstractControl) {
    formControl.setErrors({ exceedingLoaded: true });
    // if (!input) {
    //
    //   return false;
    // }
    //
    // return true;
  }

  // listenForChanges() {
  //   this.form.get('quantityLoaded').valueChanges.subscribe(change)
  //   this.form.controls.age.valueChanges.subscribe(age=>{
  //     if(isNaN(age)){
  //       this.form.controls.age.setErrors({invalidNumber:true})  // <--- Set invalidNumber to true
  //     }else{
  //       this.form.controls.age.setErrors(null)
  //     }
  //   })
  // }
}
