import { OVEntity, OVField } from '@ov-suite/ov-metadata';

@OVEntity('StageType')
export class StageTypeModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  name: string;
}
