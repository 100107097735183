import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function releaseForPickingGql(): DocumentNode {
  return gql(
    `mutation ReleaseForPicking($loads: [ReleasePickInput!]!) {
      releaseForPicking(loads: $loads) { successfulIds }
      }`,
  );
}
export function releaseForStagingGql(): DocumentNode {
  return gql(
    `mutation ReleaseForStaging($loads: [ReleasePickInput!]!) {
      releaseForStaging(loads: $loads) { successfulIds }
      }`,
  );
}

export interface ReleaseForPickingResponse {
  releaseForPicking: {
    successfulIds: number[];
  };
}
export interface ReleaseForStagingResponse {
  releaseForStaging: {
    successfulIds: number[];
  };
}
