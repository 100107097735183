<div class="main-content">
<!--  <mat-tab-group [dynamicHeight]="true">-->
<!--    <mat-tab label="Detail">-->
<!--      <ov-suite-load-detail-tab></ov-suite-load-detail-tab>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Pick Details">-->
<!--      <ov-suite-picks-tab></ov-suite-picks-tab>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Load Details">-->
<!--      <ov-suite-load-tab></ov-suite-load-tab>-->
<!--    </mat-tab>-->
<!--    <mat-tab label="Log">-->
<!--      <ov-suite-log-tab></ov-suite-log-tab>-->
<!--    </mat-tab>-->
<!--  </mat-tab-group>-->


  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">

      <div class="ht-block"
        [ngClass]="{ 'ht-selected': active === 1 }"
        [style.border-bottom]="active === 1 ? '3px solid ' + highlightColor : 'none'">
        <a ngbNavLink>Detail</a>
      </div>
      <ng-template ngbNavContent>
        <ov-suite-load-detail-tab></ov-suite-load-detail-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <div class="ht-block"
           [ngClass]="{ 'ht-selected': active === 2 }"
           [style.border-bottom]="active === 2 ? '3px solid ' + highlightColor : 'none'">
        <a ngbNavLink>Stage Details</a>
      </div>
      <ng-template ngbNavContent>
        <ov-suite-stage-tab></ov-suite-stage-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <div class="ht-block"
           [ngClass]="{ 'ht-selected': active === 3 }"
           [style.border-bottom]="active === 3 ? '3px solid ' + highlightColor : 'none'">
        <a ngbNavLink>Pick Details</a>
      </div>
      <ng-template ngbNavContent>
        <ov-suite-picks-tab></ov-suite-picks-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <div class="ht-block"
           [ngClass]="{ 'ht-selected': active === 4 }"
           [style.border-bottom]="active === 4 ? '3px solid ' + highlightColor : 'none'">
        <a ngbNavLink>Load Details</a>
      </div>
      <ng-template ngbNavContent>
        <ov-suite-load-tab></ov-suite-load-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="6">
      <div class="ht-block"
           [ngClass]="{ 'ht-selected': active === 6 }"
           [style.border-bottom]="active === 6 ? '3px solid ' + highlightColor : 'none'">
        <a ngbNavLink>Load Amount Details</a>
      </div>
      <ng-template ngbNavContent>
        <ov-suite-load-amount-tab></ov-suite-load-amount-tab>
      </ng-template>
    </li>
    <li [ngbNavItem]="5">
      <div class="ht-block"
           [ngClass]="{ 'ht-selected': active === 5 }"
           [style.border-bottom]="active === 5 ? '3px solid ' + highlightColor : 'none'">
        <a ngbNavLink>Load Log</a>
      </div>
      <ng-template ngbNavContent>
        <ov-suite-log-tab></ov-suite-log-tab>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
