<div class="main-content">
  <div class="main-container">
    <ov-suite-clean-table
      #pickTable
      [columnData]="columnData"
      [dataSource]="dataSource"
      emptyMessage="No Picks Currently"
    >
    </ov-suite-clean-table>
  </div>
</div>
