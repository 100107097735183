import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { DateRangeComponent } from './date-range.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    NgbDatepickerModule,
    CommonModule,
    // NgbModule,
    // CommonModule,
    // NgbDatepickerModule,
    // FormsModule,
    // ReactiveFormsModule,
  ],
  declarations: [DateRangeComponent],
  exports: [DateRangeComponent],
})
export class DateRangeModule {}
