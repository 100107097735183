export enum FeatureKey {
  MANAGE_USERS = 28,
  VIEW_PROFILE = 40,
  PICKING_WAVES = 101,
  VEHICLE_RESOURCES = 102,
  ROUTES = 103,
  LOAD_ALLOCATION = 104,
  WAVE_ALLOCATION = 105,
  INVENTORY_LIST = 106,
  INVENTORY_PRODUCT = 107,
  PROCESS_DEFINITIONS = 108,
  WORKFLOW_PROCESSES = 109,
  EXCEPTIONS = 110,
  RESOURCES = 110,
  ORDERS = 301,
  CREDIT_LIMIT_OVERRIDE = 302,
  DOMAINS = 901,
  AREAS = 902,
  CUSTOMERS = 903,
  FACTORIES = 904,
  PRODUCTS = 905,
  EQUIPMENT = 906,
  TAGS = 907,
  TRANSPORTERS = 908,
  VEHICLE_CLASSES = 909,
  VEHICLES = 910,
  VEHICLE_TYPES = 911,
}
