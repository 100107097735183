import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { UserModel } from '@ov-suite/models-idm';

@OVEntity({ name: 'LoadAmount' })
export class LoadAmountModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'number' })
  amount: number;

  @OVField({ type: 'number' })
  productSkuId: number;

  @OVField({ type: () => ProductSkuModel })
  productSku: ProductSkuModel;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: 'number' })
  createdById: number;

  @OVField({ type: () => UserModel })
  createdBy: UserModel;
}
