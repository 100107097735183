type App = 'idm' | 'admin' | 'warehouse' | 'yard' | 'execution' | 'ceramicPortal' | 'order';

type WebUrlEnvironment = Record<App, string>;
type WebPortEnvironment = Record<App, number>;
type AppIdEnvironment = Record<App, number>;

interface OtherEnvironment {
  mapApiKey: string;
  awsFileServiceApi: string;
}

interface InhanceWorkerEnvironment {
  authUrl: string;
  appClientId: string;
  appClientSecret: string;
  apiUrl: string;
}

interface RedisEnvironment {
  host?: string;
  port?: number;
  db?: number;
  password?: string;
}

export interface Environment {
  production: boolean;
  location: string;
  envName: string;

  domain: string;
  apiUrl: string;
  sentryUrl?: string;

  webUrl: WebUrlEnvironment;

  webPort: WebPortEnvironment;

  appId: AppIdEnvironment;

  other: OtherEnvironment;

  inhanceWorker?: InhanceWorkerEnvironment;

  redis?: RedisEnvironment;
}

class Env implements Environment {
  production = false;

  location = 'local';

  envName = 'development';

  sentryUrl;

  domain;

  // apiUrl = 'https://dev.api.ovsuite.com';
  apiUrl;

  webUrl: WebUrlEnvironment = {
    idm: 'http://localhost:4201',
    admin: 'http://localhost:4202',
    warehouse: 'http://localhost:4203',
    yard: 'http://localhost:4204',
    execution: 'http://localhost:4205',
    ceramicPortal: 'http://localhost:4206',
    order: 'http://localhost:4207',
  };

  webPort: WebPortEnvironment = {
    idm: 4201,
    admin: 4202,
    warehouse: 4203,
    yard: 4204,
    execution: 4205,
    ceramicPortal: 4206,
    order: 4207,
  };

  appId: AppIdEnvironment = {
    idm: 8,
    admin: 9,
    warehouse: 1,
    yard: 6,
    execution: 5,
    ceramicPortal: 7,
    order: 3,
  };

  other: OtherEnvironment = {
    mapApiKey: `AIzaSyAd5CAIBIGzFYxj8SU1i1VN3pTXf3O8Ugs`,
    awsFileServiceApi: `https://uun21udnjd.execute-api.eu-central-1.amazonaws.com/dev`,
  };

  inhanceWorker?: InhanceWorkerEnvironment;

  redis?: RedisEnvironment;

  updateValues(env: Environment) {
    if (env) {
      localStorage.setItem('config', JSON.stringify(env));
    }
    const fields: (keyof Environment)[] = [
      'production',
      'envName',
      // 'cognito',
      'apiUrl',
      'webUrl',
      'appId',
      'other',
      'inhanceWorker',
      'redis',
    ];
    fields.forEach((key: keyof Environment) => {
      if (env[key]) {
        // @ts-ignore
        this[key] = env[key];
      }
    });
  }
}

const d = new Env();
export const setEnvironment = (env: Environment) => {
  d.updateValues(env);
};
export const environment = d;
