import { OVEntity, OVField, OVTable } from '@ov-suite/ov-metadata';
import { InventoryContainer, LoadAllocation } from '@ov-suite/models-warehouse';
import { PickStageStatus } from '@ov-suite/helpers-shared';
import { OrderItemModel } from '@ov-suite/models-order';
import { Inventory, InventoryLocationModel, ProductSkuModel } from '@ov-suite/models-admin';
import { UserModel } from '@ov-suite/models-idm';

@OVEntity({ name: 'Pick' })
@OVTable<PickModel>([
  {
    key: 'id',
    title: 'ID',
    type: 'number',
  },
  {
    type: 'date',
    title: 'Created',
    key: 'createdDate'
  },
  {
    title: 'Load Bay',
    type: 'deep-string',
    key: 'load.loadBay.name',
    keys: ['load', 'pick.loadBay'],
  },
  {
    title: 'Container',
    type: 'deep-string',
    key: 'load.wave.waveConfig.waveName',
    keys: ['load', 'load.wave', 'load.wave.waveConfig', 'load.wave.waveConfig.waveName'],
  },
  {
    title: 'SKU',
    type: 'deep-string',
    key: 'productSku.sku',
    keys: ['productSku']
  },
  {
    title: 'Status',
    type: 'string',
    key: 'pickStatus',
  },
])
export class PickModel {
  @OVField({
    type: 'number',
    sidebar: true,
    generated: true,
    readonly: true,
  })
  id: number;

  @OVField({ type: 'string' })
  pickStatus: PickStageStatus;

  @OVField({ type: 'number' })
  priority: number; // Lower = more urgent

  @OVField({ type: () => LoadAllocation })
  load: LoadAllocation;

  @OVField({ type: () => OrderItemModel })
  orderItem: OrderItemModel;

  @OVField({ type: 'number' })
  quantity: number;

  @OVField({ type: () => ProductSkuModel })
  productSku?: ProductSkuModel;

  @OVField({ type: 'date' })
  createdDate: Date;

  @OVField({ type: 'date' })
  startDate?: Date;

  @OVField({ type: 'date' })
  completedDate?: Date;

  @OVField({ type: 'date' })
  reservationDate?: Date;

  @OVField({ type: 'date' })
  pauseDate?: Date;

  @OVField({ type: 'string' })
  pickProcessId?: string;

  @OVField({ type: () => UserModel })
  createdByUser?: UserModel;

  @OVField({ type: () => UserModel })
  handledByUser?: UserModel;

  @OVField({ type: 'string' })
  exceptionMessage?: string;

  @OVField({ type: 'boolean' })
  generated?: boolean;

  @OVField({ type: () => InventoryContainer })
  containerPicked?: InventoryContainer;

  @OVField({ type: () => Inventory })
  inventoryPicked?: Inventory;

  @OVField({ type: () => InventoryLocationModel })
  source: InventoryLocationModel;

  @OVField({ type: () => InventoryLocationModel })
  target: InventoryLocationModel;
}
