import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { VehicleClass } from '@ov-suite/models-admin';
import { LoadAllocationOrderService } from '../data-services/load-allocation.order.service';
import { LoadDTO, LoadFilter, OrderDTO } from '../../load-allocation.interface';
import { LoadAllocationEventBusService } from '../event-bus/load-allocation.event-bus.service';
import {
  LoadReleasedEvent,
  OrderMovedEvent,
  OrdersClearedEvent,
  OrderSelectedEvent,
  OrderSplitEvent,
} from '../event-bus/load-allocation.events';
import { LoadAllocationLoadAllocationService } from '../data-services/load-allocation.load-allocation.service';
import { LoadAllocationVehicleService } from '../data-services/load-allocation.vehicle.service';
import { LoadAllocationDateService } from '../load-allocation.date.service';
import { LoadAllocationVehicleHelperService } from '../data-services/load-allocation.vehicle-helper.service';

/**
 * Fully manages and maintains the view of orders on the top right tab
 * Controls paging, ordering, filters and search.
 *
 * This does not FETCH the data. that is handled by order service
 */
@Injectable()
export class LoadAllocationViewService {
  /** Set of all the orders loaded at any given point */
  private readonly allOrderDTOs$: BehaviorSubject<OrderDTO[]> = new BehaviorSubject([]);

  /** Set of all the loads that are loaded at any given point */
  private readonly allLoadDTOs$: BehaviorSubject<LoadDTO[]> = new BehaviorSubject([]);

  /** Maps to help access data rapidly without heavy .find calls */
  private readonly allOrdersDTOMap: Map<number, OrderDTO> = new Map(); // Order ID => Order DTO

  private readonly vehicleIdLoadDTOMap: Map<string, LoadDTO> = new Map(); // Vehicle ID => Load DTO

  private readonly loadIdLoadDTOMap: Map<number | string, LoadDTO> = new Map(); // Load ID => LoadDTO

  /** Array of order DTOs to be displayed on the top right pane. Filters should be applied here */
  public readonly displayedOrderDTOs$: BehaviorSubject<OrderDTO[]> = new BehaviorSubject(null);

  /** Array of vehicle DTOs to be displayed on the lower left pane. Filters should be applied here */
  public readonly displayedLoadDTOs$: BehaviorSubject<LoadDTO[]> = new BehaviorSubject(null);

  /** Handles showing which order has currently been selected */
  public readonly selectedOrderDTO$: BehaviorSubject<OrderDTO> = new BehaviorSubject<OrderDTO>(null);

  public vehicleSearchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public vehicleStatusFilter$: BehaviorSubject<LoadFilter> = new BehaviorSubject(LoadFilter.All);

  public vehicleClassFilter$: BehaviorSubject<VehicleClass> = new BehaviorSubject<VehicleClass>(null);

  public orderSearchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private readonly orderService: LoadAllocationOrderService,
    private readonly loadAllocationService: LoadAllocationLoadAllocationService,
    private readonly vehicleService: LoadAllocationVehicleService,
    private readonly eventBus: LoadAllocationEventBusService,
    private readonly dateService: LoadAllocationDateService,
    private readonly vehicleHelperService: LoadAllocationVehicleHelperService,
  ) {
    this.subscribeToDateChanges();
    this.subscribeToOrderDTOChanges();
    this.subscribeToLoadedOrders();
    this.subscribeToOrderChanges();

    this.subscribeToLoadDTOChanges();
    this.subscribeToLoadedVehiclesAndLoads();
    this.subscribeToLoadChanges();

    /** Event Bus Subscriptions */
    this.subscribeToOrdersMoving();
  }

  /** If Date Changes, clear everything. Possible Race Condition Here */
  private subscribeToDateChanges(): void {
    this.dateService.date$.subscribe(() => {
      this.selectedOrderDTO$.next(null);
      this.allLoadDTOs$.next([]);
      this.allOrderDTOs$.next([]);
      this.allOrdersDTOMap.clear();
      this.vehicleIdLoadDTOMap.clear();
      this.loadIdLoadDTOMap.clear();
      this.displayedOrderDTOs$.next([]);
      this.displayedLoadDTOs$.next([]);
    });
  }

  /** Ensures that the order DTO map is always up-to-date */
  private subscribeToOrderDTOChanges(): void {
    this.allOrderDTOs$.subscribe(dtoArray => {
      dtoArray.forEach(dto => {
        this.allOrdersDTOMap.set(dto.orderId, dto);
      });
    });
  }

  /** When an order comes in, it is added to the set */
  private subscribeToLoadedOrders(): void {
    this.orderService.orderList$.subscribe(orders => {
      const dtoSet = [];
      orders.forEach(order => {
        if (this.allOrdersDTOMap.has(order.id)) {
          dtoSet.push(this.allOrdersDTOMap.get(order.id));
        } else {
          const dto = OrderDTO.fromOrder(order);
          dtoSet.push(dto);
        }
      });
      this.allOrderDTOs$.next(dtoSet);
    });
  }

  /** Ensures that whenever new orders are added or allocated, the correct orders are displayed on the order list */
  private subscribeToOrderChanges(): void {
    combineLatest([this.allOrderDTOs$, this.orderService.allocatedOrderIds])
      .pipe(
        map(([orderIds, allocatedIds]) => {
          const newSet = new Set(orderIds.map(o => o.orderId));
          allocatedIds.forEach(id => newSet.delete(id));
          return newSet;
        }),
      )
      .subscribe(idSet => {
        const displayOrder = [...idSet];
        displayOrder.sort((a, b) => b - a);
        const dtoMap = this.allOrdersDTOMap;
        this.displayedOrderDTOs$.next(displayOrder.map(id => dtoMap.get(id)));
      });
  }

  /** Ensures that the load DTO maps are always up-to-date. Apply filters here */
  private subscribeToLoadChanges(): void {
    combineLatest([this.allLoadDTOs$, this.vehicleSearchTerm$, this.vehicleClassFilter$, this.vehicleStatusFilter$]).subscribe(
      ([dtoArray, searchTerm, vehicleClass, vehicleStatus]) => {
        let output = [...dtoArray];

        if (searchTerm || vehicleClass || vehicleStatus) {
          const lowerTerm = searchTerm?.toLowerCase();
          const filteredVehicles = this.vehicleHelperService.listSimple$().value.filter(vehicle => {
            let result = true;
            if (searchTerm) {
              result = vehicle.registration.toLowerCase().includes(lowerTerm) || vehicle.className.toLowerCase().includes(lowerTerm);
            }
            if (result && vehicleClass) {
              result = vehicle.classId === vehicleClass.id;
            }
            return result;
          });
          output = filteredVehicles.map(vehicle => this.vehicleIdLoadDTOMap.get(vehicle.identifier));
          if (vehicleStatus) {
            output = output.filter(dto => dto.status === vehicleStatus);
          }
        }
        this.displayedLoadDTOs$.next(output);
      },
    );
  }

  private subscribeToLoadedVehiclesAndLoads(): void {
    combineLatest([this.loadAllocationService.list$(), this.vehicleHelperService.listSimple$()])
      .pipe(
        filter(([loads, vehicles]) => !!loads && !!vehicles),
        map(([loads, vehicles]) => {
          const mapOfDTOs: Record<string, LoadDTO> = {};

          loads.forEach(load => {
            const existing = this.vehicleIdLoadDTOMap.get(load.getVehicleIdentifier());
            if (!existing) {
              const vehicle = vehicles.find(veh => veh.identifier === load.getVehicleIdentifier());
              mapOfDTOs[load.getVehicleIdentifier()] = LoadDTO.fromLoadAllocation(load, vehicle);

              return;
            }
            mapOfDTOs[load.getVehicleIdentifier()] = existing;
            if (existing.loadId !== load.id) {
              existing.loadId = load.id;
            }
          });
          return vehicles.map(vehicle => mapOfDTOs[vehicle.identifier]).filter(vehicle => !!vehicle);
        }),
      )
      .subscribe(loads => {
        this.allLoadDTOs$.next([...loads]);
      });
  }

  private subscribeToLoadDTOChanges(): void {
    this.allLoadDTOs$.subscribe(dtoArray => {
      dtoArray.forEach(dto => {
        this.loadIdLoadDTOMap.set(dto.loadId, dto);
        this.vehicleIdLoadDTOMap.set(dto.getVehicleIdentifier(), dto);
      });
    });
  }

  /** **** Event Bus Subscriptions **** */

  /**
   * Core logic for dragging and dropping of orders. Handles the view changes only.
   * Does not manage load allocations themselves. This is handled by load-allocation service
   * */
  subscribeToOrdersMoving() {
    this.eventBus.events$.subscribe(event => {
      if (event instanceof OrderMovedEvent) {
        this.handleOrderMovedEvent(event);
        return;
      }
      if (event instanceof OrderSelectedEvent) {
        this.handleOrderSelectedEvent(event);
        return;
      }
      if (event instanceof OrdersClearedEvent) {
        this.handleOrdersCleared();
        return;
      }
      if (event instanceof LoadReleasedEvent) {
        this.handleLoadReleased(event);
        return;
      }
      if (event instanceof OrderSplitEvent) {
        this.handleOrderSplitEvent(event);
      }
    });
  }

  /** ** Event Handlers ** */

  /**
   * Handles the view elements of:
   *  - Moving item within its own seciton
   *  - Removing the item from the list on the upper right
   *  - Adding the item to the allocation on the lower left
   */
  handleOrderMovedEvent(event: OrderMovedEvent): void {
    if (event.source === event.target) {
      const list = event.source === 'list' ? this.displayedOrderDTOs$.value : event.source.orderDTOs;
      moveItemInArray(list, event.sourceIndex, event.targetIndex);
      return;
    }
    if (event.source === 'list' && event.target !== 'list') {
      this.orderService.allocateOrderIds(event.orderDTO.orderId);
    }
    if (event.target === 'list' && event.source !== 'list') {
      this.orderService.deallocateOrderIds(event.orderDTO.orderId);
    }
    if (event.target !== 'list') {
      event.target.orderDTOs.push(event.orderDTO);
      event.target.updateStatus();
    }
    if (event.source !== 'list') {
      event.source.orderDTOs = event.source.orderDTOs.filter(dto => dto !== event.orderDTO);
      event.source.updateStatus();
    }
  }

  private handleOrderSelectedEvent(event: OrderSelectedEvent): void {
    this.selectedOrderDTO$.next(event.orderDTO);
  }

  private handleOrdersCleared(): void {
    this.displayedOrderDTOs$.next([]);
  }

  private handleLoadReleased(event: LoadReleasedEvent): void {
    const dto = this.loadIdLoadDTOMap.get(event.loadId);
    dto.committed = event.state;
  }

  private handleOrderSplitEvent(event: OrderSplitEvent): void {
    const newDTO1 = OrderDTO.fromOrder(event.newOrder1);
    const newDTO2 = OrderDTO.fromOrder(event.newOrder2);
    const index = this.displayedOrderDTOs$.value.findIndex(dto => dto.orderId === event.originalOrderId);
    this.selectedOrderDTO$.next(newDTO1);
    if (index >= 0) {
      console.log('Found index');
      const list = [...this.displayedOrderDTOs$.value];
      list[index] = newDTO1;
      list.splice(index, 1, newDTO1, newDTO2);
      this.displayedOrderDTOs$.next(list);
      return;
    }
    for (const loadDTO of this.allLoadDTOs$.value) {
      const loadIndex = loadDTO.orderDTOs.findIndex(orderDTO => orderDTO.orderId === event.originalOrderId);
      if (loadIndex >= 0) {
        loadDTO.orderDTOs.splice(loadIndex, 1, newDTO1, newDTO2);
        break;
      }
    }
  }

  /** ******* External Commands ****** */
  public setVehicleSearchTerm(event: Event): void {
    this.vehicleSearchTerm$.next((event.target as HTMLInputElement).value || null);
  }

  public setOrderSearchTerm(event: Event): void {
    this.orderSearchTerm$.next((event.target as HTMLInputElement).value || null);
  }

  public setVehicleStatusFilter(loadFilter: LoadFilter): void {
    this.vehicleStatusFilter$.next(loadFilter);
  }

  public setVehicleClassFilter(vehicleClass: VehicleClass): void {
    this.vehicleClassFilter$.next(vehicleClass);
  }
}
