import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { ExternalVehicle, LoadAllocation } from '@ov-suite/models-warehouse';
import { BehaviorSubject, combineLatest, filter, Subscription } from 'rxjs';
import { v4 } from 'uuid';
import { DomainService } from '@ov-suite/helpers-angular';
import { getCreate } from '@ov-suite/graphql-helpers';
import * as _ from 'lodash';
import moment from 'moment';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { DocumentNode } from 'graphql';
import { MutationResult } from 'apollo-angular';
import { LazyService } from './load-allocation.lazy.service';
// eslint-disable-next-line import/no-cycle
import { LoadAllocationVehicleService } from './load-allocation.vehicle.service';
import { LoadAllocationOrderService } from './load-allocation.order.service';
import { LoadAllocationProductSkuService } from './load-allocation.product-sku.service';
import { LoadDTO } from '../../load-allocation.interface';
import { loadKeys } from '../../graphql-helpers/keys.helpers';
import { LoadAllocationDateService } from '../load-allocation.date.service';
import { LoadAllocationEventBusService } from '../event-bus/load-allocation.event-bus.service';
import { LoadReleasedEvent, OrderMovedEvent, OrderSplitEvent } from '../event-bus/load-allocation.events';
import { upsertLoadAllocationGql } from '../../load-allocation.grahpql';

/**
 * Used to manage Load Allocations in various ways
 */
@Injectable()
export class LoadAllocationLoadAllocationService extends LazyService<LoadAllocation> {
  subscriptions: Subscription[] = [];

  /** LoadId to DTO */
  loadToDTOMap: Map<string | number, BehaviorSubject<LoadDTO>> = new Map();

  dirtyLoadSet: Set<number | string> = new Set();

  private readonly debounceSave: Function = _.debounce(() => this.saveLoadAllocations(), 1000, {
    leading: false,
    trailing: true,
    maxWait: 5000,
  });

  constructor(
    private readonly ovAutoService: OvAutoService,
    private readonly vehicleService: LoadAllocationVehicleService,
    private readonly orderService: LoadAllocationOrderService,
    private readonly productSkuService: LoadAllocationProductSkuService,
    private readonly dateService: LoadAllocationDateService,
    private readonly domainService: DomainService,
    private readonly eventBus: LoadAllocationEventBusService,
  ) {
    super(LoadAllocation, ovAutoService, { relations: ['externalVehicle'] });
    this.subscribeToDateChanges();
    this.subscribeToVehicleChanges();
    this.subscribeToEventBus();
  }

  /** Need to reload data on Date change */
  private subscribeToDateChanges() {
    this.dateService.date$.subscribe(date => {
      this.itemMap.clear();
      this.draftItemMap.clear();
      this.loadToDTOMap.clear();
      this.dirtyLoadSet.clear();
      this.fetchLoadAllocations(date);
    });
  }

  /** When vehicles are loaded and Existing loads are loaded. Draft load allocations needs to be created */
  private subscribeToVehicleChanges(): void {
    combineLatest([this.vehicleService.list$(), this.list$()])
      .pipe(filter(([vehicles, loads]) => !!vehicles?.length && !!loads))
      .subscribe(([vehicles, loads]) => {
        const draftsToCreate: LoadAllocation[] = [];
        const loadMap: Record<number, LoadAllocation> = {};

        loads.forEach(load => {
          loadMap[load.vehicleId] = load;
        });

        vehicles.forEach(vehicle => {
          if (!loadMap[vehicle.id]) {
            const draftLoad = new LoadAllocation();
            draftLoad.id = v4() as unknown as number; // Will be converted to number when saved
            draftLoad.vehicleId = vehicle.id;
            draftLoad.orderIds = [];
            draftLoad.date = moment(this.dateService.date$.value).format('YYYY-MM-DD');
            draftsToCreate.push(draftLoad);
          }
        });

        if (draftsToCreate.length) {
          this.loadDraftItems(draftsToCreate);
        }
      });
  }

  private subscribeToEventBus(): void {
    this.eventBus.events$.subscribe(event => {
      if (event instanceof OrderMovedEvent) {
        this.handleOrderMovedEvent(event);
      }
      if (event instanceof OrderSplitEvent) {
        this.handleOrderSplitEvent(event);
      }
    });
  }

  private handleOrderMovedEvent(event: OrderMovedEvent): void {
    if (event.target === 'list' && event.source === 'list') {
      // We can safely ignore this event as it does not change anything
      return;
    }
    if (event.target !== 'list' && event.source !== 'list' && event.source === event.target) {
      const { loadId } = event.target;
      const list = this.get$(loadId).value.orderIds;
      moveItemInArray(list, event.sourceIndex, event.targetIndex);
      this.dirtyLoadSet.add(loadId);
      return;
    }
    if (event.target !== 'list') {
      const { loadId } = event.target;
      this.get$(loadId).value.orderIds.push(event.orderDTO.orderId);
      this.dirtyLoadSet.add(loadId);
      this.debounceSave();
    }
    if (event.source !== 'list') {
      const { loadId } = event.source;
      this.get$(loadId).value.orderIds = this.get$(loadId).value.orderIds.filter(id => id !== event.orderDTO.orderId);
      // this.get$(loadId).value.orders = this.get$(loadId).value.orders.filter(order => order.id !== event.orderDTO.orderId);
      this.dirtyLoadSet.add(loadId);
      this.debounceSave();
    }
  }

  private handleOrderSplitEvent(event: OrderSplitEvent): void {
    for (const load of this.list$().value) {
      const index = load.orderIds.findIndex(id => id === event.originalOrderId);
      if (index >= 0) {
        load.orderIds.splice(index, 1, event.newOrder1.id, event.newOrder2.id);
        this.dirtyLoadSet.add(load.id);
        this.debounceSave();
        break;
      }
    }
  }

  /** ** GraphQL Methods *** */
  public async fetchLoadAllocations(date: Date): Promise<void> {
    this.ovAutoService
      .multipleFetch({
        loads: {
          entity: LoadAllocation,
          type: 'custom',
          paramsName: 'planDate',
          paramsType: 'DateTime',
          name: 'listAllocationLoads',
          variables: date,
          keys: loadKeys,
          mapToClass: true,
        },
      })
      .then(response => {
        const loads: LoadAllocation[] = response.loads as LoadAllocation[];

        loads.forEach(load => {
          this.orderService.allocateOrderIds(...load.orderIds);
        });

        this.loadItems(loads);
      });
  }

  public async saveLoadAllocations(): Promise<void> {
    const domainPath = this.domainService.currentDomain.value;
    // ¯\_(ツ)_/¯
    const loads = [];
    this.dirtyLoadSet.forEach(loadId => {
      let load: LoadAllocation;

      if (typeof loadId === 'string') {
        load = this.draftItemMap.get(loadId).value;
        load['tempId'] = loadId;
      } else {
        load = this.itemMap.get(loadId).value;
      }

      delete load.id;
      const item = getCreate(load);
      item['domainIds'] = domainPath.path;

      loads.push(item);
    });

    if (!loads.length) {
      return;
    }

    const sessionId = sessionStorage.getItem('lockedSession');
    const source = 'load-allocation';

    await this.executeGraphQL(upsertLoadAllocationGql(), { loads, sessionId, source })
      .then(res => {
        this.dirtyLoadSet.clear();
        // ¯\_(ツ)_/¯
        // ¯\_(ツ)_/¯
        // ¯\_(ツ)_/¯
        // const savedResponse = res.data['upsertLoadAllocation'];
        // const itemsToPromote: Record<string, LoadAllocation> = {};
        // savedResponse.forEach(item => {
        //   if (item.type === 'create') {
        //     console.log('\n\n\n\n\n\n After Creation');
        //     console.log(item);
        //
        //     // const oldId = aliasLibrary[item.tempId];
        //
        //     // console.log({ oldId });
        //
        //     // console.log('GOT VALUE: ', this.get$(oldId).value);
        //     // item.commitDate = new Date();
        //     // item.releaseDate = null;
        //
        //     // const oldId = aliasLibrary[item.tempId];
        //     itemsToPromote[item.tempId] = Object.assign(this.get$(item.tempId).value, { id: item.id });
        //     this.dirtyLoadSet.delete(item.tempId);
        //   } else if (item.type === 'update') {
        //     this.dirtyLoadSet.delete(item.id);
        //   }
        //   this.promoteDrafts(itemsToPromote);
        // });
      })
      .catch(() => {
        window.location.reload();
      });
  }

  executeGraphQL<T, U>(query: DocumentNode, data: T): Promise<MutationResult<U>> {
    return this.ovAutoService.apollo
      .mutate<U>({
        mutation: query,
        fetchPolicy: 'no-cache',
        variables: {
          ...data,
        },
      })
      .toPromise();
  }

  addExternalVehicles(selected: ExternalVehicle[]) {
    const draftItems: LoadAllocation[] = selected.map(vehicle => {
      const draftLoad = new LoadAllocation();
      draftLoad.id = v4() as unknown as number; // Will be converted to number when saved
      draftLoad.vehicleId = null;
      draftLoad.externalVehicleId = vehicle.id;
      draftLoad.orderIds = [];
      draftLoad.date = moment(this.dateService.date$.value).format('YYYY-MM-DD');
      this.dirtyLoadSet.add(draftLoad.id);
      return draftLoad;
    });

    this.loadDraftItems(draftItems);
    this.debounceSave();
  }

  public commit(loadId: number | string) {
    const load = this.get$(loadId).value;
    if (load) {
      this.eventBus.triggerEvent(new LoadReleasedEvent(loadId, true));
      load.commit = true;
      load.commitDate = new Date();
      this.dirtyLoadSet.add(loadId);
      this.debounceSave();
    }
  }

  public uncommit(loadId: number | string): void {
    const load = this.get$(loadId).value;
    if (load) {
      this.eventBus.triggerEvent(new LoadReleasedEvent(loadId, false));
      load.commit = false;
      load.commitDate = null;
      this.dirtyLoadSet.add(loadId);
      this.debounceSave();
    }
  }

  onDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
