<div class="outer-container">
  <div class="main-container">
    <mat-card>
        <div class="filter-row">
          <ov-suite-multi-select-dropdown
            #productFilter
            class="filter-item"
            title="Product"
            [list]="productList"
            (change)="setProductFilters($event)"
          ></ov-suite-multi-select-dropdown>
          <ov-suite-multi-select-dropdown
            #statusFilter
            class="filter-item"
            title="Status"
            [list]="statusList"
            (change)="setStatusFilter($event)"
          ></ov-suite-multi-select-dropdown>
          <button mat-stroked-button class="filter-item" (click)="clearFilters()">Clear Filters</button>
          <ng-container *ngIf="!(loadManagementDataService.load | async)?.completeDate">
            <button mat-stroked-button color="primary" class="filter-item" (click)="openAddModal()">Add</button>
            <button mat-stroked-button color="warn" class="filter-item" [disabled]="!resettablePicks$.value.size" (click)="reset()">Reset</button>
            <button mat-stroked-button color="warn" class="filter-item" [disabled]="!cancellablePicks$.value.size" (click)="cancel()">Cancel</button>
            <button mat-stroked-button color="warn" class="filter-item" [disabled]="!pausablePicks$.value.size" (click)="pause()">{{shouldUnpause() ? 'Unpause' : 'Pause'}}</button>
          </ng-container>
          <ng-container>
            <ov-suite-excel-formatter class="filter-item"
                                      [formClass]="formClass"
                                      [columnData]="columnData"
                                      (overrideExportEmitter)="fetchData()"
                                      [overrideExportData]="fetchDownloadData"
                                      [overrideFetchQuery]="true"
            ></ov-suite-excel-formatter>
          </ng-container>
        </div>

        <ov-suite-clean-table
          #pickTable
          [columnData]="columnData"
          [dataSource]="dataSource"
          [selectable]="!(loadManagementDataService.load | async)?.completeDate"
          (selected)="setSelected($event)"
          emptyMessage="No Picks Created"
        >
        </ov-suite-clean-table>
    </mat-card>
  </div>
</div>

<ng-template #cancellablePicksTable>
  <ov-suite-clean-table
    [dataSource]="cancellablePicksDataSource"
    [columnData]="columnData"
    [disablePaging]="true"
    [disableSorting]="true"
  >
  </ov-suite-clean-table>
</ng-template>

<ng-template #resettablePicksTable>
  <ov-suite-clean-table
    [dataSource]="resettablePicksDataSource"
    [columnData]="columnData"
    [disablePaging]="true"
    [disableSorting]="true"
  >
  </ov-suite-clean-table>
</ng-template>

<ng-template #pausablePicksTable>
  <ov-suite-clean-table
    [dataSource]="pausablePicksDataSource"
    [columnData]="columnData"
    [disablePaging]="true"
    [disableSorting]="true"
  >
  </ov-suite-clean-table>
</ng-template>

<ng-template #pickingItemLogHistoryDialog>
  <ov-suite-picking-item-log-modal
    [loadManagementDataService]="loadManagementDataService"
  >
  </ov-suite-picking-item-log-modal>
</ng-template>
