import { OVField, OVForm, OVSearchable, OVEntity, OVTable, OVSearch } from '@ov-suite/ov-metadata';
import { DomainModel, HasSimple, VehicleSimple } from '@ov-suite/models-admin';

@OVEntity('ExternalVehicle', 'shared')
@OVTable<ExternalVehicle>([
  {
    key: 'registration',
    title: 'Vehicle Reg.',
    type: 'string',
  },
  {
    key: 'vehicleClass',
    title: 'Vehicle Class',
    type: 'string',
  },
  {
    key: 'make',
    title: 'Vehicle Make',
    type: 'string',
  },
  {
    key: 'model',
    title: 'Vehicle Model',
    type: 'string',
  },
  {
    key: 'startTime',
    startHidden: true,
    title: 'Start Time',
    type: 'string',
    disableFiltering: true,
  },
  {
    key: 'endTime',
    startHidden: true,
    title: 'End Time',
    type: 'string',
    disableFiltering: true,
  },
  {
    key: 'weightLimit',
    startHidden: true,
    title: 'Weight Limit (kg)',
    type: 'string',
    disableFiltering: true,
  },
  {
    title: 'Dimension',
    type: 'other',
    startHidden: true,
    disableFiltering: true,
    keys: ['height', 'width', 'length'],
    action: item => String(item?.height * item?.width * item?.length),
  },
])
@OVForm([
  ['#External Vehicle'],
  ['vehicleClass', 'registration'],
  ['make', 'model'],
  ['weightLimit'],
  ['height', 'width', 'length'],
  ['startTime', 'endTime'],
  ['domains'],
])
@OVSearch<ExternalVehicle>({ searchKeys: ['make', 'registration', 'model'] })
export class ExternalVehicle implements HasSimple {
  @OVField({
    type: 'string',
    title: 'Unique ID',
    sidebar: true,
    generated: true,
    readonly: false,
  })
  id: number;

  @OVField({
    type: 'string',
    title: 'Vehicle Make',
    tooltip: 'Vehicle Make',
    placeholder: 'Isuzu',
    required: true,
  })
  make: string;

  @OVField({
    type: 'string',
    title: 'Vehicle Model',
    tooltip: 'Vehicle Model',
    placeholder: 'Model Name',
    required: true,
  })
  model: string;

  @OVSearchable()
  @OVField({
    type: 'string',
    title: 'Vehicle Registration',
    placeholder: '444GC0GP',
    required: true,
  })
  registration: string;

  @OVField({
    type: 'string',
    title: 'Vehicle Class',
    placeholder: '8 Ton Truck',
    required: true,
  })
  vehicleClass: string;

  @OVField({
    type: 'number',
    title: 'Length (cm)',
    placeholder: 'Length',
    required: false,
  })
  length?: number;

  @OVField({
    type: 'number',
    title: 'Height (cm)',
    placeholder: 'Height',
    required: false,
  })
  height?: number;

  @OVField({
    type: 'number',
    title: 'Width (cm)',
    placeholder: 'Width',
    required: false,
  })
  width?: number;

  @OVField({
    type: 'number',
    title: 'Weight Limit (kg)',
    placeholder: '1000kg',
    required: false,
  })
  weightLimit?: number;

  @OVField({
    type: 'time',
    title: 'Start Time',
    placeholder: 'Required',
    required: true,
  })
  startTime: string;

  @OVField({
    type: 'time',
    title: 'End Time',
    placeholder: 'Required',
    required: true,
  })
  endTime: string;

  @OVField({
    type: 'domain-selector',
    title: 'Domains',
    required: true,
  })
  domains: DomainModel[];

  getSimple(): VehicleSimple {
    return {
      id: this.id,
      internalId: null,
      externalId: this.id,
      identifier: `e${this.id}`,
      name: this.make,
      registration: this.registration,
      weightLoadAllowed: this.weightLimit ?? 0,
      length: this.length ?? 0,
      width: this.width ?? 0,
      height: this.height ?? 0,
      className: this.vehicleClass,
      classId: null,
      weightRedPercentage: 100,
      weightOrangePercentage: 90,
      volumeRedPercentage: 100,
      volumeOrangePercentage: 90,
    }
  }
}
