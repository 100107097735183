import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ov-suite-vehicle-table-bulk-action',
  templateUrl: './vehicle-table-bulk-action.component.html',
  styleUrls: ['./vehicle-table-bulk-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTableBulkActionComponent implements OnInit {
  constructor() {}

  @Input() dropdownData: Record<string, unknown>;

  @Input() dataSelected: boolean;

  @Output() dataChange = new EventEmitter<Record<string, unknown>>();

  @Output() bulkActionApply = new EventEmitter();

  startTime = '';

  endTime = '';

  startLocation: number;

  endLocation: number;

  changes: Record<string, unknown>;

  ngOnInit() {}

  onCleanUpKeys(data: Record<string, unknown>): Record<string, unknown> {
    let results: Record<string, unknown> = {};
    for (const key in data) {
      if (key) {
        if (data[key]) {
          results = { ...results, [key]: data[key] };
        }
      }
    }

    return results;
  }

  onChange(item, key) {
    this.changes = { ...this.changes, [key]: item };
    this.dataChange.emit(this.onCleanUpKeys(this.changes));
  }

  onBulkActionApply() {
    this.bulkActionApply.emit();
  }

  onClearInputs() {
    this.startTime = '00:00';
    this.endTime = '00:00';
    this.startLocation = null;
    this.endLocation = null;
  }
}
