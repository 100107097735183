import { v4 } from 'uuid';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { LoadDTO, OrderDTO } from '../../load-allocation.interface';
import { OrderModel } from '@ov-suite/models-order';

export abstract class LoadEvent {
  id: string = v4();

  protected constructor(public readonly eventType: string) {}
}

export class OrderMovedEvent extends LoadEvent {
  constructor(
    public readonly orderDTO: OrderDTO,
    public readonly source: LoadDTO | 'list',
    public readonly target: LoadDTO | 'list',
    public readonly sourceIndex: number,
    public readonly targetIndex: number,
  ) {
    super('OrderMoved');
  }
}

export class OrderSelectedEvent extends LoadEvent {
  constructor(public readonly orderDTO: OrderDTO) {
    super('OrderSelected');
  }
}

export class OrdersClearedEvent extends LoadEvent {
  constructor() {
    super('OrdersCleared');
  }
}

export class LoadReleasedEvent extends LoadEvent {
  constructor(public readonly loadId: number | string, public readonly state: boolean) {
    super('LoadReleased');
  }
}

export class OrderSplitEvent extends LoadEvent {
  constructor(public readonly originalOrderId: number, public readonly newOrder1: OrderModel, public readonly newOrder2: OrderModel) {
    super('OrderSplit');
  }
}

export class DateChangedEvent extends LoadEvent {
  constructor(public readonly newDate: Date) {
    super('DateChanged');
  }
}
