import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';

@OVEntity({ name: 'LoadLog' })
export class LoadLogModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  event: string;

  @OVField({ type: 'string' })
  message: string;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: 'number' })
  createdById: number;

  @OVField({ type: () => UserModel })
  createdBy: UserModel;
}
