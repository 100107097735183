import { Component, Inject, Input, OnInit } from '@angular/core';
import { QueryParams } from '@ov-suite/helpers-shared';
import { OvAutoService } from '@ov-suite/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadManagementDataService } from '../../load-management.data.service';
import { LoadAllocation } from "@ov-suite/models-warehouse";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: 'ov-suite-load-detail-loadbay-change',
  templateUrl: './load-detail-loadbay-change.component.html',
})
export class LoadDetailLoadbayChangeComponent implements OnInit {
  filter?: Record<string, [QueryParams]>;

  form = this.formBuilder.group({
    loadId: [''],
    loadBayId: [''],
    loadBayMinCapacity: ['']
  })

  constructor(
    public readonly ovAutoService: OvAutoService,
    public dataService: LoadManagementDataService,
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<LoadDetailLoadbayChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public load: LoadAllocation
  ) {
  }

  async ngOnInit(): Promise<void> {
    if (!this.dataService.loadBays.getValue().length) {
      await this.dataService.fetchLoadBays();
    }

    this.form.patchValue({
      loadId: this.load?.id,
      loadBayId: this.load?.loadBayId,
      loadBayMinCapacity: this.load?.loadBayMinCapacity,
    });

    this.form.get("loadBayId").valueChanges.subscribe(value => {
      if (value) {
        const loadBays = this.dataService.loadBays.getValue();
        const loadBay = loadBays.find(lb => lb.id == value)
        if (loadBay) {
          this.form.patchValue({
            loadBayMinCapacity: loadBay.minCapacity,
          })
        }
      }
    })
  }

  onSave() {
    if (this.form.valid) {
      let loadBayUpdate = this.form.getRawValue();
      loadBayUpdate.loadBayId = parseInt(loadBayUpdate.loadBayId, 10);
      this.dialogRef.close(loadBayUpdate);
    } else {
      this.form.markAllAsTouched();
    }
  }
}
