import { Injectable } from '@angular/core';
import { OvAutoService } from '@ov-suite/services';
import { OrderModel, SplitParam } from '@ov-suite/models-order';
import { BehaviorSubject, combineLatest, filter, mergeMap, Observable, pairwise, Subscription, tap, zip } from 'rxjs';
import { ProductSkuModel } from '@ov-suite/models-admin';
import { DateRange } from '@ov-suite/ui';
import { QueryParams } from '@ov-suite/helpers-shared';
import * as _ from 'lodash';
import { LazyService } from './load-allocation.lazy.service';
import { LoadAllocationProductSkuService } from './load-allocation.product-sku.service';
import { LoadAllocationDateService } from '../load-allocation.date.service';
import { LoadAllocationEventBusService } from '../event-bus/load-allocation.event-bus.service';
import { splitOrderGql } from '../../graphql-helpers/mutations.gql';
import { OrderSplitEvent } from '../event-bus/load-allocation.events';

interface SplitOrderResponse {
  orderSplit: {
    baseOrder: OrderModel;
    firstOrder: OrderModel;
    secondOrder: OrderModel;
  };
}

/**
 * Used to manage orders in various ways
 */
@Injectable()
export class LoadAllocationOrderService extends LazyService<OrderModel> {
  totalWeightMap: Map<number, BehaviorSubject<number>> = new Map();

  totalVolumeMap: Map<number, BehaviorSubject<number>> = new Map();

  subscriptions: Subscription[] = [];

  /** Set of all orders that have been allocated */
  allocatedOrderIds: BehaviorSubject<Set<number>> = new BehaviorSubject(new Set());

  public readonly orderList$: BehaviorSubject<OrderModel[]> = new BehaviorSubject(null);

  private readonly generalSearchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);

  private readonly customerSearchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);

  private readonly orderSearchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);

  private readonly dateRange$: BehaviorSubject<DateRange> = new BehaviorSubject(null);

  private currentPage = 0;

  private readonly pageSize = 20;

  public ordersLoading = false;

  private readonly debounceFetchInitial: Function = _.debounce(() => this.fetchInitialOrders(), 200, {
    leading: false,
    trailing: true,
    maxWait: 3000,
  });

  constructor(
    private readonly ovAutoService: OvAutoService,
    private readonly productSkuService: LoadAllocationProductSkuService,
    private readonly dateService: LoadAllocationDateService,
    private readonly eventBus: LoadAllocationEventBusService,
  ) {
    super(OrderModel, ovAutoService, {
      keys: ['id', 'alias', 'orderCode', 'customerId', 'orderItems', 'orderItems.id', 'orderItems.productSkuId', 'orderItems.quantity'],
    });
    this.subscribeToDateChanges();
    this.subscribeToSearchChanges();
  }

  /**
   * Need to refresh data when date changes
   */
  subscribeToDateChanges() {
    this.dateService.date$.subscribe(() => {
      this.fetchInitialOrders();
    });
  }

  subscribeToSearchChanges() {
    combineLatest([this.generalSearchTerm$, this.customerSearchTerm$, this.orderSearchTerm$, this.dateRange$])
      .pipe(
        pairwise(),
        filter(([[a, b, c, d], [e, f, g, h]]) => !!a || !!b || !!c || !!d || a !== e || b !== f || c !== g || d !== h),
      )
      .subscribe(() => {
        this.debounceFetchInitial();
      });
  }

  public getLoadTotalWeight$(orderId: number): BehaviorSubject<number> {
    let subject = this.totalWeightMap.get(orderId);
    if (subject) {
      return subject;
    }
    subject = new BehaviorSubject<number>(0);
    const subscription = this.get$(orderId)
      .pipe(
        filter(orders => !!orders),
        mergeMap(order => {
          let total = 0;
          const obs: Observable<ProductSkuModel>[] = [];
          order.orderItems.forEach(orderItem => {
            obs.push(
              this.productSkuService.get$(orderItem.productSkuId).pipe(
                filter(productSku => !!productSku),
                tap(productSku => {
                  // Unit of measurement is in g. Divide by 1000 to upscale to kg
                  total += orderItem.quantity * (productSku.weight / 1000);
                }),
              ),
            );
          });
          return zip(obs).pipe(mergeMap(() => Promise.resolve(total)));
        }),
      )
      .subscribe(res => subject.next(res));
    this.subscriptions.push(subscription);
    this.totalWeightMap.set(orderId, subject);
    return subject;
  }

  public getLoadTotalVolume$(orderId: number): BehaviorSubject<number> {
    let subject = this.totalVolumeMap.get(orderId);
    if (subject) {
      return subject;
    }
    subject = new BehaviorSubject<number>(0);
    const subscription = this.get$(orderId)
      .pipe(
        filter(orders => !!orders),
        mergeMap(order => {
          let total = 0;
          const obs: Observable<ProductSkuModel>[] = [];
          order.orderItems.forEach(orderItem => {
            obs.push(
              this.productSkuService.get$(orderItem.productSkuId).pipe(
                filter(productSku => !!productSku),
                tap(productSku => {
                  // Unit of measurement is in cm. Divide by 100 to upscale to m
                  total += orderItem.quantity * (productSku.length / 100) * (productSku.height / 100) * (productSku.width / 100);
                }),
              ),
            );
          });
          return zip(obs).pipe(mergeMap(() => Promise.resolve(total)));
        }),
      )
      .subscribe(res => subject.next(res));
    this.subscriptions.push(subscription);
    this.totalVolumeMap.set(orderId, subject);
    return subject;
  }

  getSearchQueryAndFilter(): [Record<string, QueryParams[]>, Record<string, QueryParams[]>, Record<string, QueryParams[]>] {
    const fetchSearch: Record<string, QueryParams[]> = {};
    const fetchFilter: Record<string, QueryParams[]> = {};
    const fetchQuery: Record<string, QueryParams[]> = {
      status: ['CONFIRMED', 'PARTIALLY_PROCESSED'],
      'customer.active': [{ operator: '=', value: true }],
    };

    if (this.generalSearchTerm$.value) {
      const term = this.generalSearchTerm$.value;
      fetchSearch['orderCode'] = [term];
      fetchSearch['alias'] = [term];
      fetchSearch['customer.name'] = [term];
      fetchSearch['customer.customerCode'] = [term];
    } else {
      if (this.customerSearchTerm$.value) {
        const term = this.customerSearchTerm$.value;
        fetchFilter['customer.name'] = [term];
        fetchFilter['customer.customerCode'] = [term];
      }
      if (this.orderSearchTerm$.value) {
        const term = this.orderSearchTerm$.value;
        fetchFilter['orderCode'] = [term];
      }
      if (this.dateRange$.value?.to && this.dateRange$.value?.from) {
        const dateRange = this.dateRange$.value;
        fetchFilter['orderDate'] = [{ type: 'date-range', to: dateRange.to, from: dateRange.from }];
      }
    }

    return [fetchSearch, fetchFilter, fetchQuery];
  }

  fetchInitialOrders() {
    this.ordersLoading = true;
    this.currentPage = 0;
    const [fetchSearch, fetchFilter, fetchQuery] = this.getSearchQueryAndFilter();
    // this.eventBus.triggerEvent(new OrdersClearedEvent());
    this.orderList$.next([]);
    this.ovAutoService
      .list({
        entity: OrderModel,
        limit: this.pageSize,
        offset: this.currentPage * this.pageSize,
        keys: [...this.params.keys, 'customer.name', 'customer.code'],
        orderDirection: 'DESC',
        search: fetchSearch,
        filter: fetchFilter,
        query: fetchQuery,
      })
      .then(response => {
        if (response.data.length) {
          this.currentPage += 1;
          this.loadItems(response.data);
          this.orderList$.next(response.data);
        }
      })
      .finally(() => {
        this.ordersLoading = false;
      });
  }

  fetchMoreOrders() {
    this.ordersLoading = true;
    const [fetchSearch, fetchFilter, fetchQuery] = this.getSearchQueryAndFilter();
    this.ovAutoService
      .list({
        entity: OrderModel,
        limit: this.pageSize,
        offset: this.currentPage * this.pageSize,
        keys: this.params.keys,
        orderDirection: 'DESC',
        search: fetchSearch,
        filter: fetchFilter,
        query: fetchQuery,
      })
      .then(response => {
        if (response.data.length) {
          this.currentPage += 1;
          this.loadItems(response.data);
          this.orderList$.next([...this.orderList$.value, ...response.data]);
        }
      })
      .finally(() => {
        this.ordersLoading = false;
      });
  }

  public allocateOrderIds(...ids: number[]): void {
    const newIds = new Set([...this.allocatedOrderIds.value, ...ids]);
    this.allocatedOrderIds.next(newIds);
  }

  public deallocateOrderIds(...ids: number[]): void {
    const newIds = new Set([...this.allocatedOrderIds.value]);
    ids.forEach(id => newIds.delete(id));
    this.allocatedOrderIds.next(newIds);
  }

  public setGeneralSearchTerm(term: string): void {
    this.generalSearchTerm$.next(term || null);
    this.customerSearchTerm$.next(null);
    this.orderSearchTerm$.next(null);
    this.dateRange$.next(null);
  }

  public setCustomerSearchTerm(term: string): void {
    this.customerSearchTerm$.next(term || null);
    this.generalSearchTerm$.next(null);
  }

  public setOrderSearchTerm(term: string): void {
    this.orderSearchTerm$.next(term || null);
    this.generalSearchTerm$.next(null);
  }

  public setDateRange(dateRange: DateRange): void {
    this.dateRange$.next(dateRange || null);
    this.generalSearchTerm$.next(null);
  }

  async splitOrder(orderId: number, firstAlias: string, secondAlias: string, orderItems: SplitParam[]): Promise<void> {
    if (!orderItems.length) {
      return;
    }
    this.ovAutoService.apollo
      .mutate<SplitOrderResponse>({
        mutation: splitOrderGql,
        variables: {
          request: {
            baseOrderId: orderId,
            firstAlias,
            secondAlias,
            orderItems: orderItems.map(o => ({ id: o.orderItem.id, quantity: o.quantity })),
          },
        },
      })
      .subscribe(response => {
        const { baseOrder, firstOrder, secondOrder } = response.data.orderSplit;
        this.handleSplitOrder(baseOrder.id, firstOrder, secondOrder);
      });
  }

  private handleSplitOrder(originalOrderId: number, newOrder1: OrderModel, newOrder2: OrderModel): void {
    this.eventBus.triggerEvent(new OrderSplitEvent(originalOrderId, newOrder1, newOrder2));
    this.loadItems([newOrder1, newOrder2]);
  }

  onDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
