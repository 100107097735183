import { Component } from '@angular/core';
import { LoadAllocation, WaveInstance } from '@ov-suite/models-warehouse';
import { MatCustomDataSource } from '@ov-suite/ui';
import { ColumnData } from '@ov-suite/helpers-shared';
import { OvAutoService } from '@ov-suite/services';
import gql from 'graphql-tag';
import { getKeys } from '@ov-suite/graphql-helpers';
import { getFieldMetadata } from '@ov-suite/ov-metadata';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { PickDetailModel } from '../pick-detail.model';

@Component({
  selector: 'ov-suite-pick-list',
  templateUrl: './pick-list.component.html',
})
export class PickListComponent {
  dataSource: MatCustomDataSource<PickDetailModel> = new MatCustomDataSource<PickDetailModel>([]);

  columnData: ColumnData<PickDetailModel>[] = [
    {
      title: 'Pick Type',
      type: 'other',
      action: item => {
        if (!item.load) {
          return 'Stage Supply';
        }
        if (!item.pickingItem.productSku) {
          return 'Stage Pick';
        }
        return 'Regular Pick';
      },
      keys: [],
      disableSorting: true,
    },
    {
      title: 'Product / Box',
      type: 'other',
      action: ({ pickingItem }) =>
        pickingItem?.productSku?.name ?? pickingItem.containerPicked?.name ?? pickingItem.inventoryPicked?.productSku?.name ?? 'Unknown',
      keys: ['pickingItem.productSku.name'],
      disableSorting: true,
    },
    {
      title: 'Target',
      type: 'other',
      action: item => item.pickingItem?.target?.name ?? item.load?.loadBay?.name ?? 'Unknown',
      keys: [],
      disableSorting: true,
    },
    {
      type: 'number',
      title: 'Quantity',
      key: 'quantity',
      disableSorting: true,
    },
    {
      type: 'other',
      title: 'Order(s)',
      action: item => item.load?.orders.map(order => order.orderCode).join(', ') ?? 'N/A',
      keys: [],
      disableSorting: true,
    },
    {
      type: 'other',
      title: 'Scheduled Time',
      action: item => {
        if (item.load?.wave) {
          return this.getTime(item.load.wave, 'HH:mm DD-MM-YYYY');
        }
        if (item.pickingItem.createdDate) {
          return moment(item.pickingItem.createdDate).format('HH:mm DD-MM-YYYY');
        }
        return '';
      },
      keys: [],
    },
    {
      title: 'Actions',
      type: 'button-bar',
      disableSorting: true,
      buttons: [
        {
          icon: 'fa fa-arrow-right',
          actionType: 'custom',
          classes: 'btn btn-dark btn-sm mx-1',
          action: item => {
            this.router.navigate(['/load-management/load'], { queryParams: { id: item.load.id } });
          },
          disabled: item => !item.load,
        },
      ],
      keys: [],
    },
  ];

  formClass = LoadAllocation;

  constructor(private readonly ovAutoService: OvAutoService, private readonly router: Router, private readonly route: ActivatedRoute) {
    route.params.subscribe(() => {
      this.getData();
    });
  }

  getData() {
    this.ovAutoService.apollo
      .query<{ listAllPickDetails: PickDetailModel[] }>({
        fetchPolicy: 'no-cache',
        query: gql(`query {
        listAllPickDetails {
          ${getKeys({
            name: '',
            input: PickDetailModel,
            metadata: getFieldMetadata(PickDetailModel),
            api: '',
            keys: [
              'quantity',
              'pickingItem',
              'pickingItem.productSku',
              'pickingItem.productSku.name',
              'load',
              'load.id',
              'load.orders',
              'load.orders.orderCode',
              'load.loadBay',
              'load.loadBay.name',
              'pickingItem.inventoryPicked',
              'pickingItem.containerPicked',
              'pickingItem.containerPicked.id',
              'pickingItem.containerPicked.name',
              'pickingItem.target',
              'pickingItem.createdDate',
              'pickingItem.loadId',
              'pickingItem.pickStatus',
              'load.wave',
              'load.wave.startDate',
              'load.wave.waveConfig',
              'load.wave.waveConfig.startTime',
            ],
          })}
        }
      }`),
      })
      .subscribe(response => {
        console.log({ data: response.data.listAllPickDetails });
        this.dataSource.data = response.data.listAllPickDetails;
      });
  }

  getTime(wave: WaveInstance, format: string): string {
    const day = wave.startDate;
    const time = wave.waveConfig.startTime;

    return moment(`${day} ${time}`).format(format);
  }
}
