import { Routes } from '@angular/router';
import { WorkflowProcessListComponent } from './list/workflow-process-list.component';
import { ProcessViewComponent } from './process-view/process-view.component';

export const WorkflowProcessRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: WorkflowProcessListComponent,
      },
      {
        path: ':id',
        component: WorkflowProcessListComponent,
      },
      {
        path: 'view/:id',
        component: ProcessViewComponent,
      },
    ],
  },
];
