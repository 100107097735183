import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PickingItemModel, ProductSkuModel } from '@ov-suite/models-admin';
import { ColumnData, PickStageStatus } from '@ov-suite/helpers-shared';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogData, ConfirmationDialogService, OvAutoService } from '@ov-suite/services';
import gql from 'graphql-tag';
import { DropdownItem, MultiSelectDropdownComponent, CleanTableComponent, MatCustomDataSource } from '@ov-suite/ui';
import { ConfirmationComponent } from '../confirmation-dialog/confirmation.component';
import { AddModalComponent } from './add-modal/add-modal.component';
import { LoadManagementDataService } from '../load-management.data.service';
import { filterEvery, filterMap, filterSet } from '../../../helpers/pick-stage-tab.helpers';

function cleanEnum(input: string) {
  const output = input.toLowerCase();
  return output
    .split('_')
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}

@Component({
  selector: 'ov-suite-picks-tab',
  templateUrl: './picks-tab.component.html',
  styleUrls: ['./picks-tab.component.css'],
})
export class PicksTabComponent implements OnInit, AfterViewInit {
  @ViewChild('cancellablePicksTable') cancellablePicksTable: TemplateRef<unknown>;

  @ViewChild('resettablePicksTable') resettablePicksTable: TemplateRef<unknown>;

  @ViewChild('pausablePicksTable') pausablePicksTable: TemplateRef<unknown>;

  @ViewChild('pickingItemLogHistoryDialog') pickingItemLogHistoryDialog: TemplateRef<unknown>;

  @ViewChild('pickTable') pickTable: CleanTableComponent<PickingItemModel>;

  @ViewChild('productFilter') productFilter: MultiSelectDropdownComponent<ProductSkuModel>;

  @ViewChild('statusFilter') statusFilter: MultiSelectDropdownComponent<PickStageStatus>;

  pickSubscription: Subscription;

  // Filters
  currentStatusFilters: Record<string, boolean> = {};

  currentProductFilters: Record<number, boolean> = {};

  // Dropdown Items
  statusList: DropdownItem<PickStageStatus>[] = [];

  productList: DropdownItem<ProductSkuModel>[] = [];

  // Table Data
  dataSource: MatCustomDataSource<PickingItemModel> = new MatCustomDataSource<PickingItemModel>([]);

  formClass = PickingItemModel;

  fetchDownloadData: BehaviorSubject<PickingItemModel[]> = new BehaviorSubject<PickingItemModel[]>(null);

  columnData: ColumnData<PickingItemModel>[] = [
    // {
    //   title: 'Product',
    //   type: 'deep-string',
    //   key: 'productSku.name',
    // },
    {
      title: 'Product / Pallet',
      type: 'other',
      action: pickingItem =>
        pickingItem?.productSku?.name ?? pickingItem.containerPicked?.name ?? pickingItem.inventoryPicked?.productSku?.name ?? 'Unknown',
      keys: ['pickingItem.productSku.name'],
      disableSorting: true,
    },
    {
      type: 'number',
      title: 'Priority',
      key: 'priority',
    },
    {
      type: 'number',
      title: 'Quantity Picked',
      key: 'quantityPicked',
    },
    {
      type: 'number',
      title: 'Quantity Reserved',
      key: 'quantityReserved',
    },
    {
      type: 'number',
      title: 'Total Quantity',
      key: 'quantity',
    },
    {
      title: 'Status',
      type: 'other',
      action: pick => {
        switch (pick.pickStatus) {
          default:
            return cleanEnum(pick.pickStatus);
        }
      },
      keys: ['pickStatus'],
    },
    {
      title: 'Actions',
      type: 'button-bar',
      disableSorting: true,
      buttons: [
        {
          icon: 'fa fa-eye',
          actionType: 'custom',
          classes: 'btn btn-dark btn-sm mx-1',
          action: item => {
            this.viewPickingItemLogDialog(item.id);
          },
        },
      ],
      keys: [],
    },
  ];

  selected: Set<PickingItemModel> = new Set();

  // READY, IN-PROGRESS picks selected
  cancellablePicks$ = new BehaviorSubject<Set<PickingItemModel>>(new Set());

  cancellablePicksDataSource = new MatCustomDataSource<PickingItemModel>([]);

  // NOT READY, IN_PROGRESS, RESET picks
  resettablePicks$ = new BehaviorSubject<Set<PickingItemModel>>(new Set());

  resettablePicksDataSource = new MatCustomDataSource<PickingItemModel>([]);

  // READY, IN_PROGRESS, MANUALLY_PAUSED, PAUSED
  pausablePicks$ = new BehaviorSubject<Set<PickingItemModel>>(new Set());

  pausablePicksDataSource = new MatCustomDataSource<PickingItemModel>([]);

  // Filter Counts
  statusCounts: Record<string, number> = {};

  productCounts: Record<number, number> = {};

  productMap = new Map<number, ProductSkuModel>();

  constructor(
    public route: ActivatedRoute,
    public loadManagementDataService: LoadManagementDataService,
    public dialog: MatDialog,
    private readonly ovAutoService: OvAutoService,
    private readonly dialogService: ConfirmationDialogService,
  ) {}

  ngAfterViewInit(): void {
    this.initData();
    this.cancellablePicks$.subscribe(picks => {
      this.cancellablePicksDataSource.data = [...picks];
    });
    this.resettablePicks$.subscribe(picks => {
      this.resettablePicksDataSource.data = [...picks];
    });
    this.pausablePicks$.subscribe(picks => {
      this.pausablePicksDataSource.data = [...picks];
    });
  }

  initData() {
    this.pickSubscription?.unsubscribe();

    this.dataSource.sortData = (unused, matSort) => {
      if (matSort.active) {
        return this.filterData(this.loadManagementDataService.picks.value, matSort);
      }

      return unused;
    };
    this.pickSubscription = this.loadManagementDataService.picks.subscribe(value => {
      this.dataSource.data = this.filterData(value);
    });
  }

  filterData(picks: PickingItemModel[], sort?: MatSort): PickingItemModel[] {
    const output = this.applyFilters(picks);

    if (sort && sort.direction) {
      const up = sort.direction === 'asc' ? 1 : -1;
      switch (sort.active) {
        case 'Product':
          output.sort((a, b) => (a.productSku.name.toLowerCase() > b.productSku.name.toLowerCase() ? up : -up));
          break;
        case 'Priority':
          output.sort((a, b) => (a.priority > b.priority ? up : -up));
          break;
        case 'Quantity Picked':
          output.sort((a, b) => (a.quantityPicked > b.quantityPicked ? up : -up));
          break;
        case 'Quantity Reserved':
          output.sort((a, b) => (a.quantityReserved > b.quantityReserved ? up : -up));
          break;
        case 'Total Quantity':
          output.sort((a, b) => (a.quantity > b.quantity ? up : -up));
          break;
        case 'Status':
          output.sort((a, b) => (a.pickStatus > b.pickStatus ? up : -up));
          break;
        default:
      }
    }

    return output;
  }

  ngOnInit(): void {
    this.loadManagementDataService.picks.subscribe(picks => {
      this.setupFilters(picks);
    });
  }

  setupFilters(picks: PickingItemModel[]) {
    this.statusCounts = {};
    this.productCounts = {};
    picks.forEach(pick => {
      this.statusCounts[pick.pickStatus] ??= 0;
      this.statusCounts[pick.pickStatus] += 1;

      this.productCounts[pick.productSku.id] = pick.quantity;

      this.productMap.set(pick.productSku.id, pick.productSku);
    });
    this.statusList = Object.entries(this.statusCounts).map(([status, count]) => {
      return { label: `${cleanEnum(status)} (${count})`, value: status as PickStageStatus };
    });
    this.productList = Object.entries(this.productCounts).map(([productId, count]) => {
      const product = this.productMap.get(Number(productId));
      return { label: `${product.name} (${count})`, value: product };
    });
  }

  setStatusFilter(statuses: PickStageStatus[]) {
    this.currentStatusFilters = {};
    statuses.forEach(status => {
      this.currentStatusFilters[status] = true;
    });
    this.initData();
  }

  setProductFilters(productSkus: ProductSkuModel[]) {
    this.currentProductFilters = {};
    productSkus.forEach(sku => {
      this.currentProductFilters[sku.id] = true;
    });
    this.initData();
  }

  applyFilters(picks: PickingItemModel[]): PickingItemModel[] {
    return picks.filter(pick => {
      let shouldFilter = true;
      if (Object.values(this.currentStatusFilters).length) {
        shouldFilter = shouldFilter && !!this.currentStatusFilters[pick.pickStatus];
      }
      if (Object.values(this.currentProductFilters).length) {
        shouldFilter = shouldFilter && !!this.currentProductFilters[pick.productSku.id];
      }
      return shouldFilter;
    });
  }

  openAddModal() {
    const dialogRef = this.dialog.open(AddModalComponent, {
      data: {
        loadId: this.loadManagementDataService.load.value.id,
        pickingItems: this.dataSource.data,
      },
    });
    dialogRef.afterClosed().subscribe(async refresh => {
      if (refresh) {
        await this.loadManagementDataService.getPicks();
      }
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Cancel',
        text: `Are you sure you want to cancel the selected picks? (${this.cancellablePicks$.value.size} Total)`,
        component: this.cancellablePicksTable,
      },
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const pickIds = filterMap(this.cancellablePicks$.value, pick => pick.id);

        this.ovAutoService.apollo
          .mutate({
            mutation: gql`
              mutation cancelPickingItems($pickIds: [Int!]!) {
                cancelPickingItems(pickIds: $pickIds)
              }
            `,
            variables: {
              pickIds: [...pickIds],
            },
          })
          .subscribe(async () => {
            this.pickTable.clearSelection();
            await this.loadManagementDataService.getPicks();
          });
      }
    });
  }

  reset() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: 'Reset',
        text: `Are you sure you want to reset the selected picks? (${this.resettablePicks$.value.size} Total)`,
        component: this.resettablePicksTable,
      },
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const pickIds = filterMap(this.resettablePicks$.value, pick => pick.id);
        this.ovAutoService.apollo
          .mutate({
            mutation: gql`
              mutation resetPickingItems($pickIds: [Int!]!) {
                resetPickingItems(pickIds: $pickIds)
              }
            `,
            variables: {
              pickIds: [...pickIds],
            },
          })
          .subscribe(async () => {
            this.pickTable.clearSelection();
            await this.loadManagementDataService.getPicks();
          });
      }
    });
  }

  pause() {
    const shouldUnpause = this.shouldUnpause();
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: shouldUnpause ? 'Unpause' : 'Pause',
        text: `Are you sure you want to ${shouldUnpause ? 'unpause' : 'pause'} the selected picks? (${
          this.pausablePicks$.value.size
        } Total)`,
        component: this.pausablePicksTable,
      },
    });
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const pickIds = filterMap(this.pausablePicks$.value, pick => pick.id);
        this.ovAutoService.apollo
          .mutate({
            mutation: gql`
            mutation pausePickingItems($pickIds: [Int!]!) {
              ${shouldUnpause ? 'unpausePickingItems' : 'pausePickingItems'}(pickIds: $pickIds)
            }
          `,
            variables: {
              pickIds: [...pickIds],
            },
          })
          .subscribe(async () => {
            this.pickTable.clearSelection();
            await this.loadManagementDataService.getPicks();
          });
      }
    });
  }

  clearFilters() {
    this.currentStatusFilters = {};
    this.currentProductFilters = {};
    this.productFilter.clearSelection();
    this.statusFilter.clearSelection();
    this.initData();
  }

  setSelected(picks: Set<PickingItemModel>) {
    const cancellablePicks = filterSet(picks, pick => pick.pickStatus === 'READY' || pick.pickStatus === 'IN_PROGRESS');
    const resettablePicks = filterSet(
      picks,
      pick => pick.pickStatus !== 'READY' && pick.pickStatus !== 'IN_PROGRESS' && pick.pickStatus !== 'RESET',
    );
    const pausablePicks = filterSet(picks, pick => {
      switch (pick.pickStatus) {
        case PickStageStatus.READY:
        case PickStageStatus.IN_PROGRESS:
        case PickStageStatus.PAUSED:
        case PickStageStatus.MANUALLY_PAUSED:
          return true;
        default:
          return false;
      }
    });
    this.selected = picks;
    this.cancellablePicks$.next(cancellablePicks);
    this.resettablePicks$.next(resettablePicks);
    this.pausablePicks$.next(pausablePicks);
  }

  shouldUnpause(): boolean {
    if (!this.pausablePicks$.value.size) {
      return false;
    }
    return filterEvery(
      this.pausablePicks$.value,
      pick => pick.pickStatus === PickStageStatus.PAUSED || pick.pickStatus === PickStageStatus.MANUALLY_PAUSED,
    );
  }

  async fetchData() {
    this.fetchDownloadData.next(this.dataSource.data);
  }

  viewPickingItemLogDialog(pickingId: number) {
    this.loadManagementDataService.fetchPickingItemLog(pickingId);

    const confirmationDialogData: ConfirmationDialogData = {
      title: 'Picking Item Log',
      component: this.pickingItemLogHistoryDialog,
      width: 500,
    };

    this.dialogService.openComponentDialog(confirmationDialogData);
  }
}
