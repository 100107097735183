/* eslint-disable @typescript-eslint/naming-convention */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Environment, setEnvironment } from '@ov-suite/helpers-shared/lib/environments/environment';
import moment from 'moment';
import { AppModule } from './app/app.module';
import '@angular/compiler';

Date.prototype.toJSON = function () {
  return moment(this).format();
};

fetch('/assets/config.json', {
  method: 'GET',
})
  .then(response => {
    if (response.ok) {
      return response.json();
    }
    return Promise.reject(response);
  })
  .then((data: Environment) => {
    localStorage.setItem('config', JSON.stringify(data));

    if (data.production) {
      enableProdMode();
    }
    setEnvironment(data);

    // Sentry.init({
    //   dsn: 'https://0b7b4582bf074070bce339393d86b0c5@sentry.linksuite.io/2',
    //   integrations: [
    //     // @ts-ignore
    //     new Integrations.BrowserTracing({
    //       tracingOrigins: ['localhost', environment.webUrl.admin, environment.webUrl.idm],
    //       routingInstrumentation: Sentry.routingInstrumentation,
    //     }),
    //   ],
    //
    //   // We recommend adjusting this value in production, or using tracesSampler
    //   // for finer control
    //   release: 'adminlink-web-1.2.3',
    //   environment: environment?.webUrl?.admin ? environment.webUrl.admin : 'dev',
    //   tracesSampleRate: 1.0,
    // });

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  })
  .catch(error => {
    console.warn('Something went wrong.', error);
  });
