import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { setContext } from '@apollo/client/link/context';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from '@ov-suite/authguard-angular/lib/authentication/authentication.service';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

@NgModule({
  imports: [BrowserModule, HttpClientModule, ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      deps: [HttpLink, AuthenticationService],
      useFactory(httpLink: HttpLink, authenticationService: AuthenticationService) {
        const authLink = setContext(async (_, { headers }) => {
          try {
            const token = await authenticationService.getToken();
            if (token) {
              return {
                headers: {
                  ...headers,
                  authorization: `Bearer ${token}`,
                },
              };
            }
            return headers;
          } catch (e) {
            console.error(e);
            return headers;
          }
        });

        return {
          cache: new InMemoryCache(),
          link: authLink.concat(
            httpLink.create({
              uri: uri => `${getUrl('api')}/graphql/graphql-${uri.operationName}`,
            }),
          ),
        };
      },
    },
  ],
})
export class GraphQLModule {}
