import { Component, TemplateRef, ViewChild } from '@angular/core';
import { LoadAllocation } from '@ov-suite/models-warehouse';
import { HierarchyTab } from '@ov-suite/ui';
import { ColumnData, QueryParams } from '@ov-suite/helpers-shared';
import { ConfirmationDialogData, ConfirmationDialogService } from '@ov-suite/services';

@Component({
  selector: 'ov-suite-completed-load-list',
  templateUrl: './completed-load-list.component.html',
})
export class CompletedLoadListComponent {
  @ViewChild('invoiceDownloadDialog') invoiceDownloadDialog: TemplateRef<unknown>;

  selectedOrderIds: number[] = [];

  selectedLoadId: number;

  tabs: HierarchyTab[] = [
    { title: 'Active', path: '/load-management/active' },
    { title: 'Completed', path: '/load-management/completed' },
  ];

  filter: Record<string, QueryParams[]> = {
    completeDate: [{ operator: 'IS NOT', value: null }],
  };

  formClass = LoadAllocation;

  columnData: ColumnData<LoadAllocation>[] = [
    {
      type: 'number',
      key: 'id',
      title: 'Load ID',
    },
    {
      type: 'deep-string',
      key: 'loadBay.name',
      title: 'Load Bay',
    },
    {
      type: 'deep-string',
      key: 'wave.waveConfig.waveName',
      title: 'Wave',
    },
    {
      type: 'date',
      key: 'date',
      title: 'Load Date',
      format: 'DD/MM/YYYY',
    },
    {
      type: 'date-time',
      key: 'completeDate',
      title: 'Completed Date',
      // format: 'DD/MM/YYYY',
    },
    {
      type: 'deep-string',
      key: 'vehicle.registration',
      title: 'Vehicle Registration',
      disableSorting: true,
    },
    {
      type: 'number',
      key: 'orderIds',
      title: 'Order ID',
      disableFiltering: true,
    },
    {
      title: 'Actions',
      type: 'buttons',
      disableSorting: true,
      buttons: [
        {
          actionType: 'routeLink',
          classes: 'btn-sm',
          title: 'View',
          queryParams: data => ({ id: data.id }),
          routerLink: () => ['load-management/load'],
        },
        {
          title: 'Invoice',
          classes: 'btn-sm',
          icon: 'fa fa-file-text-o',
          action: data => {
            this.openDownloadModal(data.id, data.orderIds);
          },
        },
      ],
      keys: [],
    },
  ];

  constructor(private readonly confirmationDialogService: ConfirmationDialogService) {}

  openDownloadModal(loadId: number, orderIds: number[]) {
    // Set global selectedOrderIds.
    this.selectedOrderIds = orderIds;
    this.selectedLoadId = loadId;

    const dialogData: ConfirmationDialogData = {
      title: `Invoice Download`,
      component: this.invoiceDownloadDialog,
      preventClose: true,
      width: 550,
    };

    this.confirmationDialogService.openComponentDialog(dialogData);
  }
}
