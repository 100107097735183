import {
  FieldMetadata,
  FieldParamsConstructor,
  getFieldMetadata,
  Constructor,
  GenericHierarchy,
  getTypeMetadata,
  HasId,
  PageGet,
  SingleCreate,
  SingleDelete,
  SingleGet,
  SingleUpdate,
} from '@ov-suite/ov-metadata';
import { DocumentNode, GraphQLResolveInfo, SelectionNode } from 'graphql';
import gql from 'graphql-tag';
import { OrderModel } from '@ov-suite/models-order';

export interface Query {
  [key: string]: unknown;
}

export interface DynamicService<T extends GenericHierarchy> {
  list: PageGet<T>;
  get: SingleGet<T>;
  create: SingleCreate<T>;
  update: SingleUpdate<T>;
  delete: SingleDelete<T>;
}

export function getUpdate<T extends GenericHierarchy>(newItem: T, oldItem: T): Partial<T> & { id: string | number } {
  const update = { ...newItem };
  const metadata = getFieldMetadata(newItem.constructor as Constructor<T>);
  metadata.fields.forEach(field => {
    if (field.unnecessary) {
      delete update[field.propertyKey];
    } else if (field.readonly) {
      delete update[field.propertyKey];
    } else if ((field as FieldParamsConstructor).withQuantity) {
      delete update[field.propertyKey];
      if (newItem[field.propertyKey]) {
        update[`${field.propertyKey}QuantityList`] = newItem[field.propertyKey].map(obj => {
          const { id, quantity, ...otherFields } = obj;
          const quantityField = { id, quantity };
          Object.keys(otherFields).forEach(key => {
            if (otherFields[key]) {
              quantityField[`${key}Id`] = otherFields[key].id;
            }
          });
          return quantityField;
        });
      }
    } else if (typeof field.type === 'function' && Array.isArray(field.type())) {
      delete update[field.propertyKey];
      if (newItem[field.propertyKey]) {
        update[`${field.propertyKey}IdList`] = newItem[field.propertyKey].map(obj => obj.id);
      }
    } else if (typeof field.type === 'function' && (typeof field.type() === 'function' || typeof field.type() === 'string')) {
      const value = update[field.propertyKey];
      if (newItem[field.propertyKey]?.id !== oldItem[field.propertyKey]?.id) {
        update[`${field.propertyKey}Id`] = value?.id ?? null;
      }
      delete update[field.propertyKey];
    } else if (field.type === 'permission') {
      delete update[field.propertyKey];

      update[`${field.propertyKey}IdList`] = newItem[field.propertyKey].map(userTypeFeature => ({
        id: userTypeFeature.id,
        featureId: userTypeFeature.feature.id,
        permission: userTypeFeature.permission,
      }));
    } else if (field.type === 'boolean') {
      if (!!newItem[field.propertyKey] === !!oldItem[field.propertyKey]) {
        delete update[field.propertyKey];
      } else {
        update[field.propertyKey] = !!update[field.propertyKey];
      }
    } else if (field.type === 'domain-selector') {
      if (field.isAuthenticationService) {
        update[`${field.propertyKey}IdList`] = update[field.propertyKey].map(obj => obj.id);
      } else {
        update[`${field.propertyKey}IdList`] = update[field.propertyKey].map(obj => obj.path);
      }
      delete update[field.propertyKey];
    } else if (newItem[field.propertyKey] === oldItem[field.propertyKey]) {
      delete update[field.propertyKey];
    } else if (field.type === 'customer-staging-type-dialog') {
      update[field.propertyKey] = (newItem ?? oldItem)[field.propertyKey].map(obj => ({
          id: obj.id,
          stageTypeId: obj.stageTypeId,
          instruction: obj.instruction,
      }))
    } else if (field.type === 'map') {
      if ((newItem ?? oldItem)[field.propertyKey]) {
        update[field.propertyKey] = {
          line1: (newItem ?? oldItem)[field.propertyKey].line1,
          line2: (newItem ?? oldItem)[field.propertyKey].line2,
          line3: (newItem ?? oldItem)[field.propertyKey].line3,
          line4: (newItem ?? oldItem)[field.propertyKey].line4,
          line5: (newItem ?? oldItem)[field.propertyKey].line5,
          line6: (newItem ?? oldItem)[field.propertyKey].line6,
          postalCode: (newItem ?? oldItem)[field.propertyKey].postalCode,
          geography: (newItem ?? oldItem)[field.propertyKey].geography
            ? JSON.stringify((newItem ?? oldItem)[field.propertyKey].geography)
            : null,
        };
      }
    }
  });

  update.id = oldItem.id;
  return update;
}

export function getCreate<T extends GenericHierarchy>(item: T): T {
  const output = { ...item };
  const metadata = getFieldMetadata<T>(item.constructor as Constructor<T>);
  metadata.fields.forEach(field => {
    if (field.unnecessary) {
      delete output[field.propertyKey];
    } else if (field.generated) {
      delete output[field.propertyKey];
    } else if ((field as FieldParamsConstructor).withQuantity) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[`${field.propertyKey}QuantityList`] = item[field.propertyKey].map(obj => {
          const { quantity, ...otherFields } = obj;
          const quantityField = { quantity };
          Object.keys(otherFields).forEach(key => {
            quantityField[`${key}Id`] = otherFields[key].id;
          });
          return quantityField;
        });
      }
    } else if (typeof field.type === 'function' && Array.isArray(field.type())) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[`${field.propertyKey}IdList`] = item[field.propertyKey].map(obj => obj.id);
      }
    } else if (field.type === 'permission') {
      delete output[field.propertyKey];
      output[`${field.propertyKey}IdList`] = item[field.propertyKey].map(userTypeFeature => ({
        featureId: userTypeFeature.feature.id,
        permission: userTypeFeature.permission,
      }));
    } else if (typeof field.type === 'function' && (typeof field.type() === 'function' || typeof field.type() === 'string')) {
      delete output[field.propertyKey];
      if (item[field.propertyKey]) {
        output[`${field.propertyKey}Id`] = item[field.propertyKey].id;
      } else if (item[field.propertyKey] === null) {
        output[`${field.propertyKey}Id`] = null;
      }
    } else if (field.type === 'boolean') {
      output[field.propertyKey] = !!output[field.propertyKey];
    } else if (field.type === 'domain-selector') {
      delete output[field.propertyKey];
      if (field.isAuthenticationService) {
        output[`${field.propertyKey}IdList`] = item[field.propertyKey].map(obj => obj.id);
      } else {
        output[`${field.propertyKey}IdList`] = item[field.propertyKey]?.map(obj => obj.path);
      }
    } else if (field.type === 'customer-staging-type-dialog') {
      if (item[field.propertyKey] !== null) {
        output[field.propertyKey] = output[field.propertyKey].map(obj => ({
          stageTypeId: obj.stageTypeId,
          instruction: obj.instruction,
        }))
      }
    } else if (field.type === 'map') {
      if (item[field.propertyKey]) {
        output[field.propertyKey] = {
          line1: item[field.propertyKey].line1,
          line2: item[field.propertyKey].line2,
          line3: item[field.propertyKey].line3,
          line4: item[field.propertyKey].line4,
          line5: item[field.propertyKey].line5,
          line6: item[field.propertyKey].line6,
          postalCode: item[field.propertyKey].postalCode,
          geography: item[field.propertyKey].geography ? JSON.stringify(item[field.propertyKey].geography) : null,
        };
      }
    }
  });
  return output;
}

interface ListWithCountQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  specificKeys?: string[];
  api?: string;
}

export function listWithCountQueryKeys<T extends HasId>(params: ListWithCountQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  const keys = getKeys<T>(params);

  const header = `${name}($orderDirection: String, $orderColumn: String, $filter: String, $limit: Int, $offset: Int)`;
  const request = `${name}(orderDirection: $orderDirection, orderColumn: $orderColumn, filter: $filter, limit: $limit, offset: $offset)`;
  const body = `data { ${keys} } totalCount`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface ListQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  specificKeys?: string[];
  keys?: string[];
  relations?: string[];
}

interface CustomQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  specificKeys?: string[];
  keys?: string[];
  paramsName: string;
  paramsType: string;
}

export function listQueryKeys<T extends HasId>(params: ListQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  const keys = getKeys(params);

  const header = `${name}($params: ListParamsInput!)`;
  const request = `${name}(params: $params)`;
  const body = `data { ${keys} } totalCount`;
  return gql(`query ${header} { ${request} { ${body} } }`);
}

// TODO: Evaluate Use
export function getQueryString(documentNode: DocumentNode): string {
  return documentNode.loc?.source.body;
}

interface GetAncestorQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
}

export function getAncestorQueryKeys<T>(params: GetAncestorQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  return gql(`query ${name}($id: Int!) {\n ${name}(id: $id) {\nid\nname\nchildren { id name }\n}\n}`);
}

interface GetQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
  relations?: string[];
}

export function getQueryKeys<T extends HasId>(params: GetQueryKeysParams<T>): DocumentNode {
  const { name } = params;
  const keys = getKeys(params);

  const header = `${name}($id: Int!)`;
  const request = `${name}(id: $id)`;
  const body = `${keys}`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface GetByIdsQueryKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
  relations?: string[];
}

export function getByIdsQueryKeys<T extends HasId>(params: GetByIdsQueryKeysParams<T>): DocumentNode {
  const { name, input } = params;
  const keys = getKeys({
    ...params,
    input,
  });

  const header = `${name}($ids: [Int!]!)`;
  const request = `${name}(ids: $ids)`;
  const body = `${keys}`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface GetQueryKeysStringParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
}

// TODO: Evaluate Use
export function getQueryKeysString<T extends HasId>(params: GetQueryKeysStringParams<T>): DocumentNode {
  const { name, input } = params;
  const keys = getAllKeys({
    ...params,
    input,
  });

  const header = `${name}($id: String!)`;
  const request = `${name}(id: $id)`;
  const body = `${keys}`;

  return gql(`query ${header} { ${request} { ${body} } }`);
}

interface CreateMutationKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
  /** Returns the new entity as a model. Default true */
  // TODO make default false.
  refreshModel?: boolean;
}

export function createMutationKeys<T extends HasId>(params: CreateMutationKeysParams<T>): DocumentNode {
  const { name, metadata, refreshModel = true } = params;
  let keys: string;

  if (!refreshModel) {
    keys = 'id';
  } else {
    keys = getKeys(params);
  }

  const header = `${name}($data: ${metadata.name}CreateInput!)`;
  const request = `${name}(data: $data)`;
  const body = `${keys}`;

  return gql(`mutation ${header} { ${request} { ${body} } }`);
}

export function createBulkInsertKeys<T extends HasId>(metadata: FieldMetadata<T>): DocumentNode {
  const header = `bulk${metadata.name}Insert($data: [${metadata.name}CreateInput!]!)`;
  const request = `bulk${metadata.name}Insert(data: $data)`;

  return gql(`mutation ${header} { ${request} }`);
}

export interface UpdateMutationKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  keys?: string[];
  /** Returns the new entity as a model. Default true */
  // TODO make default false.
  refreshModel?: boolean;
}

export function updateMutationKeys<T extends HasId>(params: UpdateMutationKeysParams<T>): DocumentNode {
  const { name, metadata, refreshModel = true } = params;
  let keys: string;

  if (!refreshModel) {
    keys = 'id';
  } else {
    keys = getKeys(params);
  }

  const header = `${name}($data: ${metadata.name}UpdateInput!)`;
  const request = `${name}(data: $data)`;
  const body = `${keys}`;

  return gql(`mutation ${header} { ${request} { ${body} } }`);
}

interface DeleteMutationKeysParams {
  name: string;
}

export function deleteMutationKeys(params: DeleteMutationKeysParams): DocumentNode {
  const { name } = params;
  return gql(`mutation ${name}($id: Int!) {\n ${name}(id: $id)\n}`);
}

interface GetAllKeysParams<T> {
  name: string;
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  repeatList?: string[];
}

export function getAllKeys<T extends HasId>(params: GetAllKeysParams<T>): string {
  const { name, input, metadata, repeatList = [] } = params;
  const lines: string[] = [];

  const fields = metadata.fields.filter(item => !item.unnecessary);

  fields.forEach(field => {
    const noRepeat = repeatList.includes(`${metadata.name}.${field.propertyKey}`);
    if (typeof field.type === 'string') {
      switch (field.type) {
        case 'boolean':
        case 'date':
        case 'date-time':
        case 'time':
        case 'number':
        case 'string':
        case 'json':
        case 'image':
          lines.push(field.propertyKey);
          break;
        case 'map':
          lines.push(field.propertyKey);
          lines.push(' {\n id\nline1\nline2\nline3\nline4\nline5\nline6\npostalCode\ngeography\n}\n');
          break;
        case 'domain-selector':
          lines.push(field.propertyKey);
          lines.push(' {\n id\nname\npath\n}\n');
          break;
        case 'permission':
          lines.push(field.propertyKey);
          lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
          break;
        // Ignore case "Title"
        default:
      }
    } else if (!noRepeat) {
      if (field.idOnly) {
        lines.push(field.propertyKey);
      } else {
        const { metadata: newMetadata } = typeof field.type === 'function' ? getTypeMetadata(field.type) : null;
        lines.push(`${field.propertyKey} { `);
        if ((field as FieldParamsConstructor)?.keys?.length) {
          lines.push((field as FieldParamsConstructor).keys.filter(key => !key.includes('.')).join('\n'));
          const deepKeys = (field as FieldParamsConstructor)?.keys.filter(key => key.includes('.'));
          if (deepKeys.length) {
            deepKeys.forEach(key => {
              const arrayKeys = key.split('.');
              let gqlLine = arrayKeys.join(' {\n');
              gqlLine += '\n}'.repeat(arrayKeys.length - 1);
              lines.push(gqlLine);
            });
          }
        } else {
          const newRepeatList = [...repeatList];
          newRepeatList.push(`${metadata.name}.${field.propertyKey}`);
          const { entity: fieldType } = getTypeMetadata(field.type);
          lines.push(
            getAllKeys({
              name,
              input,
              metadata: newMetadata,
              repeatList: newRepeatList,
            }),
          );
        }
        lines.push('}');
      }
    }
  });
  return lines.join('\n');
}

interface GetSpecificKeysParams<T> {
  name?: string;
  input: Constructor<T>;
  keys: string[];
  metadata?: FieldMetadata<T>;
  repeatList?: string[];
}

// export function getSpecificKeys<T extends HasId>(params: GetSpecificKeysParams<T>): string {
//   const { input, keys, metadata, name, repeatList = [] } = params;
//   const lines: string[] = [];
//
//   const localMetadata = metadata ?? getFieldMetadata(input);
//   const localName = name ?? localMetadata.plural;
//
//   let fields = localMetadata.fields.filter(item => !item.unnecessary);
//
//   const currentKeys = keys.map(key => key.split('.')[0]);
//
//   fields = fields.filter(field => currentKeys.includes(field.propertyKey));
//
//   fields.forEach(field => {
//     const noRepeat = repeatList.includes(`${metadata.name}.${field.propertyKey}`);
//     if (typeof field.type === 'string') {
//       switch (field.type) {
//         case 'boolean':
//         case 'date':
//         case 'date-time':
//         case 'time':
//         case 'number':
//         case 'numbers':
//         case 'string':
//         case 'json':
//         case 'image':
//           lines.push(field.propertyKey);
//           break;
//         case 'map':
//           lines.push(field.propertyKey);
//           lines.push(' {\n line1\nline2\nline3\nline4\nline5\nline6\npostalCode\ngeography\n}\n');
//           break;
//         case 'domain-selector':
//           lines.push(field.propertyKey);
//           lines.push(' {\n id\nname\npath\n}\n');
//           break;
//         case 'permission':
//           lines.push(field.propertyKey);
//           lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
//           break;
//         // Ignore case "Title"
//         default:
//       }
//     } else if (!noRepeat) {
//       if (field.idOnly) {
//         lines.push(field.propertyKey);
//       } else {
//         const { metadata: newMetadata } = getTypeMetadata(field.type);
//         lines.push(`${field.propertyKey} { `);
//         const { entity: fieldType } = typeof field.type === 'function' ? getTypeMetadata(field.type) : null;
//         const newKeys = keys
//           .filter(key => key.startsWith(`${field.propertyKey}.`))
//           .map(key => key.split('.').slice(1).join('.'))
//           .filter(key => !!key);
//
//         const newRepeatList = [...repeatList];
//         newRepeatList.push(`${metadata.name}.${field.propertyKey}`);
//         if (!newKeys.length) {
//           lines.push(
//             getAllKeys({
//               name: localName,
//               input,
//               metadata: newMetadata,
//               repeatList: newRepeatList,
//             }),
//           );
//         } else {
//           lines.push(
//             getSpecificKeys({
//               input,
//               repeatList: newRepeatList,
//               keys: newKeys,
//               metadata: newMetadata,
//               name: localName,
//             }),
//           );
//         }
//         // }
//         lines.push('}');
//       }
//     }
//   });
//   return lines.join('\n');
// }

type AllParams<T> =
  | (GetQueryKeysParams<T> & { type: 'get' })
  | (ListQueryKeysParams<T> & { type: 'list' })
  | (CustomQueryKeysParams<T> & { type: 'custom' })
  | (DeleteMutationKeysParams & { type: 'delete' })
  | (UpdateMutationKeysParams<T> & { type: 'update' })
  | (CreateMutationKeysParams<T> & { type: 'create' });

export type MultipleKeysParams<T> = Record<string, AllParams<T>>;

export function multipleQueryKeys<T extends HasId>(params: MultipleKeysParams<T>): DocumentNode {
  const variableParams: string[] = [];
  const query: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    let filler: string;
    let method: string;
    let content = '';

    switch (value.type) {
      case 'get':
        variableParams.push(`$${key}Data: Int!`);
        method = `${value.name}(id: $${key}Data)`;
        break;
      case 'create':
        variableParams.push(`$${key}Data: ${value.metadata.name}CreateInput!`);
        method = `${value.name}(data: $${key}Data!)`;
        break;
      case 'list':
        variableParams.push(`$${key}Data: ListParamsInput!`);
        method = `${value.name}(params: $${key}Data)`;
        break;
      case 'update':
        variableParams.push(`$${key}Data: ${value.metadata.name}UpdateInput!`);
        method = `${value.name}(data: $${key}data)`;
        break;
      case 'delete':
        variableParams.push(`$${key}Data: Int!`);
        method = `${value.name}(id: $${key}Data)`;
        break;
      case 'custom':
        variableParams.push(`$${key}Data: ${value.paramsType}!`);
        method = `${value.name}(${value.paramsName}: $${key}Data)`;
        break;
      default:
    }

    if (value.type !== 'delete') {
      filler = getKeys(value);
    }

    switch (value.type) {
      case 'get':
      case 'custom':
      case 'create':
      case 'update':
        content = `{ ${filler} }`;
        break;
      case 'list':
        content = `{ data { ${filler} } totalCount }`;
        break;
      default:
    }

    const str = `${key}: ${method} ${content}\n`;
    query.push(str);
  });

  const headerParams = variableParams.join(', ');
  const body = query.join('\n');

  return gql(`query Multiple(${headerParams}) { ${body} }`);
}

export function multipleMutationKeys<T extends HasId>(params: MultipleKeysParams<T>): DocumentNode {
  const variableParams: string[] = [];
  const query: string[] = [];

  Object.entries(params).forEach(([key, value]) => {
    let filler: string;
    let method: string;
    let content = '';

    switch (value.type) {
      case 'create':
        variableParams.push(`$${key}Data: ${value.metadata.name}CreateInput!`);
        method = `${value.name}(data: $${key}Data)`;
        break;
      case 'update':
        variableParams.push(`$${key}Data: ${value.metadata.name}UpdateInput!`);
        method = `${value.name}(data: $${key}Data)`;
        break;
      case 'delete':
        variableParams.push(`$${key}Data: Int!`);
        method = `${value.name}(id: $${key}Data)`;
        break;
      default:
    }

    if (value.type !== 'delete') {
      filler = getKeys(value);
    }

    switch (value.type) {
      case 'create':
      case 'update':
        content = `{ ${filler} }`;
        break;
      default:
    }

    const str = `${key}: ${method} ${content}\n`;
    query.push(str);
  });

  const headerParams = variableParams.join(', ');
  const body = query.join('\n');

  return gql(`mutation Multiple(${headerParams}) { ${body} }`);
}

interface GetKeysParams<T> {
  specificKeys?: string[];
  keys?: string[];
  input: Constructor<T>;
  metadata: FieldMetadata<T>;
  api?: string;
  name: string;
  relations?: string[];
}

export function getKeys<T extends HasId>(params: GetKeysParams<T>) {
  const { specificKeys, input, metadata, name, api, keys, relations } = params;
  const keysToUse = specificKeys ?? keys;
  const builder = new KeyListBuilder(input);
  if (relations?.length) {
    builder.setRelations(relations);
  } else if (keysToUse?.length) {
    builder.setKeys(keysToUse);
  }
  return builder.getString();
}

export function getRequestedFields(info: GraphQLResolveInfo): string[] {
  const output: string[] = [];

  const getKeys = (input: readonly SelectionNode[]): string[] => {
    const strOutput: string[] = [];
    input.forEach(node => {
      if (node.kind === 'Field') {
        const name = node.alias?.value ?? node.name.value;
        strOutput.push(name);
        if (node.selectionSet) {
          const keys = getKeys((node.selectionSet?.selections as SelectionNode[]) ?? []).map(item => `${name}.${item}`);
          strOutput.push(...keys);
        }
      }
    });
    return strOutput;
  };

  info.fieldNodes.forEach(selection => {
    output.push(...getKeys(selection.selectionSet.selections));
  });

  return output;
}

export function parseMutationDomains<T>(original: unknown, _data: unknown): T {
  if (original['domains']) {
    _data['domainsIdList'] = original['domains'].map(domain => domain.path);
    return _data as T;
  }
  return _data as T;
}

class KeyListBuilder<T> {

  keys: string[];

  relations: string[];

  metadata: FieldMetadata<T>;

  depthLimit: number = 2;

  constructor(public model: Constructor<T>) {
    this.metadata = getFieldMetadata(model);
  }

  setRelations(relations: string[]): this {
    this.relations = relations;
    return this;
  }

  setKeys(keys: string[]): this {
    this.keys = keys;
    return this;
  }

  setDepthLimit(limit: number): this {
    this.depthLimit = limit;
    return this;
  }


  getString() {
    const lines: string[] = [];

    let fields = this.metadata.fields;

    if (this.keys) {
      const currentKeys = this.keys.map(key => key.split('.')[0]);
      fields = fields.filter(field => currentKeys.includes(field.propertyKey));
    } else {
      fields = fields.filter(item => !item.unnecessary);
    }

    fields.forEach(field => {
      if (typeof field.type === 'string') {
        switch (field.type) {
          case 'boolean':
          case 'date':
          case 'date-time':
          case 'time':
          case 'number':
          case 'numbers':
          case 'string':
          case 'json':
          case 'image':
            lines.push(field.propertyKey);
            break;
          case 'map':
            lines.push(field.propertyKey);
            lines.push(' {\n line1\nline2\nline3\nline4\nline5\nline6\npostalCode\ngeography\n}\n');
            break;
          case 'domain-selector':
            if (this.relations) {
              if (this.relations.includes(field.propertyKey)) {
                lines.push(field.propertyKey);
                lines.push(' {\n id\nname\npath\n}\n');
              }
            }
            if (this.keys) {
              if (this.keys.some(key => key.startsWith(field.propertyKey))) {
                lines.push(field.propertyKey);
                lines.push(' {\n id\nname\npath\n}\n');
              }
            }
            break;
          case 'customer-staging-type-dialog':
            lines.push(field.propertyKey);
            lines.push(' {\n id\ninstruction\nstageType { id\nname\n }\n}');
            // lines.push(' {\n id\ninstruction\n}');
            break;
          case 'permission':
            lines.push(field.propertyKey);
            lines.push(' {\nid\n feature {\n id\n}\npermission\n}\n');
            break;
          // Ignore case "Title"
          default:
        }
      } else {
        // Means this is a relation of some kind
        if (field.idOnly) {
          lines.push(field.propertyKey);
          return;
        }

        /** Use keys specified in @OVField if the specific keys are not specified */
        if (!this.keys?.length && (field as FieldParamsConstructor).keys?.length) {
          const tempMap: Record<string, any> = {}
          lines.push(`${field.propertyKey} {`);
          (field as FieldParamsConstructor).keys.forEach(key => {
            const split = key.split('.');
            if (split.length === 1) {
              lines.push(split[0]);
            } else {
              let carrying = tempMap;
              split.forEach((item, index) => {
                carrying[item] ??= {};
                if (index === split.length - 1) {
                  carrying[item] = null;
                }
                carrying = carrying[item];
              })
            }
          })

          function recurring(input: Record<string, any>): void {
            Object.keys(input).forEach(key => {
              if (input[key]) {
                lines.push(`${key} {`);
                recurring(input[key]);
                lines.push('}');
              } else {
                lines.push(key);
              }
            })
          }

          recurring(tempMap);

          lines.push('}')
          return;
        }

        const { entity } = getTypeMetadata(field.type);
        const child = new KeyListBuilder(entity);

        // Use Relations
        if (this.relations) {
          if (!this.relations.includes(field.propertyKey)) {
            return; // Ignored by relations
          }

          const childRelations = this.relations.filter(rel => rel.startsWith(`${field.propertyKey}.`)).map(rel => rel.slice(field.propertyKey.length + 1))
          child.setRelations(childRelations);
          const childString = child.getString();
          if (childString) {
            lines.push(`${field.propertyKey} { ${childString} }`);
          }

          return;
        }

        // Use Keys
        if (this.keys) {
          if (!this.keys.some(key => key.startsWith(field.propertyKey))) {
            return; // Ignored by keys
          }

          const childKeys = this.keys.filter(key => key.startsWith(`${field.propertyKey}.`)).map(key => key.slice(field.propertyKey.length + 1))
          child.setKeys(childKeys);
          const childString = child.getString();
          if (childString) {
            lines.push(`${field.propertyKey} { ${childString} }`);
          }

          return;
        }

        // Use Default
        if (this.depthLimit > 0) {
          child.setDepthLimit(this.depthLimit - 1);
          const childString = child.getString();
          if (childString) {
            lines.push(`${field.propertyKey} { ${childString} }`);
          }
        }
      }
    });
    return lines.join('\n');
  }
}
