import { BehaviorSubject, combineLatest, filter, mergeMap, Observable, of, tap } from 'rxjs';
import { VehicleSimple } from '@ov-suite/models-admin';
import { LoadAllocationVehicleService } from './load-allocation.vehicle.service';
import { LoadAllocationExternalVehicleService } from './load-allocation.external-vehicle.service';
import { LoadAllocationLoadAllocationService } from './load-allocation.load-allocation.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoadAllocationVehicleHelperService {
  private simpleList$: BehaviorSubject<VehicleSimple[]>;

  constructor(
    private readonly vehicleService: LoadAllocationVehicleService,
    private readonly externalVehicleService: LoadAllocationExternalVehicleService,
    private readonly loadAllocationService: LoadAllocationLoadAllocationService,
  ) {}

  public listSimple$(): BehaviorSubject<VehicleSimple[]> {
    if (this.simpleList$) {
      return this.simpleList$;
    }
    this.simpleList$ = new BehaviorSubject<VehicleSimple[]>(null);
    combineLatest([
      this.vehicleService.list$(),
      this.loadAllocationService.list$().pipe(
        filter(allocations => !!allocations),
        mergeMap(allocations => {
          const externalVehicleIds = allocations.filter(allocation => allocation.externalVehicleId).map(all => all.externalVehicleId);
          if (!externalVehicleIds.length) {
            return of([]);
          }
          return this.externalVehicleService.getMany$(externalVehicleIds).pipe(tap(console.log));
        }),
      ),
    ])
      .pipe(
        mergeMap(([internal, external]) => {
          internal ??= [];
          external ??= [];
          return Promise.resolve([...internal.map(v => v.getSimple()), ...external.map(v => v.getSimple())]);
        }),
      )
      .subscribe(result => this.simpleList$.next(result));
    return this.simpleList$;
  }

  public getSimple$(vehicleId: number, externalVehicleId: number): Observable<VehicleSimple> {
    if (vehicleId) {
      return this.vehicleService.get$(vehicleId).pipe(mergeMap(vehicle => Promise.resolve(vehicle.getSimple())));
    }
    return this.externalVehicleService.get$(externalVehicleId).pipe(mergeMap(vehicle => Promise.resolve(vehicle.getSimple())));
  }
}
