<div class="planner-container">
  <div class="table-planner">
    <div class="planner-main">
      <div class="planner-corner">Load Bay</div>
      <div class="planner-head" #topRow (mousedown)="dragService.dragX($event)">
        <div
          class="planner-cell"
          *ngFor="let waves of dataService.waveDays?.value || []"
          [ngStyle]="{ 'min-width.px': waves.length * 150 - 2 }"
        >
          <div class="date-subsection">
            {{ waves[0].startDate | waveDate }}
          </div>
          <div class="header-waves-container">
            <div class="wave-item" *ngFor="let wave of waves">
              <div class="wave-name">
                {{ wave.waveConfig.waveName }}
              </div>
              <div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <mat-icon class="fa fa-ellipsis-v"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item
                          [disabled]="!baseService.permissions.create"
                          (click)="dataService.releaseWave(wave)"
                  >
                    Release Wave For <strong style="color: red">Picking</strong>
                  </button>
                  <button mat-menu-item
                          [disabled]="!baseService.permissions.create"
                          (click)="dataService.releaseWaveForStaging(wave)"
                  >
                    Release Wave For <strong style="color: red">Staging</strong>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="text-center" *ngIf="!dataService.waveDays?.value?.length">
      <h5 class="text-capitalize">No waves Available for this date range</h5>
    </span>
    <div class="planner-main">
      <div class="planner-bay-column" #leftColumn (mousedown)="dragService.dragY($event)">
        <div *ngFor="let bay of dataService.loadBays.value" class="planner-cell" style="background: #ebedf0">
          {{ bay.name }}
        </div>
      </div>
      <div #body class="planner-body">
        <span *ngFor="let bay of dataService.loadBays.value" class="planner-row">
          <ng-container *ngFor="let waves of dataService.waveDays.value">
            <div *ngFor="let wave of waves" class="planner-cell planner-cell-content">
              <ov-suite-wave-allocation-wave-bay [wave]="wave" [loadBay]="bay"> </ov-suite-wave-allocation-wave-bay>
            </div>
          </ng-container>
        </span>
      </div>
    </div>
  </div>
</div>
