import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from '@ov-suite/ui';
import { ProcessDefinitionRoutes } from './process-definition.routing';
import { ProcessDefinitionComponent } from './process-definition-component/process-definition.component';
import { ProcessDefinitionService } from './process-definition.service';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ProcessDefinitionRoutes), FormsModule, UiModule],
  declarations: [ProcessDefinitionComponent],
  providers: [ProcessDefinitionService],
})
export class ProcessDefinitionModule {}
