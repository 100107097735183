<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
  <li [ngbNavItem]="1">
    <a ngbNavLink>External Vehicles List</a>
    <ng-template ngbNavContent>
      <div>
        <ov-suite-hierarchy-table
          [title]="'External Vehicles'"
          [formClass]="formClass"
          [hasBulkUpload]="false"
          (itemSelect)="onSelect($event)"
          [excludeColumns]="2"
          [selectableRows]="true"
          [hideAddButton]="true"
        >
        </ov-suite-hierarchy-table>
        <button type="button" (click)="activeModal.close()" class="btn btn-outline-primary ml-4 mb-4 float-left">Close</button>
        <button type="button" (click)="onAddToLoad()" class="btn btn-outline-dark mr-4 mb-4 float-right">
          Add To Load <i class="ml-1 fa fa-arrow-right" aria-hidden="true"> </i>
        </button>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Add Vehicle</a>
    <ng-template ngbNavContent>
      <div class="external-vehicle-container-form">
        <div class="modal-header pt-3 text-left">
          <h5 class="modal-title" id="modal-basic-title">Create External Vehicle</h5>
          <p class="p-1">
            Please note, this vehicle is added Once off, Once the load is completed the vehicle is no longer available. If you want to use
            this vehicle again, save this to a vehicle template.
          </p>
        </div>
        <div class="modal-body">
          <ov-suite-form
            #form
            [formClass]="formClass"
            [data]="externalVehicle"
            [overrideCancel]="true"
            (cancelChange)="onCancel()"
            (save)="onSave($event)"
            [showSave]="true"
            [sideBarMetadata]="null"
          ></ov-suite-form>
        </div>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
