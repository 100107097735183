import { OVField, OVEntity, OVTable, OVSearchable, OVSearch } from '@ov-suite/ov-metadata';
import { CustomerModel, DomainModel } from '@ov-suite/models-admin';
import moment from 'moment/moment';
import { OrderPriorityConstant, randToString } from '@ov-suite/helpers-shared';
import { AddressModel } from '@ov-suite/models-shared';
import { OrderItemModel } from './order-item.model';
import { OrderCommentModel } from './order-comment.model';
import { SalesRepModel } from './sales-rep.model';
import { UserModel } from '@ov-suite/models-idm';

export interface SplitParam {
  orderItem: OrderItemModel;
  quantity?: number;
}

export const orderStatusMap = {
  AWAITING_CONFIRMATION: 'Awaiting Confirmation',
  CONFIRMED: 'Confirmed',
  PLANNED: 'Planned',
  PROCESSING: 'Processing',
  COMPLETED: 'Complete',
};

@OVEntity({ name: 'Order' })
@OVTable<OrderModel>([
  {
    key: 'id',
    title: 'Order ID',
    type: 'number',
  },
  {
    key: 'orderCode',
    title: 'Order Code',
    type: 'string',
  },
  {
    title: 'Customer Code',
    type: 'deep-string',
    key: 'customer.customerCode',
  },
  {
    title: 'Customer Name',
    type: 'deep-string',
    key: 'customer.name',
  },
  {
    key: 'orderDate',
    title: 'Order Date',
    type: 'date',
  },
  {
    key: 'completedDate',
    title: 'Completed Date',
    type: 'date',
    hideColumnKey: 'completedDate',
    disableFiltering: true,
  },
  {
    title: 'SLA',
    type: 'other',
    keys: [''],
    action: order => {
      if (!order.fulfilmentDate || !order.orderDate) {
        return '0 days';
      }
      return `${moment(order?.fulfilmentDate).diff(moment(order?.orderDate), 'days')} days`
    },
    disableFiltering: true,
    disableSorting: true,
  },
  {
    title: 'Priority',
    filterKey: 'priority',
    disableFiltering: true,
    keys: ['priority', 'orderItems'], // using orderItems for the popup
    type: 'other',
    action: order => `<span [class]="'badge '+ ${OrderPriorityConstant[order?.priority]?.color}">
            ${OrderPriorityConstant[order?.priority]?.text ?? 'Standard'} </span>`,
  },
])
@OVSearch<OrderModel>({ searchKeys: ['customer.name', 'customer.customerCode', 'orderCode'] })
export class OrderModel {
  @OVField({ type: 'string' })
  id: number;

  @OVField({ type: 'string' })
  specialInstructions?: string;

  @OVField({ type: 'string' })
  fulfilmentDate: string;

  @OVField({ type: 'string' })
  orderDate: string;

  @OVField({ type: 'string' })
  completedDate: string;

  @OVField({ type: 'string' })
  approvalDate: string;

  @OVSearchable()
  @OVField({ type: 'string' })
  orderCode: string;

  @OVField({ type: 'number' })
  priority: number;

  @OVField({ type: 'number' })
  customerId: number;

  @OVField({ type: () => CustomerModel })
  customer: CustomerModel;

  @OVField({
    type: () => [OrderItemModel],
    keys: [
      'id',
      'quantity',
      'productSku.id',
      'productSku.sku',
      'productSku.name',
      'productSku.category.id',
      'productSku.category.path',
      'productSkuId',
      'unitPriceExcl',
      'unitPriceIncl'
    ],
  })
  orderItems: OrderItemModel[];

  @OVField({
    type: () => [DomainModel],
    selectionType: 'multiple',
    title: 'Domains',
    required: true,
  })
  domains: DomainModel[];

  @OVField({ type: () => [OrderCommentModel] })
  comments: OrderCommentModel[];

  @OVField({ type: 'number' })
  salesRepId: number;

  @OVField({ type: () => SalesRepModel })
  salesRep: SalesRepModel;

  @OVField({ type: 'date-time' })
  createDate: string;

  @OVField({ type: 'string' })
  externalOrderNo: string;

  @OVField({ type: 'map' })
  deliveryAddress: AddressModel;

  @OVField({ type: 'string' })
  status: string;

  @OVField({ type: 'number' })
  originalOrderId: number;

  @OVField({ type: () => UserModel })
  createdByUser: UserModel;

  @OVField({ type: () => OrderModel })
  originalOrder: OrderModel;

  @OVField({ type: 'boolean' })
  isSplit: boolean;

  @OVField({ type: 'string' })
  alias: string;

  @OVField({ type: 'string' })
  syncDate: string;

  @OVField({ type: 'string'})
  integrationException: string;

  @OVField({ type: 'string'})
  integrationId: string;

  @OVField({ type: 'boolean'})
  captureSupergroup: boolean;

  getTotalPriceExcl(): number {
    return this.orderItems.reduce((p, c) => p + c.getTotalPriceExcl(), 0)
  }

  getTotalPriceIncl(): number {
    return this.orderItems.reduce((p, c) => p + c.getTotalPriceIncl(), 0)
  }

  split(splitParams: SplitParam[]): OrderModel {
    const { orderItems, ...remaining } = this;
    const output = Object.assign(new OrderModel(), { ...remaining, orderItems: [] });
    delete output.id;

    splitParams.forEach(param => {
      const found = this.orderItems.find(l => l.id === param.orderItem.id);
      if (found) {
        const cloned = Object.assign(new OrderItemModel(), found);
        delete cloned.id;
        cloned.quantity = param.quantity;
        output.orderItems.push(cloned);
        found.quantity -= param.quantity;
      }
    });
    this.orderItems = this.orderItems.filter(o => o.quantity > 0);

    return output;
  }
}
