import { Component, OnInit } from '@angular/core';
import { SplitParam } from '@ov-suite/models-order';
import { BehaviorSubject } from 'rxjs';
import { LoadAllocationOrderService } from '../../services/data-services/load-allocation.order.service';
import { LoadAllocationViewService } from '../../services/view-services/load-allocation.view.service';
import { LoadAllocationProductSkuService } from '../../services/data-services/load-allocation.product-sku.service';

interface LocalSplitParam extends SplitParam {
  originalAmount: number;
}

@Component({
  selector: 'ov-suite-new-load-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss'],
})
export class SplitComponent implements OnInit {
  firstAlias: string;

  secondAlias: string;

  displayedColumns: string[] = ['name', 'quantity', 'type', 'split1', 'split2'];

  dataSource$: BehaviorSubject<LocalSplitParam[]> = new BehaviorSubject(null);

  constructor(
    private readonly orderService: LoadAllocationOrderService,
    private readonly viewService: LoadAllocationViewService,
    private readonly productSkuService: LoadAllocationProductSkuService,
  ) {}

  ngOnInit(): void {
    this.orderService.get$(this.viewService.selectedOrderDTO$.value.orderId).subscribe(order => {
      this.firstAlias = order.alias ?? 'Part 1';
      this.secondAlias = 'Part 2';
      const splitParams: LocalSplitParam[] = order.orderItems.map(orderItem => ({
        orderItem,
        quantity: 0,
        originalAmount: orderItem.quantity,
      }));
      this.dataSource$.next(splitParams);
    });
  }

  async split() {
    const splitParams = this.dataSource$.value.filter(param => param.quantity);
    await this.orderService.splitOrder(this.viewService.selectedOrderDTO$.value.orderId, this.firstAlias, this.secondAlias, splitParams);
  }
}
