<table mat-table [dataSource]="dataSource" class="mat-elevation-z80">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Order Code -->
  <ng-container matColumnDef="orderCode">
    <th mat-header-cell *matHeaderCellDef> Order Code </th>
    <td mat-cell *matCellDef="let invoice"> {{ invoice.orderCode }} </td>
  </ng-container>

  <!-- Invoice Number -->
  <ng-container matColumnDef="invoiceNumber">
    <th mat-header-cell *matHeaderCellDef> Invoice Number </th>
    <td mat-cell *matCellDef="let invoice"> {{ invoice.invoiceNumber }} </td>
  </ng-container>

  <!-- Icon with tool tip -->
  <ng-container matColumnDef="exception">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let invoice">
      <ng-container [ngSwitch]="invoice.invoiceStatus">
        <i *ngSwitchCase="'EXCEPTION'"
           class="fa fa-exclamation-triangle invoice-exception"
           [matTooltip]="invoice.exception"
           matTooltipPosition="left"
        ></i>
        <i *ngSwitchCase="'GENERATING'"
           class="fa fa-arrow-circle-right invoice-generating"
           matTooltip="Generating Invoice"
           matTooltipPosition="left"
        ></i>
        <i *ngSwitchCase="'SUCCESS'"
           class="fa fa-check-circle invoice-success"
           matTooltip="Invoice Creation Success"
           matTooltipPosition="left"
        ></i>
      </ng-container>
    </td>
  </ng-container>

  <!-- Action -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let invoice">
      <button *ngIf="invoice.invoiceStatus === 'EXCEPTION'"
              (click)="reloadInvoice($event, invoice)"
              mat-stroked-button
      >
        RELOAD
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns;"
      (click)="selection.toggle(row)">
  </tr>

<!--  <tr mat-footer-row *matFooterRowDef="['downloadInvoice']"></tr>-->
</table>

<!-- Download Invoice(s) -->
<div class="download-section">
  <button mat-stroked-button
          [disabled]="selection.isEmpty()"
          (click)="onDownloadInvoices()"
  >
    Download Invoice(s)
  </button>
</div>
