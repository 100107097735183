<div class="main-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <!-- Load -->
        <mat-card>
          <mat-card-content>
            <div *ngIf="(dataService.load | async) as load" class="list-space-between">
              <mat-card-title class="h6">Load</mat-card-title>
              <mat-card-subtitle>ID: {{ load.id }}</mat-card-subtitle>
              <p> <strong>Planned Date</strong> {{ load.date | date }}   </p>
              <p> <strong>Commit Date</strong> {{ load.commitDate | date:'medium' }}  </p>
              <p> <strong>Pick Release Date</strong> {{ load.releaseDate | date:'medium' }}  </p>
              <p *ngIf="load.completeDate as completeDate"> <strong>Completion Date</strong> {{ completeDate | date:'medium' }} </p>
              <p *ngIf="(dataService.picks | async).length || (dataService.stagingItems | async).length">
                <strong>Pick Progress</strong>
                <a [innerHTML]="renderPickStatus()"></a>
              </p>
            </div>
          </mat-card-content>
          <mat-divider inset></mat-divider>
          <mat-card-actions align="end" >
            <ng-container *ngIf="!dataService.load.value?.completeDate else completedActions">
              <button mat-button (click)="completeLoad()" color="primary" type="button">Complete</button>
              <button mat-button (click)="onHandleLoadDelay()" color="primary" type="button">Delay</button>
              <button mat-button (click)="onHandleLoadRestart()" color="warn" type="button">Restart</button>
              <button mat-button (click)="onHandleLoadCancel()" color="warn" type="button">Cancel</button>
            </ng-container>
            <ng-template #completedActions>
              <button mat-button (click)="reopenLoad(reopenCheck)" color="warn" type="button">Reopen</button>
            </ng-template>
          </mat-card-actions>
          <mat-card-footer>
            <mat-progress-bar *ngIf="dataService.loading" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
        <!--  Orders -->
        <mat-card class="mt-2">
          <mat-card-title class="h6">Orders</mat-card-title>
          <mat-card-subtitle>{{ orderDataSource.data.length < 2 ? 'Single Order' : 'Multiple Orders' }}</mat-card-subtitle>
          <mat-card-content>
            <ov-suite-clean-table
              [dataSource]="orderDataSource"
              [columnData]="orderColumnData"
              [disablePaging]="true"
              [disableSorting]="true"
              emptyMessage="No Customers Recorded"
            >
            </ov-suite-clean-table>
          </mat-card-content>
          <mat-card-footer>
            <mat-progress-bar *ngIf="dataService.loadingOrders" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
        <!--  Load Bay-->
        <mat-card class="mt-2">
          <mat-card-title-group>
            <mat-card-title class="h6">Load Bay</mat-card-title>
            <mat-card-subtitle>{{(dataService.inventoryLocation | async)?.name }}</mat-card-subtitle>
            <mat-card-subtitle>Minimum Load Bay Capacity: {{ dataService.load.value?.loadBayMinCapacity }}</mat-card-subtitle>
          </mat-card-title-group>
          <mat-card-actions align="end"  *ngIf="!dataService.load.value?.completeDate">
            <button mat-button [disabled]="!palletsToRemove.length" (click)="onRemovePallets()" color="warn">
              Clear Selected
            </button>

          </mat-card-actions>
          <mat-card-content>
            <ov-suite-clean-table
              *ngIf="dataService.load | async as load"
              [dataSource]="containerDataSource"
              [columnData]="inventoryContainerColumnData"
              [pageSizeOptions]="[5, 10, 15]"
              [selectable]="!load.completeDate"
              (selected)="onPalletSelect($event)"
              emptyMessage="No Containers"
            >
            </ov-suite-clean-table>
          </mat-card-content>
          <mat-card-actions align="end" *ngIf="!(dataService.load | async)?.completeDate">
            <button mat-button (click)="onHandleChangeLoadBay()" color="primary">Change</button>
          </mat-card-actions>
          <mat-card-footer>
            <mat-progress-bar *ngIf="dataService.loadingLoadBay" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>
      </div>
      <div class="col-md-5">
        <!--  Vehicle-->
        <mat-card>
          <mat-card-title class="h6">Vehicle</mat-card-title>
          <ng-container *ngIf="(dataService.vehicle | async) as vehicle">
            <mat-card-subtitle>{{ vehicle.registration }}</mat-card-subtitle>
            <mat-card-content>
              <div class="list-space-between">
                <p><strong>Name</strong> {{ vehicle.name }}</p>
                <p><strong>Class</strong> {{ vehicle.class?.name }}</p>
              </div>
            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions align="end" *ngIf="!(dataService.load | async)?.completeDate">
              <button mat-button (click)="onChangeVehicle()" color="primary">Change</button>
            </mat-card-actions>
          </ng-container>
          <mat-card-footer>
            <mat-progress-bar *ngIf="dataService.loadingVehicle" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>

        <mat-card class="mt-2">
          <mat-card-title class="h6">Vehicle Driver</mat-card-title>
          <ng-container *ngIf="(dataService.load | async) as load">
            <ng-container>
              <ov-suite-load-management-vehicle-truck-driver
                [driverName]="load?.truckDriverName"
                (updateTruckDriver)="updateVehicleDriver($event)"
              >
              </ov-suite-load-management-vehicle-truck-driver>
            </ng-container>
            <mat-card-footer>
              <mat-progress-bar *ngIf="dataService.loading"  mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
          </ng-container>
        </mat-card>

        <!-- Wave -->
        <mat-card class="mt-2">
          <mat-card-title class="h6">Wave</mat-card-title>
          <ng-container *ngIf="(dataService.wave | async) as wave">
            <mat-card-subtitle>{{wave.waveConfig?.waveName }}</mat-card-subtitle>
            <mat-card-content>
              <div class="list-space-between">
                <p><strong>Date</strong> {{wave.startDate | date:"mediumDate" }}</p>
                <p><strong>Time</strong> {{wave.waveConfig?.startTime  }}</p>
              </div>
            </mat-card-content>
            <ng-container *ngIf="!(dataService.load | async)?.completeDate">
              <mat-divider inset></mat-divider>
              <mat-card-actions align="end" >
                <button (click)="onChangeWave()" mat-button color="primary">Change</button>
              </mat-card-actions>
            </ng-container>
          </ng-container>
        </mat-card>

        <!--  Users-->
        <mat-card class="mt-2">
          <mat-card-title class="h6">Users</mat-card-title>
          <mat-card-content>
            <ov-suite-clean-table
              [dataSource]="loadersDataSource"
              [columnData]="loadersColumnData"
              [disablePaging]="true"
              [disableSorting]="true"
              emptyMessage="No Users Recorded"
            >
            </ov-suite-clean-table>
          </mat-card-content>
          <mat-card-footer>
            <mat-progress-bar *ngIf="dataService.loading"  mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
        </mat-card>

        <!--  Load Details-->
        <ng-container *ngIf="(dataService.load | async)?.details as details">
          <mat-card class="mt-2" *ngIf="details.length" >
            <mat-card-title class="h6">Details</mat-card-title>
            <ng-container>
              <ov-suite-load-management-details-card
                [details]="details"
                [orders]="orderDataSource.data"
                (updateSealNumberEmitter)="updateSealNumber($event)"
              >
              </ov-suite-load-management-details-card>
            </ng-container>
            <mat-card-footer>
              <mat-progress-bar *ngIf="dataService.loading"  mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
          </mat-card>
        </ng-container>
      </div>

    </div>
  </div>
</div>

<ng-template #reopenCheck>
  <mat-checkbox labelPosition="before" [ngModel]="restartIncompletePicks" (click)="restartIncompletePicks = !restartIncompletePicks">Restart incomplete picks?</mat-checkbox>
</ng-template>
