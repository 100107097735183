import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { UiModule } from '@ov-suite/ui';
import { OrderRoutes } from './order.routes';
import { OrderDetailsPopupComponent } from './order-details-popup/order-details-popup.component';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(OrderRoutes), FormsModule, JwBootstrapSwitchNg2Module, UiModule],
  declarations: [OrderDetailsPopupComponent],
})
export class OrderModule {}
