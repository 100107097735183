export function doubleToRand(amount: number): number {
  return Math.round(amount * 100) / 100;
}

export function doubleToRandString(amount: number): string {
  return randToString(doubleToRand(amount));
}

export function randToString(amount: number): string {
  let [int, dec] = amount.toLocaleString().replace(',', ' ').split('.');
  dec ??= '00';
  return `R&nbsp${int}.${dec.padEnd(2, '0')}`
}
