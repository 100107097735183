import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowProcess } from '@ov-suite/models-shared';
import { environment } from '@ov-suite/helpers-shared/lib/environments/environment';
import { WorkflowProcessService } from '../workflow-process.service';
import { getUrl } from '@ov-suite/helpers-angular/lib/get-url.helper';

@Component({
  selector: 'ov-suite-process-view',
  templateUrl: './process-view.component.html',
  styleUrls: ['./process-view.component.css'],
})
export class ProcessViewComponent implements OnInit {
  id: string;

  data: WorkflowProcess;

  apiUrl: string;

  constructor(protected route: ActivatedRoute, protected router: Router, protected workflowProcessService: WorkflowProcessService) {
    this.apiUrl = getUrl('api');
    this.route.params.subscribe(param => {
      this.id = param['id'];
    });
  }

  ngOnInit() {
    this.workflowProcessService.getById(this.id).subscribe(response => {
      this.data = response.data['getProcessInstance'] as WorkflowProcess;
    });
  }

  onDelete(id: string) {
    const agree = confirm('Are you sure you want to delete this process?');
    if (agree) {
      this.workflowProcessService.deleteProcess(id).subscribe(() => {
        this.router.navigate(['workflow-processes']);
      });
    }
  }
}
