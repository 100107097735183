import { OVEntity, OVField } from '@ov-suite/ov-metadata';
import { UserModel } from '@ov-suite/models-idm';
import { OrderModel } from '@ov-suite/models-order';

@OVEntity({ name: 'LoadDetail' })
export class LoadDetailModel {
  @OVField({ type: 'number' })
  id: number;

  @OVField({ type: 'string' })
  title: string;

  @OVField({ type: 'string' })
  value: string;

  @OVField({ type: 'date-time' })
  createDate: Date;

  @OVField({ type: 'number' })
  createdById: number;

  @OVField({ type: () => UserModel })
  createdBy: UserModel;

  @OVField({ type: () => OrderModel, keys: ['id', 'orderCode'] })
  order: OrderModel;

  @OVField({ type: 'number' })
  orderId: number;
}
